import React from 'react'
import HelpSupportProvider from './Context/HelpSupportProvider'
import { DialogHelpSupport } from './Content/DialogHelpSupport'

const HelpSupport = ({ isLogin, open, setOpen }) => {
    return (
        <HelpSupportProvider isLogin={isLogin}>
            <DialogHelpSupport /* open={open} setOpen={setOpen} */ /* userName={isLogin ? localStorage.getItem('userName') : ''} */ />
        </HelpSupportProvider>
    )
}

export default HelpSupport