import {
  alpha, Avatar, Badge, Box, Button, Divider, IconButton, List, ListItem, ListItemAvatar,
  Popover, Tooltip, Typography, useTheme
} from '@mui/material';
import { useEffect, useCallback, useRef, useState } from 'react';
import NotificationsActiveTwoToneIcon from '@mui/icons-material/NotificationsActiveTwoTone';
import Link from '@mui/material/Link';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { formatDistance, subSeconds, differenceInSeconds } from 'date-fns';
import { enUS, es } from 'date-fns/locale';
import { db, DeleteDoc, IFirestoreQuery, queryDynamic, updateData } from 'src/utils/firebase';
import { collection, query, where, onSnapshot, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';
import InventoryIcon from '@mui/icons-material/Inventory';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import moment from 'moment';

const NotificationsBadge = styled(Badge)(
  ({ theme }) => `
    
    .MuiBadge-badge {
        background-color: ${theme.palette.error.main};
        color: ${theme.palette.error.contrastText};
        min-width: 18px; 
        height: 18px;
        padding: 0;

        &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            box-shadow: 0 0 0 1px ${alpha(theme.palette.error.main, 0.3)};
            content: "";
        }
    }
`
);

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    margin-left: ${theme.spacing(1)};
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    transition: ${theme.transitions.create(['background', 'color'])};

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`
);

function HeaderNotifications() {

  const theme = useTheme();
  const location = useLocation();
  let typeSidebar = location.pathname.split('/')[1]
  const ref = useRef<any>(null);
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState<boolean>(false);
  const [listChatBot, setListChatBot] = useState<any>(null)
  const [countNotif, setCountNotif] = useState<number>(0);
  const [listInventory, setListInventory] = useState<any>(null)
  const [listBudget, setListBudget] = useState<any>(null)
  const [countInven, setCountInven] = useState<number>(0)
  const [countBudget, setCountBudget] = useState<number>(0)
  const [countCreditAlert, setCountCreditAlert] = useState<number>(0)
  const [reload, setReload] = useState<boolean>(false)
  const locales = { en: enUS, es: es }

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const getChats = useCallback(async () => {
    if (reload) {
      setReload(false)
    }
    try {
      const restName = localStorage.getItem("restName")
      const q = query(collection(db, `/${restName}/conversations/conversations`), where("viewed", "==", false));
      const chatsData: any = await new Promise(function (resolve, reject) {
        onSnapshot(q, (querySnapshot) => {
          let count: number = 0
          let arrayData = [];
          querySnapshot.forEach((doc) => {
            count++
            let data = doc.data()
            if (data.channel === "callWaiter") {
              data.id = doc.id
              let array = []
              let existTableNumber = arrayData.find(u => u.tableNumber === data.tableNumber)
              if (existTableNumber) {
                array = existTableNumber.data
                array.push(data)
              } else {
                array.push(data)
              }
              array.sort((a, b) => (a.id > b.id ? 1 : -1));
              let newElement = {
                tableNumber: data.tableNumber,
                id: doc.id,
                data: array
              }
              if (existTableNumber) {
                arrayData = arrayData.map(u => u.tableNumber !== data.tableNumber ? u : newElement);
              } else {
                arrayData.push(newElement)
              }
            } else {
              const phone = doc.data().phone
              data.id = doc.id
              let array = []
              let existPhone = arrayData.find(u => u.phone === phone)
              if (existPhone) {
                array = existPhone.data
                array.push(data)
              } else {
                array.push(data)
              }
              array.sort((a, b) => (a.id > b.id ? 1 : -1));
              let newElement = {
                phone: phone,
                id: doc.id,
                data: array
              }
              if (existPhone) {
                arrayData = arrayData.map(u => u.phone !== phone ? u : newElement);
              } else {
                arrayData.push(newElement)
              }
            }
          })
          arrayData.sort((a, b) => (a.id < b.id ? 1 : -1));
          resolve(arrayData)
          setCountNotif(count)
          setListChatBot(arrayData)
        })
      })
      const q2 = query(collection(db, `/${restName}/inventory/notifications`), where("viewed", "==", false));
      const inventoryData: any = await new Promise(function (resolve, reject) {
        onSnapshot(q2, (querySnapshot) => {
          let arrayData = [];
          let count: number = 0
          querySnapshot.forEach((doc) => {
            count++
            let data = doc.data()
            data.id = doc.id
            data.date = new Date(doc.data().date)
            arrayData.push(data)
          })
          arrayData.sort((a, b) => (a.date < b.date ? 1 : -1))
          setCountInven(count)
          setListInventory(arrayData)
          resolve(arrayData)
        })
      })
      const q3 = query(collection(db, `/${restName}/expenses/budget`), where("notification", "==", true));
      const budgetData: any = await new Promise(function (resolve, reject) {
        onSnapshot(q3, (querySnapshot) => {
          let arrayData = [];
          let count: number = 0
          querySnapshot.forEach((doc) => {
            count++
            let data = doc.data()
            data.id = doc.id
            data.date = new Date(doc.data().date)
            arrayData.push(data)
          })
          arrayData.sort((a, b) => (a.date < b.date ? 1 : -1))
          // console.log(arrayData)
          setCountBudget(count)
          setListBudget(arrayData)
          resolve(arrayData)
        })
      })
      const q4 = query(collection(db, `/${restName}/expenses/budget`), where("dateBudgetFinish", "<", moment(new Date()).format("YYYY-MM-DD HH:mm:ss")), where("dateBudgetFinish", "!=", '0'));
      await new Promise(function (resolve, reject) {
        onSnapshot(q4, (querySnapshot) => {
          let arrayData = [];
          querySnapshot.forEach(async (doc) => {
            // console.log(doc.data())
            // if(doc.data().category !== 'Costo O&E'){
            await updateData(`${restName}/expenses/budget`, doc.id, { disabled: true })
            // }
          })
          resolve(arrayData)
        })
      })
      const q5 = query(collection(db, `/${restName}/expenses/budget`), where("disabled", "==", false));
      await new Promise(function (resolve, reject) {
        const datteNew = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
        onSnapshot(q5, (querySnapshot) => {
          let arrayDataCount = 0;
          querySnapshot.forEach(async (doc) => {
            if (doc.data().restartDate === 'Day') {
              const datte = moment(doc.data().date).add(1, 'days').format("YYYY-MM-DD HH:mm:ss")
              if (datteNew > datte) {
                arrayDataCount++
                await updateData(`${restName}/expenses/budget`, doc.id, { remainingBudget: 0, percentageUsed: 0, date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), notification: false })
              }
            } else if (doc.data().restartDate === 'Week') {
              const datte = moment(doc.data().date).add(1, 'week').format("YYYY-MM-DD HH:mm:ss")
              if (datteNew > datte) {
                arrayDataCount++
                await updateData(`${restName}/expenses/budget`, doc.id, { remainingBudget: 0, percentageUsed: 0, date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), notification: false })
              }
            } else if (doc.data().restartDate === 'Month') {
              const datte = moment(doc.data().date).add(1, 'months').format("YYYY-MM-DD HH:mm:ss")
              if (datteNew > datte) {
                arrayDataCount++
                await updateData(`${restName}/expenses/budget`, doc.id, { remainingBudget: 0, percentageUsed: 0, date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), notification: false })
              }
            } else if (doc.data().restartDate === 'Year') {
              const datte = moment(doc.data().date).add(1, 'years').format("YYYY-MM-DD HH:mm:ss")
              if (datteNew > datte) {
                arrayDataCount++
                await updateData(`${restName}/expenses/budget`, doc.id, { remainingBudget: 0, percentageUsed: 0, date: moment(new Date()).format("YYYY-MM-DD HH:mm:ss"), notification: false })
              }
            }
          })
          resolve(arrayDataCount)
        })
      })
      // console.log('first')
      await new Promise(function (resolve, reject) {
        onSnapshot(doc(db, restName, "credit"), (doc) => {
          let count: number = 0
          // console.log("Current data: ", doc.data());
          if (doc.data() && doc.data().alert > doc.data().amount) {
            count++
          }
          setCountCreditAlert(count)
          resolve(count)
        });
      })
      if (ref.current) {
        setListChatBot(chatsData)
        setListInventory(inventoryData)
        setListBudget(budgetData)
      }
    } catch (err) {
      console.error(err);
    }
  }, [reload])

  useEffect(() => {
    getChats()
  }, [getChats]);

  const handleMessenger = (isCallWaiter: boolean) => {
    if (isCallWaiter) {
      navigate(`/${typeSidebar}/management/waiter-call`)
    } else {
      navigate(`/${typeSidebar}/applications/messenger`)
    }
    setOpen(false);
  }

  const handleInventory = async (item: string, document: string) => {
    localStorage.setItem("inventoryNotificationItem", item)
    localStorage.setItem("inventoryNotificationDoc", document)
    navigate(`/${typeSidebar}/management/commerce/products`)
    setOpen(false);
  }

  const handleBudget = async (item: string, document: string) => {
    // localStorage.setItem("inventoryNotificationItem", item)
    // localStorage.setItem("inventoryNotificationDoc", document)
    navigate(`/${typeSidebar}/dashboards/Accounting/Budget`)
    setOpen(false);
  }

  const { t }: { t: any } = useTranslation();

  const cleanNotification = async () => {
    const restName = localStorage.getItem("restName")

    // const q = query(collection(db, `/${restName}/conversations/conversations`), where("viewed", "==", false))
    const arrayConditionsConversations: IFirestoreQuery[] = [
      { property: "viewed", operator: "==", value: false }
    ]
    const dataConversations = await queryDynamic(`/${restName}/conversations/conversations`, arrayConditionsConversations)

    const arrayConditionsNotifications: IFirestoreQuery[] = [
      { property: "viewed", operator: "==", value: false }
    ]
    const dataNotifications = await queryDynamic(`/${restName}/inventory/notifications`, arrayConditionsNotifications)

    const arrayConditionsBudgetNotifications: IFirestoreQuery[] = [
      { property: "notification", operator: "==", value: true }
    ]
    const dataBudgetNotifications = await queryDynamic(`/${restName}/expenses/budget`, arrayConditionsBudgetNotifications)

    const arrayConditionsDateBudgetFinish: IFirestoreQuery[] = [
      { property: "dateBudgetFinish", operator: "<", value: moment(new Date()).format("YYYY-MM-DD HH:mm:ss") },
      { property: "dateBudgetFinish", operator: "!=", value: "0" }
    ]
    const dataDateBudgetFinish = await queryDynamic(`/${restName}/expenses/budget`, arrayConditionsDateBudgetFinish)

    const arrayConditionsBudgetDisabled: IFirestoreQuery[] = [
      { property: "disabled", operator: "==", value: false },
    ]
    const dataBudgetDisabled = await queryDynamic(`/${restName}/expenses/budget`, arrayConditionsBudgetDisabled)

    if (restName && (dataNotifications.length > 0 || dataDateBudgetFinish.length > 0 || dataConversations.length > 0 || dataBudgetNotifications.length > 0 || dataBudgetDisabled.length > 0)) {
      deleteDocsNotifications(dataNotifications, `/${restName}/inventory/notifications`)
      deleteDocsNotifications(dataDateBudgetFinish, `/${restName}/expenses/budget`)
      deleteDocsNotifications(dataConversations, `/${restName}/conversations/conversations`)
      deleteDocsNotifications(dataBudgetNotifications, `/${restName}/expenses/budget`)
      deleteDocsNotifications(dataBudgetDisabled, `/${restName}/expenses/budget`)

      setReload(true)
    }
  }

  const deleteDocsNotifications = (array: any[], path: string) => {
    array.forEach(async (element: { id: string; }) => {
      if (element && element.id) {
        await DeleteDoc(path, element.id)
      }
    });
  }

  return (
    <>
      <Tooltip arrow title={t('Notifications')}>
        <IconButtonPrimary color="secondary" ref={ref} onClick={handleOpen}>
          <NotificationsBadge
            badgeContent={countNotif + countInven + countBudget + countCreditAlert}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <NotificationsActiveTwoToneIcon sx={{ color: theme.palette.grey[500] }} />
          </NotificationsBadge>
        </IconButtonPrimary>
      </Tooltip>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box sx={{ p: 1 }} display="flex" justifyContent="space-between" alignItems={'center'}>
          <Typography variant="h5">{t('Notifications')}</Typography>
          <Button sx={{ color: '#000', textDecorationLine: 'underline' }} onClick={cleanNotification}>{t('Clean')}</Button>
          <Link display="none" href="#" variant="caption" sx={{ textTransform: 'none' }}>
            {t('Mark all as read')}
          </Link>
        </Box>
        <Divider />
        <List sx={{ p: 2 }}>
          {listChatBot && listChatBot.map((chat, index) => {
            let message = chat.data[chat.data.length - 1].response ? chat.data[chat.data.length - 1].response : "..."
            const isCallWaiter = chat.data[chat.data.length - 1].channel === 'callWaiter'
            if (isCallWaiter) {
              message = chat.data[chat.data.length - 1].request ? chat.data[chat.data.length - 1].request : chat.data[chat.data.length - 1].response
            }
            message = message.length > 40 ? message.substr(0, 40) + "..." : message
            let date = chat.data[chat.data.length - 1].response ? chat.data[chat.data.length - 1].date : ""
            if (isCallWaiter) {
              date = chat.data[chat.data.length - 1].date
            }
            let seconds = differenceInSeconds(new Date(), new Date(date))
            if (Number.isNaN(seconds)) {
              seconds = 0
            }
            return (
              <>
                <ListItem key={chat.id + index + new Date()} sx={{ display: { xs: 'block', sm: 'flex' } }} button onClick={() => handleMessenger(isCallWaiter)} >
                  <ListItemAvatar sx={{ mb: { xs: 1, sm: 0 } }}>
                    <NotificationsBadge badgeContent={chat.data.length} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                      {isCallWaiter ? (
                        <Avatar src="/static/images/logo/waiter.png" />
                      ) : (
                        <Avatar src="/static/images/logo/whatsapp.png" />
                      )}
                    </NotificationsBadge>
                  </ListItemAvatar>
                  <Box flex="1">
                    <Box display={{ xs: 'block', sm: 'flex' }} justifyContent="space-between" >
                      <Typography sx={{ fontWeight: 'bold' }}>{isCallWaiter ? t('Table') + ' ' + chat.tableNumber : chat.phone}</Typography>
                      <Typography variant="caption" sx={{ textTransform: 'none' }}>
                        {formatDistance(subSeconds(new Date(), seconds), new Date(), {
                          addSuffix: true, locale: locales[window.localStorage.i18nextLng]
                        })}
                      </Typography>
                    </Box>
                    <Typography component="span" variant="body2" color="text.secondary" >
                      {message}
                    </Typography>
                  </Box>
                </ListItem>
                <Divider variant="inset" sx={{ my: 1 }} component="li" />
              </>
            )
          })}
          {listInventory && listInventory.map((inventory, index) => {
            const seconds = differenceInSeconds(new Date(), new Date(inventory.date))
            return (
              <>
                <ListItem key={inventory.productId + inventory.id + index + new Date()} sx={{ display: { xs: 'block', sm: 'flex' } }} button onClick={() => handleInventory(inventory.item, inventory.id)} >
                  <ListItemAvatar sx={{ mb: { xs: 1, sm: 0 } }}>
                    <NotificationsBadge badgeContent={1} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                      <InventoryIcon fontSize="large" />
                    </NotificationsBadge>
                  </ListItemAvatar>
                  <Box flex="1">
                    <Box display={{ xs: 'block', sm: 'flex' }} justifyContent="space-between" >
                      <Typography variant="caption" sx={{ textTransform: 'none' }}>
                        {formatDistance(subSeconds(new Date(), seconds), new Date(), {
                          addSuffix: true, locale: locales[window.localStorage.i18nextLng]
                        })}
                      </Typography>
                    </Box>
                    <Typography component="span" variant="body2" color="text.secondary">
                      <b>{inventory.stock}{' '}{inventory.unitsName}</b>
                      {' of '}<b>{inventory.item}</b>{' is missing'}
                    </Typography>
                  </Box>
                </ListItem>
                <Divider variant="inset" sx={{ my: 1 }} component="li" />
              </>
            )
          })}
          {listBudget && listBudget.map((budget, index) => {
            const seconds = differenceInSeconds(new Date(), new Date(budget.date))
            return (
              <>
                <ListItem key={budget.id + index + new Date()} sx={{ display: { xs: 'block', sm: 'flex' } }} button onClick={() => handleBudget(budget.nameBudget, budget.id)} >
                  <ListItemAvatar sx={{ mb: { xs: 1, sm: 0 } }}>
                    <NotificationsBadge badgeContent={1} anchorOrigin={{ vertical: 'top', horizontal: 'right' }} >
                      < AccountBalanceIcon fontSize="large" />
                    </NotificationsBadge>
                  </ListItemAvatar>
                  <Box flex="1">
                    <Box display={{ xs: 'block', sm: 'flex' }} justifyContent="space-between" >
                      <Typography variant="caption" sx={{ textTransform: 'none' }}>
                        {formatDistance(subSeconds(new Date(), seconds), new Date(), {
                          addSuffix: true, locale: locales[window.localStorage.i18nextLng]
                        })}
                      </Typography>
                    </Box>
                    <Typography component="span" variant="body2" color="text.secondary">
                      {'budget '}<b>{budget.nameBudget}</b>{' has a '}<b>{((budget.remainingBudget / budget.totalBudget) * 100) !== 0 ? ((budget.remainingBudget / budget.totalBudget) * 100).toFixed(2) : '0'}</b>{'% remaining '}
                    </Typography>
                  </Box>
                </ListItem>
                <Divider variant="inset" sx={{ my: 1 }} component="li" />
              </>
            )
          })}
        </List>
      </Popover>
    </>
  );
}

export default HeaderNotifications;
