import { FC, ReactNode, useState, useContext } from 'react';
import { NavLink as RouterLink,Link } from 'react-router-dom';
import clsx from 'clsx';
import { SidebarContext } from 'src/contexts/SidebarContext';
import {
  queryRoleUserbyRestaurant
} from 'src/utils/firebase';
import PropTypes from 'prop-types';
import { IconButton, Box, Tooltip, Badge, Popover, ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone';
import KeyboardArrowUpTwoToneIcon from '@mui/icons-material/KeyboardArrowUpTwoTone';
import StorefrontIcon from '@mui/icons-material/Storefront';

import { styled } from '@mui/material/styles';


const IndicatorWrapper = styled(Box)(
  ({ theme }) => `
  position: absolute;
  left: 50%;
  margin-left: -10px;
  top: -${theme.spacing(.5)};
  width: 20px;
  height: 20px;

  .MuiSvgIcon-root {
    width: 100%;
    height: auto;
  }
`
);

const PopoverWrapper = styled(Popover)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(2)} !important;

    .MuiListItem-root {
      padding: 2px 0 !important;

      .MuiIconButton-root {
        width: 100% !important;
        height: auto !important;
        justify-content: flex-start !important;
        font-weight: bold !important;
        background: transparent !important;
        color: ${theme.colors.alpha.black[70]} !important;
        padding: ${theme.spacing(1, 2)} !important;

        .name-wrapper {
          display: block !important;
        }

        &.Mui-active,
        &:hover {
          background: ${theme.colors.alpha.black[10]} !important;
          color: ${theme.colors.alpha.black[100]} !important;
        }
      }
    }  
  }
`
);
const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      padding: 0;
      
      .MuiList-root .MuiList-root .MuiListItem-root .MuiIconButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 2px 0;
        justify-content: center;
    
        .MuiIconButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 54px;
          height: 54px;
          justify-content: center;
          font-size: ${theme.typography.pxToRem(13)};
          padding: 0;
          position: relative;

          .name-wrapper {
            display: none;
            transition: ${theme.transitions.create(['color'])};
          }

          .MuiBadge-root {
            position: absolute;
            right: 8px;
            top: 12px;

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(9)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }
  
          .MuiSvgIcon-root {
            transition: ${theme.transitions.create(['color'])};
            font-size: ${theme.typography.pxToRem(28)};
            color: ${theme.sidebar.menuItemIconColor};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiSvgIcon-root {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }
      }
    }
`
);
interface NavigationMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  open?: boolean;
  active?: boolean;
  name: string;
  restaurant:string
}

const NavigationMenuItem: FC<NavigationMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  open: openParent,
  active,
  name,
  restaurant,
  ...rest
}) => {
  const { t }: { t: any } = useTranslation();
  const { toggleSidebar } = useContext(SidebarContext);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  // eslint-disable-next-line
  const [userRole, setUserRole] = useState('');
  const [restName, setRestName] = useState<string>(null);
  async function setNameRestaurant(item: any) {
    if(localStorage.getItem('role') !== "Staff") {
      localStorage.setItem('restName', item);
      setRestName(item)
      const role = await queryRoleUserbyRestaurant('portalUsers', item);
      setUserRole(role);
      window.location.reload();
    }
  }
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  if (children) {
    return (
      <ListItem component="div" className="Mui-children" key={name} {...rest}>
        <Tooltip title={t(name)} disableInteractive placement="top" arrow>
          <IconButton
            className={clsx({ 'Mui-active': open })}
            onClick={handleClick}
          >
            {Icon && <Icon />}

            <IndicatorWrapper>
              {open ? <KeyboardArrowDownTwoToneIcon /> : <KeyboardArrowUpTwoToneIcon />}
            </IndicatorWrapper>

          </IconButton>
        </Tooltip>
        <PopoverWrapper
          classes={{ root: 'child-popover' }}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          open={open}>{children}</PopoverWrapper>
      </ListItem>
    );
  }
  
  return restaurant ? (
    <SubMenuWrapper>
      <Link
        to={link}
        key={name}
        style={{ textDecoration: 'none' }}
      >
        <ListItem>
          <Tooltip title={name} disableInteractive placement="right" arrow>
            <IconButton
              aria-label="restaurant"
              className={restName === name ? "Mui-active" : "Mui-active-hover"}
              onClick={() => setNameRestaurant(name)}>
              <StorefrontIcon color={restName === name ? "primary" : "secondary"} />
            </IconButton>
          </Tooltip>
        </ListItem>
      </Link>
    </SubMenuWrapper>
  ):(
    <ListItem component="div" key={name} {...rest}>
      <Tooltip title={t(name)} disableInteractive placement="top" arrow>
        <IconButton
          className="Mui-active"
          component={RouterLink}
          onClick={toggleSidebar}
          to={link}
        >
          {Icon && <Icon />}
          <span className="name-wrapper">
            {name}
          </span>
          {badge && <Badge badgeContent={badge} />}
        </IconButton>
      </Tooltip>
    </ListItem>
  );
};

NavigationMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired
};

NavigationMenuItem.defaultProps = {
  open: false,
  active: false,
};

export default NavigationMenuItem;
