import { FC, ReactNode, useState } from 'react';

import { Navigate, useLocation } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

import useAuth from 'src/hooks/useAuth';
import Login from 'src/content/pages/Auth/Login/Cover';

interface AuthenticatedProps {
  children: ReactNode;
}

const Authenticated: FC<AuthenticatedProps> = (props: { children: ReactNode }) => {
  const uid = localStorage.getItem('uidEmail')
  const { children } = props;

  const [requestedLocation, setRequestedLocation] = useState(null);

  const location = useLocation();

  const auth = useAuth();

  if (!auth.isAuthenticated) {
    if (location.pathname !== requestedLocation) {
      setRequestedLocation(location.pathname);
    }

    if (uid) {
      return (
        <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
          <CircularProgress size={70} />
        </Box>
      )
    }

    return <Login />;
  }

  if (requestedLocation && location.pathname !== requestedLocation) {
    setRequestedLocation(null);
    
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
};

export default Authenticated;
