import { FC, ReactNode, useState } from 'react';

import { Button, Badge, Collapse, ListItem, Typography, Box } from '@mui/material';
import { NavLink as RouterLink } from 'react-router-dom';
import { TFunction, useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { queryRoleUserbyRestaurant } from 'src/utils/firebase';
import { BoneLightTheme } from 'src/theme/schemes/BoneLightTheme';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  open?: boolean;
  active?: boolean;
  subName?: string;
  displayName?: string;
  name: string;
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({ children, link, icon: Icon, badge, open: openParent, active, subName, name, displayName, ...rest }) => {
  let restName = subName === "Restaurants" ? localStorage.getItem('restName') : ""
  const excludeSubsections = ["On-line Chat", "Pages", 'Soporte', 'Reserved rooms', 'Statistics', 'Discounts'];
  const [menuToggle, setMenuToggle] = useState<boolean>(openParent);
  const { t }: { t: TFunction<"translation"> } = useTranslation();
  const toggleMenu = (): void => setMenuToggle((Open) => !Open)
  const changeRestaurant = async (restName: string, change: boolean) => {
    if (change && localStorage.getItem('role') !== "Staff") {
      localStorage.setItem('restName', restName);
      const role = await queryRoleUserbyRestaurant('portalUsers', restName);
      localStorage.setItem('role', role);
      window.location.reload();
    }
  }

  if (children) {
    return (
      <ListItem id={subName ? `listItem-test-${subName}` : `listItem-test-${name}`} component="div" className="Mui-children" key={name}>
        <Button className={clsx({ 'Mui-active': menuToggle })} startIcon={Icon && <Box><Icon /></Box>} id={name} onClick={toggleMenu}>
          {badge && <Badge badgeContent={badge} />}
          <Typography sx={{ fontWeight: 500, width: 'inherit', textAlign: 'initial', font: '-webkit-control' }}>
            {t(name)}
          </Typography>
          <Box sx={{ width: 'inherit', textAlignLast: 'end' }}>
            {menuToggle ? <ExpandLess sx={{ transform: 'rotate(90deg)' }} /> : <ExpandMore />}
          </Box>
        </Button>
        <Collapse in={menuToggle} id={subName && 'collapseSideBarMenuItems-test'} >
          {children}
        </Collapse>
      </ListItem>
    );
  }

  let className = "Mui-active"
  if (restName !== name) {
    className = "Mui-active-hover"
  }

  return (
    <ListItem sx={{ display: 'block' }} key={name} component='li'>
      <Button
        className={className}
        component={RouterLink}
        onClick={() => changeRestaurant(name, subName === "Restaurants")}
        to={link}
        startIcon={Icon && <Box sx={{ marginLeft: '-3px', display: 'grid' }} ><Icon /></Box>}
      >
        <Typography display='flex' flexDirection='row' alignItems='center' sx={{ textAlign: 'initial' }} gap={1.5}>
          {
            (!excludeSubsections.includes(name)) &&
            <Box
              component='div'
              width={8}
              height={8}
              borderRadius='80px'
              sx={{
                background:
                  active
                    ?
                    BoneLightTheme.colors.gradients.orange2
                    :
                    '#8E8E8E'
              }}
            />
          }
          {t(restName ? displayName : name)}
        </Typography>
        {badge && <Badge badgeContent={badge} />}
      </Button>
      <Box sx={{ display: name !== 'Discounts' && 'none', borderBottom: 'solid 2px #E6E6E6', width: '90%', margin: '0 auto', marginY: '10px' }} />
    </ListItem>
  );
};

export default SidebarMenuItem;