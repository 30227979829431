import { useContext } from 'react';

import { Box, Drawer, Hidden } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { SidebarContext } from 'src/contexts/SidebarContext';
import SidebarMenu from './SidebarMenu';
import Logo from '../Header/Logo';

const SidebarWrapper = styled(Box)(({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 5;
        height: 100%;

        @media (min-width: ${theme.breakpoints.values.lg}px) {
          height: calc(100% - ${theme.header.height});
          margin-top: ${theme.header.height};
        }
`);

const TopSection = styled(Box)(({ theme }) => `
        margin: ${theme.spacing(2)};
`);

function Sidebar(props) {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  const closeSidebar = () => toggleSidebar();
  
  return (
    <>
      <Hidden lgDown>
        <SidebarWrapper id='sidebar'>
          <Scrollbars autoHide>
            <SidebarMenu />
          </Scrollbars>
        </SidebarWrapper>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
          id='mobile-sidebar'
        >
          <SidebarWrapper>
            <Scrollbars autoHide>
              <TopSection>
                <Logo />
              </TopSection>
              <SidebarMenu />
            </Scrollbars>
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  );
}

export default Sidebar;
