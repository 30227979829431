import { FC, useEffect } from 'react';
import { useNavigate, useRoutes } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import useAuth from 'src/hooks/useAuth';
import useScrollTop from 'src/hooks/useScrollTop';
import routes from './router';
import ThemeProvider from './theme/ThemeProvider';
import TagManager from 'react-gtm-module';
import './index.css';
import Cookies from 'universal-cookie';
import { SignInToken, queryUserRestaurants } from './utils/firebase';
import Loading from './components/Loading';

const tagManagerArgs = {
  gtmId: 'GTM-PRB2BV9'
};

TagManager.initialize(tagManagerArgs);

const App: FC = (): JSX.Element => {
  const cookies = new Cookies();
  const navigate = useNavigate()
  const { logout } = useAuth();

  fetch(`${process.env.PUBLIC_URL}/version.json`)
    .then((r) => r.json())
    .then((data) => {
      console.log(`Version ${data.version}`);
      let currentVersion = localStorage.getItem("version_portal") ?? "";
      let newVersion = data?.version ?? "";
      let objectLocation: any = window.location;
      if (currentVersion !== newVersion) {
        console.log(`new version ${newVersion}`);
        localStorage.setItem("version_portal", newVersion);
        caches.keys().then(function (names) {
          console.log(`${names}`);
          for (let name of names) {
            console.log(`${name}`);
            caches.delete(name);
            objectLocation.reload(true);
          }
        })
        logout()
      }
    })

  const content = useRoutes(routes);
  const auth = useAuth();

  useScrollTop();
  const token = cookies.get('customToken')
  const deleteCookie = () => {
    cookies.remove('customToken');
  };

  useEffect(() => {
    const fetchData = async () => {
      const singToken = await SignInToken(token);
      if (singToken) {
        const venues = await queryUserRestaurants('portalUsers/Restaurants/Users', singToken.user.uid);
        const localStorageData = {
          restName: venues.restaurants[0],
          login: 'emailAndPassword',
          uidEmail: singToken.user.uid,
          userName: singToken.user.displayName
        };
        // Guardar en el LocalStorage
        for (const key in localStorageData) {
          if (Object.prototype.hasOwnProperty.call(localStorageData, key)) {
            localStorage.setItem(key, localStorageData[key]);
          }
        }
        navigate('/accent-header/dashboards/home')
        deleteCookie();
      }
    };
    if (token?.length > 0) {
      fetchData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, token]);

  const uidEmail = localStorage.getItem('uidEmail')?.length > 0;


  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <SnackbarProvider
          maxSnack={6}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
        >
          <CssBaseline />
          {auth.isInitialized
            ? uidEmail
              ? content
              : token?.length > 0
                ? <Loading />
                : content
            : <>auth not initialized</>}
        </SnackbarProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
