import {
  Box,
  Link,
  Typography,
  Container,
  Divider,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import useAuth from 'src/hooks/useAuth';
import FirebaseAuthLogin from '../LoginFirebaseAuth';
import { useTranslation } from 'react-i18next';
import { SignInStyle } from './style';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import GoogleButton from 'src/components/Buttons/LoginGoogle/GoogleButton';
import FacebookButton from 'src/components/Buttons/LoginFacebook/FacebookButton';
import { useNavigate } from 'react-router-dom';
import { queryUserRestaurants } from 'src/utils/firebase';

//Responsive
import { BaseViewPorts } from 'src/theme/viewports/BaseViewPorts';

function LoginCover() {
  const navigate = useNavigate()
  const viewports = BaseViewPorts()
  const { signInWithGoogle, singInWithFacebook } = useAuth() as any;
  const logoSignIn = 'https://storage.googleapis.com/order-and-eat-now/Images/Logos/LOGO_O%26E_PORTAL.svg'
  const { classes } = SignInStyle()
  const { method } = useAuth() as any;
  const { t }: { t: any } = useTranslation();

  const handleGoogleClick = async () => {
    try {
      const request = await signInWithGoogle();

      localStorage.removeItem('dataRegister')
      localStorage.setItem('login', 'loginWithGoogleOrFacebook')

      localStorage.setItem('uidEmail', request.user.uid)
      localStorage.setItem('userName', request.user.displayName)

      const venues = await queryUserRestaurants('portalUsers/Restaurants/Users', request.user.uid);
      if (venues && venues.restaurants.length > 0) {
        localStorage.setItem('restName', venues.restaurants[0]);
      }

      navigate('/')
    } catch (err) {
      console.error(err);
    }
  };



  const handleFacebookClick = async () => {
    try {
      const request = await singInWithFacebook();
      localStorage.removeItem('dataRegister')
      localStorage.setItem('login', 'loginWithGoogleOrFacebook')
      localStorage.setItem('uidEmail', request.user.uid)
      localStorage.setItem('userName', request.user.displayName)
      const venues = await queryUserRestaurants('portalUsers/Restaurants/Users', request.user.uid);
      if (venues && venues.restaurants.length > 0) {
        localStorage.setItem('restName', venues.restaurants[0]);
      }
      navigate('/')
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <>
      <Helmet>
        <title>{t('Sign In')}</title>
      </Helmet>
      <Box
        className={viewports.desktop || viewports.laptop ? classes.contentSignInDesktop : classes.contentSignInMobileAndTablet}
      >
        {/* {open && < HelpSupport isLogin={false} open={open} setOpen={setOpen} />} */}
        <Box>
        </Box>
        <Box sx={{ margin: 'auto', marginY: (viewports.mobile || viewports.tablet) && '10%' }}>
          <Container
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: 2.5
            }}
          >
            <Box textAlign={'center'}>
              <LazyLoadImage
                src={logoSignIn}
                alt="logoSignIn"
                height={viewports.mobile ? '65' : viewports.tablet ? '99px' : '50.69px'}
                width={viewports.mobile ? '85' : viewports.tablet ? '129px' : '65.9px'}
              />
            </Box>
            <Box className={(viewports.mobile || viewports.tablet) && classes.box_mobileTitle}>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: viewports.mobile || viewports.tablet ? 'center' : 'space-between'
                }}
              >
                <Typography
                  variant="h2"
                  sx={{
                    // mb: 1,
                    color: '#000000',
                    fontWeight: 700,
                    fontSize: viewports.tablet ? '39.51px' : viewports.mobile ? '30px' : '21.52px',
                    lineHeight: viewports.tablet ? '51.45px' : viewports.mobile ? '39.06px' : '28.02px'
                  }}
                >
                  {t('Sign In')}
                </Typography>
              </Box>
              <Typography
                fontWeight={700}
                color={'#000000'}
                fontSize={viewports.tablet ? '22.39px' : viewports.mobile ? '13px' : '12.91px'}
                lineHeight={viewports.tablet ? '29.15px' : viewports.mobile ? '20.4px' : '16.81px'}
                marginTop={'2%'}
              >
                {t("Don't have an account yet?")}{' '}
                <Link id='register_link' component="a" underline='none' href="/register">
                  {t('Register now')}
                </Link>
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginY: '1%' }}>
              <Box>
                <GoogleButton
                  fullWidth={viewports.desktop || viewports.laptop ? true : false}
                  textButton={viewports.mobile || viewports.tablet ? '' : 'Login with Google'}
                  hasOnClick={handleGoogleClick}
                />
              </Box>
              <Box sx={{ marginLeft: '5%' }}>
                <FacebookButton
                  fullWidth={viewports.desktop || viewports.laptop ? true : false}
                  textButton={viewports.mobile || viewports.tablet ? '' : 'Login with Facebook'}
                  hasOnClick={handleFacebookClick}
                />
              </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginY: '-10px' }}>
              <Divider sx={{ background: '#000000', width: '42%' }} />
              <p style={{ fontWeight: 500, marginLeft: '2%', marginRight: '2%' }}>O</p>
              <Divider sx={{ background: '#000000', width: '42%' }} />
            </Box>
            {/* {method === 'Auth0' && <Auth0Login />} */}
            {method === 'FirebaseAuth' && <FirebaseAuthLogin />}
          </Container>
        </Box>
      </Box >
    </>
  );
}

export default LoginCover;