import { makeStyles } from 'tss-react/mui';
const backgroundSignIn = 'https://storage.googleapis.com/order-and-eat-now/Images/Backgrounds/SigIn/SignIn.svg'

export const SignInStyle = makeStyles()(() =>
({
    contentSignInDesktop: {
        display: 'grid',
        gridTemplateColumns: '0.5fr 0.5fr',
        minHeight: '100vh',
        backgroundImage: `url(${backgroundSignIn})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        alignItems: 'center',
    },
    contentSignInMobileAndTablet: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        backgroundImage: `url(${backgroundSignIn})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: 'right 25% bottom 45%'
    },
    container: {
        display: 'flex',
        flexDirection: 'column',
        gap: 3
    },
    box_logo: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: 2
    },
    box_mobileTitle: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        // marrginTop: '10px'
    },
    typography_signIn: {
        mb: 1,
        color: '#000000',
        fontWeight: 700
    },
    link_registerNow: {
        fontWeight: 700,
        marginLeft: 1,
        color: '#000000'
    },
    box_loginSocialMedia: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4
    },
    button_loginGoogle: {
        backgroundColor: '#FFFFFF',
        color: '#1E232C',
        padding: 1.5,
        fontSize: '16px',
        ":hover": {
            backgroundColor: '#e6e6e6'
        }
    },
    button_loginFacebook: {
        backgroundColor: '#4776D0',
        padding: 1.5,
        fontSize: '16px',
        ":hover": {
            backgroundColor: '#3b62ad'
        }
    }
}),
);