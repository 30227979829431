import { dataBackend } from './backend-order-and-eat-now'
import axios from 'axios'
import jsrsasign from 'jsrsasign';

// const jwt = require('jsonwebtoken')
const qs = require('qs')

export async function generateJWTToken() {

    const privateKey = `-----BEGIN PRIVATE KEY-----
${dataBackend.private_key}
-----END PRIVATE KEY-----`;

    const key = jsrsasign.KEYUTIL.getKey(privateKey);

    const payload = {
        "iss": "backend@order-and-eat-now.iam.gserviceaccount.com",
        "sub": "backend@order-and-eat-now.iam.gserviceaccount.com",
        "target_audience": "https://payments-yc4jumkrlq-km.a.run.app",
        "aud": "https://www.googleapis.com/oauth2/v4/token",
        "iat": Math.floor(Date.now() / 1000),
        "exp": Math.floor(Date.now() / 1000) + 3600
    };

    const header = { "alg": "RS256", "typ": "JWT" };
    const jwt = jsrsasign.jws.JWS.sign(header.alg, header, JSON.stringify(payload), key);
    return jwt
}

export async function getGoogleAccessToken(token: string) {
    let id_token: string = ""

    try {
        var data = qs.stringify({
            'grant_type': 'urn:ietf:params:oauth:grant-type:jwt-bearer',
            'assertion': token
        });
        const config = {
            url: "https://www.googleapis.com/oauth2/v4/token",
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        };
        const response = await axios.post(config.url, data, { headers: config.headers })
        const responseData = JSON.parse(JSON.stringify(response.data));
        id_token = responseData.id_token
        localStorage.setItem("bearedTokenStorage", "Bearer " + responseData.id_token)
        return id_token
    } catch (err) {
        console.log(err);
    }

    return id_token
}

export function getDomain() {
    const hostname = window.location.hostname;
    return hostname === "localhost" ? "http://localhost:57697" : "https://reverse-proxy-yc4jumkrlq-km.a.run.app"
}

export function getDomainApiChat() {
    const hostname = window.location.hostname;
    return hostname === "localhost" ? "http://localhost:57698" : "https://chat-yc4jumkrlq-rj.a.run.app"
}