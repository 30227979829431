import { useState } from 'react'
import { useTranslation } from 'react-i18next';
import { HelperSupportContext } from '.'

import { useSnackbar } from 'notistack';
import { Zoom } from '@mui/material';
import { generateJWTToken, getDomain, getGoogleAccessToken } from 'src/utils/generateToken';
import axios from 'axios';
import moment from 'moment';
import useAuth from 'src/hooks/useAuth';

const HelpSupportProvider = ({ children, isLogin }) => {

    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const auth = useAuth()

    const [subject, setSubject] = useState('')
    const [captchaValid, setCaptchaValid] = useState(false)
    const [sendEmail, setSendEmail] = useState(false)
    const [contentEmail, setContentEmail] = useState('')
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')

    const sendEmailToSupport = async () => {

        if (contentEmail === '' || subject === '' || (name === '' && !isLogin) || (email === '' && !isLogin) || !captchaValid) return notification('You must complete the form.', 'error')
        if (!validateEmail(email) && !isLogin) return notification(t('Please enter a valid email'), 'error')

        try {
            setSendEmail(true)
            let obj = {
                date: moment().format('YYYY-MM-DD'),
                titlePlace: t('Support'),
                detailsPlace: '',
                subject: subject,
                preHeader: "Order and Eat Now",
            }
            let items: any[] = [
                {
                    text: t('Name'),
                    value: isLogin ? localStorage.getItem('userName') : name
                },
                {
                    text: t('Email'),
                    value: isLogin ? auth.user.email : email
                },
                isLogin && {
                    text: t('idEmail'),
                    value: auth.user.idEmail
                },
                {
                    text: t('Subject'),
                    value: subject
                },
                {
                    text: '------------------------------------------',
                    value: '------------------------------------------'
                },
                {
                    text: t('Request'),
                    value: contentEmail
                },
            ]
            let objDetails = {
                items,
                itemsTitle: `${t('Help Support')} ${isLogin ? t('for') : '-'} ${isLogin ? localStorage.getItem('restName') : t('without venue')}`,
            }
            obj = {
                ...obj,
                ...objDetails
            }
            let data = {
                personalizations: [
                    {
                        to: [{ email: 'soporte@whitesoft.atlassian.net' }],
                        dynamic_template_data: obj
                    }
                ],
                template_id: "d-7b17ea27fb2e42e89a5822f94966d628"
            };
            const token = await generateJWTToken()
            const id_token = await getGoogleAccessToken(token);
            let config: any = {
                method: 'POST',
                url: getDomain() + '/send/email',
                headers: {
                    Accept: "application/json",
                    'Authorization': "Bearer " + id_token,
                    'Content-Type': 'application/json'
                },
                data: data
            };
            // console.log({ config })
            await axios(config).then(async function (response) {
                notification("Email sent successfully", 'success');
                setName('')
                setEmail('')
                setSubject('')
                setContentEmail('')
                setSendEmail(false)
            }).catch(function (error) {
                console.log(error);
                setSendEmail(false)
            })
        } catch (error) {
            console.log(error)
            setSendEmail(false)
        }
        // console.log({ editorStateHtml, subject })
    }

    const validateEmail = (email: string) => {
        // eslint-disable-next-line no-useless-escape
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
            return true;
        }
    };

    const notification = (message: string, variant: any) => {
        enqueueSnackbar(t(message), {
            variant: variant,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
            },
            autoHideDuration: 3000,
            TransitionComponent: Zoom
        });
    };

    return (
        <HelperSupportContext.Provider
            value={{
                t,
                subject,
                setSubject,
                captchaValid,
                setCaptchaValid,
                sendEmail,
                setSendEmail,
                contentEmail,
                setContentEmail,
                name,
                setName,
                email,
                setEmail,
                sendEmailToSupport
            }}
        >
            {children}
        </HelperSupportContext.Provider>
    )
}

export default HelpSupportProvider