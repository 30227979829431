
import firebase from 'firebase/compat/app';
import * as _ from 'lodash';
import 'firebase/compat/auth';
import {
  getDocs,
  deleteDoc,
  getFirestore,
  query,
  where,
  doc,
  updateDoc,
  onSnapshot,
  getDoc,
  setDoc,
  deleteField,
  increment,
  limit,
  startAfter,
  QueryConstraint,
  DocumentData,
  WhereFilterOp,


} from 'firebase/firestore'
import { getAuth, onAuthStateChanged, signInWithCustomToken, signInWithEmailAndPassword } from "firebase/auth";
import moment from 'moment';
import { firebaseConfig } from 'src/config';
import { sendPasswordResetEmail, confirmPasswordReset } from "firebase/auth";
import { sendSms } from './sendsms'
import { genrateColor } from 'src/utils/GenerateRandomColor'
import uniqid from 'uniqid'
import { changeStatusTable } from 'src/content/management/TableReservation/TableDistribution/Utils';
import { collection } from 'firebase/firestore';

const app = firebase.initializeApp(firebaseConfig);
export const db = getFirestore(app)
const auth = getAuth(app);
let uid: any = ""

//function that brings the uid of the user
onAuthStateChanged(auth, (user) => {
  if (user) {
    // console.log({ auth, user })
    uid = user.uid;
  }
});

export const firebaseSignIn = async () => {
  const currentUser = auth.currentUser;
  if (!currentUser) {
    const firebasePassword = process.env.REACT_APP_FIREBASE_PASSWORD ?? "";
    const firebaseUser = process.env.REACT_APP_FIREBASE_USER ?? "";
    await signInWithEmailAndPassword(auth, firebaseUser, firebasePassword);
  }
}


export const SignInToken = async (token) => {
  try {
    const singIn = await signInWithCustomToken(auth, token);
    return singIn
  } catch (error) {
    console.error('Error al iniciar sesión:', error);
  }
};


//function create new document
export async function saveDocIncrement(collectionName: string, documentName: string, field: string, incremen: boolean, val: number = 1) {
  if (incremen) {
    await updateDoc(doc(db, collectionName, documentName), { [field]: increment(+val) });
  } else {
    await updateDoc(doc(db, collectionName, documentName), { [field]: increment(-val) });
  }
}

//function create new document
export async function saveDoc(collectionName: string, documentName: string, data: any) {
  await setDoc(doc(db, collectionName, documentName), data, { merge: true });
}
//Function delete one Document
export async function DeleteDoc(collectionName: string, documentName: string) {
  await deleteDoc(doc(db, collectionName, documentName));
}
//delete Field
export async function DeleteField(collectionName: string, documentName: string, refIdCategory: string) {
  const productRef = doc(db, collectionName, documentName);
  await updateDoc(productRef, {
    [refIdCategory]: deleteField()
  });
}
//delete product
export async function deleteProductbyCategory(collectionName: string, documentName: string, idCategory: string, idProduct: string) {
  const productRef = doc(db, collectionName, documentName);
  const refIdCategory = `${idCategory}.${idProduct}`
  await updateDoc(productRef, {
    [refIdCategory]: deleteField()
  });
}
//update order by product when delete product
export async function updateOrderProduct(collectionName: string, documentName: string, idCategory: string, idProduct: "string", order: number) {
  const productRef = doc(db, collectionName, documentName);
  const refIdProduct = `${idCategory}.${idProduct}.order`
  await updateDoc(productRef, {
    [refIdProduct]: order
  });
}
//update category of restaurant
export async function updateCategory(collectionName: string, idCategory: string, documentName: string, data: any) {
  const orderRef = doc(db, collectionName, documentName);
  const refDocumentDisplayName = `${idCategory}.displayName`
  const refDocumentUrlImg = `${idCategory}.landingImage`
  const refDocumenticon = `${idCategory}.icon`
  const refDocumentNoDisplay = `${idCategory}.NoDisplay`
  await updateDoc(orderRef, {
    [refDocumentDisplayName]: data.categoryName,
    [refDocumentUrlImg]: data.urlImagecategory,
    [refDocumenticon]: data.urlIconcategory,
    [refDocumentNoDisplay]: data.checkedDisplayCategory,
  });
}
//function update order drag and drop of categories
export async function updateOrderCat(collectionName: string, idCategory: string, order: number, documentName: string) {
  const orderRef = doc(db, collectionName, documentName);
  const orderCat = `${idCategory}.order`
  await updateDoc(orderRef, {
    [orderCat]: order
  });
}
//delete  category of restaurant
export async function deleteCategory(collectionName: string, idCategory: string, documentName: string) {
  const cityRef = doc(db, collectionName, documentName);
  await updateDoc(cityRef, {
    [idCategory]: deleteField()
  });
}
//delete  table of layout
export async function deleteTableFirebase(collectionName: string, documentName: string, refTable: string, idTable: string,) {
  const ref = doc(db, collectionName, documentName);
  const referTable = `${refTable}.${idTable}`
  console.log("refTable", referTable)
  await updateDoc(ref, {
    [referTable]: deleteField()
  });
}
//update Status and color for each table 
export async function changeStatusAndColorTable(
  collectionName: string, documentName: string, refTable: string, idTable: string, status: string, color: string, statusorder: string, occupationDate: string, releaseDate: string) {
  const ref = doc(db, collectionName, documentName);
  const referTableStatus = `${refTable}.${idTable}.status`
  const referTableColor = `${refTable}.${idTable}.color`
  const referOrderStatus = `${refTable}.${idTable}.orderStatus`
  const refeOccupationDate = `${refTable}.${idTable}.occupationDate`
  const refeReleaseDate = `${refTable}.${idTable}.releaseDate`
  await updateDoc(ref, {
    [referTableStatus]: status,
    [referTableColor]: color,
    [referOrderStatus]: statusorder,
    [refeOccupationDate]: occupationDate,
    [refeReleaseDate]: releaseDate,
  });
}
//update Status table
export async function changeStatusTableFirebase(
  collectionName: string, documentName: string, refTable: string, idTable: string, state: string) {
  const ref = doc(db, collectionName, documentName);
  const referOrderStatus = `${refTable}.${idTable}.orderStatus`
  await updateDoc(ref, {
    [referOrderStatus]: state,
  });
}
//delete key document
export async function deleteKeyDocument(collectionName: string, document: string, key: string) {
  const documentRef = doc(db, collectionName, document);
  await updateDoc(documentRef, { [key]: deleteField() });
}

//query to bring info collections from bd
export async function queryData(collectionName: string) {
  let queryCollection = collection(db, collectionName)
  return await getDocs(queryCollection)
}

export async function queryDeleteDoc(collection: string, docId: string) {
  await deleteDoc(doc(db, collection, docId))
}

export async function updateData(collection: string, document: string, collectionObject: any) {
  const docRef = doc(db, collection, document);
  await updateDoc(docRef, collectionObject);
}

//function bring info of collection from bd
export async function queryDoc(collectionName: string, documentName: string) {
  let queryCollection = doc(db, collectionName, documentName);
  return await getDoc(queryCollection);
}

export const fetchDocuments = (url: string, onNewData: (documents: any[]) => void) => {
  try {
    const collectionRef = collection(db, url);

    const unsubscribe = onSnapshot(collectionRef, (querySnapshot) => {
      const documents = [];
      // Itera sobre los documentos y agrega sus datos al array
      querySnapshot.forEach((doc) => {
        documents.push({ id: doc.id, data: doc.data() });
      });
      // Llama a la función onNewData con los nuevos documentos
      onNewData(documents);
    });
    // Retorna la función unsubscribe para cancelar la suscripción al evento en caso de ser necesario
    return unsubscribe;
  } catch (e) {
    console.error("Error getting documents:", e);
    return () => { }; // Retorna una función vacía en caso de error
  }
};

/* export const fetchDocuments = async (url: string) => {
  try {
    const collectionRef = collection(db, url);
    const querySnapshot = await getDocs(collectionRef);
    const documents = [];

    // Itera sobre los documentos y agrega sus datos al array
    querySnapshot.forEach((doc) => {
      documents.push({ id: doc.id, data: doc.data() });
    });
    console.log(documents)
    return documents;
  } catch (e) {
    console.error("Error getting documents:", e);
    return [];
  }
}; */

// updated Color to Database
export async function updateColorsDocs(collectionObject) {
  const docRef = doc(db, 'red', 'colors');
  await updateDoc(docRef, collectionObject);
}


//query to bring orders of each restaurant, according to the state
export async function queryOrders(restaurantName, state) {
  const orders = query(collection(db, `/${restaurantName}/orders/orders`), where("state", "==", state));
  const querySnapshot = await getDocs(orders);
  const arrayData = [];
  querySnapshot.forEach((doc) => {
    arrayData.push({
      idOrder: doc.id,
      date: doc.data().date,
      products: doc.data().products,
      state: doc.data().state,
      uidEmail: doc.data().uidEmail,
      uidPhone: doc.data().uidPhone
    })
  })
  return arrayData
}
//query to bring all reservations from bd
export async function queryBookings(restaurantName) {
  const bookings = query(collection(db, `/${restaurantName}/reservations/reservations`));
  const querySnapshot = await getDocs(bookings);
  let arrayData = [];
  let count = 0
  querySnapshot.forEach((doc) => {

    let color = genrateColor()
    arrayData.push({
      id: count.toString(),
      allDay: false,
      color: color,
      description: '',
      status: doc.data().status,
      room: doc.data().idRoom,
      phone: doc.data().phone,
      end: doc.data().endDate + "T15:00:00",
      start: doc.data().startDate + "T15:00:00",
      title: doc.data().fullname,
      idBooking: doc.data().idReserve,
    })
    count++
  })

  return arrayData
}
//query to bring all reservations from bd
export async function queryBookingsRentCar(restaurantName) {
  const bookings = query(collection(db, `/${restaurantName}/products/reservation`), where("status", "in", ['fully paid', 'partial payment', 'pending payment']));
  const querySnapshot = await getDocs(bookings);
  let arrayData = [];
  let count = 0
  querySnapshot.forEach((doc) => {
    if ((doc.data().status !== 'pending payment' && doc.data().status !== 'cancelled') || (doc.data().status === 'pending payment' && doc.data().codePayment)) {
      let infoCar = doc.data().data.find((data) => data.default === true);
      let nameCar = infoCar.data.find((data) => data.type === 'name');
      let color = genrateColor()
      arrayData.push({
        id: count.toString(),
        allDay: false,
        color: color,
        description: '',
        status: doc.data().status,
        car: nameCar ? nameCar.value : '',
        phone: doc.data().phone,
        end: doc.data().endDate,
        start: doc.data().startDate,
        title: doc.data().fullname,
        idBooking: doc.data().idReservation,
        codePayment: doc.data().codePayment ? doc.data().codePayment : false
      })
      count++
    }
  })

  return arrayData
}
//query to bring all reservations for id
export async function queryBookingsById(restaurantName, colectionName, idReserve, table = false) {
  const orders = query(collection(db, `/${restaurantName}/${colectionName}`), where("idReserve", "==", idReserve));
  const querySnapshot = await getDocs(orders);
  let ObjData = {};
  querySnapshot.forEach((doc) => {
    if (table) {
      ObjData = {
        startDate: doc.data().startDate,
        endDate: doc.data().endDate,
        email: doc.data().email,
        phone: doc.data().phoneNumber,
        numberTable: doc.data().numberTable,
        numberPeople: doc.data().numberPeople,
        name: doc.data().name,
        status: doc.data().status,
        idReserve: doc.data().idReserve,
        specialRequi: doc.data().specialRequi
      }
    } else {
      ObjData = {
        startDate: doc.data().startDate,
        endDate: doc.data().endDate,
        email: doc.data().email,
        fullname: doc.data().fullname,
        phone: doc.data().phone,
        extras: doc.data().extras,
        numberOfAdults: doc.data().numberOfAdults,
        numberOfChildrens: doc.data().numberOfChildrens,
        status: doc.data().status,
        nigthNumbers: doc.data().nigthNumbers,
        amount: doc.data().amount,
        totalPrice: doc.data().totalPrice,
        valueAdvanced: doc.data().valuePaid,
        idRoom: doc.data().idRoom,
        urlInvoice: doc.data().urlInvoice,
        guests: doc.data().guests,
        isHostel: doc.data().isHostel,
        weekDaysPrice: doc.data().weekDaysPrice,
        codePayment: doc.data().codePayment ? doc.data().codePayment : false
      }
    }

  })
  return ObjData
}
//query to bring all reservations for id to rent car
export async function queryBookingsByIdRentCar(restaurantName, idBooking) {
  const orders = query(collection(db, `/${restaurantName}/products/reservation`), where("idReservation", "==", idBooking));
  const querySnapshot = await getDocs(orders);
  let ObjData = {};
  querySnapshot.forEach((doc) => {
    ObjData = doc.data()
  })
  return ObjData
}
//query to bring available cars for a range of dates
export async function queryRentCarAvailable(restaurantName, startDate, endDate) {
  const startDateformat = moment(startDate).format('YYYY-MM-DD HH:mm')
  const endDateformat = moment(endDate).format('YYYY-MM-DD HH:mm')
  const q = query(collection(db, `/${restaurantName}/products/reservation`), where("startDate", ">=", startDateformat));
  const notIn: any = await new Promise(function (resolve, reject) {
    const arrayData = [];
    onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
      snapshot.docChanges().forEach((coll) => {
        if (coll.doc.data().endDate <= endDateformat) {
          arrayData.push(coll.doc.data().idProduct)
        }
      });
      resolve(arrayData)
    });

  })
  let q2 = query(collection(db, `/${restaurantName}/products/products`))
  if (notIn.length > 0) {
    q2 = query(collection(db, `/${restaurantName}/products/products`), where("id", "not-in", notIn));
  }
  const arrayData: any = await new Promise(function (resolve, reject) {
    const data = [];
    onSnapshot(q2, { includeMetadataChanges: true }, (snapshot) => {
      snapshot.docChanges().forEach((coll) => {
        data.push(coll.doc.data())
      });
      resolve(data)
    });

  })
  return arrayData
}
//query to bring from bd,the scheduled agenda for the hotel
export const queryGetRoomsAvailable = async (
  restaurantName: string,
  documentName: string,
  collectionName: string,
  numberOfPerson: number,
  dateInicial: string,
  dateFinal: string
) => {
  let arrayRoomsdata: any[] = []
  let arrayRoomsdataObj: any[] = []
  let listIdsRoomsavailable: string[] = []
  let idCalendarData: any[] = []
  let numberofdays: number = 1
  let roomOccupancy: any[] = []
  let similarRoomsReamining: any[] = []
  // 1 seleccionar de calendar los datos en las fechas seleccionadas
  const q = query(collection(db, `/${restaurantName}/${documentName}/${collectionName}`),
    where("date", ">=", dateInicial),
    where("date", "<=", dateFinal),
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.data().Guess >= numberOfPerson) {
      idCalendarData.push(
        {
          id: doc.id,
          data: doc.data()
        }
      )
      arrayRoomsdata = [...arrayRoomsdata, doc.data()]
      // se crea aaray de obj con todos los ids de los cuarts q cumplan las condiciones
      doc.data().ids.forEach(({ id, reaminingRoomSimilars }: any) => {
        arrayRoomsdataObj.push({
          numberId: id
        })
        similarRoomsReamining.push({
          idRoom: id,
          availabilitySimilarRooms: reaminingRoomSimilars
        })
      })

      //si es un hostal quiere decir que el array idsHostel tiene data entonces se agregan a el array de objetos de los room
      doc.data().idsHostel.forEach(({ id, remaining }: any) => {
        // console.log("reamining", remaining)
        if (remaining >= numberOfPerson) {// Si el restante es mayor o igual a la cantidad ee personas, muestra esta room en la busqueda
          arrayRoomsdataObj.push({
            numberId: id
          })
          roomOccupancy.push({
            idRoom: id,
            occupancy: remaining
          })
        }
      })
    }
  });
  const groupByIdRoom = _.groupBy(arrayRoomsdataObj, room => room.numberId);
  // eslint-disable-next-line
  let f1 = moment(dateInicial)
  let f2 = moment(dateFinal)
  numberofdays = f2.diff(f1, 'days')
  // numberofdays = arrayRoomsdata.length
  for (const property in groupByIdRoom) { // se recorre el obj agrupado y se guardan solomlos arrays q cumplan con la condicion .length === numberofdays
    if (groupByIdRoom[property].length === (numberofdays + 1)) {
      listIdsRoomsavailable.push(property)
    }
  }
  return { listIdsRoomsavailable, roomOccupancy, similarRoomsReamining }

}
// query to change ids selected for booking
export const changeIdsOldForNew = async (
  restaurantName: string,
  documentName: string,
  collectionName: string,
  numberOfPerson: number,
  dateInicial: string,
  dateFinal: string
) => {
  let idCalendarData: any[] = []
  const q = query(collection(db, `/${restaurantName}/${documentName}/${collectionName}`),
    where("date", ">=", dateInicial),
    where("date", "<=", dateFinal),
  );
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.data().Guess === numberOfPerson) {
      idCalendarData.push(
        {
          id: doc.id,
          data: doc.data()
        }
      )
    }
  });
  return idCalendarData
}
//method for update bookin selected
export async function updateBooking(idBooking: string, collectionName: string, newData: any) {
  const { idRoom, urlImage, totalPrice, extras, valuePaid, status, startDate, endDate, nigthNumbers, guests, isHostel, weekDaysPrice } = newData
  const docRef = doc(db, `${collectionName}/reservations/reservations/${idBooking}`)
  setDoc(docRef, { idRoom, urlImage, totalPrice, extras, valuePaid, status, startDate, endDate, nigthNumbers, guests, isHostel, weekDaysPrice }, { merge: true })
}
// function update status booking and value advanced
export const updateStatusBooking = async (restaurantName: string, idBooking: string, status: string, valueAdvanced: number) => {
  const docRef = doc(db, `${restaurantName}/reservations/reservations`, idBooking);
  await updateDoc(docRef, {
    status: status,
    valuePaid: valueAdvanced
  });

}
// method update calendar
export async function updateRoomsOfCalendar(idCalendar: string, collectionName: string, newData: any, isHostel: boolean) {
  const docRef = doc(db, `${collectionName}/rooms/calendar/${idCalendar}`)
  const element = isHostel ? "idsHostel" : "ids"
  setDoc(docRef, { [element]: newData }, { merge: true })
}
//query to bring the users associated with one or more restaurants
export async function queryUserRestaurants(collectionName: string, uidUser: string) {
  uid = uidUser ? uidUser : uid
  const qUserRest = query(collection(db, collectionName), where('idEmail', '==', uid));
  const querySnapshot = await getDocs(qUserRest);
  let data = null;
  querySnapshot.forEach((doc) => {
    data = doc.data()
  })
  return data
}
//query to bring bookings whithout payment
export async function queryBookingsWithoutPayment(restaurantName, status) {
  const bookings = query(collection(db, `/${restaurantName}/reservations/reservations`), where("status", '==', status));
  const querySnapshot = await getDocs(bookings);
  let bookingsWhitoutPayment = [];
  querySnapshot.forEach((doc) => {
    bookingsWhitoutPayment.push({
      idBooking: doc.data().idReserve,
      fullname: doc.data().fullname,
      startDate: doc.data().startDate,
      endDate: doc.data().endDate,
      phone: doc.data().phone,
      email: doc.data().email
    })
  })
  const myOrderedArray = _.orderBy(bookingsWhitoutPayment, 'startDate', 'asc');
  return myOrderedArray
}
//query to bring bookings by name
export async function queryBookingsByIdorByName(restaurantName: string, name: string, limitNumber: number) {
  let now = moment().format("YYYY-MM-DD")
  let criterioBusuqeda = name.toUpperCase()
  let dataArray: any[] = []
  const totalDataQuery = query(collection(db, `/${restaurantName}/reservations/reservations`), where("fullname", "==", criterioBusuqeda))
  const first = query(collection(db, `/${restaurantName}/reservations/reservations`), where("fullname", "==", criterioBusuqeda), limit(limitNumber))

  const querySnapshot = await getDocs(first);
  const querySnapshotTotalData = await getDocs(totalDataQuery);
  // Get the last visible document
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  const totalDataNumber = querySnapshotTotalData.docs.length

  querySnapshot.forEach((doc) => {
    if (doc.data().hasOwnProperty('fullname')) {
      const { date, status, fullname, urlImage, totalPrice, idReserve, startDate, endDate, phone, email } = doc.data()
      let dateLimit = moment(startDate).isSameOrAfter(now);
      if (dateLimit) {
        dataArray.push({
          fullname,
          status,
          urlImage,
          date: date.split(' ')[0],
          totalPrice,
          idBooking: idReserve,
          startDate,
          endDate,
          phone,
          email,
        })
      }
    }
  })

  return {
    data: dataArray,
    lastData: lastVisible,
    total: totalDataNumber
  }
}
//query to bring next bookings by name (pagination)
export async function nextBookingsByIdorByName(restaurantName: string, name: string, limitNumber: number, lastVisible: any) {
  let dataArray2: any[] = []
  let criterioBusuqeda = name.toUpperCase()
  const next = query(collection(db, `/${restaurantName}/reservations/reservations`), where("fullname", "==", criterioBusuqeda), startAfter(lastVisible), limit(limitNumber));
  const querySnapshotNext = await getDocs(next);
  querySnapshotNext.forEach((doc) => {
    if (doc.data().hasOwnProperty('fullname')) {
      const { date, status, fullname, urlImage, totalPrice, idReserve, startDate, endDate, phone, email } = doc.data()
      dataArray2.push({
        fullname,
        status,
        urlImage,
        date: date.split(' ')[0],
        totalPrice,
        idBooking: idReserve,
        startDate,
        endDate,
        phone,
        email,
      })

    }
  })
  return dataArray2
}
//query to bring next bookings by status (pagination)
export async function nextBookingDebt(restaurantName: string, limitNumber: number, lastVisible: any, dates: string[], status: string) {
  let dataArray2: any[] = []
  const next = query(collection(db, `/${restaurantName}/reservations/reservations`), where("status", "==", status), where(`startDate`, ">=", dates[0]), where(`startDate`, "<=", dates[1]), startAfter(lastVisible), limit(limitNumber));
  const querySnapshotNext = await getDocs(next);
  querySnapshotNext.forEach((doc) => {
    const { date, status, fullname, urlImage, totalPrice, idReserve, startDate, endDate, phone, email } = doc.data()
    dataArray2.push({
      fullname,
      status,
      urlImage,
      date: date.split(' ')[0],
      totalPrice,
      idBooking: idReserve,
      startDate,
      endDate,
      phone,
      email,
    })
  })
  return dataArray2
}
// this function collects all users
export async function queryAllUserbyRestaurant(collectionName, restName) {
  const qUserRest = query(collection(db, `${collectionName}/${restName}/users`));
  const querySnapshot = await getDocs(qUserRest);
  const arrayData = [];
  querySnapshot.forEach((doc) => {
    arrayData.push({
      data: doc.data()
    })
  })
  return arrayData
}

export async function queryProfileUserbyRestaurant(userId, restName) {
  // this function receives the idEmail of the user to be searched and the restaurant where it is located, then searches for it within the collections and brings its data for display.
  const dataUser = query(collection(db, `portalUsers/${restName}/users`), (where('idEmail', '==', userId)));
  const querySnapshot = await getDocs(dataUser);
  const arrayData = [];
  querySnapshot.forEach((doc) => {
    arrayData.push({
      data: doc.data()
    })
  })
  return arrayData
}

export async function deleteOneUser(userId: string, restName: string, allData: boolean, userRestaurants: any) {
  // this function receives the userId which is equal to idEmail of the user to delete and the restaurant where he/she is located, then it looks for him/her inside the collections specifying the references and deleting his/her documents.
  const docuRef = doc(db, `portalUsers/${restName}/users/${userId}`)
  await deleteDoc(docuRef)

  const docRef = doc(db, `portalUsers/Restaurants/Users/${userId}`)
  if (allData) {
    await deleteDoc(docRef)
  } else {
    const restaurant = userRestaurants.restaurants
    const index = restaurant.indexOf(restName);
    if (index > -1) {
      restaurant.splice(index, 1);
    }
    await setDoc(docRef, { restaurants: restaurant }, { merge: true })
  }
}

export async function queryCreateUsers(restName: string, name: string, role: string, user: any, urlImage: string, googleAndFacebook: boolean) {
  //Function to create user receives values from PageHeader and takes them to the createUserWithEmailAndPassword method, then looks for the firestore reference where the users are created in the restaurants, then looks for the reference and creates in the Restaurants document
  //const infoUsuario = await createUserWithEmailAndPassword(auth, email, password)

  const docuRef = doc(db, `portalUsers/${restName}/users/${user.uid}`)
  setDoc(docuRef, { name: name, email: user.email, idEmail: user.uid, role: role })

  const docRef = doc(db, `portalUsers/Restaurants/Users/${user.uid}`)
  const sed = setDoc(docRef, { idEmail: user.uid, temporalPass: googleAndFacebook ? false : true, profileImage: urlImage, restaurants: [restName] })
  return sed
}
export const restoreDocumentsLanguage = async (restName: string, i18nextLng: string) => {
  const getLanguage = await queryData(`${restName}/language/${i18nextLng}`);
  const dataLanguage = getLanguage.docs;
  if (dataLanguage.length > 0) {
    return i18nextLng
  } else {
    const documentRef = doc(db, restName, 'language');
    try {
      const documentSnapshot = await getDoc(documentRef);
      if (documentSnapshot.exists()) {
        const documentData = documentSnapshot.data();
        return documentData.name
      } else {
        return false
      }
    } catch (error) {
      return error;
    }
  }
};

export async function updateTemporalPassword(uid: string, temporalPass: boolean) {
  const docRef = doc(db, `portalUsers/Restaurants/Users/${uid}`)
  setDoc(docRef, { temporalPass: temporalPass }, { merge: true })
}

export async function addUserOtherRestaurant(restName, user, role) {
  const docuRef = doc(db, `portalUsers/${restName}/users/${user.uid}`)
  await setDoc(docuRef, { name: user.displayName ? user.displayName : '', email: user.email, idEmail: user.uid, role: role })
  let queryCollection = doc(db, 'portalUsers/Restaurants/Users', user.uid);
  const dataDoc = await getDoc(queryCollection)
  if (dataDoc) {
    const data = dataDoc?.data()
    const restaurants = data.restaurants
    restaurants.push(restName)
    const docRef = doc(db, `portalUsers/Restaurants/Users/${user.uid}`)
    await setDoc(docRef, { idEmail: user.uid, restaurants: restaurants })
  }
}

export async function updateUsers(userId, Name, Role, restName, roleValidation) {
  // This function is used to update user data validating whether they are administrators or not, it receives parameters, creates the database reference and updates the corresponding fields.
  if (roleValidation === "is_administrator") {
    const docRef = doc(db, `portalUsers/${restName}/users`, userId);
    await updateDoc(docRef, {
      name: Name,
      role: Role
    });
  } else {
    const docRef = doc(db, `/portalUsers/${restName}/users`, userId);
    await updateDoc(docRef, {
      name: Name
    });
  }
}
export async function updateImagesUser(userId, restName, imageCover, key) {
  // This function is used to update user data validating whether they are administrators or not, it receives parameters, creates the database reference and updates the corresponding fields.
  const docRef = doc(db, `portalUsers/Restaurants/Users`, userId);
  await updateDoc(docRef, {
    [key]: imageCover
  });

}


// this function collects role
export async function queryRoleUserbyRestaurant(collectionName, restName) {
  const qUserRest = query(collection(db, `${collectionName}/${restName}/users`), where('idEmail', '==', uid));
  const querySnapshot = await getDocs(qUserRest);
  let role = "";
  querySnapshot.forEach((doc) => {
    role = doc.data().role
    localStorage.setItem("role", role)
  })
  return role
}
export async function queryImagesUserbyRestaurant(userid) {
  const qUserRest = query(collection(db, `portalUsers/Restaurants/Users`), where('idEmail', '==', userid));
  const querySnapshot = await getDocs(qUserRest);
  let cover = "";
  let profileImage = ""
  let arrayImagesUser = []
  querySnapshot.forEach((doc) => {
    cover = doc.data().frontPageImage
    profileImage = doc.data().profileImage
    arrayImagesUser.push(cover, profileImage)
  })
  return arrayImagesUser
}

//update state of order
export async function updateStateOrder(collectionName, idOrder, status, tableNumber) {
  // console.log(collectionName,idOrder,status,tableNumber)
  const orderRef = doc(db, `/${collectionName}/orders/orders`, idOrder);
  await updateDoc(orderRef, { state: status });
  if (status === 'delivered') {
    await updateDoc(orderRef, { isPaid: true });
  }
  if (status === "prepared" && tableNumber === "pickup") {
    await sendSms(collectionName, idOrder, "sms")
  }
}
//function update all product to prepared state
export async function updateAllProducts(collectionName, item, viewbartender, tableslayout) {
  let countElementos = 0;
  let countCombos = 0;
  const orderRef = doc(db, `/${collectionName}/orders/orders`, item.idOrder);
  const allproductsprepared = item.allProductsarray.map(function (element: any) {
    item.products.forEach(product => {
      if (product === element) {
        element["state"] = "prepared"
        element["DatePrepared"] = getDateFormat()
      }
      if (viewbartender !== undefined) {
        if (element.combo.status) {
          if (element.combo.drink.idDrinkCombo === product.combo.drink.idDrinkCombo) {
            element.combo.drink["state"] = "prepared"
          }

        }
      }
    });

    if (element.hasOwnProperty('state')) {
      countElementos = countElementos + 1
    }
    if (element.combo.status) {
      countCombos = countCombos + 1
    }
    if (element.combo.drink.hasOwnProperty('state')) {
      countElementos = countElementos + 1
    }
    return element
  });

  const inventoryStorage = localStorage.getItem("inventory") ?? "";
  if (inventoryStorage === "true") {
    let now = moment();
    allproductsprepared.forEach(async (product: any) => {
      await prepationDiscount(collectionName, product.categoryId, product.productId, item.idOrder, now, product.quantity)
    });
  }
  try {
    await updateDoc(orderRef, {
      products: allproductsprepared
    });
  } catch (error) {
    console.log(error)
  }
  if (countElementos === (allproductsprepared.length + countCombos)) {
    try {
      await updateStateOrder(collectionName, item.idOrder, "prepared", item.tableNumber)
      //aca debo actualizar mesa a estado preparado
      await changeStatusTable(tableslayout, "prepared", item.tableNumber)
    } catch (error) {
      console.log(error)
    }
  }

}
//function update each product to prepared state
export async function updateStateProduct(collectionName, item, product, viewbartender, tableslayout) {
  let countElementos = 0;
  let countCombos = 0
  const orderRef = doc(db, `/${collectionName}/orders/orders`, item.idOrder);
  const addStatePrepared = item.allProductsarray.map(function (element: any, i) {
    if (element === product) {
      element["state"] = "prepared"
      element["DatePrepared"] = getDateFormat()
    }
    if (viewbartender !== undefined) {
      if (element.combo.status) {
        if ((element.combo.drink.idDrinkCombo === product.combo.drink.idDrinkCombo)) {
          element.combo.drink["state"] = "prepared"
        }
      }
    }
    if (element.hasOwnProperty('state') && element.state !== 'preparing') {
      countElementos = countElementos + 1
    }
    if (element.combo.status) {
      countCombos = countCombos + 1
    }
    if (element.combo.drink.hasOwnProperty('state')) {
      countElementos = countElementos + 1
    }
    return element
  });
  const inventoryStorage = localStorage.getItem("inventory") ?? "";
  if (inventoryStorage === "true") {
    let now = moment();
    await prepationDiscount(collectionName, product.categoryId, product.productId, item.idOrder, now, product.quantity)
  }
  try {
    await updateDoc(orderRef, { products: addStatePrepared });
  } catch (error) {
    console.log(error)
  }
  if (countElementos === (addStatePrepared.length + countCombos)) {
    try {
      await updateStateOrder(collectionName, item.idOrder, "prepared", item.tableNumber)
      //aca actualizar eatdo mesa a preparado
      await changeStatusTable(tableslayout, "prepared", item.tableNumber)
    } catch (error) {
      console.log(error)
    }
  }
}
export async function prepationDiscount(collectionName: string, categoryId: string, productId: string, idOrder: string, now: moment.Moment, quantity: number) {
  try {
    let queryCollection = doc(db, `${collectionName}/products/inventory`, `${categoryId}-${productId}`);
    const inventory = await getDoc(queryCollection);
    const inventoryData = inventory?.data()
    if (inventoryData) {
      inventoryData.products.forEach(async (product: any, i: number) => {
        let quantityTotal = product.quantity * quantity
        let history = {
          uidEmail: localStorage.getItem("uidEmail") ?? 'NULL',
          userName: localStorage.getItem("userName") ?? 'NULL',
          description: 'Preparation discount',
          date: now.format('YYYY-MM-DD HH:mm:ss'),
          quantity: parseInt("-" + quantityTotal),
          item: product.productName,
          idOrder: idOrder,
          productId: product.productId,
          price: product.price
        }
        await updateDoc(doc(db, `${collectionName}/inventory/stock`, product.productId), { stock: increment(-quantityTotal) });
        await saveDoc(`${collectionName}/inventory/history`, uniqid(), history)
        const stock = await getDoc(doc(db, `${collectionName}/inventory/stock`, product.productId));
        const stockData = stock?.data()
        if (stockData && parseInt(stockData.alert) > parseInt(stockData.stock)) {
          await saveDoc(`${collectionName}/inventory/notifications`, `${product.productId}-${now.format('YYYYMMDD')}`, {
            productId: product.productId,
            item: product.productName,
            date: now.format('YYYY-MM-DD HH:mm:ss'),
            unitsName: product.unitsName,
            stock: stockData.stock,
            viewed: false
          })
        }
      });
    }
  } catch (error) {
    console.log(error)
  }
}
//function update products to state delivered
export async function updateStateDelivered(collectionName, item, product, tableslayout) {
  // console.log(collectionName,item,product)
  let countElementos = 0;
  let countCombos = 0
  const orderRef = doc(db, `/${collectionName}/orders/orders`, item.idOrder);
  const addStateDelivered = item.allProducts.map(function (element: any) {
    if (element.id === product.id) {
      element["state"] = "delivered"
      element["DateDelivered"] = getDateFormat()
    }
    if (element.combo.status && product.clickDrink) {
      if ((element.combo.drink.idDrinkCombo === product.combo.drink.idDrinkCombo)) {
        element.combo.drink["state"] = "delivered"
      }
    }
    if (element.hasOwnProperty('state') && element.state === 'delivered') {
      countElementos = countElementos + 1
    }
    if (element.combo.status) {
      countCombos = countCombos + 1
    }
    if (element.combo.drink.hasOwnProperty('state') && element.combo.drink.state === 'delivered') {
      countElementos = countElementos + 1
    }
    return element
  });
  try {
    await updateDoc(orderRef, {
      products: addStateDelivered
    });
  } catch (error) {
    console.log(error)
  }
  if (countElementos === (addStateDelivered.length + countCombos)) {
    try {
      await updateStateOrder(collectionName, item.idOrder, "delivered", item.tableNumber)
      //aca actualizar eatdo mesa a preparado
      await changeStatusTable(tableslayout, "delivered", item.tableNumber)
    } catch (error) {
      console.log(error)
    }
  }
}
//function update all products to state delivered
export async function updateAllProductsDelivered(collectionName, item, tableslayout) {
  // console.log(collectionName,item)
  const orderRef = doc(db, `/${collectionName}/orders/orders`, item.idOrder);
  const addStateDelivered = item.allProducts.map(function (element: any) {
    element["state"] = "delivered"
    element["DateDelivered"] = getDateFormat()
    if (element.combo.status) {
      element.combo.drink["state"] = "delivered"
    }
    return element
  });

  try {
    await updateDoc(orderRef, {
      products: addStateDelivered
    });
    await updateStateOrder(collectionName, item.idOrder, 'delivered', item.tableNumber)
    //aca actualizar eatdo mesa a preparado
    await changeStatusTable(tableslayout, "delivered", item.tableNumber)
  } catch (error) {
    console.log(error)
  }

}

//Get realtime updates with Cloud Firestore 
export async function realTimeOrderGet(collectionName, idOrder) {
  // eslint-disable-next-line
  const unsub = onSnapshot(doc(db, `/${collectionName}/orders/orders`, idOrder), (doc) => {
    console.log("Current data: ", doc.data());
  });
}
//Listen to multiple documents in a collection
export async function ordersUpdtRealtime(collectionName, state) {
  const q = query(collection(db, `/${collectionName}/orders/orders`), where("state", "==", state));
  // const orders = [];

  const orders = await new Promise(function (resolve, reject) {
    onSnapshot(q, (querySnapshot) => {
      const orders1 = []
      querySnapshot.forEach((doc) => {
        orders1.push({
          idOrder: doc.id,
          date: doc.data().date,
          products: doc.data().products,
          state: doc.data().state,
          uidEmail: doc.data().uidEmail,
          uidPhone: doc.data().uidPhone

        })
      });

      if (orders1.length > 0) {
        resolve(orders1)

      }
    });
  })
  return orders
}

//function get Date 
function getDateFormat(): string {
  let date_ob = new Date();
  let day = ("0" + date_ob.getDate()).slice(-2);
  let month = ("0" + (date_ob.getMonth() + 1)).slice(-2);
  let year = date_ob.getFullYear();
  let hours = date_ob.getHours();
  let minutes = date_ob.getMinutes();
  let seconds = date_ob.getSeconds();
  let dateFormat = year + "-" + month + "-" + day + " " + hours + ":" + minutes + ":" + seconds
  return dateFormat
}

export async function getDataRegularDiscounts(restName, type) {

  const queryDataDiscounts = query(collection(db, `${restName}/discounts/${type}`))

  const querySnapshot = await getDocs(queryDataDiscounts);
  const arrayData = [];
  querySnapshot.forEach((doc) => {
    arrayData.push({
      data: doc.data(),
      id: doc.id
    })
  })
  return arrayData
}

export async function getDataDiscounts(restName, type) {

  const queryDataDiscounts = query(collection(db, `${restName}/discounts/${type}`))

  const querySnapshot = await getDocs(queryDataDiscounts);
  const arrayData = [];
  querySnapshot.forEach((doc) => {
    arrayData.push({
      data: doc.data(),
      id: doc.id
    })
  })
  return arrayData
}

export async function getDiscounts(restName: string, type: string, currentDay: string, discountSecret: string) {
  const collectionDb = collection(db, `${restName}/discounts/${type}`)
  const queryDiscounts = type === "regularDiscounts" ?
    query(collectionDb, where('active', '==', true), where('day', '==', currentDay)) :
    query(collectionDb, where('discountSecret', '==', discountSecret), where('active', '==', true))

  const querySnapshot = await getDocs(queryDiscounts);
  const arrayData: any[] = [];
  querySnapshot.forEach((doc) => {
    arrayData.push({
      data: doc.data(),
      id: doc.id
    })
  })
  return arrayData
}

export const resetPassword = async (email: string) => {
  return await sendPasswordResetEmail(auth, email)
}

export const confirmPasswordchange = async (oobCode: any, password: string) => {
  return await confirmPasswordReset(auth, oobCode, password)
}

//Analytics
//query search information of orders per day
export async function queryOrdersByPeriods(collectionName: string, dateinicial: string, datefinal: string, type: string) {
  let DatesFilter = []
  let q = query(collection(db, `/${collectionName}/orders/orders`),
    where("date", ">=", dateinicial),
    where("date", "<=", datefinal),
    where("isPaid", "==", true)
  );

  if (type && type === 'bestSellers') q = query(collection(db, collectionName), where("date", ">=", dateinicial), where("date", "<=", datefinal),);

  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    DatesFilter.push(doc.data())
  });
  return DatesFilter
}

export async function queryCreditByPeriods(collectionName, dateinicial, datefinal) {
  const formattedInicio = dateinicial.toISOString().split('.')[0].replace('T', ' ');
  const formattedFin = datefinal.toISOString().split('.')[0].replace('T', ' ');
  let datesFilter = []
  const q = query(collection(db, `/${collectionName}/credit/history`),
    where("date", ">=", formattedInicio), where("date", "<=", formattedFin), where("channel", "==", "whatsapp"));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    datesFilter.push(doc.data())
  });
  return datesFilter
}

//transactions

export async function queryHistoryCreditByRestaurant(collectionName: string, BringAll: boolean, dateinicial: any, datefinal: any, phone: string) {
  let DataHistory = []
  let status = ""
  let q = query(collection(db, `/${collectionName}/credit/history`));
  if (!BringAll) {
    q = query(collection(db, `/${collectionName}/credit/history`), where("date", ">=", dateinicial), where("date", "<=", datefinal));
  }
  if (phone) {
    if (dateinicial && datefinal) {
      q = query(collection(db, `/${collectionName}/credit/history`), where("phone", "==", phone), where("date", ">=", dateinicial), where("date", "<=", datefinal));
    } else {
      q = query(collection(db, `/${collectionName}/credit/history`), where("phone", "==", phone));
    }
  }
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let service = ""
    let phone = doc.data().hasOwnProperty('phone') ? doc.data().phone : ""
    if (doc.data().hasOwnProperty('orderId')) {
      status = 'Service Cost'
    } else if (doc.data().hasOwnProperty('channel')) {
      status = 'Service Cost'
      service = doc.data().channel
    } else {
      status = 'Recharge'
    }
    if (doc.data().hasOwnProperty('idBooking')) {
      status = 'Service Cost'
      for (const property in doc.data()) {
        if (typeof doc.data()[property] === 'object') {
          let statuspayment = doc.data()[property]['status']
          let amount = doc.data()[property]['amount']
          if (statuspayment === "fully paid") {
            DataHistory.push({
              id: doc.id,
              number: doc.id,
              issuedDate: doc.data()[property]['date'],
              amount,
              currency: '$',
              status,
              service: service,
              phone: phone,
              message: doc.data().message ?? "",
              statuspayment
            })
          }
          if (statuspayment === "partial payment") {
            DataHistory.push({
              id: doc.id,
              number: doc.id,
              issuedDate: doc.data()[property]['date'],
              amount,
              currency: '$',
              status,
              service: service,
              phone: phone,
              message: doc.data().message ?? "",
              statuspayment
            })
          }

        }
      }

    } else {
      DataHistory.push({
        id: doc.id,
        number: doc.id,
        issuedDate: doc.data().date,
        amount: doc.data().amount,
        currency: '$',
        status: status,
        service: service,
        phone: phone,
        message: doc.data().message ?? ""
      })

    }
  });
  return DataHistory

}

export async function getDataByDate(collectionName: string, dateinicial: string, datefinal: string, booking: boolean = false, pendingForPayment: boolean = false) {
  let q = query(collection(db, collectionName));

  if (dateinicial && datefinal) {
    q = query(collection(db, collectionName), where(`date`, ">=", dateinicial), where(`date`, "<=", datefinal))
    // q = query(collection(db, collectionName ), where(`${statuspaid}.date`, ">=", dateinicial), where(`${statuspaid}.date`, "<=", datefinal))
  }
  let dataHistory = []
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (booking) {
      if (pendingForPayment) {
        if (doc.data().status === "pending payment" || doc.data().status === "partial payment") {
          dataHistory.push({
            id: doc.id,
            data: doc.data()
          })
        }
      } else {
        if (doc.data().status === "partial payment" || doc.data().status === "fully paid") {
          dataHistory.push({
            id: doc.id,
            data: doc.data()
          })
        }

      }
    } else {
      dataHistory.push({
        id: doc.id,
        data: doc.data()
      })

    }
  })
  // console.log("dataHistory",dataHistory)
  return dataHistory
}
//consulta para trear el history de las bookings
export async function queryHistoryBookings(collectionName: any, dateIncial: string, dateFinal: string, currenDtataSplit: number, statusOption: string, rentCart: boolean) {
  let dataHistory = []
  let dates = []
  let dateStart = dateIncial.split(' ')[0]
  let dateFinale = dateFinal.split(' ')[0]
  let dateOne = moment(dateStart);
  let dateTwo = moment(dateFinale);
  if (statusOption === "specific_Date") {
    let result = dateTwo.diff(dateOne, 'days')
    currenDtataSplit = result + 1
  }
  for (let index = 0; index < currenDtataSplit; index++) {
    let day = moment(dateStart).add(index, 'days').format("YYYY-MM-DD")
    dates.push(day)
  }
  const historyBookings = query(collection(db, `${collectionName}`));
  const querySnapshot = await getDocs(historyBookings);
  querySnapshot.forEach((doc) => {
    if (doc.data().hasOwnProperty('idBooking')) {
      for (const property in doc.data()) {
        if (typeof doc.data()[property] === 'object') {
          let dateHistory = moment(doc.data()[property]['date']).format('YYYY-MM-DD')
          dates.forEach(element => {
            if (dateHistory === element) {
              dataHistory.push(
                {
                  id: doc.id,
                  data: doc.data()[property]
                }
              )
            }
          });
        }
      }
    } else {
      if (rentCart) {
        if (doc.data().hasOwnProperty('orderId')) {
          let dateHistory = moment(doc.data().date).format('YYYY-MM-DD')
          dates.forEach(element => {
            if (dateHistory === element) {
              dataHistory.push(
                {
                  id: doc.id,
                  data: doc.data()
                }
              )
            }
          });
        }
      }
    }
  })

  return dataHistory
}
// query to bring debt bookings for date (analitycs booking)
export async function getDataDebtBookings(collectionName: string, status: string, dateinicial: string, datefinal: string, limitNumber: number) {
  let dataHistory = []
  //se halla el total de los datos sin el limite
  let totalData = query(collection(db, collectionName), where("status", "==", status), where(`startDate`, ">=", dateinicial), where(`startDate`, "<=", datefinal))
  const querySnapshotTotal = await getDocs(totalData);
  let totalNumber = querySnapshotTotal.docs.length
  //1.Se limita la query
  let q = query(collection(db, collectionName), where("status", "==", status), where(`startDate`, ">=", dateinicial), where(`startDate`, "<=", datefinal), limit(limitNumber))
  const querySnapshot = await getDocs(q);
  //2. se busca el ultimo visible de esa query limitada
  const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  querySnapshot.forEach((doc) => {
    dataHistory.push({
      id: doc.id,
      data: doc.data()
    })
  })
  let result = {
    data: dataHistory,
    lastData: lastVisible,
    total: totalNumber
  }
  return result
}
//transactions invoices POS
const getAmountToOrderRestaurant = (data, amount) => {
  if (data.products !== undefined && data.products.length) {
    if (data.hasOwnProperty('idDiscount')) {
      if (data.hasOwnProperty('cashPayment') && data.cashPayment === true) {
        amount = data.discountsTotalAmount
      } else {
        if (data.hasOwnProperty('amountDiscount')) {
          for (let index = 0; index < data.products.length; index++) {
            const element = data.products[index];
            amount += (element.totalAmount - (data.amountDiscount / data.products.length))
          }
        } else {
          for (let index = 0; index < data.products.length; index++) {
            const element = data.products[index];
            amount += (element.totalAmount - ((element.totalAmount * data.percentageDiscount) / 100))
          }
        }
      }
    } else {
      for (let index = 0; index < data.products.length; index++) {
        const element = data.products[index];
        amount += element.totalAmount
      }
    }

  }
  return amount
}

export async function queryHistoryInvoiceByRestaurant2(collectionName: string, BringAll: boolean, dateinicial: string, datefinal: string, type: string) {
  let DataHistory = []
  let q = query(collection(db, collectionName));
  if (!BringAll) {
    q = query(collection(db, collectionName), where("date", ">=", dateinicial), where("date", "<=", datefinal));
  }
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let amount = 0
    let status = ""
    if (type === "OrdersRestaurant") {
      amount = getAmountToOrderRestaurant(doc.data(), amount)
    }
    if (doc.data().hasOwnProperty("valuePaid")) {
      amount = doc.data().valuePaid
    }
    if (type === "bookingOfRoom") {
      status = doc.data().status
    } else {
      status = doc.data().state
    }
    DataHistory.push({
      id: doc.id,
      number: doc.id,
      issuedDate: doc.data().date,
      amount: amount,
      // data:doc.data(),
      currency: '$',
      status: status,
      tax: doc.data().tax,
      type
    })
  })

  return DataHistory

}
export async function queryHistoryInvoiceByRestaurant(collectionName: string, BringAll: boolean, dateinicial: string, datefinal: string, phone: string) {
  // console.log(collectionName, BringAll, dateinicial, datefinal, phone)
  let DataHistory = []
  let q = query(collection(db, `/${collectionName}/orders/orders`));
  if (!BringAll) {
    q = query(collection(db, `/${collectionName}/orders/orders`), where("date", ">=", dateinicial), where("date", "<=", datefinal));
  }
  // if(phone) {
  //   if(dateinicial && datefinal) {
  //     q = query(collection(db,`/${collectionName}/credit/history`), where("phone", "==", phone), where("date", ">=", dateinicial), where("date", "<=", datefinal));
  //   } else {
  //     q = query(collection(db,`/${collectionName}/credit/history`), where("phone", "==", phone));
  //   }
  // }
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    let amount = 0
    if (doc.data().products !== undefined && doc.data().products.length) {
      if (doc.data().hasOwnProperty('idDiscount')) {
        if (doc.data().hasOwnProperty('cashPayment') && doc.data().cashPayment === true) {
          amount = doc.data().discountsTotalAmount
        } else {
          if (doc.data().hasOwnProperty('amountDiscount')) {
            // console.log(doc.data())
            for (let index = 0; index < doc.data().products.length; index++) {
              const element = doc.data().products[index];
              amount += (element.totalAmount - (doc.data().amountDiscount / doc.data().products.length))
            }
          } else {
            // console.log(doc.data())
            for (let index = 0; index < doc.data().products.length; index++) {
              const element = doc.data().products[index];
              amount += (element.totalAmount - ((element.totalAmount * doc.data().percentageDiscount) / 100))
            }
          }
        }
      } else {
        for (let index = 0; index < doc.data().products.length; index++) {
          const element = doc.data().products[index];
          amount += element.totalAmount
        }
      }

    }
    if (doc.data().date && doc.data().products.length > 0) {
      DataHistory.push({
        id: doc.id,
        number: doc.id,
        issuedDate: doc.data().date,
        amount: amount,
        // data:doc.data(),
        currency: '$',
        status: doc.data().state,
        tax: doc.data().tax,
      })
    }
  });
  return DataHistory

}

export async function queryHistoryCreditByRestaurantSingle(collectionName: string, id: string) {
  let DataHistorySingle = []
  let status = ""
  let q = query(collection(db, `/${collectionName}/credit/history`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.id === id) {
      if (doc.data().hasOwnProperty('orderId')) {
        status = 'Service Cost'
      } else {
        status = 'Recharge'
      }
      if (doc.data().hasOwnProperty('idBooking')) {
        status = 'Service Cost'
        let statusPayment = localStorage.getItem("statusPayment_booking");
        for (const property in doc.data()) {
          if (typeof doc.data()[property] === 'object') {
            let statuspay = doc.data()[property]['status']
            let amount = doc.data()[property]['amount']
            let date = doc.data()[property]['date']
            if (statuspay === statusPayment) {
              DataHistorySingle.push({
                id: doc.id,
                number: doc.id,
                issuedDate: date,
                amount: amount,
                currency: '$',
                status: status
              })
            }
          }
        }
      } else {
        DataHistorySingle.push({
          id: doc.id,
          number: doc.id,
          issuedDate: doc.data().date,
          amount: doc.data().amount,
          currency: '$',
          status: status
        })
      }
    }
  });
  return DataHistorySingle[0]

}

export async function queryHistoryInvoiceByRestaurantSingle(collectionName: string, id: string) {
  let DataHistorySingle = []
  // let status = ""
  let q = query(collection(db, `/${collectionName}/orders/orders`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.id === id) {
      let amount = 0
      let cartAmount = 0
      let amountDiscount = 0
      let percentageDiscount = 0
      let discount = 0
      let simbolDiscount = ''
      let cashCode = ''
      let idDiscount = false
      if (doc.data().products !== undefined && doc.data().products.length) {
        if (doc.data().hasOwnProperty('idDiscount')) {
          idDiscount = true
          if (doc.data().hasOwnProperty('cashPayment') && doc.data().cashPayment === true) {
            amount = doc.data().discountsTotalAmount
            cartAmount = doc.data().cartTotal
            cashCode = doc.data().cashCode
            if (doc.data().hasOwnProperty('amountDiscount')) {
              amountDiscount = doc.data().amountDiscount
            } else {
              percentageDiscount = doc.data().percentageDiscount
            }
          } else {
            if (doc.data().hasOwnProperty('amountDiscount')) {
              // console.log(doc.data())
              for (let index = 0; index < doc.data().products.length; index++) {
                const element = doc.data().products[index];
                amount += (element.totalAmount - (doc.data().amountDiscount / doc.data().products.length))
                cartAmount += element.totalAmount
              }
              amountDiscount = doc.data().amountDiscount
            } else {
              // console.log(doc.data())
              for (let index = 0; index < doc.data().products.length; index++) {
                const element = doc.data().products[index];
                amount += (element.totalAmount - ((element.totalAmount * doc.data().percentageDiscount) / 100))
                cartAmount += element.totalAmount
              }
              percentageDiscount = doc.data().percentageDiscount
            }
          }
        } else {
          idDiscount = false
          for (let index = 0; index < doc.data().products.length; index++) {
            const element = doc.data().products[index];
            amount += element.totalAmount
            cartAmount += element.totalAmount
          }
        }
      }
      if (amountDiscount > 0) {
        discount = amountDiscount
        simbolDiscount = '$'
      } else if (percentageDiscount > 0) {
        discount = percentageDiscount
        simbolDiscount = '%'
      } else {
        discount = 0
        simbolDiscount = 'No aplica'
      }
      DataHistorySingle.push({
        id: doc.id,
        number: doc.id,
        issuedDate: doc.data().date,
        products: doc.data().products,
        cartAmount: cartAmount,
        amount: amount,
        amountDiscount: discount,
        simbolDiscount: simbolDiscount,
        cashCode: cashCode ? cashCode : '',
        currency: '$',
        status: doc.data().state,
        notes: doc.data().notes ? doc.data().notes : '',
        idDiscount: idDiscount,
        tax: doc.data().tax,
        // data: doc.data()
      })
    }
  });
  return DataHistorySingle[0]

}
export async function queryHistoryInvoiceByAvenue(avenue: string, id: string, typeCollection: string) {
  let DataHistorySingle = []
  // let status = ""
  let path: string = ""
  if (typeCollection === "Services") {
    path = `/${avenue}/services/calendar`
  }
  if (typeCollection === "bookingOfRoom") {
    path = `/${avenue}/reservations/reservations`
  }
  if (typeCollection === "rentCar") {
    path = `/${avenue}/products/reservation`
  }
  let q = query(collection(db, path));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.id === id) {
      let objData = {
        id: doc.id,
        number: doc.id,
        issuedDate: doc.data().date,
        name: "",
        guests: 0,
        currency: '$',
        status: "",
        dateReserve: "",
        total: 0,
        stardate: "",
        endDate: "",
        nigthNumbers: 0,
        hourReserve: "",
        nameService: "",
        valuePaid: doc.data().valuePaid,
        email: doc.data().email,
        phone: doc.data().phone,
      }
      if (typeCollection === "bookingOfRoom") {
        objData.name = doc.data().fullname
        objData.guests = doc.data().guests
        objData.nigthNumbers = doc.data().nigthNumbers
        objData.status = doc.data().status
        objData.stardate = doc.data().startDate
        objData.endDate = doc.data().endDate
        objData.total = doc.data().totalPrice
      }
      if (typeCollection === "Services") {
        objData.name = doc.data().fullName
        objData.status = doc.data().state
        objData.dateReserve = doc.data().dateReserve
        objData.total = doc.data().totalAmount
        objData.hourReserve = doc.data().hourReserve
        objData.nameService = doc.data().nameService
      }

      DataHistorySingle.push(objData)
    }
  });
  return DataHistorySingle[0]

}

export async function createJoinRestaurant(collectionName: string, restaurants: string[]) {
  new Promise((resolve, reject) => {
    restaurants.forEach(async element => {
      const result = await queryDoc(element, 'relationship')
      const data = result?.data();
      if (data === undefined) {
        await saveDoc(element, 'relationship', { joinRestaurants: [collectionName] });
        saveDoc(element, 'options', {
          joinRestaurants: true
        });
      } else {
        const newItem = [...data.joinRestaurants, ...[collectionName]]
        await saveDoc(element, 'relationship', { joinRestaurants: newItem });
      }
    })
  })
  setDoc(doc(db, `${collectionName}/relationship`), { joinRestaurants: restaurants })
  saveDoc(collectionName, 'options', {
    joinRestaurants: true
  });
}

export async function validDateCalendar(collectionName: string, dateInicial: string, edit: boolean, category: string, id: string) {
  console.log(collectionName, dateInicial, edit, category, id)

  const q = query(collection(db, collectionName), where("category", "==", category));
  const querySnapshot = await getDocs(q);
  console.log(querySnapshot.docs)
  let valid = false

  if (edit) {
    querySnapshot.forEach((doc) => {
      if (doc.data().dateBudgetFinish < dateInicial && doc.data().dateBudgetFinish !== '0' && !doc.data().disabled && doc.id !== id) {
        console.log(doc.data(), doc.id)
        valid = true
      }
    })
  } else {
    if (querySnapshot.docs.length === 0) {
      valid = true
    } else {
      querySnapshot.forEach((doc) => {
        console.log(doc.data(), doc.id)
        if (doc.data().dateBudgetFinish < dateInicial && doc.data().dateBudgetFinish !== '0' && !doc.data().disabled) {
          valid = true
        }
      })
    }
  }
  return valid
}

export async function queryDataByPeriods(collectionName: string, dateinicial: string, datefinal: string, state: boolean) {
  let DatesFilter = []
  let q = query(collection(db, collectionName),
    where("date", ">=", dateinicial),
    where("date", "<=", datefinal)
  );

  if (state) {
    q = query(collection(db, collectionName),
      where("date", ">=", dateinicial),
      where("date", "<=", datefinal),
      where('isPaid', '==', true)
    );
  }
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    DatesFilter.push(doc.data())
  });
  return DatesFilter
}

export async function noRestName() {
  const Promesa = new Promise(function (resolve, reject) {
    const queryItems = queryUserRestaurants('portalUsers/Restaurants/Users', null);
    resolve(queryItems);
  });

  const Promesa2 = new Promise(function (resolve, reject) {
    Promesa.then((usersRestaurants: any) => {
      if (usersRestaurants && usersRestaurants.restaurants.length > 0) {
        let firstRestName = usersRestaurants.restaurants[0];
        if (firstRestName !== "staff") {
          if (!localStorage.getItem('restName')) {
            localStorage.setItem('restName', firstRestName);
            //setRestName(firstRestName)
          } else {
            firstRestName = localStorage.getItem('restName')
            //setRestName(localStorage.getItem('restName'))
          }
        }
      }
    })
  })
  Promesa2.then(() => {
    console.log('Hay restName')
  })
}

//update position tables from layout restaurant
export async function updatePositionsTables(collectionName: string, documentName: string, idFloor: string, idTable: string, positions: any[]) {
  const layoutRef = doc(db, collectionName, documentName);
  const positionX = `${idFloor}.${idTable}.positionX`;
  const positionY = `${idFloor}.${idTable}.positionY`;
  await updateDoc(layoutRef, {
    [positionX]: positions[0],
    [positionY]: positions[1]
  });
}
//update status table booking
export const updateStatusTableBooking = async (restaurantName: string, idBooking: string, status: string) => {
  const docRef = doc(db, `${restaurantName}/layoutRestaurant/calendar`, idBooking);
  await updateDoc(docRef, {
    status: status,
  });

}
//query to bring all reservations from table Reservation
export async function queryBookingsTable(restaurantName) {
  let now = moment().format("YYYY-MM-DD")
  console.log("now", now)
  const bookings = query(collection(db, `/${restaurantName}/layoutRestaurant/calendar`));
  const querySnapshot = await getDocs(bookings);
  let arrayData = [];
  let count = 0
  querySnapshot.forEach((doc) => {
    let color = genrateColor()
    let starDate = doc.data().startDate.split(' ')
    let EndDate = doc.data().endDate.split(' ')
    let dateLimit = moment(starDate[0]).isSameOrAfter(now);
    if (dateLimit) {
      arrayData.push({
        id: count.toString(),
        allDay: false,
        color: color,
        description: '',
        table: doc.data().numberTable,
        phone: doc.data().phoneNumber,
        end: EndDate[0] + `T${EndDate[1]}`,
        start: starDate[0] + `T${starDate[1]}`,
        title: doc.data().name,
        idBooking: doc.data().idReserve,
        status: doc.data().status,
      })
      count++

    }
  })

  return arrayData
}

//query traer eventos festivos y otros
export async function queryEventsByCountry(collectionName: string, country: string, holidays = false) {
  const events = query(collection(db, `/${collectionName}/EventCalendar/${country}`));
  const querySnapshot = await getDocs(events);
  let arrayData = [];
  let count = 0
  querySnapshot.forEach((doc) => {
    let flag = false
    if (doc.data().startDate === doc.data().endDate) {
      flag = true
    }
    let color = genrateColor()
    arrayData.push({
      id: count.toString(),
      allDay: flag,
      color: color,
      description: doc.data().description,
      end: doc.data().endDate + "T15:00:00",
      start: doc.data().startDate + "T15:00:00",
      title: doc.data().event,
      idBooking: doc.data().idEvent,
      category: doc.data().category,
      Holidays: holidays

    })
    count++
  })

  return arrayData
}

//trae las categorias de los eventos dias
export async function queryCategories(counry: string) {
  const cat = query(collection(db, `/portalUsers/EventCalendar/categories`), where('country', "==", counry));
  const querySnapshot = await getDocs(cat);
  const arrayData = [];
  querySnapshot.forEach((doc) => {
    arrayData.push({
      id: doc.id,
      label: doc.data().nameCategory
    })
  })
  let restaurantName = localStorage.getItem("restName") ?? "";
  if (restaurantName) {
    const catRest = query(collection(db, `${restaurantName}/EventCalendar/categories`), where('country', "==", counry));
    const querySnapshotRest = await getDocs(catRest);
    querySnapshotRest.forEach((doc) => {
      arrayData.push({
        id: doc.id,
        label: doc.data().nameCategory
      })
    })
  }
  return arrayData
}
//trae los dias de la semana 
export async function queryWeeksDays(language) {
  const weekDays = query(collection(db, `/portalUsers/weekdays/${language}`));
  const querySnapshot = await getDocs(weekDays);
  const arrayData = [];
  querySnapshot.forEach((doc) => {
    arrayData.push(doc.data())

  })
  return arrayData

}

//actulaizar eventos days de calendar 
export const updateEventCalendar = async (collectionName: string, country: string, idEvent: string, data: any) => {
  const docRef = doc(db, `${collectionName}/EventCalendar/${country}`, idEvent);
  const { event, description, category } = data;
  await updateDoc(docRef, {
    category: category,
    description: description,
    event: event
  });

}

//delete Event calendar
export async function DeleteEventCalendar(collectionName: string, country: string, refIdEvent: string) {
  const docRef = doc(db, `${collectionName}/EventCalendar/${country}/${refIdEvent}`)
  await deleteDoc(docRef)
}
//delete event category
export async function DeleteEventCategory(collectionName: string, refIdCategory: string) {
  const docRef = doc(db, `${collectionName}/EventCalendar/categories/${refIdCategory}`)
  await deleteDoc(docRef)
}

//query traer eventos sin categoria
export async function queryEventsWithOutcategory(collectionName: string, country: string) {
  const events = query(collection(db, `/${collectionName}/EventCalendar/${country}`), where('category', "==", ""));
  const querySnapshot = await getDocs(events);
  let arrayData = [];
  querySnapshot.forEach((doc) => {
    arrayData.push(doc.data())
  })

  return arrayData
}
export async function queryProductsById(collectionName: string, id: string) {
  let data = []
  const q = query(collection(db, collectionName), where("id", "==", id))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    data.push(doc.data())
  })
  return data
}

export async function queryReservationByReview(collectionName: string, key: string, value: any) {
  let data = []
  const q = query(collection(db, collectionName), where(key, "==", value))
  const querySnapshot = await getDocs(q)
  querySnapshot.forEach((doc) => {
    data.push(doc.data())
  })
  return data
}

export const getHolidays = async (dateInicial: string, dateFinal: string, country: any, restaurantName: string) => {

  let listIdsEventHolidaysPortal: any[] = []
  let listIdsEventHolidaysRestaurant: any[] = []

  const q = query(collection(db, `/portalUsers/EventCalendar/${country.country}`));
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (doc.data().startDate >= dateInicial && doc.data().endDate <= dateFinal) {
      listIdsEventHolidaysPortal.push(doc.data())
    }
  })

  const q2 = query(collection(db, `/${restaurantName}/EventCalendar/${country.country}`));
  const querySnapshotTwo = await getDocs(q2);
  querySnapshotTwo.forEach((doc) => {
    if (doc.data().startDate >= dateInicial && doc.data().endDate <= dateFinal) {
      listIdsEventHolidaysRestaurant.push(doc.data())
    }
  })

  return { listIdsEventHolidaysPortal, listIdsEventHolidaysRestaurant }
}

//query to bring all calendar resrvation room from bd
export async function queryCalendarBookingRoom(restaurantName) {
  const bookings = query(collection(db, `/${restaurantName}/rooms/calendar`));
  const querySnapshot = await getDocs(bookings);
  let arrayDatacalendar = [];
  querySnapshot.forEach((doc) => {
    arrayDatacalendar.push({
      data: doc.data(),
      id: doc.id
    })
  })
  return arrayDatacalendar
}

export const updatePersonalServices = async (restaurantName: string, idService: string, data: []) => {
  const docRef = doc(db, `${restaurantName}/services/services`, idService);
  await updateDoc(docRef, {
    personal: data
  });
}

export async function updateEmployeeServicesExceptions(id, restName, arrayExceptions, key) {
  const docRef = doc(db, `${restName}/services/personal`, id);
  await updateDoc(docRef, {
    [key]: arrayExceptions
  });

}


export async function getDataGraphAnalitycsByFilter(
  collectionName: string,
  dateinicial: string,
  datefinal: string,
  state: string[],
  booking: boolean,
  type: string,
) {
  let q = query(collection(db, collectionName));

  if (dateinicial && datefinal) {
    if (type) {
      q = query(collection(db, collectionName), where(`date`, ">=", dateinicial), where(`date`, "<=", datefinal), where(`type`, "==", type))
    } else {
      q = query(collection(db, collectionName), where(`date`, ">=", dateinicial), where(`date`, "<=", datefinal))
    }
  }
  let dataHistory = []
  const querySnapshot = await getDocs(q);
  querySnapshot.forEach((doc) => {
    if (booking) {
      if (state.includes(doc.data().state)) {
        dataHistory.push({
          id: doc.id,
          data: doc.data()
        })
      }
    } else {
      dataHistory.push({
        id: doc.id,
        data: doc.data()
      })
    }
  })
  return dataHistory
}
export interface IFirestoreQuery {
  property: string;
  operator: WhereFilterOp;
  value: any;
}

export const queryDynamic = async (collectionName: string, arrayConditions: IFirestoreQuery[]) => {
  const filter: QueryConstraint[] = arrayConditions.map((condition) =>
    where(condition.property, condition.operator, condition.value)
  )
  const q = query(collection(db, collectionName), ...filter);
  const data: any = await new Promise(function (resolve, reject) {
    const arrayData: { data: DocumentData; id: string }[] = [];
    onSnapshot(q, { includeMetadataChanges: true }, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        arrayData.push({
          data: change.doc.data(),
          id: change.doc.id
        })
      });
      resolve(arrayData)
    });

  })
  return data;
}
export const updateDocDirectory = async (collectionName: string, docId: string, nombre: any, compania: any, labels: any) => {
  try {
    const docRef = doc(db, collectionName, docId);
    await updateDoc(docRef, {
      name: nombre,
      company: compania,
      labels: labels
    });
  } catch (error) {
    console.error('Error al actualizar el documento:', error);
  }
};

export async function deleteData(path: string) {
  const docRef = doc(db, path);
  try {
    await deleteDoc(docRef);
  } catch (error) {
    console.error('Error al eliminar la información:', error);
  }
}

export const getChats = (id: unknown, collectionn: string, onData: { (data: any): void; (arg0: any[]): void; }) => {
  try {
    firebaseSignIn()
    const q4 = query(collection(db, `red/conversations/${collectionn}`), where("phone", "==", id));
    const unsubscribe = onSnapshot(
      q4,
      (querySnapshot) => {
        const data = [];
        querySnapshot.forEach((item) => {
          data.push(item.data());
        });
        onData(data);
      },
      (error) => {
        console.log("Error al escuchar cambios en la colección:", error);
      }
    );
    return unsubscribe;
  } catch (error) {
    console.log(error);
  }
};



export default firebase;
