import {
  Box,
  Typography,
  styled,
  Fade,
  ClickAwayListener
} from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useLocation } from 'react-router-dom';

const PlanHeaderButton = (props) => {
  const planIcon = props.planIcon;
  const planType = props.planType;
  const handleTooltipClose = props.handleTooltipClose;
  const handleTooltipOpen = props.handleTooltipOpen;
  const isOpen = props.isOpen;
  const { t } = useTranslation();
  const location = useLocation();
  const typeSidebar = location.pathname.split('/')[1];

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      background: '#FFFFFF',
      boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.25)',
      borderRadius: '10px'
    }
  }));

  return (
    <>
      <ClickAwayListener onClickAway={handleTooltipClose}>
        <div>
          <LightTooltip
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 600 }}
            onClose={handleTooltipClose}
            onOpen={handleTooltipOpen}
            onClick={handleTooltipOpen}
            open={isOpen}
            placement="left-start"
            title={
              <Box>
                <Typography sx={{ color: 'black' }}>
                  {t(`You currently have the ${planType?.plan} plan.`)}
                </Typography>
                {planType?.plan === 'Premium' ? (
                  ''
                ) : (
                  <Typography sx={{ color: 'black' }}>
                    {t('If you wish to upgrade your plan,')}
                  </Typography>
                )}
                <Link to={`/${typeSidebar}/dashboards/prices`} style={{color: 'black'}}>
                  {t('Click here.')}
                </Link>
              </Box>
            }
            id="plans-btn"
          >
            <Box>
              <LazyLoadImage
                src={planIcon()}
                width={'55px'}
                height={'39px'}
                alt="Plans"
              />
              <Typography sx={{ textAlign: 'center' }}>
                {t(planType?.plan ? planType.plan : 'Free')}
              </Typography>
            </Box>
          </LightTooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default PlanHeaderButton;
