import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Button,
  Divider,
  FormHelperText,
  Checkbox,
  Link,
  FormControlLabel,
  TextField,
  Typography,
  CircularProgress,
  Zoom,
  Card,
  useMediaQuery,
  useTheme,
  InputAdornment,
  IconButton
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { useSnackbar } from 'notistack';
import { queryUserRestaurants } from 'src/utils/firebase';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import { useState } from 'react';
import { BaseViewPorts } from 'src/theme/viewports/BaseViewPorts';
import { DialogHelpSupport } from 'src/components/HelpSupport/Content/DialogHelpSupport';

const ImgWrapper = styled('img')(
  ({ theme }) => `
    margin-right: ${theme.spacing(1)};
`
);


function LoginFirebaseAuth() {

  const theme = useTheme();
  const viewports = BaseViewPorts()
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { signInWithEmailAndPassword, signInWithGoogle } = useAuth() as any;
  const isMountedRef = useRefMounted();
  const { t }: { t: any } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [showPassword, setShowPassword] = useState(false);

  const handleGoogleClick = async () => {
    try {
      await signInWithGoogle();
    } catch (err) {
      console.error(err);
    }
  };

  const enqueueSnackbarView = (message: string, variant: any) => {
    enqueueSnackbar(t(message), {
      variant: variant,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'center'
      },
      TransitionComponent: Zoom
    });
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <>
      <Button
        fullWidth
        onClick={handleGoogleClick}
        size="large"
        variant="outlined"
        disabled
        sx={{ display: 'none' }}
      >
        <ImgWrapper alt="Google" src="/static/images/logo/google.svg" />
        Sign in with Google
      </Button>
      <Divider sx={{ mt: 4, mb: 2, display: 'none' }}>{t('or')}</Divider>
      <Formik
        initialValues={{
          email: '',
          password: '',
          terms: false,
          submit: null
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email(t('The email provided should be a valid email address'))
            .max(255)
            .required(t('The email field is required')),
          password: Yup.string()
            .max(255)
            .required(t('The password field is required')),
          terms: Yup.boolean().oneOf(
            [true],
            t('You must agree to our terms and conditions')
          )
        })}
        onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
          try {
            //1. authenticates by email and passw
            const result = await signInWithEmailAndPassword(values.email, values.password);
            const UserUid = result.user.uid;
            //2.if exists, the uid of the user is saved in the localstorage
            if (UserUid) {
              localStorage.setItem('login', 'emailAndPassword')
              localStorage.setItem("uidEmail", UserUid);
              localStorage.setItem("userName", result.user.displayName);
              //3. agrega un establecimiento por defecto
              const venues = await queryUserRestaurants('portalUsers/Restaurants/Users', UserUid);
              if (venues && venues.restaurants.length > 0) {
                localStorage.setItem('restName', venues.restaurants[0]);
              }
            }
            if (isMountedRef.current) {
              setStatus({ success: true });
              setSubmitting(false);
            }
          } catch (err) {
            if (err.code === 'auth/wrong-password' || err.code === 'auth/user-not-found') {
              enqueueSnackbarView(t("Invalid email or password"), 'error');
            }
            if (isMountedRef.current) {
              setStatus({ success: false });
              setErrors({ submit: err.message });
              setSubmitting(false);
            }
          }
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values
        }) => (
          <form noValidate onSubmit={(e) => { e.preventDefault(); handleSubmit(e) }}>
            <Card sx={{ padding: 1.5, display: 'flex', flexDirection: 'column', gap: 1 }}>
              <Typography fontSize={'16px'} fontWeight={600}>
                {t('Email address')}
              </Typography>
              <TextField
                id={'loginEmail_input'}
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                // label={t('Email address')}
                placeholder={t('ejemplo@gmail.com')}
                // margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
              />
              <Typography fontSize={'16px'} fontWeight={600}>
                {t('Password')}
              </Typography>
              <TextField
                id={'loginPassword_input'}
                error={Boolean(touched.password && errors.password)}
                fullWidth
                helperText={touched.password && errors.password}
                // label={t('Password')}
                placeholder={t('**********')}
                // margin="normal"
                name="password"
                onBlur={handleBlur}
                onChange={handleChange}
                type={showPassword ? 'text' : 'password'}
                value={values.password}
                variant="outlined"
                InputProps={{
                  endAdornment:
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOffOutlinedIcon /> : <VisibilityOutlinedIcon />}
                      </IconButton>
                    </InputAdornment>
                }}
              />
            </Card>
            <Box
              alignItems="center"
              display="flex"
              justifyContent="space-between"
              marginTop={'2%'}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    id={'loginTerms_check'}
                    checked={values.terms}
                    name="terms"
                    onChange={handleChange}
                  />
                }
                label={
                  <>
                    <Typography fontSize={!viewports.mobile ? '15px' : '12px'}>
                      {t('I accept the')}{' '}
                      <Link component="a" href="https://orderandeatnow.com.au/terms" underline="hover" target="_blank" sx={{ color: '#000000', fontWeight: 700, }}>
                        {t('terms and conditions')}
                      </Link>
                      .
                    </Typography>
                  </>
                }
              />
              <Link component={RouterLink} underline="hover" to="/account/recover-password" sx={{ display: 'none' }}>
                <b>{t('Lost password?')}</b>
              </Link>
            </Box>
            {Boolean(touched.terms && errors.terms) && (
              <FormHelperText error>{errors.terms}</FormHelperText>
            )}
            {!mobile && (
              <Typography textAlign={'right'} sx={{ fontSize: '16px' }}>
                <Link component="a" underline='none' href="#" sx={{ fontWeight: 500, color: '#000000' }}>
                  {t('Forgot your password?')}
                </Link>
              </Typography>
            )}
            <Button
              id='signIn-button'
              sx={{ mt: 3, backgroundColor: "#FF0A38" }}
              startIcon={isSubmitting ? <CircularProgress size="1rem" /> : null}
              disabled={isSubmitting}
              size="large"
              fullWidth
              type="submit"
              variant="contained"
            >
              {t('Login')}
            </Button>
            {mobile && (
              <Typography textAlign={'right'} sx={{ marginTop: '26px', textAlign: 'center', fontSize: '16px' }}>
                <Link component="a" underline='none' href="#" sx={{ fontWeight: 500, color: '#000000' }}>
                  {t('Forgot your password?')}
                </Link>
              </Typography>
            )}
          </form>
        )}
      </Formik>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
          position: 'fixed',
          bottom: 0,
          right: 0,
          paddingRight: 2, // Ajusta este valor para cambiar el margen derecho
          paddingBottom: 2, // Ajusta este valor para cambiar el margen inferior
        }}
      >
        <DialogHelpSupport />
      </Box>
    </>
  );
};

export default LoginFirebaseAuth;
