import { generateJWTToken, getGoogleAccessToken, getDomain } from './generateToken'

export function getDynamicPassword(length: number) {
    let password: string = ""
    const charset = 'ABCDEFGHKMNPQRSTUVWXYZabcdefghkmnpqrstuvwxyz23456789'
    for (var i = 0, n = charset.length; i < length; ++i) {
        password += charset.charAt(Math.floor(Math.random() * n));
    }
    return password
}

export async function changePassword(uidUser: string, password: string) {
    const token = await generateJWTToken();
    const id_token = await getGoogleAccessToken(token);
    let params = { 
        uidUser: uidUser, 
        password: password
    }
    const request = await fetch(getDomain() + "/admin/users/changePassword",
        {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + id_token
            },
        }
    );
    const user = await request.json();
    return user
}