import { useState, useEffect } from 'react';

import { Box, List } from '@mui/material';
import { useLocation, matchPath } from 'react-router-dom';
import { styled } from '@mui/material/styles';

import NavigationMenuItem from './item';
import { MenuItem } from './items';
import { queryUserRestaurants, queryRoleUserbyRestaurant, queryDoc } from 'src/utils/firebase';
import { sidebarMenu } from 'src/utils/sidebarMenu';

const MenuWrapper = styled(Box)(({ theme }) => `
  .MuiList-root {
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > .MuiList-root {
      display: flex;
      flex-direction: row;
    }
  }
`);

const SubMenuWrapper = styled(Box)(({ theme }) => `
    .MuiList-root {
      padding: 0;
      display: flex;
      flex-direction: row;
      
      .MuiList-root .MuiList-root .MuiListItem-root .MuiIconButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 0 2px;
        justify-content: center;
    
        .MuiIconButton-root {
          display: flex;
          background-color: transparent;
          width: 58px;
          border-radius: ${theme.general.borderRadiusLg};
          height: 58px;
          justify-content: center;
          font-size: ${theme.typography.pxToRem(13)};
          padding: 0;
          position: relative;

          .name-wrapper {
            display: none;
            transition: ${theme.transitions.create(['color'])};
          }

          .MuiBadge-root {
            position: absolute;
            right: 8px;
            top: 12px;

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(9)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }
  
          .MuiSvgIcon-root {
            transition: ${theme.transitions.create(['color'])};
            font-size: ${theme.typography.pxToRem(32)};
            color: ${theme.colors.alpha.trueWhite[70]};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.colors.alpha.trueWhite[10]};

            .MuiSvgIcon-root {
              color: ${theme.colors.alpha.trueWhite[100]};
            }
          }
        }
      }
    }
`
);

function NavigationMenu() {
  const [menuItems, setStateMenu] = useState([]);
  const [userRole, setUserRole] = useState('');
  // eslint-disable-next-line
  const [restName, setRestName] = useState<string>(null);
  // eslint-disable-next-line
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [chatbot, setChatbot] = useState<boolean>(false);
  const [options, setOptions] = useState<any>();
  // eslint-disable-next-line
  const [uidUser, setUidUser] = useState<string>(null);
  const [reservationRoom, setReservationRoom] = useState<boolean>(false);

  const { pathname } = useLocation();

  useEffect(() => {
    let itemsnR = [];
    let newObjetMenu = [{
      heading: "",
      items: []
    }]
    let typeSidebar = pathname.split('/')[1]
    const Promesa = new Promise(function (resolve, reject) {
      const queryItems = queryUserRestaurants('portalUsers/Restaurants/Users', null);
      resolve(queryItems);
    });

    const getOptionChatbot = async (restName: string) => {
      const query = await queryDoc(restName, "options")
      const data = query?.data()
      if (data) {
        setChatbot(data.chatbot)
        setReservationRoom(data.reservation)
        setOptions(data)
        if (data.inventory) {
          localStorage.setItem("inventory", 'true')
        } else {
          localStorage.removeItem("inventory")
        }
      }
    }
    const Promesa2 = new Promise(function (resolve, reject) {
      Promesa.then((usersRestaurants: any) => {
        if (usersRestaurants && usersRestaurants.restaurants.length > 0) {
          const firstRestName = usersRestaurants.restaurants[0];
          setOpenDialog(usersRestaurants.temporalPass)
          setUidUser(usersRestaurants.idEmail)
          if (!options) {
            getOptionChatbot(localStorage.getItem('restName') ?? firstRestName)
          }
          if (firstRestName !== "staff") {
            if (!localStorage.getItem('restName')) {
              localStorage.setItem('restName', firstRestName);
              setRestName(firstRestName)
            } else {
              setRestName(localStorage.getItem('restName'))
            }
          }
          resolve(queryRoleUserbyRestaurant('portalUsers', firstRestName));
          usersRestaurants.restaurants.forEach((r) => {
            itemsnR.push({
              name: r,
              link: `/${typeSidebar}/dashboards/analytics`
            });
          });
        }
      });
    });
    Promesa2.then((roleUser: any) => {
      setUserRole(localStorage.getItem('role'));
      const nameRestaurant = localStorage.getItem('restName');
      let menuItemsNew = sidebarMenu(roleUser, typeSidebar, nameRestaurant, itemsnR, options, chatbot, reservationRoom);
      menuItemsNew.forEach((element) => {
        element.items.forEach((e) => {
          if (element.heading === "Restaurants") {
            e.restaurant = true
          }
          newObjetMenu[0].items.push(e)
        })
      })
      setStateMenu(newObjetMenu);
    });
  }, [userRole, pathname, options, chatbot, reservationRoom]);


  const renderNavigationMenuItems = ({
    items,
    path
  }: {
    items: MenuItem[];
    path: string;
  }): JSX.Element => {
    return (
      <SubMenuWrapper>
        <List component="div">
          {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
        </List>
      </SubMenuWrapper>
    )
  }


  const reduceChildRoutes = ({
    ev,
    path,
    item
  }: {
    ev: JSX.Element[];
    path: string;
    item: any;
  }): Array<JSX.Element> => {
    const key = item.name;

    const exactMatch = item.link ? !!matchPath({
      path: item.link,
      end: true
    }, path) : false;

    if (item.items) {
      const partialMatch = item.link ? !!matchPath({
        path: item.link,
        end: false
      }, path) : false;

      ev.push(
        <NavigationMenuItem
          key={key}
          active={partialMatch}
          open={partialMatch}
          name={item.name}
          icon={item.icon}
          link={item.link}
          badge={item.badge}
          restaurant=""
        >
          {renderNavigationMenuItems({
            path,
            items: item.items
          })}
        </NavigationMenuItem>
      );
    } else {
      ev.push(
        <NavigationMenuItem
          key={key}
          active={exactMatch}
          name={item.name}
          link={item.link}
          badge={item.badge}
          icon={item.icon}
          restaurant={item.restaurant}
        />
      );
    }

    return ev;
  }
  return (
    <>
      {menuItems.map((section) => (
        <MenuWrapper key={section.heading}>
          <List component="div">
            {renderNavigationMenuItems({
              items: section.items,
              path: pathname
            })}
          </List>
        </MenuWrapper>
      ))}
    </>
  );
}

export default NavigationMenu;
