import { Box, Chip, useMediaQuery, useTheme } from '@mui/material'
import { collection, onSnapshot } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { db, queryData, updateData } from 'src/utils/firebase'
import StarIcon from '@mui/icons-material/Star';
// import { Scrollbars } from 'react-custom-scrollbars-2';

interface favoritesSectionsI {
    name: string,
    link: string
}

const FavoritesButton = () => {

    const { t }: { t: any } = useTranslation();
    const navigate = useNavigate()
    const theme = useTheme();
    const tablet = useMediaQuery(theme.breakpoints.down('md'));
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    const restName = localStorage.getItem('restName') ?? ""
    const uidEmail = localStorage.getItem('uidEmail') ?? ""
    const typeMenu = localStorage.getItem('typeMenu') ?? ""

    const [favoritesSections, setFavoritesSections] = useState<favoritesSectionsI[]>([])

    useEffect(() => {
        const getFavoritesSection = async () => {
            if (restName && uidEmail) {
                const collectionName = `portalUsers/Restaurants/Users`
                const ref = collection(db, collectionName);
                onSnapshot(ref, (snapshot) => {
                    snapshot.forEach(async (doc) => {
                        if (doc.id === uidEmail) {
                            let docUser = doc.data()
                            if (!docUser.hasOwnProperty('favorites')) {
                                docUser = {
                                    ...doc.data(), ...{
                                        favorites: {
                                            [restName]: []
                                        }
                                    }
                                }
                                await updateData(`portalUsers/Restaurants/Users`, uidEmail, docUser)
                            } else {
                                if (!docUser.favorites[restName]) {
                                    docUser.favorites[`${restName}`] = []
                                    await updateData(`portalUsers/Restaurants/Users`, uidEmail, docUser)
                                }
                            }
                            setFavoritesSections(docUser.favorites[restName])
                        }
                    })
                })
            }
        }
        getFavoritesSection()
    }, [restName, uidEmail])

    const redirect = (link: string) => {
        navigate(link)
    }

    const hasTypeMenuDifference = async () => {
        if (!typeMenu) return

        let change = false
        favoritesSections.forEach((element) => {
            if (typeMenu !== element.link.split('/')[1]) {
                change = true
                let newLink = ''
                element.link.split('/').forEach((oldString, index) => {
                    if (index === 1) {
                        newLink = newLink + '/' + typeMenu
                    } else {
                        if (index !== 0) {
                            newLink = newLink + '/' + oldString
                        }
                    }
                })
                element.link = newLink
            }

        })
        if (change) {
            const dataPortalUsers = await queryData(`portalUsers/Restaurants/Users`)
            const docs = dataPortalUsers.docs
            let docUser = docs.find((doc) => doc.id === uidEmail).data()
            if (docUser.favorites[restName]) {
                docUser.favorites = { ...docUser.favorites, ...{ [restName]: favoritesSections } }
                await updateData(`portalUsers/Restaurants/Users`, uidEmail, docUser)
            }
        }
    }

    if (favoritesSections && favoritesSections.length > 0) {
        hasTypeMenuDifference()
    }

    return favoritesSections && favoritesSections.length > 0 && (
        <>
            <Box sx={{
                // backgroundColor: 'red',
                display: 'flex',
                // gap: 1,
                height: '40px',
                width: tablet ? mobile ? '100%' : '400px' : '700px',
                maxWidth: '1000px',
                overflowX: 'auto',
                overflowY: 'hidden',
                '&::-webkit-scrollbar': {
                    // display: mobile && "none",
                    height: '7px',
                },
                '&::-webkit-scrollbar-thumb': {
                    background: '#ccc',
                    borderRadius: '4px',
                }
            }}>
                {favoritesSections.map((item) => (
                    <Box key={item.link} sx={{ margin: 1 }}>
                        <Chip icon={<StarIcon />} label={t(item.name)} onClick={() => redirect(item.link)} color="primary" size='small' />
                    </Box>
                ))}
            </Box>
        </>
    )
}

export default FavoritesButton