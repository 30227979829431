import { Box } from '@mui/material'
import React from 'react'

const MockupMobile = ({ children }) => {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Box
                sx={{
                    margin: '0',
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                }}
            >
                <Box
                    sx={{
                        margin: 'auto',
                        padding: '10px 10px 10px',
                        width: '300px',
                        height: '650px',
                        boxShadow: '0 0 20px #e2e2e2',
                        borderRadius: '30px',
                        background: 'black',
                        position: 'relative',
                    }}
                >
                    <Box
                        sx={{
                            width: '150px',
                            height: '20px',
                            background: 'black',
                            position: 'absolute',
                            top: '0',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            borderRadius: '0 0 20px 20px',
                            zIndex: 10
                        }}
                    >
                        <span
                            style={{
                                position: 'relative',
                                width: '60px',
                                height: '5px',
                                background: '#d2d2d2',
                                display: 'block',
                                margin: 'auto',
                                marginTop: '5px',
                                borderRadius: '20px',
                            }}
                        ></span>
                    </Box>
                    {children}
                </Box>
            </Box>
        </Box>
    )
}

export default MockupMobile