import { FC, createContext, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { themeCreator } from './base';
import { StyledEngineProvider } from '@mui/material/styles';

export const ThemeContext = createContext((themeName: string): void => { });

const ThemeProviderWrapper: FC = (props) => {
  const curThemeName = localStorage.getItem('appTheme') || 'BoneLightTheme';
  const [themeName, _setThemeName] = useState(curThemeName);
  
  const theme = themeCreator(themeName);
  
  const setThemeName = (themeName: string): void => {
    localStorage.setItem('appTheme', themeName);
    _setThemeName(themeName);
  };

  return (
    <StyledEngineProvider injectFirst>
      {/* <CacheProvider value={cacheRtl}> */}
      <ThemeContext.Provider value={setThemeName}>
        <ThemeProvider theme={theme}>{props.children}</ThemeProvider >
      </ThemeContext.Provider>
      {/* </CacheProvider> */}
    </StyledEngineProvider>
  );
};

export default ThemeProviderWrapper;