import { mock } from 'src/utils/axios';
import type { Data } from 'src/models/user';

let users: Data[] = [
];

mock.onGet('/api/users').reply(() => {
  return [200, { users }];
});

mock.onGet('/api/user').reply((config) => {
  const { userId } = config.params;
  const user = users.find((_user) => _user.id === userId);

  return [200, { user }];
});