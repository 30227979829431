export const dataBackend = {
    "type": "service_account",
    "project_id": "order-and-eat-now",
    "private_key_id": "81631a412ac676b7bb5e5745b5e44a2491b4cbd8",
    "private_key": "-----BEGIN PRIVATE KEY-----\nMIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQC3Ma2oeFZUUs8U\nSri6zMR5dzDVZaL4sFHoAUKFznOxbpl60IMoDtRFFOojCcL2w/dOd2PfEHNDePL5\n6HDSTce5oQFFhn/E5uBG2qwRjcOJCkPllFpX/VMXN5GFvEaR8F7NPGar4Emt8RhD\nCndrtCiwQj9i2Z3+k62KX602czI7l8dMjWfGywGG2tkJoeG5C6gwr1u+tlhNRRt/\nG/4DJ1gfEgjzgvSehFh7lFpUcq/V2WUXOStdVpzPyC4HBxwu+GqQ5iUNlczA0MHn\nj63zTjXBGSnR2XkuRTxF9/kDAXy3CtRUeonTlCIqNPqnxnBtZ5QvfbbAQrP8C0U0\nk/o3UE/DAgMBAAECggEAGL7EoteSbbR71VVci5sCRRgFCCZA88+5WJnm9vUSqppy\nb+T/emc6ZBR1pgL5C2UgIEC/RE/QCgKp63jq2HV6L4hgiBgpZRr8m4WJLZvVGEtD\nLZ0y8OS+lwnCBesPK0JpQ9bPVZMn0K+a499jjzBYOKrSLhOQJGWlUOYomwzHccYN\njYU+PZkse8TStDr3ZqW8SKrAt7TyBEFKo/BR5RLNIe5pGw3HTi5IJfvZweiR89NS\nywdNK9DTmg9XZV+B8L0BgIXe3NUWqh0jUACPwaPulxUNbx/IWMVlXtk3Ms2nIMDT\no3cLY3//NF3gldxNhxyLMfJMYVat13IAN/eMNbBOsQKBgQDqTYEYVV4Q0WAbZ9ZQ\nzVpZzvg6UoMVW3pGFeBb3Zr22W4N9nugbXclyOy0EiwlsQ1gRwvQaX0kgJduXmpg\nfgx7RqZTaT/bo3gxJVBXwUnbnUaP376ZbDWRfnAt4hWjJrrJtzUenYnFy1wZzUNS\nm1Orsljm/sGYGXtVpNJPzFvCsQKBgQDIKJD69mx187eoegcIb+OY5IqDSJyITKjQ\nbjGPZWxj6U2K1FgD5qCoZwiIlMUtYGd7CSwtpgQGl1CETCYDnlQimtk4RHVNzoF1\nS/XcsF7UwOOEpGjTC/+dAdeFJUNqrcvYHUmdvqPICZ9RUyJNC/wZdmCIdCGomShR\nBHahv/mOswKBgEBy3z69RKmlniULs/T395FakAci6TG3vvOGUveSBm0+M7qdgTYI\nxkxbb1i84u3fPMmt4T8LtUOUJ5NRXzt5kJ9cOKYa/ljBJ2+FTwtEfLdvFJjAt+Hl\n1PpXaC4G0WLWZWzU+0SXmu0QWh4yywDu/t3sqe2oR8I5yCYvtzYCBX+RAoGAUa38\nO+2DpT6nm+DwLck7H5Fj1N80wYpG+Yd+Qf4plTwHoLe2kyDyPsaM/O9452OH1vWP\nSbGNwwLMoLBScveRbe2y5vtIw0+aFLvCwSRYDooIToCpJjanQPMXeAYU1NvHa5HI\nEM9TFraWhSFfxgE2B03/Zq+9bIfTo/wmTSuCM3ECgYEA3rS6BHiZeGPzOn2pbzZG\nKx0YJSJZqF+IkptNKSG7/RevXv6J+deQqZT5fu12/p2F9scmAaftmIcmBNoQxH97\n3t3syUp39Fl3QjHSyxqynU+U0A7PTscjQUTPzAtElzNcatlCX1nEtIXAGn9DWt6X\n9dcE3btdk7catpWz4zJwp7s=\n-----END PRIVATE KEY-----\n",
    "client_email": "backend@order-and-eat-now.iam.gserviceaccount.com",
    "client_id": "107772328821837857494",
    "auth_uri": "https://accounts.google.com/o/oauth2/auth",
    "token_uri": "https://oauth2.googleapis.com/token",
    "auth_provider_x509_cert_url": "https://www.googleapis.com/oauth2/v1/certs",
    "client_x509_cert_url": "https://www.googleapis.com/robot/v1/metadata/x509/backend%40order-and-eat-now.iam.gserviceaccount.com"
}