const esJSON = {
  'Sign In': 'Inicia sesion',
  'Tips of the week' : 'Propinas de la semana',
  'Goal': 'Meta',
  'Progress': 'Progreso',
  'sign in': 'Iniciar sesion',
  'see more...' : 'ver más...',
  'Fill in the fields below to sign into your account.': 'Rellene los siguientes campos para acceder a su cuenta.',
  'Your email address here...': 'Su dirección de correo electrónico aquí...',
  'Your password here...': 'Su contraseña aquí...',
  'I accept the': 'Acepto los',
  'terms and conditions': 'términos y condiciones',
  'The email field is required': 'El campo de correo electrónico es obligatorio',
  'The password field is required': 'El campo de la contraseña es obligatorio',
  'The email provided should be a valid email address': 'El correo electrónico proporcionado debe ser una dirección de correo electrónico válida',
  'You must agree to our terms and conditions': 'Debe aceptar nuestros términos y condiciones',
  'Designed with easy format and make orders the priority. Orders will appear as they come, through a modern and simple designed program that will prioritise orders.': 'Diseñado con un formato sencillo y haciendo que los pedidos sean la prioridad. Los pedidos aparecerán a medida que vayan llegando, a través de un programa de diseño moderno y sencillo que priorizará los pedidos.',
  Dashboards: 'Cuadros de mando',
  "Use this section to keep track of the amount of money you have received and better understand the financial status of your business": "Utilice esta sección para realizar un seguimiento de la cantidad de dinero que ha recibido y comprender mejor el estado financiero de su negocio",
  'Search...': 'Buscar...',
  'Analytics Dashboard': 'Panel de análisis',
  'Analytics Inventory': 'Inventario de análisis',
  'New order total': 'Total órdenes nuevas',
  Analytics: 'Analítica',
  Banking: 'Bancario',
  Commerce: 'Comercio',
  Crypto: 'Cripto',
  Finance: 'Finanzas',
  Fitness: 'Aptitud física',
  Healthcare: 'Cuidado de la salud',
  'Doctors Page': 'Página de médicos',
  'Hospital Overview': 'Descripción del hospital',
  Helpdesk: 'Mesa de ayuda',
  Learning: 'Aprendiendo',
  Monitoring: 'Supervisión',
  Tasks: 'Tareas',
  Applications: 'Aplicaciones',
  Calendar: 'Calendario',
  'File Manager': 'Administrador de archivos',
  'Jobs Platform': 'Plataforma de trabajos',
  Mailbox: 'Buzón',
  Messenger: 'Mensajero',
  'Projects Board': 'Junta de proyectos',
  Venues: 'Establecimientos',
  Venue: 'Establecimiento',
  'These are your venue': 'Estos son tus establecimientos',
  'Create Venue': 'Crear establecimiento',
  'Create new venue': 'Crear nuevo establecimiento',
  'Fill in the fields below to create a new venue': 'Complete los campos a continuación para crear un nuevo establecimiento',
  'The venue name field is required': 'El campo, nombre del establecimiento es obligatorio.',
  'The venue display name field is required': 'El campo del nombre para mostrar del establecimiento es obligatorio',
  'Venue name': 'Nombre del establecimiento',
  'Venue display name': 'Nombre para mostrar del establecimiento',
  'User name': 'Nombre de usuario',
  'The user name field is required': 'El campo de nombre de usuario es obligatorio',
  'The language field is required': 'El campo de idioma es obligatorio',
  'Add venue': 'Agregar establecimiento',
  'Date created': 'Fecha de creación',
  'Display name': 'Nombre para mostrar',
  'Venues - Management': 'Establecimientos - Gestión',
  'Search by name...': 'Buscar por nombre...',
  Management: 'Administración',
  Profile: 'Perfil',
  Users: 'Usuarios',
  User: 'Usuario',
  'View': 'Ver',
  'Delete': 'Eliminar',
  'delete': 'eliminar',
  'Search by name, email or username...': 'Buscar por nombre, correo electrónico o nombre de usuario...',
  'Number': 'Número',
  'Photos': 'Fotos',
  'Add new': 'Añadir nuevo',
  'Edit option': 'Editar opción',
  'Add option': 'Añadir opción',
  'Please select an option.': 'Por favor seleciona una opción.',
  'Not options': 'No hay opciones',
  'Delete photo': 'Eliminar photo',
  'Please enter a ': 'Por favor ingrese un ',
  'List All': 'Listar todo',
  'User Profile': 'Perfil del usuario',
  'New Users': 'Nuevos usuarios',
  'New User': 'Nuevo usuario',
  'Add new user': 'Añadir un nuevo usuario',
  'Add user': 'Añadir un usuario',
  'All users': 'Todos los Usuarios',
  'Users - Management': 'Usuarios - Gestión',
  'User Details - Management': 'Datos del usuario - Gestión',
  'Choose between table or grid views for displaying the users list.': 'Elija entre las vistas de tabla o de cuadrícula para mostrar la lista de usuarios.',
  'Go back': 'Volver',
  'The username field is required': 'El campo del nombre de usuario es obligatorio',
  'The role field is required': 'El campo de rol es obligatorio',
  'The user account was created successfully': 'La cuenta de usuario se ha creado con éxito',
  'Full Name:': 'Nombre completo:',
  'Email: ': 'Dirección de correo electrónico:',
  Administrators: 'Administradores',
  Kitchen: 'Cocina',
  Delivery: 'Domicilios',
  'Orders Delivery': 'Pedidos por Domicilio',
  'Delivery zones': 'Zonas de domicilio',
  'Delivery driver': 'Domiciliarios',
  'Search by name or user': 'Buscar por nombre o usuario',
  'Search by user': 'Buscar por usuario',
  'Use this page to manage your delivery driver, the fast and easy way': 'Use esta página para administrar sus domiciliarios, de la manera más rápida y fácil',
  'Add delivery driver': 'Agregar domiciliario',
  'Unassigned delivery driver': 'Conductor de entrega no asignado',
  'Delivery name': 'Domiciliario',
  'Delivery state': 'Estado del domicilio',
  'Delivery code': 'Código de entrega',
  'assigned': 'asignado',
  'started': 'en ruta',
  'delivered': 'entregado',
  'We couldn`t find any delivery driver matching your search criteria': 'No pudimos encontrar ningún domiciliario que coincida con sus criterios de búsqueda',
  'Invitation': 'Invitación',
  'Sent': 'Enviada',
  'Accepted': 'Aceptada',
  'Inactive': 'Inactivo',
  'Inactivate': 'Inactivar',
  'Activate': 'Activar',
  'The zones deliverys for your venue can be managed from this page': 'Desde esta página se pueden gestionar los domicilios por zonas para tu establecimiento',
  'Add zones': 'Añadir zonas',
  'Add zones here': 'Añadir zonas aquí',
  'Invalid price': 'Precio inválido',
  Cashier: 'Cajero',
  Name: 'Nombre',
  Email: 'Correo electrónico',
  Actions: 'Acciones',
  'Users Management': 'Gestión de los usuarios',
  'All aspects related to the app users can be managed from this page':
    'Desde esta página se pueden gestionar todos los aspectos relacionados con los usuarios de la app',
  'Create user': 'Crear usuario',
  'Rows per page': 'Filas por página',
  'Fill in the fields below to create and add a new user to the site':
    'Rellene los siguientes campos para crear y añadir un nuevo usuario al sitio',
  'Full Name': 'Nombre completo',
  'Email address': 'Dirección de correo electrónico',
  Password: 'Contraseña',
  Role: 'Rol',
  Cancel: 'Cancelar',
  cancel: 'cancelar',
  'Profile for': 'Perfil de',
  'This is you profile page': 'Esta es su página de perfil',
  'Change cover': 'Cambiar la portada',
  'Click to Edit': 'Haga clic para editar',
  Save: 'Guardar',
  save: 'guardar',
  'Save 5%' : 'Ahorra 5%',
  'Save 10%' : 'Ahorra 10%',
  'You saved 5%' : 'Ahorraste un 5%',
  'You saved 10%' : 'Ahorraste un 10%',
  'Congratulations' : 'Felicitaciones',
  'Try it': 'Pruébalo',
  'Unlimited': 'Ilimitado',
  'Please choose an option':'Por favor escoge una opción',
  'Fields with * must be completed or edited to update the data successfully.':
    'Los campos con * deben ser completados o editados para actualizar los datos con éxito.',
  'Name field is empty': 'El campo del nombre está vacío',
  'Name and Role fields are empty': 'Los campos Nombre y Función están vacíos',
  'Role field is empty': 'El campo del rol está vacío',
  'data was successfully updated': 'los datos se han actualizado con éxito',
  Projects: 'Proyectos',
  Shop: 'Tienda',
  Shopping: 'Compras',
  'List of all products to buy': 'Lista de todos los productos a comprar',
  'Select a product to buy on the right side': 'Seleccione un producto para comprar en el lado derecho',
  'We couldn`t find any products matching your search criteria': 'No pudimos encontrar ningún producto que coincida con su criterio de búsqueda',
  'Products List': 'Lista de productos',
  'View Product': 'Ver el producto',
  'Create': 'Crear',
  Invoices: 'Facturas',
  'View Single': 'Ver individual',
  'Auth Pages': 'Autenticación',
  Login: 'Ingresar',
  Basic: 'Básico',
  Cover: 'Cubrir',
  Register: 'Registrarse',
  Wizard: 'Mago',
  'Recover Password': 'Recuperar contraseña',
  Status: 'Estado',
  Foundation: 'Fundación',
  'Extra Pages': 'Páginas extra',
  'Error 404': 'Error 404',
  'Error 500': 'Error 500',
  'Coming Soon': 'Próximamente',
  Maintenance: 'Mantenimiento',
  Overview: 'Visión general',
  'Layout Starters': 'Arrancadores de diseño',
  'Starter Kit 1': 'Kit de inicio 1',
  'Starter Kit 2': 'Kit de inicio 2',
  Documentation: 'Documentación',
  Welcome: 'Hola',
  'These are your analytics stats for today':
    'Estas son las estadísticas analíticas de hoy',
  Help: 'Ayuda',
  'Features tour': 'Tour de características',
  'Getting started guide': 'Guía de inicio',
  'Contact support': 'Soporte de contacto',
  Version: 'Versión',
  Search: 'Buscar',
  'Search terms here...': 'Términos de búsqueda aquí...',
  'Recent searches': 'Búsquedas recientes',
  'Clear all': 'Borrar todo',
  'View all recent searches': 'Ver todas las búsquedas recientes',
  Notifications: 'Notificaciones',
  Settings: 'Ajustes',
  'FeedBack': 'Comentarios',
  'Check the feedback of your customers': 'Revisa los comentarios de tus clientes',
  'Settings - Management': 'Configuración - Gestión',
  'Settings ': 'Ajustes ',
  'This is your configuration': 'Esta es tu configuración',
  'this could be your beautiful finance inventory administration panel.': 'este podría ser su hermoso panel de administración de inventario de finanzas.',
  'Add setting': 'Agregar ajuste',
  'Add new setting': 'Agregar nueva configuración',
  'Edit setting': 'Editar la configuración',
  'The field is required': 'El campo es obligatorio',
  'Delete setting': 'Eliminar configuración',
  'Are you sure you want to delete ': 'Estás seguro de que quieres eliminar ',
  'Add Setting': 'Agregar configuración',
  'Value': 'Valor',
  'How to turn on location services': 'Cómo activar los servicios de ubicación',
  'In the browser click in': 'En el navegador haga clic en',
  'Go to permissions and active the location': 'Ir a permisos y activar la ubicación',
  'Language Switcher': 'Selector de idioma',
  'Sign out': 'Desconectar',
  'Change Theme': 'Cambiar de tema',
  'View all notifications': 'Ver todas las notificaciones',
  'Tokyo React Admin Dashboard': 'Panel de administración de Tokyo React',
  'High performance React template built with lots of powerful Material-UI components across multiple product niches for fast & perfect apps development processes':
    'Plantilla React de alto rendimiento construida con una gran cantidad de poderosos componentes Material-UI en múltiples nichos de productos para procesos de desarrollo de aplicaciones rápidos y perfectos',
  'Browse Live Preview': 'Explorar vista previa en vivo',
  'Live Preview': 'Vista previa en vivo',
  'Key Features': 'Características clave',
  dashboards: 'cuadros de mando',
  applications: 'aplicaciones',
  'management sections': 'secciones de gestión',
  'dark/light themes': 'temas oscuros / claros',
  components: 'componentes',
  'Some of the features that make Tokyo one of the best admin templates available today':
    'Algunas de las características que hacen de Tokio una de las mejores plantillas de administración disponibles en la actualidad',
  'Design Source Files': 'Diseñar archivos fuente',
  'Start working on your project directly from one of the included starter kits or use the Figma/Sketch files to create a prototype first':
    'Comience a trabajar en su proyecto directamente desde uno de los kits de inicio incluidos o use los archivos Figma / Sketch para crear un prototipo primero',
  'We only translated a small part of the template, for demonstration purposes':
    'Solo traducimos una pequeña parte de la plantilla, con fines de demostración',
  Orders: 'Pedidos',
  'Search Orders': 'Buscar Ordenes',
  'Search by table number or order id...': 'Buscar por número de mesa o por número de orden...',
  'Orders Kitchen': 'Pedidos de Cocina',
  'These are your active Orders': 'Estos son sus pedidos activos',
  Table: 'Mesa',
  'Change table': 'Cambiar mesa',
  Removed: 'Se ha eliminado',
  Remove: 'Eliminar',
  Address: 'Dirección',
  'Phone Number': 'Número de teléfono',
  "We couldn't find any orders matching your search criteria":
    'No hemos podido encontrar ningún pedido que coincida con sus criterios de búsqueda',
  "The discount on this invoice is no longer available": 'El descuento de esta factura ya no esta disponible',
  Bartender: 'Bartender',
  'Orders Drinks': 'Pedidos de Bebidas',
  Waiter: 'Mesero',
  'Call waiter': 'Llamar al mesero',
  'Bill': 'Solicitar la cuenta',
  'Orders Waiter': 'Pedidos de mesero',
  'Waiter orders': 'Pedidos de mesero',
  'Waiter calls': 'Llamadas de mesero',
  'These are your orders by the waiter': 'Estos son sus pedidos por el mesero',
  'Search by table number...': 'Buscar por número de mesa...',
  'Search table': 'Buscar mesa',
  Pickup: 'Recoger',
  'Orders Pickup': 'Pedidos por Recoger',
  Products: 'Productos',
  Categories: 'Categorías',
  'There are no categories': 'No hay categorías',
  'List Products': 'Lista de productos',
  Order: 'Pedido',
  Drinks: 'Drinks',
  'New product': 'Nuevo producto',
  'Add a New product for the': 'Añadir un nuevo producto para la',
  'Edit product for the': 'Editar producto para la',
  'General information must have data':
    'La información general debe tener datos',
  'Images information must have data':
    'La información de las imágenes debe tener datos',
  'Inventory information must have data':
    'La información del inventario debe tener datos',
  'List Items': 'Lista de elementos',
  'Edit Product': 'Editar Producto',
  'Add Product': 'Añadir Producto',
  'Ingredients Added': 'Ingredientes añadidos',
  'You must write a product name': 'Debe escribir el nombre del producto',
  'You must write a short Description': 'Debe escribir una breve descripción',
  'You must write a product Amount': 'Debe escribir un producto Cantidad',
  'General Information': 'Información General',
  Images: 'Imágenes',
  Ingredients: 'Ingredientes',
  Ingredient: 'Ingrediente',
  'Extra Ingredients': 'Ingredientes extra',
  'Extra Ingredient': 'Ingrediente extra',
  Sides: 'Adicionales',
  Side: 'Adicional',
  Drink: 'Bebida',
  'Remove drink': 'Quitar bebida',
  Complementary: 'Para Acompañar',
  Combo: 'Combo',
  'Cook level': 'Término de cocción',
  'Term': 'Término',
  'Cook level added': 'Término de cocción agregado',
  'General Information Added': 'Información general añadida',
  'You must write a url image': 'Debe escribir una imagen url',
  'Images Added': 'Imágenes añadidas',
  'Extra Added': 'Extra añadido',
  'Side Added': 'Adiconal Añadido',
  'Complementary Added': 'Para Acompañar Añadido',
  'Combo Added': 'Combo Añadido',
  'Side Combo Information Added': 'Información de combo adicional añadida',
  'Side Combo Information Edited': 'Información de combo adicional editada',
  'Drink Combo Information Added':
    'Información sobre la combinación de bebidas añadida',
  'Ingredient Drink': 'Ingrediente de la bebida',
  'Ingredients Drink': 'Ingredientes de la bebida',
  'Ingredient Drink Combo Information Added':
    'Se ha añadido información sobre la combinación de ingredientes de la bebida',
  'Ingredient Drink Combo Information Edited':
    'Información editada de la combinación de ingredientes de la bebida',
  'Drink Combo': 'Combo de Bebida',
  'Side Combo': 'Combo adicional',
  'Display Name': 'Nombre para mostrar',
  'Short Description': 'Descripción Corta',
  Amount: 'Valor',
  amount: 'valor',
  Rating: 'Clasificación',
  Favourite: 'Favoritos',
  'Sold Out': 'Agotado',
  'Large Image': 'Imagen grande',
  'Short Image': 'Imagen corta',
  Category: 'Categoría',
  'restaurant': 'restaurante',
  'Name Venue': 'Nombre del establecimiento',
  'Updated category order.': 'Actualización del orden de las categorías.',
  'Click to add a new category': 'Haga clic para añadir una nueva categoría',
  'you should select an image for the category!': 'Debe seleccionar una imagen para la categoría!',
  'You must write the url of the image!':
    '¡Debes escribir la url de la imagen!',
  'you should select an icon for the category!':
    '¡Debe seleccionar Icono de la Categoría!',
  Title: 'Título',
  'You must write a cover name': 'Debe escribir un nombre de portada',
  'Imagen Url': 'Url de imagen',
  'Category Icon': 'Icono',
  category: 'categoría',
  Edit: 'Editar',
  'Apply changes': 'Guardar cambios',
  'No Display': 'No Visible',
  Add: 'Añadir',
  Saving: 'Guardando',
  CLOSE: 'CERRAR',
  Close: 'Cerrar',
  Automation: 'Automatización',
  Colors: 'Colores',
  Color: 'Color',
  'Change App Color': 'Cambiar el color de la aplicación',
  'Change Your App Colors': 'Cambie los colores de su aplicación',
  'What your app would look like': 'Cómo sería su aplicación',
  'minimal writing': 'escritura mínima',
  'details': 'detalles',
  'Details': 'Detalles',
  'Total amount': 'Importe total',
  'Total Amount': 'Costo total',
  'PAY NOW': 'PAGUE AHORA',
  'Color palete': 'Paleta de colores',
  'primary color': 'color primario',
  'seconday color': 'color secundario',
  'Loading': 'Cargando',
  'Save Change': 'Guardar el cambio',
  'Background': 'Fondo',
  'Font': 'Fuente',
  'Primary Font': 'Fuente principal',
  'primary font': 'fuente principal',
  Content: 'Contenido',
  'Language': 'Idioma',
  'You want to eliminate the': 'Usted quiere eliminar el',
  'language?': 'idioma?',
  'Virtual Queue': 'Turnos asignados',
  'This is your virtual queue': 'Estos son sus turnos en asignados',
  'Select an option': 'Seleccione una opción',
  'No found data in the queue': 'No se han encontrado datos en los turnos',
  'Date': 'Fecha',
  'Mobile': 'Móvil',
  Discounts: 'Descuentos',
  'Discounts - Management': 'Descuentos - Gestión',
  'Regular Discounts': 'Descuentos regulares',
  'Coupons or Discounts': 'Cupones o descuentos',
  'Day': 'Día',
  'Days': 'Días',
  'Description': 'Descripción',
  'Discount Type': 'Tipo de Descuento',
  'Discount': 'Descuento',
  'Time Start': 'Hora de inicio',
  'Time Start:': 'Hora de inicio:',
  'Time End': 'Hora final',
  'Time End:': 'Hora final:',
  'State': 'Estado',
  'Percentage': 'Porcentage',
  'Disabled': 'Deshabilitado',
  'Discount Code': 'Codigo de descuento',
  'Amount of Discounts': 'Cantidad de descuentos',
  'Expiry Date': 'Fecha de vencimiento',
  'Are you sure you want to permanently delete this Discount': '¿Estás seguro de que quieres eliminar permanentemente este descuento',
  'The Discounts is not valid must be greater than 1': 'Los descuentos no son válidos, deben ser mayores que 1',
  'The Start time is invalid must be less than the End time': 'La hora de inicio no es válida, debe ser menor que la hora de finalización',
  'The Discount was edit successfully': 'El descuento se ha editado con éxito',
  'The Amount Of Discounts is not valid must be greater than 1': 'La cantidad de descuentos no es válida, debe ser mayor que 1',
  'The Expiry Date is not valid': 'La fecha de vencimiento no es válida',
  'Amount Discount': 'Descuento por importe',
  'Percentage Discount': 'Porcentaje de descuento',
  "It looks like you come up with an exclusive module available only for our premium plan. Don't worry, we have excellent news for you.": 'Parece que has llegado a un módulo exclusivo disponible solo para nuestros planes premium. No te preocupes, tenemos una excelente noticia para ti.',
  "We invite you to upgrade your plan to unlock full access to this exciting module and enjoy all its features. You don't want to miss this opportunity!" : 'Te invitamos a actualizar tu plan para desbloquear acceso completo a este emocionante módulo y disfrutar de todas sus funcionalidades. ¡No querrás perderte esta oportunidad!',
  'Upgrade your plan today!' : '¡Actualiza tu plan hoy mismo!',
  'Sunday': 'Domingo',
  'Monday': 'Lunes',
  'Tuesday': 'Martes',
  'Wednesday': 'Miércoles',
  'Thursday': 'Jueves',
  'Friday': 'Viernes',
  'Saturday': 'Sábado',
  'Active Discount': 'Activar descuento',
  'Code Discount or Coupon': 'Código de descuento o cupón',
  'Amount of Discounts:': 'Cantidad de descuentos:',
  'Expiry Date:': 'Fecha de vencimiento:',
  'Type Discount': 'Tipo de descuento',
  'Discounts Management': 'Gestión de descuentos',
  'All aspects related to your venue discounts can be managed from this page.': 'Todos los aspectos relacionados con los descuentos de tu establecimiento pueden ser gestionados desde esta página.',
  'Create discounts': 'Crear descuentos',
  'Add new Discount': 'Añadir nuevo descuento',
  'Fill in the fields below to create and add a new Discount to the venue': 'Rellene los campos siguientes para crear y añadir un nuevo descuento a tu establecimiento".',
  'Discounts Type': 'Tipo de descuentos',
  'Regular Discount': 'Descuento regular',
  'The discount was created successfully': 'El descuento se ha creado con éxito.',
  'Create Regular Discount': 'Crear descuento regular',
  'The Day field is required': 'El campo Día es obligatorio',
  'The Description field is required': 'El campo Descripción es obligatorio',
  'The Type field is required': 'El campo Tipo es obligatorio',
  'The discount must be a minimum of 1': 'El descuento debe  ser minimo de 1',
  'The Discount field is required': 'El campo Descuento es obligatorio',
  'The Code Discount or Coupon field is required': 'El campo Código de descuento o cupón es obligatorio.',
  'The expiryDate field must be greater than the current date': 'El campo expiryDate debe ser mayor que la fecha actual',
  'The number of discounts must be at least 1': 'el número de descuentos debe ser al menos 1',
  'Create Discount': 'Crear descuento',
  'The Discount has been removed': 'El descuento ha sido eliminado',
  'Number of orders': 'Número De Ordenes',
  'Total money': 'Total Dinero',
  'Money': 'Dinero',
  'Enable': 'Habilitar',
  'Number Pickup Orders': 'Número de Ordenes Recogidas',
  'Total Money Pickup': 'Total Dinero Ordenes Recogidas',
  'Number Delivered Orders': 'Número De Ordenes Entregadas',
  'Total Money Delivered': 'Total Dinero Ordenes Entregadas',
  'Service Costs': 'Costos de el servicio',
  'Service whatsApp': 'Servicio de whatsApp',
  'Total whatsApp messages': 'Total mensajes whatsApp',
  'Total messages cost': 'Costo total mensajes',
  'Preparation average': 'Promedio de preparación',
  'Average preparation total': 'Promedio de preparación total',
  'Delivery average': 'Promedio de entrega',
  'Waiter average': 'Promedio Mesero',
  'This Week': 'Esta Semana',
  'last Week': 'Semana Pasada',
  'This month': 'Este Mes',
  'Last month': 'Mes Pasado',
  'Specific Date': 'Fecha Específica',
  'The date cannot be older than ': 'La fecha no puede tener más de ',
  'months': ' meses',
  'Total New Orders': 'Total Nuevas Ordenes',
  'Total Money': 'Total Dinero',
  'Total New Orders Pickups': 'Total Nuevas Ordenes Recogidas',
  'Total Delivered': 'Total Ordenes Entregadas',
  'Total Preparation Average': 'Total Promedio de preparación',
  'Total Delivery Average': 'Total Promedio de Entrega',
  'Total Waiter Average': 'Total Promedio Mesero',
  'Best-selling products': 'Productos más vendidos',
  'No Data': 'No hay Información',
  'Required': 'Obligatorio',
  Transactions: 'Transacciones',
  "All recent transactions can be found below": "Todas las transacciones recientes se pueden encontrar a continuación",
  'Total Credit': 'Credito Total',
  "total credit in your account": "crédito total en su cuenta",
  'Search transactions by id or amount  ...': "Buscar transacciones por id o monto...",
  Showing: "Mostrando",
  'Show all': "Mostrar Todo",
  'Last Week': "Semana Pasada",
  "This Month": "Este Mes",
  "Last Month": "Mes Pasado",
  "Specific Data": "Fecha Especifica",
  "Download CSV": "Descargar CSV",
  "Download PDF": "Descargar PDF",
  "We couldn't find any invoices matching your search criteria": "No pudimos encontrar ninguna factura que coincida con sus criterios de búsqueda",
  "Invoice": "Factura",
  "Invoice for": "Factura para",
  "Transaction Date": "Fecha de Transacción",
  "Hour": "Hora",
  "Recharge": "Recarga",
  "Service Cost": "Costo Servicio",
  "Item": "Descripción",
  "Price": "Precio",
  "Quantity": "Cantidad",
  "Additional informations": "Información Adicional",
  "These transactions were made in the month of": "Estas transacciones se realizaron en el mes de",
  "Home": "Inicio",
  "View all invoices": "Ver todas las facturas",
  "Buy": "Comprar",
  "Buy Credit": "Comprar Credito",
  "Redirecting": "Redireccionando",
  "Enter amount to recharge Please": "Introduce el valor a recargar Por favor",
  "Enter amount Valid Please": "Ingrese cantidad Válida por favor",
  "Credit": "Crédito",
  'Products added to inventory': 'Productos agregados al inventario',
  'Products removed to inventory': 'Productos eliminados del inventario',
  "Start Date": "Fecha Incio",
  "End Date": "Fecha Fin",
  "Done": "Hecho",
  "Purchase Was Successful": "La compra fue exitosa",
  "Thank You!": "Gracias!",
  "Payment": 'Pago',
  "PAYMENT": 'PAGO',
  "Cash Payment": "Pago en efectivo",
  "Cash": "Cajero",
  "These are your active orders for payment in cash": "Estas son sus órdenes activas de pago en efectivo",
  "Search by cash payment code or by order number...": "Buscar por código de pago en efectivo o por número de pedido...",
  "Domiciliary gets cash": 'Domiciliario recibe efectivo',
  "Cart Total": "Total del carrito",
  "PAY": "PAGAR",
  "Details Cash Payment": "Detalles Pago en efectivo",
  "these are your order payment details": "Estos son los datos de pago de su pedido",
  "Details Payment": "Detalles del pago",
  "INVOICE": "FACTURACIÓN",
  "Id Order": "Id de Orden",
  "PRODUCTS": "PRODUCTOS",
  "Notes": 'Notas',
  "See note": "Ver nota",
  "This functionality allows to generate invoice PDF": "Esta funcionalidad permite generar factura PDF",
  "To activate this functionality you must click on edit": "Para activar esta funcionalidad debes dar clic en editar",
  "Category Image": "Imagen Categoría",
  "Add New category": "Agregar Nueva Categoría",
  "Upload": "Subir",
  "Password temporal": "Contraseña temporal",
  "Your password is temporary, please change it by entering the following data": 'Tu contraseña es temporal, por favor cambiarla ingresando los siguientes datos',
  "Save password": 'Guardar contraseña',
  "New password": 'Nueva contraseña',
  "Confirm new password": 'Confirmar nueva contraseña',
  "The password must contain at least 8 characters": 'La contraseña debe contener al menos 8 caracteres',
  "The password does not match": 'La contraseña no coincide',
  "Updated password": 'Contraseña actualizada',
  "Reset password": 'Restablecer contraseña',
  "Reset password for user": 'Restablecer contraseña para el usuario',
  "Generate password": 'Generar contraseña',
  "Reset password successful": 'Restablecimiento de contraseña exitoso',
  "The user already exists for this venue": 'El usuario ya existe para este establecimiento',
  "Invalid email or password": 'Correo electrónico o contraseña no válidos',
  "Select a chat to show the conversation": 'Selecciona un chat para mostrar la conversación',
  "Today": 'Hoy',
  "All": 'Todos',
  "Unread": 'No leído',
  "Write here your message...": 'Escribe aquí tu mensaje...',
  "Send": 'Enviar',
  "Message": 'Mensaje',
  "Messages": 'Mensajes',
  "Phone": 'Teléfono',
  "Conversation": 'Conversación',
  "Total cash": 'Total pago en efectivo',
  "Total by bank transfer": 'Total por transferencia bancaria',
  "Transference": 'Transferencia',
  'Join your venues': 'Únase a sus establecimientos',
  'If you are trying to delete a venue you must choose the Disabled option.': 'Si está intentando eliminar un establecimiento, debe elegir la opción Deshabilitado.',
  'Join venues cannot be disabled if you still have venues linked to it.': 'Unirse a establecimientos no puede deshabilitarse si aún tiene establecimoentos vinculados a él.',
  'If you are trying to add a venue you must choose the Active option.': 'Si está intentando añadir un establecimiento, debe elegir la opción Activo.',
  'Select a venue to disassociate yourself': 'Seleccionar un establecimiento para desvincularse',
  'Join created': 'Unión creada',
  'Your join has been deleted': 'Su unión ha sido eliminada',
  'Edit Cover': 'Editar Portada',
  "Category Cover": "Portada",
  "An error occurred, please try again.": "Ocurrio Un Error, Intenta de Nuevo",
  "Add New Cover": "Nevo Cover",
  "This functionality allows add a new category": "Esta funcionalidad permite agregar una nueva categoría",
  "This functionality allows add a new Cover": "Esta funcionalidad permite agregar un nuevo cover",
  "Category removed successfully.": "Categoría eliminada con éxito.",
  "There are no Products added for this category.": "No hay Productos agregados para esta categoría.",
  "There are no Rooms added for this category.": "No hay Habitaciones agregadas para esta categoría.",
  'Number of guests : ': "Número de huéspedes :",
  "Updated Element order.": "Orden de Elemento Actualizado",
  "Add Room": "Agregar Habitación",
  "You must write a room name": "Debes escribir un nombre",
  "You must write a short Description ": "Debes escribir un corta descripción",
  "The number of Guests must be greater than 0": "El número de huéspedes debe ser mayor a cero",
  "You must write a room Amount": "Debes escribir un valor para la habitación",
  "Number Of Guests": "Número De Huéspedes",
  "General Information Deleted": "Información General Eliminada",
  "Number of guests": "Número de Huéspedes",
  'Edit Room': "Edit Habitación",
  "Create Room": "Crear Habitación",
  "Add a New Room for the": "Agregar Nueva Habitación para ",
  "Edit Room for the": "Editar Habitación para ",
  "Room Pictures": "Imagenes Habitación",
  "Additional services": "Servicios Adicionales",
  "Additional Information": "Información Adicional",
  "Additional services must have data": "Debes Registrar los Servicios Adicionales",
  "Additional information must have data": "Debes Registrar la Información Adicional",
  "Edit Image": "Editar Imagen",
  "Delete Image": "Borrar Imagen",
  "Add Image": "Agregar Imagen",
  "Image Edited": "Imagen Editada",
  "Image Deleted": "Imagen Eliminada",
  "Aditional Services Added": "Servicios Agregados",
  "Title or Description cannot be empty": "Título o Descripción No pueden estar vacias",
  "Aditional Services Deleted": "Servicios Eliminados",
  "All information is required": "Toda la Información Es requerida",
  'Aditional Information Added': "Información adicional Agregada",
  "Aditional Information edited": "Información Adicional Editada",
  'Aditional Information Deleted': "Información Eliminada",
  "You must write a category name": "Debes escribir un nombre de categoría",
  "Select an image for the category!": "Seleccione una imagen",
  "Select an icon for the category!": "Seleccione un icono",
  "Rooms": "Habitaciones",
  "Reserved rooms": "Reservar habitaciones",
  "Make the programming of your agenda": "Realiza la programación de tu agenda",
  "Processing data...": "Procesando Datos...",
  "Schedule": "Agendar",
  "Initial date": "Fecha Inicial",
  "Final date": "Fecha Final",
  "You must write a start date": "Debes escribir una fecha Inicial",
  "You must write a final date": "Debes escribir una fecha Final",
  "Agenda Scheduled successfully.": "Agenda Programada con éxito",
  "to ": "a",
  'Enter value greater than 2000': "Ingrese valor superior a 2000 ",
  'Product name': 'Nombre del producto',
  'Stock': 'Existencias',
  'Select a category': 'Seleccione una categoría',
  'Measurement units': 'Unidades de medida',
  'Select a measurement units': 'Seleccione una unidad de medida',
  'Add new unit of measure': 'Añadir nueva unidad de medida',
  'There are no measurement units': 'No hay unidades de medida',
  'You are going to receive a notification when you have less than ': 'Recibirás una notificación cuando tengas menos de ',
  'Add stock': 'Añadir existencias',
  'Remove stock': 'Quitar existencias',
  'Enter stock here': 'Ingrese existencias aquí',
  'to': 'para',
  'TO': 'A',
  'Enter a valid value': 'Ingresa un valor válido',
  'Stock cannot exceed ': 'El stock no puede ser superior a ',
  'Do you really want to delete ': 'De verdad quieres eliminar ',
  'You won`t be able to revert after deletion': 'No podrá revertir después de la eliminación',
  'Barcode Scanner': 'Escáner código de barras',
  'Barcode': 'Código de barras',
  'Fill in the fields below to create a new product': 'Complete los campos a continuación para crear un nuevo producto',
  'Add new product': 'Agregar nuevo producto',
  'Use this page to manage your products the inventory, the fast and easy way': 'Utilice esta página para gestionar el inventario de sus productos, de forma rápida y sencilla',
  'Search by product name...': 'Buscar por nombre de producto...',
  'Inventory': 'Inventario',
  'Sales': 'Ventas',
  'Cost': 'Costo',
  'Inventory history': 'Historial del inventario',
  'Preparation discount': 'Descuento de preparación',
  'History': 'Historial',
  'Services': 'Servicios',
  'Our service': 'Nuestro servicio',
  'Print': 'Imprimir',
  'Total Amount Sold': 'Cantidad total vendida',
  'Paid': 'Pagado',
  'paid': 'pagado',
  'Delivered': 'Entregado',
  'Prepared': 'Preparado',
  'Preparing': 'Preparando',
  'Tax': 'Impuesto',
  'Show Bookings': "Mostrar Reservas",
  "Bookings": "Reservaciones",
  "Check the scheduled reservations in your calendar": "Consulte las reservas agendadas en su calendario",
  'Not yet paid in cash': 'Todavía no se ha pagado en efectivo',
  'Delivery to pay': 'Entrega para pagar',
  'PAY TO DELIVERY': 'PAGAR PARA DOMICILIO',
  'Pay to delivery': 'Pagar para domicilio',
  'enter a valid email address': 'introduzca una dirección de correo electrónico válida',
  'write the email address where you want to receive your invoice': 'escriba la dirección de correo electrónico donde desea recibir su factura',
  'SEND INVOICE': 'ENVIAR FACTURA',
  'Pay': 'Pagar',
  'Product': 'Producto',
  'Ingredients removed': 'Ingredientes eliminados',
  'Sub Total': 'Sub Total',
  'Receipt order and eat now': 'Recibo order and eat now',
  "RESERVATION": "RESERVACIÓN",
  'Here you can Find the details of your reservation': "Aquí puedes encontrar los detalles de tu reserva",
  "Id Booking": "Id Reserva",
  'Search by Id': "Buscar por id...",
  "Search Booking": "Buscar Reserva",
  'No reservations found': "Reserva No encontrada",
  "Enter a reservation ID": "Digíte Id de Reserva",
  "Find reservations": "Buscar Reservas",
  "Month": "Mes",
  'month': 'mes',
  "Previous Day": "Día Anterior",
  "Next Day": "Día Siguiente",
  "BOOKINGS FOR THIS DAY": "RESERVAS PARA ESTE DÍA",
  "Action": "Acción",
  "Room": "Habitación",
  "Contact": "Contacto",
  "An error has occurred!": "Un error ha ocurrido!",
  "Name Room": "Nombre habitación",
  'Room capacity': "Capacidad",
  'Room reserved for': "Cuarto Reservado para",
  "Check-in ": "Entrada",
  'Check-out ': "Salida",
  'Nightly rate': "Precio por noche",
  "Number of nights": "Número de Noches",
  'Value paid': "Valor pagado",
  BOOKING: "RESERVA",
  'ROOM INFORMATION ': "INFORMACIÓN DE HABITACIÓN",
  ' Room for ': " habitación para ",
  people: "Personas",
  "adults": "Adultos",
  children: "Niños",
  'Extra Information: ': "Información Extra",
  'Details Extra': "Detalles",
  'Additional services: ': "Servicios Adicionales",
  'Service': "Servicio",
  'Total price': "Precio Total",
  'For': "Para",
  'guests': "Huéspedes",
  nigths: "noches",
  'Remaining Value': "Valor Restante",
  'Paid Value': "Valor pagado",
  "Loading...": "Cargando...",
  'Pay Remaining': "Pagar Restante",
  'Pay Total': "Pagar Total",
  "pending payment": "Pago Pendiente",
  "partial payment": "Pago Parcial",
  "fully paid": "Pago Total",
  "RESERVATION DETAILS": "DETALLES RESERVA",
  'Here you can see the details of your reservation': "Aquí puedes ver los detalles de tu reserva",
  'ROOM AVAILABLE': "HABITACIÓN DISPONIBLE",
  'Change room': "Cambiar habitación",
  "Select date": "Seleccionar una fecha",
  'Image': "Imagen",
  'Select': "Seleccionar",
  "Reserve": "Reservar",
  "We cannot reserve a stay that exceeds the limit of days": "No podemos reservar una estancia que supere el límite de días",
  'Show rooms': "Mostrar habitaciones",
  'Check-out date must be greater than check-in date': 'La fecha de salida debe ser posterior a la fecha de entrada',
  'No Available Rooms have been found': 'No se han encontrado habitaciones disponibles',
  'Uploading File...': "Subiendo Archivo...",
  'SELECT DATES ': "SELECCIONAR FECHAS",
  'Select the dates to review the available rooms': "Seleccione las fechas para revisar las habitaciones disponibles",
  'ATTACHMENTS ': "ADJUNTOS",
  'Upload Invoice': "Subir factura",
  "Sending...": "Enviando...",
  'Send Email': "Enviar Email",
  'View Invoice': "Ver factura",
  'CLIENT INFORMATION ': "INFORMACIÓN DE EL CLIENTE",
  'Name:  ': "Nombre: ",
  'Contact:  ': "Contacto: ",
  'Email:  ': "Email: ",
  'id Booking': "Id Reserva",
  "Analytics - Booking": "Analítica - Reservas",
  "Booking Analytics": "Analítica de reservas",
  "Analytics administration panel for reservations.": "Panel de administración de analíticas para reservas.",
  "Number of Bookings": "Número de Reservas",
  "Sale Booking": "Venta Reservas",
  "RESERVATION PENDING PAYMENT OR PARTIAL PAYMENT": "RESERVAS CON PAGO PENDIENTE O PARCIAL",
  "Debt Reserves": "Reservas en deuda",
  "Debt Money": "Dinero Deuda",
  "Debt Bookings": "Numero de Reservas en deuda",
  'Tip': 'Propina',
  'Budget': 'Presupuesto',
  'Remaining budget': 'Presupuesto restante',
  'Date Finish': 'fecha de finalización',
  'Search by name, user and category...': 'Buscar por nombre, usuario o categoría...',
  'Create budget': 'Crear presupuesto',
  'Budget Accounting': 'Contabilidad del presupuesto',
  'this could be your budget management panel.': 'este podría ser su panel de gestión presupuestaria.',
  'Add new budget': 'Agregar nuevo presupuesto',
  'Fill in the fields below to create a new budget': 'Rellene los siguientes campos para crear un nuevo presupuesto',
  'Budget name': 'Nombre del presupuesto',
  'Responsible': 'Responsable',
  'Expence category': 'Categoría de gasto',
  'Type of expence': 'Tipo de gasto',
  'Drag or select a file': 'Arrastra o seleccione un archivo',
  'Date of expence': 'Fecha de gasto',
  'Expenses Accounting': 'Contabilidad de gastos',
  'this could be your expenses management panel.': 'este podría ser su panel de gestión de gastos.',
  'Create expenses': 'Crear gastos',
  'Total expence': 'Gasto total',
  'Notification percentage': 'Porcentaje de notificación',
  'Restart date': 'Fecha de reinicio',
  'The name of the budget is required': 'Se requiere el nombre del presupuesto',
  'The value of the budget is required': 'Se requiere el valor del presupuesto',
  'You will be notified when your budget has exceeded this value.': 'Se le notificará cuando su presupuesto haya superado este valor',
  'Budget reset time is required': 'Se necesita un tiempo de reinicio del presupuesto',
  "Without Date": '|fecha',
  'Budget used': 'Presupuesto usado',
  'Budget spent': 'Presupuesto gastado',
  'Remaining': 'Restante',
  'Total budget': 'Presupuesto total',
  'Total expenses': 'Gastos totales',
  'Total income': 'Ingresos Totales',
  'New expense': 'Nuevo gasto',
  'New income': 'Nuevo ingreso',
  'Accounting': 'Contabilidad',
  'Without category': 'Sin categoría',
  'Expenses': 'Gastos',
  'Invalid file extension': 'Extension de archivo no valida',
  'Valid file extension': 'Extension de archivo valida',
  'the expense was deducted from ': 'el gasto se dedujo de ',
  'Search by type expence, responsible and category...': 'Búscar por tipo de gasto, responsable y categoría...',
  'First you have to create a budget and its category': 'Primero hay que crear un presupuesto y su categoría',
  'Week': 'Semana',
  'Year': 'Año',
  'year':'año',
  'The value of Restart date is required': 'Se requiere la fecha de reinicio',
  'the expense was successfully created': 'el gasto fue creado con éxito',
  "Cost O&E": "Costo O&E",
  "COST O&E": "COSTO O&E",
  "RESERVATION FULL PAYMENT OR PARTIAL PAYMENT": "RESERVAS CON PAGO TOTAL O PARCIAL",
  'template': 'Plantilla',
  'Add Template': 'Agregar plantilla',
  'Add new template': 'Agregar nueva plantilla',
  'Edit template': 'Editar plantilla',
  'Text': 'Texto',
  'Template': 'Plantilla',
  'Template Virtual': 'Plantilla cola virtual',
  'Successful Payment! We are pleased to inform you that your subscription to our platform has been processed successfully. Thank you for choosing us!':'¡Pago exitoso! Nos complace informarle que su suscripción a nuestra plataforma ha sido procesada correctamente. ¡Gracias por elegirnos!',
  'In this message it is necessary to use these key words for the proper functioning of the platform': 'En este mensaje es necesario utilizar estas palabras clave para el buen funcionamiento de la plataforma',
  "This keyword  is to display the name of the logged in person placing the order": "Esta clave es para mostrar el nombre de la persona registrada que realiza el pedido",
  "This keyword  is to display the name of your venue in the message": "Esta palabra clave es para mostrar el nombre de su establecimiento en el mensaje",
  'This keyword  is for displaying the order id order in the message': "Esta palabra clave es para mostrar el número de identificación del pedido en el mensaje",
  'Editing error': 'Error al editar',
  'Editing error, invalid url': 'Error al editar, url inválida',
  'Store locator': 'Sucursales',
  'Stores': 'Sucursales',
  'Store name': 'Nombre de tienda',
  'These are your active stores': 'Estas son sus sucursales activas',
  'Create new store': 'Crear nueva sucursal',
  'Total value': "Valor Total",
  'List rooms': "Listar habitaciones",
  'Enable booking dates': "Habilitar fechas de reserva",
  'Your credit is about to run out': 'Su crédito está a punto de agotarse',
  'payments': 'Pagos',
  'Payments': 'Pagos',
  'None': 'Ninguno',
  'Payment gateway': 'Pasarela de pagos',
  'List': 'Lista',
  'Options': 'Opciones',
  'Is the payment gateway live': 'Esta la pasarela de pagos en produccion?',
  'Yes': 'Si',
  'Not': 'No',
  'Create table layout': "Crear Distribución Mesas",
  "Create here the distribution of the tables in your restaurant.": "Crea aquí la distribución de las mesas en tu restaurante.",
  'Table Layout': "Distribución Mesas",
  'Add Table': "Agregar Mesa",
  'Add Floor': "Agregar Piso",
  "New table details": "Detalles Nueva mesa",
  "Square": "Cuadrado",
  "Rectangle": "Rectángulo",
  "Circle": "Circular",
  "What shape it will see it in your map": "De cual forma se vera la mesa en su espacio",
  "Shape": "Forma",
  "Number of seats": "Número de sillas",
  "Table Number": "Número de mesa",
  "it is not allowed to add more floors!": "no se permite agregar mas pisos!",
  "Delete Floor": "Quitar Piso",
  "added Floor!": "Piso Agregado",
  "deleted Floor!": "Piso Borrado",
  Floor: "Piso",
  "First Floor": "Primer Piso",
  "Second Floor": "Segundo Piso",
  "Third Floor": "Tercer Piso",
  "you must select a table shape": "debes seleccionar una forma de mesa",
  "You must write the number of chairs for the table": "debes escribir el número de sillas para la mesa",
  "you must write the table number": "debes escribir el número de la mesa",
  ' hours': " Horas",
  "Delete Table": "Quitar Mesa",
  "Booking Table": "Reserva Mesa",
  "Reserved": "Reservado",
  "Booking Information": "Información Reserva",
  "BOOKING INFORMATION": "INFORMACIÓN RESERVA",
  "Table number": "Número de Mesa",
  "Contact information": "Informacipon de Contacto",
  "Number of people": "Número de Personas",
  "Time": "Hora Reserva",
  "Requirements": "Requisitos",
  "Assign table": "Asignar Mesa",
  Occupied: "Ocupado",
  adult: "Adulto",
  child: "Niño",
  night: "Noche",
  "per night": "Por noche",
  nights: "Noches",
  Capacity: "Capacidad",
  Result: "Resultados",
  "Next eight days": "Siguientes 8 dias",
  'Next thirty days': "Siguientes 30 dias",
  Assign: "Asignar",
  Assigning: "Asignando",
  'Table Occupancy': "Ocupación Mesas",
  'Check the status of the tables and their occupation': "Consulta el estado de las mesas y su ocupación",
  'There cannot be two tables with the same number': "No pueden haber dos mesas con el mismo número",
  'Update your plan and access more services. Empower your business with decisions based on accurate data.' :'Actualiza tu plan y accede a más servicios. Potencia tu negocio con decisiones basadas en datos precisos.',
  Free: "Gratis",
  'free': 'gratis',
  'essential': 'básico',
  'Essential' : 'Básico',
  'Standard' : 'Estándar',
  'standard' : 'estándar',
  "Change Status": "Cambiar Estado",
  'Pages': 'Paginas',
  'Edit Website': 'Editar el sitio web',
  'Click on the page you would like to edit.': 'Haga clic en la página que desea editar.',
  'Disable page': 'Deshabilitar pagina',
  'Enable page': 'Habilitar pagina',
  'Edit page': 'Editar pagina',
  'Delete page': 'Borrar pagina',
  'Position': 'Posición',
  'State:': 'Estado:',
  'Name page': 'Nombre de la pagina',
  'Double field text': 'Campo doble de texto',
  'Email field': 'Campo email',
  'Area field': 'Campo de área',
  'Reception email': 'Email de recepcion',
  'You must choose the type of field you want to add': 'Debe elegir el tipo de campo que quiere agregar',
  'Button': 'Botón',
  'Fields': 'Campos',
  'Divider': 'Divisor',
  'Value one': 'Valor uno',
  'Value two': 'Valor dos',
  'Type the text you want to be displayed on the web page': 'Escribe el texto que quieres que se vea en la pagina web.',
  'You must upload the image you want to use as logo': 'Debes subir la imagen que quieres poner como logo.',
  'You will see a division on the website': 'Verá una división en el sitio web.',
  'You must type the text that will be displayed inside the text fields.': 'Debe escribir el texto que se vera dentro de los campos de texto.',
  'You must enter the text that will appear in the middle of the button.': 'Debes ingresar el texto que se vera en medio del botón.',
  "These are today's analytical statistics" : 'Estas son las estadísticas analíticas de hoy',  
  'Form': 'Formulario',
  'Link': 'Link',
  'table': 'Mesas',
  'Hours': 'Horas',
  "Invoice Number: ": "Factura Número",
  "Bookings Details": "Detalles Reserva",
  'Pending payment Booking': "Reservas pendiente de pago",
  "Partial payment Booking": "Reservas con Pago parcial",
  "Please enter a  name client": "Digíte un nombre de Cliente",
  "Search by": "Buscar por",
  "name": "Nombre",
  "Search by name": "Buscar por Nombre",
  "Client name": "Nombre Cliente",
  "Select Days": "Seleccionar Dias",
  'Here you can select the special days': "Aca puedes seleccionar los dias especiales",
  'Create New Event': "Crear nuevo evento",
  'Edit Event': "Editar Evento",
  "Event Title": "Título Evento",
  "Event Description": "Descripción",
  "Event Star Date": "Fecha Inicio",
  "Event End Date": "Fecha Fin",
  'Delete this event': "Eliminar Evento",
  'Add Event': "Agregar Evento",
  "write a category": "Escriba una categoría",
  'Add Category': "Agregar categoría",
  "Do you want to delete category?": "¿Quieres eliminar la categoría?",
  'The calendar has been successfully updated!': "Calendario Actualizado!",
  'The event has been deleted': "Evento Borrado",
  'Add new category': "Agregar Categoría",
  'Analytics Rent Car': 'Analitica Renta de carros',
  'Most reserved cars': 'Autos más reservados',
  'Events': 'Eventos',
  'Payment Details': 'Detalles del pago',
  'Details per night': 'Detalles por noche',
  "Cars": 'Carros',
  "Car": 'Carro',
  "Rent Car": 'Alquilar carros',
  "rent car": 'alquilar carros',
  "Change date": 'Cambiar fecha',
  "Change car": 'Cambiar carro',
  "Information of booking": 'Información de la reserva',
  "Information of client": 'Información del cliente',
  "Pickup date": 'Fecha de recogida',
  "Pickup location": 'Lugar de recogida',
  "Return date": 'Fecha de regreso',
  "Return location": 'Lugar de devolución',
  "SELECT CARS": 'SELECCIONAR CARROS',
  "DETAILS CARS": 'DETALLES DEL CARRO',
  "Select the dates to view the available cars": 'Selecciona las fechas para ver los carros disponibles',
  "Cars available for": 'Carros disponibles para',
  "Click to add a new car": 'Haz clic para añadir un carro nuevo',
  "New rent car": 'Nuevo carro para alquilar',
  "Edit rent car": 'Editar carro para alquilar',
  "Add the characteristics of the car to ren": 'Añade las características del carro a alquilar',
  "Additional cost": 'Costo adicional',
  "Do you really want to pay": 'De verdad quieres pagar',
  "You won`t be able to revert after do pay": 'No podrá revertir después de pagar',
  "Money in favor": 'Dinero a favor',
  "Events Name": "Nombre Eventos",
  'Select tags...': "Seleccione Eventos",
  "Is it a hostel?": "¿Es un Hostal?",
  Hostel: "Hostal",
  "Details Prices": "Precios Detallados",
  'Required field': "Campo Requerido",
  'value at night': "Valor por Noche",
  "How many similar rooms are available?": "¿Cuántas habitaciones similares hay disponibles?",
  'Qualification': 'Calificación',
  'this review': 'esta opinión',
  'Create new page': 'Crear nueva pagina',
  'Formatted text': 'Texto con formato',
  'You must name your new page': 'Debe nombrar su nueva página',
  'Page name': 'Nombre de pagina',
  'Modules': 'Modulos',
  'Active': 'Habilitado',
  'Only': 'Unico',
  'Times': 'Veces',
  'Add services': 'Añadir servicios',
  'Do you really want to duplicate ': 'Realmente quieres duplicar ',
  'Duplicate': 'Duplicar',
  'Add section': 'Agregar sección',
  "Schedule programming failed.": "Programación de agenda fallida.",
  "There are no rooms available to schedule.": "No hay habitaciones disponibles para Agendar",
  "Deleting Element": "Borrando Elemento",
  "Editable service?": "¿Servicio Editable?",
  "Is it a package?": "¿Es un paquete?",
  "YES": "SI",
  "Not Apply": "No aplica",
  "Guests  cannot be zero": "Números de Huespedes no puede ser cero",
  'Number Similar Rooms': "Número de similar habitaciones",
  'Amount  cannot be zero': "Valor No puede ser cero",
  "What includes?": "¿Qué incluye?",
  "This plan includes the following": "Este plan incluye lo siguiente",
  "Please write an item": "Porfavor escribe un item",
  'You must place what the package includes': "Debes colocar que incluye el paquete",
  "The accommodation offers": "El alojamiento ofrece",
  "The image cannot exceed 20 MB": "La imagen no puede superar los 20 MB",
  'Calendar personal': 'Calendario del personal',
  'Calendar services': 'Calendario de servicios',
  'Service management': 'Administración de servicios',
  'this could be your service management panel.': 'este podria ser su panel de gestion de servicios.',
  'Create services': 'Crear servicios',
  'Create service': 'Crear servicio',
  'Name service': 'Nombre del servicio',
  'Service time': 'Tiempo del servicio',
  'Complete the form to create your service.': 'Complete el formulario para crear su servicio.',
  'Amounts': 'Costos',
  'Complete': 'Finalizar',
  'Payment not made. We regret to inform you that your payment could not be processed at this time.':'Pago no realizado. Lamentamos informarle que su pago no ha podido ser procesado en este momento.',
  'Please verify your payment method details and try again. If the issue persists, we recommend contacting our support team for further assistance.':'Por favor, verifique los detalles de su método de pago e intente nuevamente. Si persiste el problema, le recomendamos ponerse en contacto con nuestro equipo de soporte para recibir asistencia adicional.',
  'See amounts': 'Ver costos',
  'See personal': 'Ver personal',
  'Enable service': 'Habilitar servicio',
  'Disable service': 'Deshabilitar servicio',
  'Complete the form to edit your service.': 'Complete el formulario para editar su servicio.',
  'Personal management': 'Administración de personal',
  'Successfully edit service': 'Servicio editado satisfactoriamente',
  'Successfully create service': 'Servicio creado satisfactoriamente',
  'this could be your personal management panel.': 'este podria ser su panel de gestion de personal.',
  'Create personal': 'Crear personal',
  'Book a table': "Reserva una mesa",
  "What day would you like your booking to be made?": "¿Qué día quieres que se haga tu reserva?",
  "At what time would you like to make your booking?": "¿A qué hora le gustaría hacer su reserva?",
  "what name would you like the booking to be under?": "¿Con qué nombre te gustaría que estuviera la reserva?",
  "how many people are attending?": "¿Cuántas personas asisten?",
  "NEXT": "SIGUIENTE",
  "Add value": "Agregar Valor",
  "Event values": "Valores por Evento",
  "Prices for events": "Precios por eventos",
  "The number of similar rooms must be greater than 0": "El número de cuartos similares debe ser mayor a 0",
  "The restaurant is not available at the time you want to make a reservation.": "El restaurante no está disponible en el momento en que desea realizar la reserva.",
  "No tables available": "No hay mesas disponibles",
  'Invalid Email': "Email Invalido",
  "please select any special requirements:": "Porfavor Selecciona algún requerimiento especial:",
  "Please, enter your opening hours": "Por favor, introduzca su horario de apertura",
  "BOOK A TABLE": "RESERVA UNA MESA",
  "Please fill out one of the following details:": "Porfavor completa los siguientes detalles:",
  "reservation made successfully": "Reserva realizada con éxito!",
  Birthday: "Cumpleaños",
  "Highchair": "Silla alta",
  "Wheelchair access": "Acceso silla de ruedas",
  "Pram space": "Espacio coche de bebe",
  "Other": "Otro",
  "Guests Min": "Huéspedes Min",
  "Guests Max": "Huéspedes Max",
  "Guests": "Huéspedes",
  "Includes": "Incluye",
  "Charge per event?": "¿Cobro por evento?",
  'Edit service': 'Editar servicio',
  'Table price': 'Tabla de precios',
  'You can see the different prices according to the day.': 'Puedes ver los diferentes precios según el día.',
  'Personal list': 'Lista del personal',
  'You can see the people assigned to this service.': 'Puedes ver las personas asignadas en este servicio.',
  'No personnel': 'No hay personal',
  'You must complete the form.': 'Debes completar el formulario',
  'You have successfully deleted the service.': 'Haz eliminado el servicio satisfactoriamente',
  'Please write a name': "Porfavor escribe un nombre",
  'Please write a last name': "Porfavor escribe un apellido",
  "Successfully edited employee": "Edición Satisfactoria",
  "Successfully created employee": "Creación Satisfactoria",
  'An error has occurred': "Ha ocurrido un error",
  "Are you sure you want to delete?": "¡Está seguro que desea eliminar?",
  'Edit Employee': "Editar Empleado",
  'Add Employee': "Agregar Empleado",
  "Complete the form to edit employee": "Complete la información para editar el empleado",
  "Complete the form to add employee": "Complete la información para agregar el empleado",
  "Employee Name": "Nombre",
  "Employee Last Name": "Apellido",
  "Select one or more": "Seleccione uno o mas",
  "Disable Employee": "Deshabilitar Empleado",
  'List of services': "Lista de Servicios",
  'Last Name': "Apellidos",
  'Availability': "Disponibilidad",
  'Show Services': "Mostrar Servicios",
  "Services not added": "Servicios no agregados",
  'Disable Person': "Deshabilitar Persona",
  'Enabled Person': "habilitar Persona",
  "We couldn't find any data matching your search criteria": "No pudimos encontrar ningún dato que coincida con sus criterios de búsqueda",
  'Main image': 'Imagen principal',
  'Add images': 'Agregar imagenes',
  'Image Added': 'Imagen agregada',
  'Services calendar': 'Calendario de servicios',
  'Next Week': 'Siguiente semana',
  'Previous Week': 'Semana anterior',
  'Reservation details': 'Detalles de la reservación',
  'Customer details': 'Datos del cliente',
  'Service details': 'Datos del servicio',
  'Personnel details': 'Datos del personal',
  'Start time': 'Hora de inicio',
  'End time': 'Hora final',
  "Configure availability": "Configurar Disponibilidad",
  "Temporary employee": "Empleado Temporal",
  'Booking of services': 'Reserva de servicios',
  'this could be your services calendar management panel.': 'este podría ser el panel de gestión de su calendario de servicios',
  'Choose the service': 'Elige el servicio',
  'No services available, please enable a service.': 'No hay servicios disponibles, por favor habilite un servicio.',
  'Email sent successfully': 'Email enviado correctamente',
  'Successfully uploaded invoice': 'Factura subida correctamente',
  'This service is not available on this date': 'Este servicio no está disponible en esta fecha',
  'Anyone': 'Cualquier persona',
  Temporary: "Temporal",
  People: "Personas",
  'Confirm': 'Confirmar',
  'Rellene los campos obligatorios': 'Rellene los campos obligatorios',
  'Please enter a valid email': 'Por favor ingrese un correo electrónico válido',
  'Successful reservation': 'Reserva exitosa',
  'Edit Booking': 'Editar Reserva',
  'The reservation was successfully deleted': 'La reserva se eliminó correctamente',
  'Please fill in the required fields': 'Por favor, complete los campos obligatorios',
  'Please select a time': 'Por favor seleccione una hora',
  'Please select a person for your service': 'Por favor seleccione una persona para su servicio',
  'please add working days': 'Por agregue horas de disponibilidad',
  "Personal Calendar": "Calendario Empleados",
  "Check staff availability": "Consultar disponibilidad de personal",
  "There are no people to add": "No hay personas agregadas",
  "Edit Hours": "Editar Horas",
  "Remove on date": "Remover de Fecha",
  'Please select a person for your service or another time for your reservation.': 'Por favor seleccione una persona para su servicio o otro tiempo para su reserva.',
  'Edit cover page or name of establishment': 'Editar portada o nombre del establecimiento',
  'Please enter a value': 'Por favor ingrese un valor',
  'The minimum value is $20.000': 'El valor mínimo es $20.000',
  'Best Sellers': 'Los más vendidos',
  'Most popular services': 'Servicios más populares',
  'You should write the first date': 'Debes escribir la primera fecha',
  'You should write the second date': 'Debes escribir la segunda fecha',
  'You can only select a period of ': 'Solo puedes seleccionar un periodo de ',
  'You can only select a period valid': 'Solo puedes seleccionar un periodo válido',
  'Money pending payment': "Dinero Pendiete por pago",
  "BOOKINGS FULLY PAY": "RESERVAS CON PAGO COMPLETO",
  "BOOKINGS FULLY PAY OR PARTIAL PAY": "RESERVAS CON PAGO COMPLETO O PARCIAL",
  "RESERVATION PENDING PAYMENT": "RESERVAS PENDIENTE DE PAGO",
  "Number of transactions": "Número de transacciones",
  Debt: "Deuda",
  'The order of your services has been updated successfully': 'El orden de tus servicios se ha actualizado correctamente',
  'You cannot move this item': 'No puedes mover este elemento',
  'There are no employees available for this day or hours': 'No hay empleados disponibles para este día u horas',
  'Move': 'Mover',
  'There are no available personnel': 'No hay personal disponible',
  "Adviser": "Asesor",
  'There are no available hours': 'No hay horas disponibles',
  "Bookings Pending Payment": "Reservaciones Pendientes por Pago",
  "Bookings Fully Pay": "Reservaciones con pago completo",
  "Bookings Fully Pay or Partial Pay": "Reservas con pago completo o parcial",
  'Total Cost': "Total",
  'Details Booking': "Detalles Reserva",
  'Service Name': "Nombre Servicio",
  "Booking Date": "Fecha Reserva",
  "Booking Hour": "Hora Reserva",
  "Value Paid": "Valor Pagado",
  "Details Client": "Detalles Cliente",
  "Payment details": "Detalles Pago",
  "Night Numbers": "Número de Noches",
  'Service Analytics': "Analítica de Servicios",
  'Service images': "Imagenes de Servicios",
  'Successfully created service': 'Servicio creado con éxito',
  'Create new user': 'Crear un nuevo usuario',
  "We couldn't find any users matching your search criteria": 'No hemos podido encontrar ningún usuario que coincida con tus criterios de búsqueda',
  "The user account has been removed": "La cuenta de usuario ha sido eliminada",
  "The user account has not been deleted": "La cuenta de usuario no ha sido eliminada",
  "The reservation was successfully edited": "La reserva ha sido editada con éxito",
  "To be able to make reservations from the portal you must contact a staff member to adjust your settings.": "Para poder hacer reservas desde el portal debe ponerse en contacto con un miembro del personal para ajustar su configuración.",
  "Are you sure you want to permanently delete this user account": "¿Está seguro de que quiere eliminar permanentemente esta cuenta de usuario?",
  "This employee is temporary, check the date of availability to see their schedule.": "Este empleado es temporal, revisa la fecha de disponibilidad para ver su agenda",
  "You can add your social networks": "Puedes añadir tus redes Sociales",
  "Social Network": "Redes Sociales",
  "Percentage to base value": "Porcentaje al valor base",
  "You have pending orders to prepare": "Tienes ordenes pendientes por preparar",
  "Edit category": "Editar categoria",
  "Recommended cover dimensions": "Dimensiones recomendadas para la portada",
  "Recommended dimensions for the category": "Dimensiones recomendadas para la categoria",
  "Recommended product dimensions": "Dimensiones recomendadas para el producto",
  "Recommended dimensions for service": "Dimensiones recomendadas para el servicio",
  "Recommended vehicle dimensions": "Dimensiones recomendadas para los vehiculos",
  "Recommended room dimensions": "Dimensiones recomendadas para las habitaciones",
  "Height": "Alto",
  "Width": "Ancho",
  "App settings": "Ajustes de aplicación",
  'You already have the best plan': 'Ya tienes el mejor plan',
  'Upgrade plan' : 'Mejorar plan',
  'Upgrade your plan' : 'Mejora tu plan',
  'If you wish to upgrade your plan,': 'Si deseas actualizar tu plan haz',
  'Update plan' : 'Actualizar plan',
  'Click here.' : 'click aquí',
  'You currently have the Free plan.' : 'Actualmente cuentas con el plan gratis.',
  'You currently have the Essential plan.': 'Actualmente cuentas con el plan básico.',
  'You currently have the Standard plan.': 'Actualmente cuentas con el plan estándar.',
  'You currently have the Premium plan.': 'Actualmente cuentas con el plan premium.',
  'GET' : 'OBTENER',
  'Plans':'Planes',
  '1 year' : '1 año',
  '1 month': '1 mes',
  '6 months':'6 meses',
  'For a lifetime':'De por vida',
  'Choose plan': 'Escoge tu plan',
  'Our Plans' : 'Nuestros Precios',
  "Check out what we can do for you!" : '¡Compruebe lo que podemos hacer por usted!',
  "Theme Settings": "Ajustes de tema",
  'Bookings included' : 'Reservas incluidas',
  'In the month you have a limited amount of reservations, you can get more reservations by upgrading your plan.': 'En el mes tienes una cantidad limitada de reservas, puedes obtener mas reservas actualizando tu plan.',
  'Customize application': 'Personalizar aplicativo',
  'All plans allow you to adapt our platform to your corporate colors, texts, logos, etc.': 'Todos los planes permiten que adecues nuestra plataforma a tus colores corporativos, textos, logos, etc.',
  '24/7 support':'Soporte 24/7',
  'All plans can have 24/7 support, but paid plans will always have priority of response order or solution.':'Todos los planes pueden contar con soporte 24/7, pero los planes de paga siempre tendrán prioridad de orden de respuesta o solución.',
  'Improve your customer service with our powerful Chatbot, which allows you to auto-reply and customer support without operator.': 'Mejore su atención al cliente con nuestro poderoso Chatbot, el cual le permite, respuestas automáticas, atención al usuario sin la necesidad de un operario.',
  'Weekly reports':'Reportes semanales',
  'Every day new transactions enter to your business, so we offer metrics, statistics, results, comparisons, etc. Which allows you to make decisions and know the economic status of your establishment.':'Cada día ingresan nuevas transacciones a su negocio, por eso ofrecemos métricas, estadísticas, resultados de ventas, comparativos, etc. El cual le sirve para la toma de decisiones y saber el estado económico de su establecimiento.',
  'Admin users':'Usuarios administradores',
  'You can add staff to manage the platform, depending on the plan you choose you can get a number of admin users.':'Usted puede agregar personal para administar la plataforma, dependiendo el plan que elija puede obtener una cantidad de usuarios administradores.',
  'Keep several conversations at the same time and answering automatic chats from a WhatsApp account, you can provide a more personalized and immediate service. (We take care of configuring it depending on your needs).':'Mantenga varias conversaciones al mismo tiempo y respondiendo chats automaticos desde una cuenta de Whatsapp, usted puede brindar un servicio más personalizado e inmediato. (Nosotros nos encargamos de configurarlo dependiendo sus necesidades).',
  'Waiters': 'Meseros',
  'Add your employees to the platform, which will have a space so they can see their tasks to do.':'Agregue a la plataforma a sus empleados, el cual tendrán un espacio para que puedan ver sus tareas a realizar.',
  'Branches': 'Sucursales',
  'We offer the option that you can create different branches to manage them independently.':'Ofrecemos la opción de que puedas crear diferentes sucursales para administrarlas de manera independiente.',
  "Layout Blueprints": "Planos de Diseños",
  "Choose layout": "Elige tu diseño",
  "Light color schemes": "Esquemas de colores claros",
  "Dark color schemes": "Esquemas de colores oscuros",
  "Favorites": "Favoritos",
  "Locate my venue": "Localizar mi establecimiento",
  "Customers": "Clientes",
  "Report": "Reporte",
  "Customer report": "Reporte de clientes",
  "this is your management dashboard for your client reporting.": "este es tu panel de gestión para tu reporte de clientes.",
  "Search customer by name or phone...": "Buscar clientes por nombre o teléfono...",
  "There is no information to show in this period": "No hay información para mostrar en este periodo",
  "Sales channel": "Canal de venta",
  "Rent car": "Renta de vehículos",
  "Empty": "No tiene",
  "Your location is in this place": "Su ubicación se encuentra en este lugar",
  "Latitude": "Latitud",
  "Longitude": "Longitud",
  "Possible address": "Posible Dirección",
  "You must locate your venue": "Debes localizar tu establecimiento",
  "Change my location": "Cambiar mi ubicación",
  "Create new category": "Crear nueva categoria",
  "See": "Ver",
  "Best Day": "Mejor día",
  "Best Hour": "Mejor hora",
  "Total Sales": "Total vendido",
  "Best day and time of sale": "Mejor día y hora de ventas",
  "Date range": "Rango de fechas",
  "Subject": "Asunto",
  "Support": "Soporte",
  "Help Support": "Soporte de ayuda",
  "Hi": "Hola",
  "how we can help you": "cómo podemos ayudarle",
  "without venue": 'sin establecimiento',
  "for": "para",
  "Request": "Solicitud",
  "Pay gateways by QR": "Pasarelas de pago por QR",
  "New pay gateways by QR": "Nueva pasarelas de pago por QR",
  "New gateway": "Nueva pasarela",
  "View gateways": "Ver pasarelas",
  "Could not upload file": "No se ha podido cargar el archivo",
  "QR uploaded successfully": "QR cargado con éxito",
  "Saving data...": "Guardando datos...",
  "Completed!": "Completado!",
  "QR codes for transfers": "Códigos QR para transferencias",
  "Effective": "Efectivo",
  "Transfer by QR": "Transferencia por QR",
  "Payment by QR": "Pago por QR",
  "Not yet paid": "Todavía no se ha pagado",
  "Timetable": "Horario",
  "Estimated delivery time": "Tiempo de entrega estimado",
  "See delivery time": "Ver tiempo de entrega",
  "Assigned orders": "Pedidos asignados",
  "Last update": "Ultima actualizacion",
  "Location": "Ubicación",
  "See location of delivery mans": "Ver ubicación de repartidores",
  "ID delivery": "ID domiciliario",
  "Details delivery man": "Detalles del domiciliario",
  "Double click for a preview": "Haga doble clic para obtener una vista previa",
  'Restaurants': 'Restaurantes',
  "This sound cannot be deleted because it is being used by another establishment.": "Este sonido no se puede eliminar porque esta siendo utilizado por otro establecimiento.",
  "Don't have an account yet?": "¿Aún no tiene una cuenta?",
  "Register now": "Registrate ahora",
  "Forgot your password?": "¿Olvidó su contraseña?",
  "Login with Google": "Ingresar con Google",
  "Login with Facebook": "Ingresar con Facebook",
  "Welcome to Order & Eat Now": "Bienvenido a Order & Eat Now",
  "We will start the registration process in 3 simple steps": "Comenzaremos el proceso de registro en 3 simples paso",
  "let's start": "Iniciemos",
  "Already have an account?": "¿Ya tienes una cuenta?",
  "In 3 quick steps": "En 3 rápidos pasos",
  "Complete the data": "Completa los datos",
  "Verify your email": "Verifica tu correo",
  "Login to the dashboard": "Ingresa al dashboard",
  "Need help?": "¿Necesitas ayuda?",
  "Contact us at": "Contactanos",
  "of": "de",
  "Complete the form": "Completa el formulario",
  "Or you can register with:": "O puedes registrarte con:",
  "Name of establishment": "Nombre del establecimiento",
  "Continue": "Continuar",
  "Press enter": "Presiona enter",
  "Enter Name of establishment...": "Ingresa Nombre del establecimiento...",
  "Register with Google": "Registrate con Google",
  "Register with Facebook": "Registrate con Facebook",
  "Select type of establishment": "Seleccione el tipo de establecimiento",
  "Select type of establishment...": "Seleccione el tipo de establecimiento...",
  "Restaurant": "Restaurante",
  "Hotel": "Hotel",
  "Vehicle rental": "Alquiler de vehículos",
  "Please wait a moment, we are validating the information.": "Por favor espera un momento estamos validando la información",
  "We are collecting all the necessary information to be ready...": "Estamos recolectando todo lo necesario para estar listos...",
  "Username": "Nombre de usuario",
  "Enter UserName...": "Ingresa nombre de usuario...",
  "Excellent!": "¡Excelente!",
  "Welcome to": "Te damos la bienvenida a",
  "Login to the Dashboard": "Ingresar al Dashboard",
  "You're done! We have sent a code": "¡Listo! Hemos enviado un código",
  "to your email, please enter it:": "a tu correo, por favor ingrésalo:",
  "Enter the 6 digit code": "Ingresa el código de  6 dígitos",
  "Resend code": "Reenviar código",
  "in": "en",
  "Verification Code": "Código de verificación",
  "Hello": "Hola",
  "Enter the following code in the registration process to complete the registration:": "Ingresa el siguiente código en el proceso de registro para finalizarlo:",
  "This code is valid for 1 minute": "Este código tiene validez de 1 minuto",
  "Do you have any questions? Contact us at": "¿Tienes alguna pregunta? Contáctanos",
  "Terms": "Términos",
  "If you have any questions or need assistance, please do not hesitate to contact our support team.": "Si tienes preguntas o necesitas ayuda, no dudes en ponerte en contacto con nuestro equipo de soporte.",
  "This message was sent to": "Este mensaje fue enviado a",
  "If you do not wish to receive these emails from Order & Eat Now in the future, you can edit your profile or unsubscribe.": "Si no desea recibir estos correos electrónicos de Order & Eat Now en el futuro, puede editar su perfil o darse de baja.",
  "The code is incorrect or no longer valid": "El código es incorrecto o ya no es válido",
  "*The password must contain at least one uppercase letter, lowercase letter, digit, symbol and a minimum of 8 characters.*": "*La contraseña debe contener al menos una letra mayúscula, minúscula, dígito, símbolo y mínimo de 8 caracteres*",
  "Only one more step is needed": "Solo necesitamos un pasos más",
  "Quick settings for your establishment": "Ajustes rápidos de tu establecimiento",
  "Hide establishment name?": "¿Ocultar el nombre del establecimiento?",
  "View preview": "Ver previsualización",
  "Search fit by word...": "Buscar ajuste por palabra...",
  "Here you can make the application settings": "Aquí puedes realizar los ajustes",
  "of the application": "de la aplicación",
  "Read": "Leidos",
  "Add to directory": "Agregar a directorio",
  "Add user to directory": "Agregar usuario al directorio",
  "Name company": "Nombre de la Empresa",
  "Edit directory user": "Editar usuario del directorio",
  "Phone number": "Numero de telefono",
  "Unregistered user": "Usuario sin registrar",
  "Directory": "Directorio",
  "Label": "Etiqueta",
  "Are you sure you want to delete the contact": "¿Estas seguro de eliminar el contacto",
  "Update": 'Actualizar',
  'Updated information': 'Información actualizada',
  'Error try again': 'Error vuelve a intentarlo',
  'Try again': 'Reintentar',
  "Please enter the client's name": "Por favor, ingrese el nombre del cliente",
  "Please enter the company's name": "Por favor, ingrese el nombre de la empresa",
  "Enter words or phrases to describe the client": "Introduce palabras o frases para describir al cliente",
  "Gateway": "Pasarela",
  "Payment gateway key": "Clave de la pasarela de pago",
  "Opening hours": "Horas de apertura",
  "You can also contact us through these channels": "También puedes contactarnos por estos medios",
  "Send Message...": "Enviar mensaje...",
  "Hi, I'm Botly, your support assistant at Order and Eat Now. I'm here to help you with any questions or concerns you may have about our platform. If you need information, assistance, or just want to share your feedback, feel free to write to me. I'm ready to help you with whatever you need! 😊": "Hola, soy Botly, tu asistente de soporte en Order and Eat Now. Estoy aquí para ayudarte con cualquier pregunta o inquietud que puedas tener acerca de nuestra plataforma. Si necesitas información, asistencia o simplemente quieres darnos tus comentarios, no dudes en escribirme. ¡Estoy listo para asistirte en lo que necesites! 😊",
  "Statistics": "Estadísticas",
  "Complete the following field:": "Completa el siguiente campo:",
  "Writing": "Escribiendo",
  "Create Branches": "Crear sucursales",
  "Create new branch": "Crear nueva sucursal",
  "Comments": "Comentarios",
  "Welcome message": "Mensaje de bienvenida",
  "Reminder message": "Mensaje de recordatorio",
  "End message": "Mensaje de Finalización",
  "Payment in cash": "Pago en efectivo",
  "Please note that to view the mobile preview you can only view it from the desktop and tablet version.": "Por favor tenga en cuenta que para ver la previsualización del celular solamente se puede ver desde la versión de escritorio y tablet.",
  "Take this to the cash register to pay.": "Llévalo a la caja registradora para pagar.",
  "Order number": "Número de orden",
  "Subtotal": "Subtotal",
  "Service fee": "Tarifa de servicio",
  "Back to home": "Volver al inicio",
  "Rate our service": "Califique nuestro servicio",
  "Please note that editing your trade texts must be done from the desktop or tablet version.": "Por favor tenga en cuenta que para editar los textos de su comercio se debe hacer desde la versión de escritorio o tablet.",
  "Edit content of the different screens": "Editar contenido de las diferentes pantallas",
  "Here you can make settings for the application's texts.": "Aquí puedes realizar los ajustes de los textos de la aplicación.",
  "Text copied to clipboard": "Texto copiado al portapapeles",
  "Share your establishment": "Comparta su establecimiento",
  "General Settings": "Ajustes generales",
  "Disable 'View comments'?": "¿Desactivar 'Ver comentarios'?",
  "Edit opening hours": "Editar horarios de atención",
  "Money received": "Dinero recibido",
  "Deliverys average": "Promedio de entregas",
  "New orders collected": "Nuevas órdenes recogidas",
  "Payment methods": "Formas de pago",
  "Money from delivered orders": "Dinero órdenes entregadas",
  "Money from collected orders": "Dinero órdenes recogidas",
  "Orders delivered": "Órdenes entregadas",
  "Message report": "Reporte de mensajes",
  /* graph */
  /*Total órdenes nuevas  */"This is a summary of the different payment methods used by your customers when making a purchase. This allows you to monitor the popularity of each payment method, detect trends and make informed decisions on how to optimize your payment strategy to meet your customers' needs and improve their shopping experience.": "Este es un resumen de las diferentes formas de pago utilizadas por tus clientes al realizar una compra. Esto te permite monitorear la popularidad de cada método de pago, detectar tendencias y tomar decisiones informadas sobre cómo optimizar tu estrategia de pago para satisfacer las necesidades de tus clientes y mejorar su experiencia de compra.",
  /* Dinero recibido */"Use this section to keep track of the amount of money you have received and better understand the financial status of your business.": "Utiliza esta sección para hacer un seguimiento de la cantidad de dinero que has recibido y comprender mejor el estado financiero de tu negocio",
  /* Promedio de entregas */"Use the tracking of delivery averages to identify potential bottlenecks and reduce waiting times, improve the quality of your service and increase customer satisfaction": "Utiliza el seguimiento del promedio de entregas para identificar posibles cuellos de botella y reducir tiempos de espera, mejora la calidad de tu servicio y aumenta la satisfacción del cliente",
  /* Propinas de la semana */"Tracking weekly tips allows you to evaluate the performance of your employees, reward and motivate your team, and ensure exceptional customer service. Use this data to improve your business and foster a culture of high-quality service": "El seguimiento de las propinas semanales te permite evaluar el desempeño de tus empleados, recompensar y motivar a tu equipo, y garantizar un servicio excepcional al cliente. Utiliza estos datos para mejorar tu negocio y fomentar una cultura de servicio de alta calidad.",
  /* Formas de pago */"This is a summary of the different payment methods used by your customers when making a purchase. This allows you to monitor the popularity of each payment method, detect trends and make informed decisions on how to optimize your payment strategy to meet your customers' needs and improve their shopping experience": "Este es un resumen de las diferentes formas de pago utilizadas por tus clientes al realizar una compra. Esto te permite monitorear la popularidad de cada método de pago, detectar tendencias y tomar decisiones informadas sobre cómo optimizar tu estrategia de pago para satisfacer las necesidades de tus clientes y mejorar su experiencia de compra",
  /* Dinero órdenes entregadas */"This is a summary of the different payment methods used by your customers when making a purchase. This allows you to monitor the popularity of each payment method, detect trends, and make informed decisions on how to optimize your payment strategy to meet your customers' needs and enhance their shopping experience": "Este es un resumen de las diferentes formas de pago utilizadas por tus clientes al realizar una compra. Esto te permite monitorear la popularidad de cada método de pago, detectar tendencias y tomar decisiones informadas sobre cómo optimizar tu estrategia de pago para satisfacer las necesidades de tus clientes y mejorar su experiencia de compra",
  /* Órdenes entregadas */"This is a summary of the different payment methods used by your customers when making a purchase. This allows you to monitor the popularity of each payment method, detect trends, and make informed decisions on how to optimize your payment strategy to meet your customers' needs and enhance their shopping experience.": "Este es un resumen de las diferentes formas de pago utilizadas por tus clientes al realizar una compra. Esto te permite monitorear la popularidad de cada método de pago, detectar tendencias y tomar decisiones informadas sobre cómo optimizar tu estrategia de pago para satisfacer las necesidades de tus clientes y mejorar su experiencia de compra",
  /* promedio mesero */"1": "1",
  /* Message report */"11": "11",
  /* Preparation average */"111": "111",
  'Use the tracking of new orders collected to maintain effective control over your sales, identify demand patterns and offer a fast and reliable shipping service to your customers, improving satisfaction and loyalty.' : 'Utilice el seguimiento de los nuevos pedidos recopilados para mantener un control efectivo sobre sus ventas, identificar patrones de demanda y ofrecer un servicio de envío rápido y confiable a sus clientes, mejorando la satisfacción y la lealtad.', 
  /*  */"5": "5",
  /*  */"1111": "1111",
  'Delivery averages': 'Promedio de entrega',
  "Here you can make general application settings": "Aquí puede realizar los ajustes generales de la aplicación",
  "Enter a link where you want to start the booking process?": "¿Ingrese un link donde quiera iniciar el proceso de reserva?",
  "Notification sounds": "Sonidos de notificación",
  "Enabling sounds for notifications in available modules": "Habilitar sonidos para las notificaciones en los módulos disponibles",
  "Add Social Networks": "Agregar Redes Sociales",
  "Activate comments": "Activar comentarios",
  "Allow your customers to leave comments.": "Permita que sus clientes dejen comentarios.",
  "Do you accept cash?": "¿Aceptan dinero en efectivo?",
  "Enable menu?": "¿Habilitar menú?",
  "Activate domiciles": "Activar domicilios",
  "Would you like the option to deliver orders?": "¿Te gustaría la opción de entregar pedidos?",
  "Would you like the option for your customers to pick up their orders?": "¿Te gustaría la opción de que tus clientes recojan sus pedidos?",
  "Do you want your customers to see the ratings?": "¿Desea que sus clientes vean las calificaciones?",
  "Would you like to have the virtual queue option?": "¿Desea tener la opción de fila virtual?",
  "Branch Adjustments": "Ajustes Sucursales",
  "Here you can make the settings for your branches": "Aquí puedes realizar los ajustes de tus sucursales",
  "ChatBot Settings": "Ajustes ChatBot",
  "Here you can adjust the ChatBot text settings.": "Aquí puedes realizar los ajustes de los textos del ChatBot.",
  "Do you want to activate the ChatBot?": "¿Quieres activar el ChatBot?",
  "Adjustments Payments": "Ajustes Pagos",
  "Here you can make payment adjustments": "Aquí puedes realizar los ajustes de los pagos",
  "Do you want to activate QR?": "¿Desea activar QR?",
  "Would you like to receive online payments?": "¿Desea recibir pagos online?",
  "Do you want to disable the split payment option?": "¿Desea deshabilitar la opción de dividir pago?",
  "Select your payment gateway": "Selecione su pasarela de pago",
  "Text Settings": "Ajustes Textos",
  "Here you can make settings for the application's texts": "Aquí puedes realizar los ajustes de los textos de la aplicación",
  "Comment of hotel": "Comentario del hotel",
  "Comment of rent car": "Comentario de alquiler de coche",
  "Comment of restaurant": "Comentario de restaurante",
  "Pick-up order": "Recoger pedido",
  "Virtual queue": "Cola virtual",
  "Color Settings": "Ajustes Colores",
  "Here you can adjust the color settings of the application.": "Aquí puedes realizar los ajustes de los colores de la aplicación",
  "Primary color": "Color primario",
  "Background color": "Color de fondo",
  "Secondary color": "Color secundario",
  "Title color": "Color título",
  "Text color": "Color texto",
  "Main font color": "Color de fuente principal",
  "Save changes": "Guardar cambios",
  "Statistics of your business": "Estadisticas de tu negocio",
  "Here we show you relevant data and metrics of your business's performance to make informed and strategic decisions.": "Aqui te mostramos datos y métricas relevantes del desempeño de tu negocio para tomar decisiones informadas y estratégicas",
  "Indicates the new orders in your company": "Indica las nuevas ordenes en tu empresa",
  "Indicates the amount of money received so far.": "Indica la cantidad de dinero recibido hasta el momento.",
  "Indicates the efficiency of the delivery process.": "Indica la eficiencia del proceso de entrega.",
  "Indicates new purchases made and prepared for shipping.": "Indica nuevas compras realizadas y preparadas para su envío",
  "Indicates the total amount of tips received during the week.": "Indica el monto total de propinas recibidas durante la semana.",
  "Indicates the available payment options for customers.": "Indica las opciones de pago disponibles para los clientes.",
  "Indicates the total amount of money for orders picked up.": "Indica el total del dinero por ordenes recogidas.",
  "Indicates the total amount of money for orders delivered.": "Indica el total del dinero por ordenes entregadas.",
  "Indicates the number of orders delivered.": "Indica el numero de ordenes entregadas",
  "Indicates the average preparation time in the selected range.": "Indica el promedio de preparacion en el rango seleccionado",
  "Indicates the cost of messages in the selected range.": "Indica el costo de mensajes en el rango seleccionado",
  'Indicates the efficiency of waiters in the selected range': 'Indica la eficiencia de los meseros en el rango seleccionado',
  'In orders': 'En ordenes',
  'NEW ORDERS': 'NUEVAS ORDENES',
  "Average deliveries": "Entregas promedio",
  "Average new orders picked up": "Promedio nuevas órdenes recogidas",
  "Total transfer": "Total transferencia",
  "Preparation": 'Preparación',
  'Total whatsapp': 'Whatsapp total',
   'April':'Abril',
  'Message cost': 'Costo del mensaje',
  'Whatsapp messages' : 'Mensajes de whatsapp',
  "Average waiter total" : 'Promedio total del mesero',
  "Texts": "Textos",
  "Error loading map": "Error al cargar el mapa",
  "Loading map...": "Cargando mapa...",
  "Address:": "Dirección:",
  "Change texts": "Cambiar textos",
  "My store": "Mi tienda"
};

export default esJSON;
