import App from './App';
import { createRoot } from 'react-dom/client';

import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import LogRocket from 'logrocket';

import { AuthProvider } from './contexts/FirebaseAuthContext';
import * as serviceWorker from './serviceWorker';
import { SidebarProvider } from './contexts/SidebarContext';
import 'src/mocks';
import 'src/utils/chart';
import store from 'src/store';
import { ErrorBoundary, ErrorBoundaryComponent } from './errors';

import 'nprogress/nprogress.css';
import 'react-phone-number-input/style.css'

const container = document.getElementById('root');

const root = createRoot(container!); // createRoot(container!) if you use TypeScript

const isprod = process.env.REACT_APP_IS_PROD ?? "";

if (isprod && isprod === "true") {
  LogRocket.init('7gezem/order-and-eat-now-portal');
}

root.render(
  <HelmetProvider>
    <Provider store={store}>
      <ErrorBoundary fallback={<ErrorBoundaryComponent />}>
        <SidebarProvider>
          <BrowserRouter>
            <AuthProvider>
              <App />
            </AuthProvider>
          </BrowserRouter>
        </SidebarProvider>
      </ErrorBoundary>
    </Provider>
  </HelmetProvider>,
);

serviceWorker.unregister();
