import { FC, ReactNode, useState, useContext, useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { SidebarContext } from 'src/contexts/SidebarContext';

import PropTypes from 'prop-types';
import { IconButton, Box, Tooltip, Badge, Popover, ListItem } from '@mui/material';
import { useTranslation } from 'react-i18next';
import KeyboardArrowRightTwoToneIcon from '@mui/icons-material/KeyboardArrowRightTwoTone';
import KeyboardArrowLeftTwoToneIcon from '@mui/icons-material/KeyboardArrowLeftTwoTone';

import { styled } from '@mui/material/styles';
import { useFavoritesSection } from 'src/hooks/useFavoritesSections';
import { queryData, updateData } from 'src/utils/firebase';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';


const IndicatorWrapper = styled(Box)(
  ({ theme }) => `
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: -${theme.spacing(1.5)};
  width: 20px;
  height: 20px;

  .MuiSvgIcon-root {
    width: 100%;
    height: auto;
  }
`
);

const PopoverWrapper = styled(Popover)(
  ({ theme }) => `
  .MuiList-root {
    padding: ${theme.spacing(2)} !important;

    .MuiListItem-root {
      padding: 2px 0 !important;

      .MuiIconButton-root {
        width: 100% !important;
        height: auto !important;
        justify-content: flex-start !important;
        font-weight: bold !important;
        background: transparent !important;
        color: ${theme.colors.alpha.black[70]} !important;
        padding: ${theme.spacing(1, 2)} !important;

        .name-wrapper {
          display: block !important;
        }

        &.Mui-active,
        &:hover {
          background: ${theme.colors.alpha.black[10]} !important;
          color: ${theme.colors.alpha.black[100]} !important;
        }
      }
    }  
  }
`
);

interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  open?: boolean;
  active?: boolean;
  name: string;
}

interface favoritesSectionsI {
  name: string,
  link: string
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  open: openParent,
  active,
  name,
  ...rest
}) => {
  const { t }: { t: any } = useTranslation();
  const { toggleSidebar } = useContext(SidebarContext);
  const uidEmail = localStorage.getItem('uidEmail') ?? ""
  const [favoritesSections, setFavoritesSections] = useState<favoritesSectionsI[]>([])

  const { favorites, setFavorites, setReload } = useFavoritesSection()
  useEffect(() => {
    if (favorites && favorites.length > 0) {
      setFavoritesSections(favorites)
    }
  }, [favorites])

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const addFavoriteSection = async (section: any) => {
    try {
      const dataPortalUsers = await queryData(`portalUsers/Restaurants/Users`)
      const docs = dataPortalUsers.docs
      let docUser = docs.find((doc) => doc.id === uidEmail).data()
      const findSection = docUser.favorites.find((item: any) => item.name === section.name && item.link === section.link)
      // console.log({ docUser })
      if (!findSection) {
        docUser.favorites.push(section)
      } else {
        const findIndex = docUser.favorites.findIndex((items: any) => items.name === section.name && items.link === section.link)
        docUser.favorites.splice(findIndex, 1)
      }
      await updateData(`portalUsers/Restaurants/Users`, uidEmail, docUser)
      setFavoritesSections(docUser.favorites)
      setFavorites(docUser.favorites)
      setReload(true)
      // console.log({ section, docUser })
    } catch (error) {
      console.error(error);
    }
  }

  if (children) {
    return (
      <ListItem component="div" className="Mui-children" key={name} {...rest}>
        <Tooltip title={t(name)} disableInteractive placement="right" arrow>
          <IconButton
            className={clsx({ 'Mui-active': open })}
            onClick={handleClick}
          >
            {Icon && <Icon />}
            {badge && <Badge badgeContent={badge} />}

            <IndicatorWrapper>
              {open ? <KeyboardArrowLeftTwoToneIcon /> : <KeyboardArrowRightTwoToneIcon />}
            </IndicatorWrapper>

          </IconButton>
        </Tooltip>
        <PopoverWrapper
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          open={open}>{children}</PopoverWrapper>
      </ListItem>
    );
  }

  return (
    <ListItem component="div" key={name} {...rest} >
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 1, width: '200px' }}>
        <Box>
          <Tooltip title={t(name)} disableInteractive placement="right" arrow>
            <IconButton
              className="Mui-active"
              component={RouterLink}
              onClick={toggleSidebar}
              to={link}
            >
              {Icon && <Icon />}
              <span className="name-wrapper">
                {name}
              </span>
              {badge && <Badge badgeContent={badge} />}
            </IconButton>
          </Tooltip>
        </Box>
        <Box>
          <IconButton aria-label="Favorites" onClick={() => addFavoriteSection({ name, link })} >
            {favoritesSections.find((sectionName) => sectionName.name === name && sectionName.link === link) ? < StarIcon fontSize='small' /> : < StarBorderIcon fontSize='small' />}
          </IconButton>
        </Box>
      </Box>
    </ListItem>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  active: PropTypes.bool,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired
};

SidebarMenuItem.defaultProps = {
  open: false,
  active: false,
};

export default SidebarMenuItem;
