import { useRef, useState } from 'react';

import { IconButton, Box, List, alpha, ListItem, Divider, Typography, ListItemText, Popover, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { TFunction, useTranslation } from 'react-i18next';
import WarningTwoToneIcon from '@mui/icons-material/WarningTwoTone';

import Text from 'src/components/Text';
import internationalization from 'src/i18n/i18n';

import deFlag from 'country-flag-icons/3x2/DE.svg';
import usFlag from 'country-flag-icons/3x2/US.svg';
import esFlag from 'country-flag-icons/3x2/ES.svg';
import frFlag from 'country-flag-icons/3x2/FR.svg';
import cnFlag from 'country-flag-icons/3x2/CN.svg';
import aeFlag from 'country-flag-icons/3x2/AE.svg';

const SectionHeading = styled(Typography)(({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`
);
const ImageWrapper = styled('img')(({ theme }) => `
        width: 30px;
        margin: 3px;
`
);

const IconButtonPrimary = styled(IconButton)(({ theme }) => `
    margin-left: ${theme.spacing(0.5)};
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 42px;
    height: 42px;
    border-radius: 100%;
    transition: ${theme.transitions.create(['background', 'color'])};

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`
);

function LanguageSwitcher() {
    const [isOpen, setOpen] = useState<boolean>(false);
    const ref = useRef<any>(null);

    const { i18n } = useTranslation();
    const { t }: { t: TFunction<"translation"> } = useTranslation();

    const getLanguage = i18n.language;

    const switchLanguage = ({ lng }: { lng: string }) => {
        internationalization.changeLanguage(lng)
        handleClose();
    }

    const handleOpen = (): void => setOpen(true)
    const handleClose = (): void => setOpen(false)

    return (
        <>
            <Tooltip arrow title={t('Language Switcher')}>
                <IconButtonPrimary color="primary" ref={ref} onClick={handleOpen} id='language-btn'>
                    {getLanguage.includes('de') && <ImageWrapper alt="German" src={deFlag} />}
                    {getLanguage.includes('en') && <ImageWrapper alt="English" id='english-lang' src={usFlag} />}
                    {getLanguage.includes('es') && <ImageWrapper alt="Spanish" src={esFlag} />}
                    {getLanguage.includes('fr') && <ImageWrapper alt="French" src={frFlag} />}
                    {getLanguage.includes('cn') && <ImageWrapper alt="Chinese" src={cnFlag} />}
                    {getLanguage.includes('ae') && <ImageWrapper alt="Arabic" src={aeFlag} />}
                </IconButtonPrimary>
            </Tooltip>

            <Popover
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }} anchorEl={ref.current}
                onClose={handleClose} open={isOpen}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right'
                }}
            >
                <Box sx={{ maxWidth: 240 }}>
                    <SectionHeading variant="body2" color="text.primary">
                        {t('Language Switcher')}
                    </SectionHeading>

                    <List sx={{ p: 2 }} component="nav">
                        <ListItem sx={{ display: 'block' }} className={getLanguage.includes('en') ? 'active' : ''}
                            onClick={() => switchLanguage({ lng: 'en' })}
                        >
                            <ImageWrapper alt="English" src={usFlag} />
                            <ListItemText sx={{ pl: 1 }} primary="English" />
                        </ListItem>
                        <ListItem
                            sx={{ display: 'none' }}
                            className={getLanguage.includes('de') ? 'active' : ''}
                            onClick={() => switchLanguage({ lng: 'de' })}
                        >
                            <ImageWrapper alt="German" src={deFlag} />
                            <ListItemText sx={{ pl: 1 }} primary="German" />
                        </ListItem>
                        <ListItem
                            sx={{ display: 'block' }}
                            className={getLanguage.includes('es') ? 'active' : ''}
                            onClick={() => switchLanguage({ lng: 'es' })}
                        >
                            <ImageWrapper alt="Spanish" src={esFlag} />
                            <ListItemText sx={{ pl: 1 }} primary="Spanish" />
                        </ListItem>
                        <ListItem
                            sx={{ display: 'none' }}
                            className={getLanguage.includes('fr') ? 'active' : ''}
                            onClick={() => switchLanguage({ lng: 'fr' })}
                        >
                            <ImageWrapper alt="French" src={frFlag} />
                            <ListItemText sx={{ pl: 1 }} primary="French" />
                        </ListItem>
                        <ListItem
                            sx={{ display: 'none' }}
                            className={getLanguage.includes('cn') ? 'active' : ''}
                            onClick={() => switchLanguage({ lng: 'cn' })}
                        >
                            <ImageWrapper alt="Chinese" src={cnFlag} />
                            <ListItemText sx={{ pl: 1 }} primary="Chinese" />
                        </ListItem>
                        <ListItem
                            sx={{ display: 'none' }}
                            className={getLanguage.includes('ae') ? 'active' : ''}
                            onClick={() => switchLanguage({ lng: 'ae' })}
                        >
                            <ImageWrapper alt="Arabic" src={aeFlag} />
                            <ListItemText sx={{ pl: 1 }} primary="Arabic" />
                        </ListItem>
                    </List>

                    <Divider />

                    <Text color="warning">
                        <Box p={2} sx={{ maxWidth: 340, display: 'none' }}>
                            <WarningTwoToneIcon fontSize="small" />
                            <Typography variant="body1">
                                {t('We only translated a small part of the template, for demonstration purposes')}!
                            </Typography>
                        </Box>
                    </Text>
                </Box>
            </Popover>
        </>
    );
};

export default LanguageSwitcher;