import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Avatar, Box, IconButton, InputBase, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ReCAPTCHA from "react-google-recaptcha";
import { createRef, useEffect, useState } from "react";
import axios from "axios";
import { StyleHero } from "./StyleHero";
import { getDomainApiChat } from "src/utils/generateToken";
import { faker } from '@faker-js/faker';
import { getChats } from "src/utils/firebase";
import { PulseLoader } from 'react-spinners';

export const DialogHelpSupport = (): JSX.Element => {
    const currentLanguage = window.localStorage.getItem('i18nextLng');
    const [open, setOpen] = useState(false);
    const [captcha, setCaptcha] = useState<any>(false);
    const [values, setValues] = useState<any>("");
    const [datas, setdata] = useState<any[]>([]);
    const [boolean, setboolean] = useState(0);
    const [request, setRequest] = useState(true)
    const [msg, setMsg] = useState("");
    const [loadig, setLoading] = useState(false)
    const { t } = useTranslation();
    const { classes } = StyleHero();
    const icons = {
        chat: "https://storage.googleapis.com/order-and-eat-now/Images/Iconos/chatPortal/buttonChat.svg",
        avatar: "https://storage.googleapis.com/order-and-eat-now/Images/Iconos/chatPortal/avatarChatBoot.svg",
    };
    const msgWelcomeBoot = "Hi, I'm Botly, your support assistant at Order and Eat Now. I'm here to help you with any questions or concerns you may have about our platform. If you need information, assistance, or just want to share your feedback, feel free to write to me. I'm ready to help you with whatever you need! 😊"
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dt = () => {
        const userPhoneID = window.localStorage.getItem("userPhoneID");
        if (!userPhoneID) {
            return;
        }
        const handleData = (data) => {
            setdata(data);
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            data?.length === boolean ? null : setboolean(data.length);
        };

        const unsubscribe = getChats(userPhoneID, "soporte", handleData);

        return unsubscribe;
    };

    useEffect(() => {
        const aValue = window.localStorage.getItem("userPhoneID");
        if (!aValue) {
            window.localStorage.setItem("userPhoneID", '+579' + faker.datatype.number(1000000000));
        }
        if (captcha) {
            setCaptcha(true);
        }
        dt()
    }, [captcha, boolean, request, dt]);

    const handleResetCaptcha = () => {
        recaptchaRef.current.reset();
        setCaptcha(false);
    };
    const recaptchaRef: any = createRef();
    const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY ?? "";
    const handleChange = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
    ) => {
        event.preventDefault();
        let { name, value } = event.target;
        const insertValue = { ...values };
        insertValue[name] = value;
        setValues(event.target.value);
    };
    const handleClickOpen = () => { setOpen(true) };
    const handleClose = () => { setOpen(false) };

    const chatBoot = async (e: any, valuess: any) => {
        setLoading(true)
        e.preventDefault();
        const values = valuess;
        setMsg(values);
        setValues("");
        setRequest(!request);
        try {
            const config = {
                headers: { "Content-Type": "application/json" },
            };
            axios.post(
                getDomainApiChat() + "/supportPortal",
                /* supportPortal */
                {
                    prompt: values,
                    phone: window.localStorage.getItem("userPhoneID"),
                    language: currentLanguage
                },
                config
            )
                .then(function (response) {
                    console.log(response);
                    console.log("response");
                    dt();
                    setMsg("");
                    setLoading(false)
                })
                .catch(function (error) {
                    dt();
                    console.log("chatBoot_Portal1",error);
                    setMsg("");
                    setLoading(false)
                });
        } catch (error) {
            console.log(error);
            console.log("chatBoot_Portal2",error);
            setMsg("");
            setLoading(false)
        }
    };

    return (
        <div>
            <Box>
                <IconButton sx={{ '&:hover': { background: 'transparent', transform: 'scale(1.2)' } }} onClick={handleClickOpen}>
                    <LazyLoadImage src={icons.chat} alt="iconHelp" />
                    <Typography
                        variant="body1"
                        fontWeight={700}
                        marginLeft={'5px'}
                    >
                    </Typography>
                </IconButton>
            </Box>
            {
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className={classes.dialog}
                    id="dialogContentText"
                    scroll="paper"
                >
                    <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
                        <Box className={classes.dialogBox}>
                            <Avatar
                                sx={{ marginX: "10px" }}
                                alt="Remy Sharp"
                                src={icons.avatar}
                            />
                            {"Botly"}
                        </Box>
                        <Box>
                            <Button onClick={handleClose}>
                                <CloseIcon className={classes.CloseIcon} />
                            </Button>
                        </Box>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            sx={{
                                overflowY: "scroll",
                                "::-webkit-scrollbar": { display: "none" },
                                scrollbarWidth: "none",
                            }}
                            className={classes.dialogContent}
                        >
                            {!captcha ?
                                <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'flex-end' }} width={'100%'} >
                                    <Typography variant="body1" color="initial">
                                        {t("Complete the following field:")}
                                    </Typography>
                                    <ReCAPTCHA
                                        ref={recaptchaRef}
                                        sitekey={RECAPTCHA_KEY}
                                        size="normal"
                                        id="recaptcha-google"
                                        onChange={(token: any) => { setCaptcha(token) }}
                                        onExpired={handleResetCaptcha}
                                    />
                                </Box>
                                :
                                (
                                    <Box>
                                        {
                                            <Box sx={{ marginBottom: '10px' }} className={classes.boot}>
                                                {t(msgWelcomeBoot)}
                                            </Box>
                                        }
                                        {datas?.length > 0 &&
                                            datas.map((item, index) => (
                                                <Box key={index} margin={"0"}>
                                                    <Box display={item.request === '' && 'none'} className={classes.msg}>{item.request}</Box>
                                                    <Box display={item.request === '' && 'none'} className={classes.boot}>{item.response}</Box>
                                                    <Button
                                                        sx={{ width: "0px", height: "0px", padding: "0px" }}
                                                        autoFocus
                                                    ></Button>
                                                </Box>
                                            ))}
                                        {!(msg === "") && (
                                            <Box margin={"0"}>
                                                <Box display={msg === "" && "none"} className={classes.msg}>
                                                    {msg}
                                                </Box>
                                                <Button
                                                    sx={{ width: "0px", height: "0px", padding: "0px" }}
                                                    autoFocus
                                                ></Button>
                                            </Box>
                                        )}
                                        {loadig &&
                                            <Box>
                                                <Typography alignItems={'end'} sx={{ display: 'flex' }}>
                                                    {t('Writing')}
                                                    <PulseLoader loading={loadig} size={5} color="#8E8E8E" />
                                                </Typography>
                                            </Box>
                                        }
                                    </Box>
                                )
                            }
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions
                        sx={{ width: "100%", }}
                    >
                        <form
                            className={classes.dialogActions}
                            onSubmit={(e) => !captcha && chatBoot(e, values)}
                        >
                            <InputBase
                                id="outlined-multiline-flexible"
                                multiline
                                placeholder={t('Send Message...')}
                                name="message"
                                value={values}
                                maxRows={5}
                                className={classes.bootTextFiel}
                                onChange={handleChange}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        e.preventDefault();
                                        chatBoot(e, values);
                                    }
                                }}
                                disabled={!captcha}
                            />
                            <Button type="submit">
                                <ArrowForwardIosIcon className={classes.ArrowForwardIosIcon} />
                            </Button>
                        </form>
                    </DialogActions>
                </Dialog>
            }
        </div>
    );
};