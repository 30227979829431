import { generateJWTToken, getGoogleAccessToken, getDomain } from './generateToken'

export async function sendSms(restaurantName: string, documentId: string, templateKey: string) {
    const token = await generateJWTToken();
    const id_token = await getGoogleAccessToken(token);
    let params = {
        restaurantName,
        documentId,
        templateKey
    }
    const request = await fetch(getDomain() + "/twilio/send-sms",
        {
            method: "POST",
            body: JSON.stringify(params),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Authorization": "Bearer " + id_token
            },
        }
    );
    return await request.json();
}