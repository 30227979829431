import { useRef, useState } from 'react';

import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import { alpha, Avatar, Box, Button, darken, Divider, List, ListItem, ListItemText, Popover, Typography } from '@mui/material';
import { TFunction, useTranslation } from 'react-i18next';
import { InboxTwoTone, UnfoldMoreTwoTone, AccountBoxTwoTone, AccountTreeTwoTone, LockOpenTwoTone } from '@mui/icons-material';
import { styled } from '@mui/material/styles';

import useAuth from 'src/hooks/useAuth';

const UserBoxButton = styled(Button)(({ theme }) => `
    padding: ${theme.spacing(1)};
    background-color: ${darken(theme.sidebar.menuItemBg, .05)};

    .MuiButton-label {
      justify-content: flex-start;
    }

    &:hover {
      background-color: ${darken(theme.sidebar.menuItemBg, .1)};
    }
`);

const MenuUserBox = styled(Box)(({ theme }) => `
    background: ${theme.colors.alpha.black[5]};
    padding: ${theme.spacing(2)};
`);

const UserBoxText = styled(Box)(({ theme }) => `
    text-align: left;
    padding-left: ${theme.spacing(1)};
`);

const UserBoxLabel = styled(Typography)(({ theme }) => `
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.sidebar.menuItemColor};
    display: block;

    &.popoverTypo {
      color: ${theme.palette.secondary.main};
    }
`);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
    color: ${alpha(theme.sidebar.menuItemColor, .6)};

    &.popoverTypo {
      color: ${theme.palette.secondary.light};
    }
`
);

function SidebarTopSection(props) {

  const [isOpen, setOpen] = useState<boolean>(false);
  const ref = useRef<any>(null);

  const { t }: { t: TFunction<"translation"> } = useTranslation();

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const { user, logout } = useAuth();

  let typeSidebar = pathname.split('/')[1]

  const handleOpen = (): void => setOpen(true);

  const handleClose = (): void => setOpen(false);

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();
      navigate('/');
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <UserBoxButton fullWidth color="secondary" ref={ref} onClick={handleOpen} id="UserBoxButton">
        <Avatar variant="rounded" alt={user.name} src={props.profileImage} />
        <Box display="flex" flex={1} alignItems="center" justifyContent="space-between"        >
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
          <UnfoldMoreTwoTone fontSize="small" sx={{ ml: 1 }} />
        </Box>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user.name} src={props.profileImage} />
          <UserBoxText>
            <UserBoxLabel className="popoverTypo" variant="body1">{user.name}</UserBoxLabel>
            <UserBoxDescription className="popoverTypo" variant="body2">
              {user.jobtitle}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button to={`/${typeSidebar}/management/user/${localStorage.getItem("uidEmail")}`} component={NavLink}>
            <AccountBoxTwoTone fontSize="small" />
            <ListItemText primary={t('Profile')} />
          </ListItem>
          <ListItem
            button
            to="/preview/applications/mailbox/inbox"
            component={NavLink}
            sx={{ display: 'none' }}
          >
            <InboxTwoTone fontSize="small" />
            <ListItemText primary={t('Inbox')} />
          </ListItem>
          <ListItem
            button
            to="/preview/applications/projects-board"
            component={NavLink}
            sx={{ display: 'none' }}
          >
            <AccountTreeTwoTone fontSize="small" />
            <ListItemText primary={t('Projects')} />
          </ListItem>
        </List>
        <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleLogout} id="SignOutSidebarTopSection">
            <LockOpenTwoTone sx={{ mr: 1 }} />
            {t('Sign out')}
          </Button>
        </Box>
      </Popover>
    </>
  );
}

export default SidebarTopSection;
