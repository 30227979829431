import { FC, ReactNode,useState,useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { getUserInfo } from 'src/utils/getUserInfo';

import Sidebar from './Sidebar';
import Header from './Header';

// import ThemeSettings from 'src/components/ThemeSettings';

interface BoxedSidebarLayoutProps {
  children?: ReactNode;
}

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: calc(${theme.sidebar.width} + ${theme.spacing(3)});
        }

        .footer-wrapper {
            margin: 0;
            background: transparent;
        }
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
`
);

const BoxedSidebarLayout: FC<BoxedSidebarLayoutProps> = () => {
  const [ profileImage, SetProfileImage ] = useState<string>("")

  useEffect(()=>{
    const getDataUser = async() =>{
      let profileImageUser = await  getUserInfo()
      SetProfileImage(profileImageUser)
    }
    getDataUser()
  },[])
  return (
    <>
      <Sidebar/>
      <MainWrapper>
        <MainContent>
          <Header profileImage={profileImage}/>
          <Outlet />
          {/* <ThemeSettings /> */}
        </MainContent>
      </MainWrapper>
    </>
  );
};

export default BoxedSidebarLayout;
