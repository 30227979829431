import { queryUserRestaurants } from 'src/utils/firebase';

export async function getUserInfo() {
  let uidEmail = localStorage.getItem("uidEmail")
  
  try {
    const profileUser = await queryUserRestaurants('portalUsers/Restaurants/Users', uidEmail);
    if (profileUser.hasOwnProperty('profileImage')) {
      return profileUser.profileImage
    }
  } catch (error) {
    console.log(error)
  }
}
