import { Stack } from '@mui/material';
import HeaderNotifications from './Notifications';
import LanguageSwitcher from './LanguageSwitcher';

function HeaderButtons() {
  return (
    <Stack direction='row'>
      <LanguageSwitcher />
      <HeaderNotifications />
    </Stack>
  );
}

export default HeaderButtons;
