import { Buffer } from 'buffer';

const CreateRedirectUrl = (keyOption: string, options: any, restName: string, i18nextLng: string) => {

    let urlPath: string = ''

    const createUrlDynamic = () => {
        let option = ''
        let pathName = ''
        let venue = Buffer.from(restName).toString('base64')
        const encodeRedirect = Buffer.from(options[keyOption].redirect).toString('base64')
        if (options[keyOption].redirect === 'restaurant') {
            if (options[keyOption].option === 'our-service') {
                option = 'our-service'
                pathName = i18nextLng
            } else {
                const option = Buffer.from(options[keyOption].option).toString('base64')
                pathName = option
            }
        } else if (options[keyOption].redirect === 'reservation') {
            option = 'table'
            pathName = encodeRedirect
        } else if (options[keyOption].redirect === 'services') {
            option = options[keyOption].redirect
        } else {
            pathName = encodeRedirect
        }
        return { option, venue, pathName }
    }

    if (keyOption && options[keyOption]) {
        let url = options[keyOption].value ? options[keyOption].value : 'https://app.orderandeatnow.com.au'
        if (!url.includes('https://')) {
            url = `https://${url}`
        }
        if (url.charAt(url.length - 1) !== '/') {
            url += '/'
        }
        const { option, venue, pathName } = createUrlDynamic()
        if (option !== '') {
            url += `${option}/`
        }
        url += `${venue}`
        if (pathName !== '') {
            url += `/${pathName}`
        }
        urlPath = url
    }

    return { urlPath }
}

export default CreateRedirectUrl