export const responsive = [
    {
        breakpoint: 1024,
        settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
        }
    },
    {
        breakpoint: 600,
        settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
        }
    },
    {
        breakpoint: 480,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }
];
export const prices = [
    {
        name: 'Free',
        translate: 'prices.tableFree',
        price: 'For a lifetime'
    },
    {
        name: 'Essential',
        translate: 'prices.tableEssential',
        price: '$150.000'
    },
    {
        name: 'Standard',
        translate: 'prices.tableStandard',
        price: '$450.000'
    },
    {
        name: 'Premium',
        translate: 'prices.tablePremium',
        price: '$950.000'
    }
];
export const responsiveRowsRestaurant = {
    free: {
        name: 'Free',
        img: 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/Prices-WebSite/WebSite-Gratis.svg',
        sections: [
            {
                name: 'Bookings included',
                value: 500,
            },
            {
                name: 'Customize application',
                value: true,
            },
            {
                name: '24/7 support',
                value: true,
            },
            {
                name: 'Chatbot',
                value: false,
            },
            {
                name: 'Weekly reports',
                value: false,
            },
            {
                name: 'Admin users',
                value: 2
            },
            {
                name: 'Whatsapp Chatbot',
                value: false,
            },
            {
                name: 'Waiters',
                value: 3,
            },
            {
                name: 'Branches',
                value: 1,
            }
        ]
    },
    essential: {
        name: 'Essential',
        img: 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/Prices-WebSite/WebSite-Basico.svg',
        sections: [
            {
                name: 'Bookings included',
                value: 2000,
            },
            {
                name: 'Customize application',
                value: true,
            },
            {
                name: '24/7 support',
                value: true,
            },
            {
                name: 'Chatbot',
                value: true,
            },
            {
                name: 'Weekly reports',
                value: false,
            },
            {
                name: 'Admin users',
                value: 5,
            },
            {
                name: 'Whatsapp Chatbot',
                value: false,
            },
            {
                name: 'Waiters',
                value: 10,
            },
            {
                name: 'Branches',
                value: 5,
            }
        ]
    },
    standard: {
        name: 'Standard',
        img: 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/Prices-WebSite/WebSite-Estandar.svg',
        sections: [
            {
                name: 'Bookings included',
                value: 5000,
            },
            {
                name: 'Customize application',
                value: true,
            },
            {
                name: '24/7 support',
                value: true,
            },
            {
                name: 'Chatbot',
                value: true,
            },
            {
                name: 'Weekly reports',
                value: true,
            },
            {
                name: 'Admin users',
                value: 10,
            },
            {
                name: 'Whatsapp Chatbot',
                value: false,
            },
            {
                name: 'Waiters',
                value: 20,
            },
            {
                name: 'Branches',
                value: 10,
            }
        ]
    },
    premium: {
        name: 'Premium',
        img: 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/Prices-WebSite/WebSite-Premium.svg',
        sections: [
            {
                name: 'Bookings included',
                value: 'Unlimited',
            },
            {
                name: 'Customize application',
                value: true,
            },
            {
                name: '24/7 support',
                value: true,
            },
            {
                name: 'Chatbot',
                value: true,
            },
            {
                name: 'Weekly reports',
                value: true,
            },
            {
                name: 'Admin users',
                value: 'Unlimited,'
            },
            {
                name: 'Whatsapp Chatbot',
                value: true,
            },
            {
                name: 'Waiters',
                value: 'Unlimited',
            },
            {
                name: 'Branches',
                value: 'Unlimited',
            }
        ]
    }
};
export const icon = [
    {
        name: 'Free',
        icons: 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/Prices-WebSite/WebSite-Gratis.svg',
        price: 'For a lifetime'
    },
    {
        name: 'Essential',
        icons: 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/Prices-WebSite/WebSite-Basico.svg',
        price: '$150.000'
    },
    {
        name: 'Standard',
        icons: 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/Prices-WebSite/WebSite-Estandar.svg',
        price: '$450.000'
    },
    {
        name: 'Premium',
        icons: 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/Prices-WebSite/WebSite-Premium.svg',
        price: '$950.000'
    },
];
export const rowsRestaurant = [
    {
        name: 'Bookings included',
        free: 500,
        essential: 2000,
        standard: 5000,
        premium: 'Unlimited'
    },
    {
        name: 'Customize application',
        free: true,
        essential: true,
        standard: true,
        premium: true
    },
    {
        name: '24/7 support',
        free: true,
        essential: true,
        standard: true,
        premium: true
    },
    {
        name: 'Chatbot',
        free: false,
        essential: true,
        standard: true,
        premium: true
    },
    {
        name: 'Weekly reports',
        free: false,
        essential: false,
        standard: true,
        premium: true
    },
    {
        name: 'Admin users',
        free: 2,
        essential: 5,
        standard: 10,
        premium: 'Unlimited'
    },
    {
        name: 'Whatsapp Chatbot',
        free: false,
        essential: false,
        standard: false,
        premium: true
    },
    {
        name: 'Waiters',
        free: 3,
        essential: 10,
        standard: 20,
        premium: 'Unlimited'
    },
    {
        name: 'Branches',
        free: 1,
        essential: 5,
        standard: 10,
        premium: 'Unlimited'
    },
    {
        name: '',
        free: '',
        essential: 'prices.buttonTable',
        standard: 'prices.buttonTable',
        premium: 'prices.buttonTable'
    }
];
export const descriptionHover = [
    { name: "Bookings included", description: "In the month you have a limited amount of reservations, you can get more reservations by upgrading your plan." },
    { name: "Customize application", description: "All plans allow you to adapt our platform to your corporate colors, texts, logos, etc." },
    { name: "24/7 support", description: "All plans can have 24/7 support, but paid plans will always have priority of response order or solution." },
    { name: "Chatbot", description: "Improve your customer service with our powerful Chatbot, which allows you to auto-reply and customer support without operator." },
    { name: "Weekly reports", description: "Every day new transactions enter to your business, so we offer metrics, statistics, results, comparisons, etc. Which allows you to make decisions and know the economic status of your establishment." },
    { name: "Admin users", description: "You can add staff to manage the platform, depending on the plan you choose you can get a number of admin users." },
    { name: "Whatsapp chatbot", description: "Keep several conversations at the same time and answering automatic chats from a WhatsApp account, you can provide a more personalized and immediate service. (We take care of configuring it depending on your needs)." },
    { name: "Waiters", description: "Add your employees to the platform, which will have a space so they can see their tasks to do." },
    { name: "Branches", description: "We offer the option that you can create different branches to manage them independently." },
];
export const pricesPlans = {
    Essential: {
        onemonth: 150000
    },
    Standard:{
        onemonth: 450000
    },
    Premium: {
        onemonth: 950000
    }
}
