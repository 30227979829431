import { Box, IconButton, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CreateRedirectUrl from 'src/content/management/Settings/utils/CreateRedirectUrl';
import { useDataSettings } from 'src/hooks/useSettings';
import { LocalStorage } from 'src/localStorge/LocalStorage';
import { restoreDocumentsLanguage } from 'src/utils/firebase';

const MyStore = () => {

    const { t }: { t: any } = useTranslation();
    const MyStoreIcon = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/myStore.svg'

    const { restName, i18nextLng } = LocalStorage()
    const { options } = useDataSettings()


    const navigateToApp = async () => {
        const language = await restoreDocumentsLanguage(restName, i18nextLng.slice(0, 2))
        const { urlPath } = CreateRedirectUrl('addRedirects', options, restName, language)
        if (urlPath) {
            window.open(urlPath, '_blank')
        }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
            }}
        >
            <Box>
                <IconButton onClick={navigateToApp}>
                    <LazyLoadImage src={MyStoreIcon} />
                </IconButton>
            </Box>
            <Box>
                <Typography>
                    {t('My store')}
                </Typography>
            </Box>
        </Box>
    )
}

export default MyStore
