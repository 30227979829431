import { Tooltip, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const LogoWrapper = styled(Link)(({ theme }) => `
        color: ${theme.colors.alpha.trueWhite[100]};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
        display: flex;
        justify-content: center;
`
);

function Logo() {
  return (

    <LogoWrapper to="/">
      <Box style={{ display: "flex", backgroundColor: "white", justifyContent: "center", borderRadius: "50%", alignItems: "center" }}>
        <Tooltip arrow placement="right" title="Order And Eat Now">
          <img src="/order-eat.svg" alt="Order And Eat Now" style={{ height:"55px" }}/>
        </Tooltip>
      </Box>
    </LogoWrapper>

  );
}

export default Logo;
