import * as constants from '../utils/constants'

export const LocalStorage = () => {

    const i18nextLng = localStorage.getItem(constants.i18nextLng) ?? null
    const versionPortal = localStorage.getItem(constants.versionPortal) ?? null
    const login = localStorage.getItem(constants.login) ?? null
    const uid = localStorage.getItem(constants.uid) ?? null
    const userName = localStorage.getItem(constants.userName) ?? null
    const restName = localStorage.getItem(constants.restName) ?? null
    const role = localStorage.getItem(constants.role) ?? null
    const restNameDisplay = localStorage.getItem(constants.restNameDisplay) ?? null
    const cypress = localStorage.getItem(constants.cypress) ?? null
    const dataRegister = localStorage.getItem(constants.dataRegister) ?? null

    return {
        i18nextLng,
        versionPortal,
        login,
        uid,
        userName,
        restName,
        role,
        restNameDisplay,
        cypress,
        dataRegister
    }
}
