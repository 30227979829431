import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useLocation, useNavigate } from 'react-router-dom';
import { queryRoleUserbyRestaurant, queryUserRestaurants } from 'src/utils/firebase';

function Loading() {
    
    const navigate = useNavigate();
    const location = useLocation();
    const [pathLink, setpathLink] = React.useState(false)
    const [userRole, setUserRole] = React.useState('')

    React.useEffect(() => {
        const Promesa = new Promise(function (resolve, reject) {
            const queryItems = queryUserRestaurants('portalUsers/Restaurants/Users', null);
            resolve(queryItems);
        });
        const Promesa2 = new Promise(function (resolve, reject) {
            Promesa.then((usersRestaurants: any) => {
                if (usersRestaurants && usersRestaurants.restaurants.length > 0) {
                    const firstRestName = usersRestaurants.restaurants[0];
                    resolve(queryRoleUserbyRestaurant('portalUsers', firstRestName));
                }
            });
        });
        Promesa2.then(async (roleUser: any) => {
            setUserRole(localStorage.getItem('role'));
            let typeSidebar = location.pathname.split('/')[1]
            const typeMenu = localStorage.getItem('typeMenu') ?? ""
            if (typeMenu) {
                // console.log(localStorage.getItem('typeMenu'))
                typeSidebar = localStorage.getItem('typeMenu')
            } else {
                typeSidebar = 'accent-header'
            }
            switch (userRole) {
                case 'Staff':
                    navigate(`/${typeSidebar}/management/restaurants`)
                    setpathLink(true)
                    break;
                case 'Admin':
                    navigate(`/${typeSidebar}/dashboards/home`)
                    setpathLink(true)
                    break;
                case 'Kitchen':
                    navigate(`/${typeSidebar}/management/kitchen`)
                    setpathLink(true)
                    break;
                case 'Delivery':
                    navigate(`/${typeSidebar}/management/delivery`)
                    setpathLink(true)
                    break;
                case 'Cashier':
                    navigate(`/${typeSidebar}/management/cash`)
                    setpathLink(true)
                    break;
                case 'Waiter':
                    navigate(`/${typeSidebar}/management/waiter`)
                    setpathLink(true)
                    break;
                case 'Adviser':
                    navigate(`/${typeSidebar}/dashboards/services/calendarService`)
                    setpathLink(true)
                    break;
            }
        });
    }, [location.pathname, navigate, pathLink, userRole])

    return !pathLink && (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh', marginTop: '150px' }}>
            <CircularProgress />
        </Box>
    )
}

export default Loading