import { useTranslation } from 'react-i18next';
import { LocalStorage } from 'src/localStorge/LocalStorage';
import { Buffer } from 'buffer';

export const ArraysSearch = () => {

    const { restName } = LocalStorage()
    const encodeRestname = Buffer.from(restName).toString('base64');
    const { t }: { t: any } = useTranslation();

    const arrayDefault = [
        { textKey: t('Transactions'), page: '/accent-header/management/transactions', module: t('Transactions') },
        { textKey: t('All recent transactions can be found below'), page: '/accent-header/management/transactions', module: t('Transactions') },
        { textKey: t('Total Credit'), page: '/accent-header/management/transactions', module: t('Transactions') },
        { textKey: t('total credit in your account'), page: '/accent-header/management/transactions', module: t('Transactions') },
        { textKey: t('Total amount'), page: '/accent-header/management/transactions', module: t('Transactions') },
        { textKey: t('Total Amount Sold'), page: '/accent-header/management/transactions', module: t('Transactions') },
        { textKey: t('Services'), page: '/accent-header/management/transactions', module: t('Transactions') },
        { textKey: t('Invoices'), page: '/accent-header/management/transactions', module: t('Transactions') },
        { textKey: t('Report'), page: '/accent-header/management/customers/report', module: t('Customers') },
        { textKey: t('Customer report'), page: '/accent-header/management/customers/report', module: t('Customers') },
        { textKey: t('this is your management dashboard for your client reporting.'), page: '/accent-header/management/customers/report', module: t('Customers') },
        { textKey: t('Settings'), page: '/accent-header/management/settings/red', module: t('Settings') },
        { textKey: t('This is your configuration'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('General'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Modules'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Add redirects'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Hide venue name'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Notification sounds'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Percentage to base value'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('See reviews'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Social Network'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Maximum date range'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Tax'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Payments'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Is the payment gateway in production?'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Pay by transfer'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Pay online'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Payment gateway'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Booking table'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Booking of services'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Chatbot'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Expenses'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Inventory'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Inventory shop'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Rent car'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Booking Hotel'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Restaurant'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Web pages'), page: `/accent-header/management/settings/${restName}`, module: t('Settings') },
        { textKey: t('Colors'), page: `/accent-header/management/colors`, module: t('Colors') },
        { textKey: t('Change Your App Colors'), page: `/accent-header/management/colors`, module: t('Colors') },
        { textKey: t('Color palete'), page: `/accent-header/management/colors`, module: t('Colors') },
        { textKey: t('primary color'), page: `/accent-header/management/colors`, module: t('Colors') },
        { textKey: t('seconday color'), page: `/accent-header/management/colors`, module: t('Colors') },
        { textKey: t('Background'), page: `/accent-header/management/colors`, module: t('Colors') },
        { textKey: t('Save Change'), page: `/accent-header/management/colors`, module: t('Colors') },
        { textKey: t('Title'), page: `/accent-header/management/colors`, module: t('Colors') },
        { textKey: t('Font'), page: `/accent-header/management/colors`, module: t('Colors') },
        { textKey: t('Primary Font'), page: `/accent-header/management/colors`, module: t('Colors') },
        { textKey: t('What your app would look like'), page: `/accent-header/management/colors`, module: t('Colors') },
        { textKey: t('minimal writing'), page: `/accent-header/management/colors`, module: t('Colors') },
        { textKey: t('Reset Color'), page: `/accent-header/management/colors`, module: t('Colors') },
        { textKey: t('Content'), page: `/accent-header/management/content/${restName}`, module: t('Content') },
        { textKey: t('Language'), page: `/accent-header/management/content/${restName}`, module: t('Content') },
        { textKey: t('Discounts'), page: `/accent-header/management/discounts/${restName}`, module: t('Discounts') },
        { textKey: t('Discounts Management'), page: `/accent-header/management/discounts/${restName}`, module: t('Discounts') },
        { textKey: t('All aspects related to your venue discounts can be managed from this page.'), page: `/accent-header/management/discounts/${restName}`, module: t('Discounts') },
        { textKey: t('Create discounts'), page: `/accent-header/management/discounts/${restName}`, module: t('Discounts') },
        { textKey: t('Regular Discounts'), page: `/accent-header/management/discounts/${restName}`, module: t('Discounts') },
        { textKey: t('Coupons or Discounts'), page: `/accent-header/management/discounts/${restName}`, module: t('Discounts') },
        { textKey: t('Discount Type'), page: `/accent-header/management/discounts/${restName}`, module: t('Discounts') },
        { textKey: t('Add new Discount'), page: `/accent-header/management/discounts/${restName}`, module: t('Discounts') },
        { textKey: t('Fill in the fields below to create and add a new Discount to the venue'), page: `/accent-header/management/discounts/${restName}`, module: t('Discounts') },
        { textKey: t('Delivery zones'), page: `/accent-header/management/deliveryZone/${encodeRestname}`, module: t('Delivery zones') },
        { textKey: t('The zones deliverys for your venue can be managed from this page'), page: `/accent-header/management/deliveryZone/${encodeRestname}`, module: t('Delivery zones') },
        { textKey: t('Users Management'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('All aspects related to the app users can be managed from this page'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('All users'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('Administrators'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('Kitchen'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('Delivery'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('Cashier'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('Waiter'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('Adviser'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('Create user'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('Add user'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('Create new user'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('Fill in the fields below to create and add a new user to the site'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('Add user'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('Fill in the fields below to create and add a new user to the site'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('Email'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('List All'), page: '/accent-header/management/Users', module: t('Users') },
        { textKey: t('User Profile'), page: '/accent-header/management/Users', module: t('Users') },
    ]

    const arrayInventory = [
        { textKey: t('Inventory'), page: '/accent-header/management/commerce/analytics', module: t('Inventory') },
        { textKey: t('Analytics Inventory'), page: '/accent-header/management/commerce/analytics', module: t('Inventory') },
        { textKey: t('this could be your beautiful finance inventory administration panel.'), page: '/accent-header/management/commerce/analytics', module: t('Inventory') },
        { textKey: t('Inventory history'), page: '/accent-header/management/commerce/analytics', module: t('Inventory') },
        { textKey: t('Products added to inventory'), page: '/accent-header/management/commerce/analytics', module: t('Inventory') },
        { textKey: t('Products removed to inventory'), page: '/accent-header/management/commerce/analytics', module: t('Inventory') },
        { textKey: t('Products'), page: '/accent-header/management/commerce/products', module: t('Inventory') },
        { textKey: t('Use this page to manage your products the inventory, the fast and easy way'), page: '/accent-header/management/commerce/products', module: t('Inventory') },
        { textKey: t('Create product'), page: '/accent-header/management/commerce/products', module: t('Inventory') },
        { textKey: t('Add new product'), page: '/accent-header/management/commerce/products', module: t('Inventory') },
        { textKey: t('Fill in the fields below to create a new product'), page: '/accent-header/management/commerce/products', module: t('Inventory') },
        { textKey: t('History'), page: '/accent-header/management/commerce/history', module: t('Inventory') },
        { textKey: t('Use this page to view inventory history, the fast and easy way.'), page: '/accent-header/management/commerce/history', module: t('Inventory') },
        { textKey: t('Transactions'), page: '/accent-header/management/commerce/transation', module: t('Inventory') },
        { textKey: t('Use this page to view inventory transations, the fast and easy way.'), page: '/accent-header/management/commerce/transation', module: t('Inventory') },
    ];

    const arrayInventoryShop = [
        { textKey: t('Shopping'), page: '/accent-header/management/commerce/shop', module: t('Inventory') },
        { textKey: t('List of all products to buy'), page: '/accent-header/management/commerce/shop', module: t('Inventory') },
    ]

    const arrayRestaurants = [
        { textKey: t('These are your analytics stats for today'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Credit'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Buy Credit'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Transactions'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Number of orders'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Total money'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Number Pickup Orders'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Total Money Pickup'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Number Delivered Orders'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Total Money Delivered'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Payment'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Service whatsApp'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Cost O&E'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Preparation avergae'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Delivery average'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Waiter average'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Tip'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Total new Orders'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Total New Orders Pickups'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Total Preparation Average'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Total Delivery average'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Total Waiter Avergae'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Total whatsApp messages'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Total messages cost'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Best-selling products'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Best day and time of sale'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Total cash'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Total by bank transfer'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Orders Kitchen'), page: '/accent-header/management/kitchen', module: t('Restaurants') },
        { textKey: t('Search Orders'), page: '/accent-header/management/kitchen', module: t('Restaurants') },
        { textKey: t('Change table'), page: '/accent-header/management/kitchen', module: t('Restaurants') },
        { textKey: t('Orders Drinks'), page: '/accent-header/management/bartender', module: t('Restaurants') },
        { textKey: t('Orders Waiter'), page: '/accent-header/management/waiter', module: t('Restaurants') },
        { textKey: t('These are your active Orders'), page: '/accent-header/management/kitchen', module: t('Restaurants') },
        { textKey: t('Orders Pickup'), page: '/accent-header/management/pickup', module: t('Restaurants') },
        { textKey: t('Not yet paid in cash'), page: '/accent-header/management/pickup', module: t('Restaurants') },
        { textKey: t('Delivery to pay'), page: '/accent-header/management/pickup', module: t('Restaurants') },
        { textKey: t('Orders Delivery'), page: '/accent-header/management/delivery', module: t('Restaurants') },
        { textKey: t('Location'), page: '/accent-header/management/delivery', module: t('Restaurants') },
        { textKey: t('Delivery name'), page: '/accent-header/management/delivery', module: t('Restaurants') },
        { textKey: t('Delivery state'), page: '/accent-header/management/delivery', module: t('Restaurants') },
        { textKey: t('Estimated delivery time'), page: '/accent-header/management/delivery', module: t('Restaurants') },
        { textKey: t('Domiciliary gets cash'), page: '/accent-header/management/delivery', module: t('Restaurants') },
        { textKey: t('Delivery driver'), page: '/accent-header/management/delivery-man', module: t('Restaurants') },
        { textKey: t('Use this page to manage your delivery driver, the fast and easy way'), page: '/accent-header/management/delivery-man', module: t('Restaurants') },
        { textKey: t('See location of delivery mans'), page: '/accent-header/management/delivery-man', module: t('Restaurants') },
        { textKey: t('Add delivery driver'), page: '/accent-header/management/delivery-man', module: t('Restaurants') },
        { textKey: t('Virtual Queue'), page: '/accent-header/management/virtual-queue', module: t('Restaurants') },
        { textKey: t('This is your virtual queue'), page: '/accent-header/management/virtual-queue', module: t('Restaurants') },
        { textKey: t('Waiter orders'), page: '/accent-header/management/waiter-order', module: t('Restaurants') },
        { textKey: t('These are your orders by the waiter'), page: '/accent-header/management/waiter-order', module: t('Restaurants') },
        { textKey: t('Cash Payment'), page: '/accent-header/management/cash', module: t('Restaurants') },
        { textKey: t('These are your active orders for payment in cash'), page: '/accent-header/management/cash', module: t('Restaurants') },
        { textKey: t('Effective'), page: '/accent-header/management/cash', module: t('Restaurants') },
        { textKey: t('Transfer by QR'), page: '/accent-header/management/cash', module: t('Restaurants') },
        { textKey: t('FeedBack'), page: '/accent-header/management/feedback', module: t('Restaurants') },
        { textKey: t('Check the feedback of your customers'), page: '/accent-header/management/feedback', module: t('Restaurants') },
        { textKey: t('QUALIFICATION'), page: '/accent-header/management/feedback', module: t('Restaurants') },
        { textKey: t('FEEDBACK'), page: '/accent-header/management/feedback', module: t('Restaurants') },
        { textKey: t('Chats'), page: '/accent-header/management/waiter-call', module: t('Restaurants') },
        { textKey: t('Create new category'), page: '/accent-header/management/categories', module: t('Restaurants') },
        { textKey: t('Add New category'), page: '/accent-header/management/categories', module: t('Restaurants') },
        { textKey: t('Recommended dimensions for the category'), page: '/accent-header/management/categories', module: t('Restaurants') },
        { textKey: t('Categories'), page: '/accent-header/management/categories', module: t('Restaurants') },
        { textKey: t('Create Product'), page: '/accent-header/management/categories', module: t('Restaurants') },
        { textKey: t('Add a New product for the'), page: '/accent-header/management/categories', module: t('Restaurants') },
        { textKey: t('General Information'), page: '/accent-header/management/categories', module: t('Restaurants') },
        { textKey: t('Ingredients'), page: '/accent-header/management/categories', module: t('Restaurants') },
        { textKey: t('Extra Ingredients'), page: '/accent-header/management/categories', module: t('Restaurants') },
        { textKey: t('Cook level'), page: '/accent-header/management/categories', module: t('Restaurants') },
        { textKey: t('Products'), page: '/accent-header/management/categories', module: t('Restaurants') },
        { textKey: t('Orders'), page: '/accent-header/dashboards/analytics', module: t('Restaurants') },
        { textKey: t('Table Layout'), page: '/accent-header/management/showtablesooking', module: t('Restaurants') },
        { textKey: t('Show Bookings'), page: '/accent-header/management/showtablesooking', module: t('Restaurants') },
        { textKey: t('Bookings'), page: '/accent-header/management/showtablesooking', module: t('Restaurants') },
        { textKey: t('Check the scheduled reservations in your calendar'), page: '/accent-header/management/showtablesooking', module: t('Restaurants') },
        { textKey: t('Table occupancy'), page: '/accent-header/management/tableoccupancy', module: t('Restaurants') },
        { textKey: t('Check the status of the tables and their occupation'), page: '/accent-header/management/tableoccupancy', module: t('Restaurants') },
        { textKey: t('Occupied'), page: '/accent-header/management/tableoccupancy', module: t('Restaurants') },
        { textKey: t('Free'), page: '/accent-header/management/tableoccupancy', module: t('Restaurants') },
        { textKey: t('Reserved'), page: '/accent-header/management/tableoccupancy', module: t('Restaurants') },
        { textKey: t('Boooking Table'), page: '/accent-header/management/bookingTable', module: t('Restaurants') },
        { textKey: t('Book a table'), page: '/accent-header/management/bookingTable', module: t('Restaurants') },
        { textKey: t('What day would you like your booking to be made?'), page: '/accent-header/management/bookingTable', module: t('Restaurants') },
        { textKey: t('At what time would you like to make your booking?'), page: '/accent-header/management/bookingTable', module: t('Restaurants') },
        { textKey: t('what name would you like the booking to be under?'), page: '/accent-header/management/bookingTable', module: t('Restaurants') },
        { textKey: t('how many people are attending?'), page: '/accent-header/management/bookingTable', module: t('Restaurants') },
        { textKey: t('Create table layout'), page: '/accent-header/management/tableLayout', module: t('Restaurants') },
        { textKey: t('Booking Analytics'), page: '/accent-header/management/analyticsBooking', module: t('Restaurants') },
        { textKey: t('Analytics administration panel for reservations.'), page: '/accent-header/management/analyticsBooking', module: t('Restaurants') },
        { textKey: t('RESERVATION FULL PAYMENT OR PARTIAL PAYMENT'), page: '/accent-header/management/analyticsBooking', module: t('Restaurants') },
        { textKey: t('Number of Bookings'), page: '/accent-header/management/analyticsBooking', module: t('Restaurants') },
        { textKey: t('Sale Booking'), page: '/accent-header/management/analyticsBooking', module: t('Restaurants') },
        { textKey: t('RESERVATION PENDING PAYMENT OR PARTIAL PAYMENT'), page: '/accent-header/management/analyticsBooking', module: t('Restaurants') },
        { textKey: t('Debt Bookings'), page: '/accent-header/management/analyticsBooking', module: t('Restaurants') },
        { textKey: t('Debt Money'), page: '/accent-header/management/analyticsBooking', module: t('Restaurants') },
        { textKey: t('Pending payment Booking'), page: '/accent-header/management/analyticsBooking', module: t('Restaurants') },
        { textKey: t('Partial payment Booking'), page: '/accent-header/management/analyticsBooking', module: t('Restaurants') },
        { textKey: t('Best day and time of sale'), page: '/accent-header/management/analyticsBooking', module: t('Restaurants') },
    ]

    const arrayHotel = [
        { textKey: t('Create new category'), page: '/accent-header/management/rooms', module: t('Booking Hotel') },
        { textKey: t('Add New category'), page: '/accent-header/management/rooms', module: t('Booking Hotel') },
        { textKey: t('Recommended dimensions for the category'), page: '/accent-header/management/rooms', module: t('Booking Hotel') },
        { textKey: t('List rooms'), page: '/accent-header/management/rooms', module: t('Booking Hotel') },
        { textKey: t('Make the programming of your agenda'), page: '/accent-header/management/calendar', module: t('Booking Hotel') },
        { textKey: t('Calendar'), page: '/accent-header/management/calendar', module: t('Booking Hotel') },
        { textKey: t('Enable booking dates'), page: '/accent-header/management/calendar', module: t('Booking Hotel') },
        { textKey: t('Bookings'), page: '/accent-header/management/ShowBookings', module: t('Booking Hotel') },
        { textKey: t('Check the scheduled reservations in your calendar'), page: '/accent-header/management/ShowBookings', module: t('Booking Hotel') },
        { textKey: t('Show Bookings'), page: '/accent-header/management/ShowBookings', module: t('Booking Hotel') },
        { textKey: t('Find reservations'), page: '/accent-header/management/findBooking', module: t('Booking Hotel') },
        { textKey: t('RESERVATION'), page: '/accent-header/management/findBooking', module: t('Booking Hotel') },
        { textKey: t('Here you can Find the details of your reservation'), page: '/accent-header/management/findBooking', module: t('Booking Hotel') },
        { textKey: t('Id Booking'), page: '/accent-header/management/findBooking', module: t('Booking Hotel') },
        { textKey: t('Search Booking'), page: '/accent-header/management/findBooking', module: t('Booking Hotel') },
        { textKey: t('Select Days'), page: '/accent-header/management/selectdays', module: t('Booking Hotel') },
        { textKey: t('Here you can select the special days'), page: '/accent-header/management/selectdays', module: t('Booking Hotel') },
    ]

    const arrayBookingOfServices = [
        { textKey: t('Services'), page: '/accent-header/dashboards/services/services', module: t('Booking of services') },
        { textKey: t('Service management'), page: '/accent-header/dashboards/services/services', module: t('Booking of services') },
        { textKey: t('this could be your service management panel.'), page: '/accent-header/dashboards/services/services', module: t('Booking of services') },
        { textKey: t('Create services'), page: '/accent-header/dashboards/services/services', module: t('Booking of services') },
        { textKey: t('Create service'), page: '/accent-header/dashboards/services/services', module: t('Booking of services') },
        { textKey: t('Complete the form to create your service.'), page: '/accent-header/dashboards/services/services', module: t('Booking of services') },
        { textKey: t('Personal'), page: '/accent-header/dashboards/services/personal', module: t('Booking of services') },
        { textKey: t('Personal management'), page: '/accent-header/dashboards/services/personal', module: t('Booking of services') },
        { textKey: t('this could be your personal management panel.'), page: '/accent-header/dashboards/services/personal', module: t('Booking of services') },
        { textKey: t('Create personal'), page: '/accent-header/dashboards/services/personal', module: t('Booking of services') },
        { textKey: t('Add Employee'), page: '/accent-header/dashboards/services/personal', module: t('Booking of services') },
        { textKey: t('Complete the form to add employee'), page: '/accent-header/dashboards/services/personal', module: t('Booking of services') },
        { textKey: t('Disable Employee'), page: '/accent-header/dashboards/services/personal', module: t('Booking of services') },
        { textKey: t('Temporary employee'), page: '/accent-header/dashboards/services/personal', module: t('Booking of services') },
        { textKey: t('Configure availability'), page: '/accent-header/dashboards/services/personal', module: t('Booking of services') },
        { textKey: t('Employee Name'), page: '/accent-header/dashboards/services/personal', module: t('Booking of services') },
        { textKey: t('Employee Last Name'), page: '/accent-header/dashboards/services/personal', module: t('Booking of services') },
        { textKey: t('Calendar personal'), page: '/accent-header/dashboards/services/calendarPersonal', module: t('Booking of services') },
        { textKey: t('Personal Calendar'), page: '/accent-header/dashboards/services/calendarPersonal', module: t('Booking of services') },
        { textKey: t('Check staff availability'), page: '/accent-header/dashboards/services/calendarPersonal', module: t('Booking of services') },
        { textKey: t('Calendar services'), page: '/accent-header/dashboards/services/calendarService', module: t('Booking of services') },
        { textKey: t('Services calendar'), page: '/accent-header/dashboards/services/calendarService', module: t('Booking of services') },
        { textKey: t('this could be your services calendar management panel.'), page: '/accent-header/dashboards/services/calendarService', module: t('Booking of services') },
        { textKey: t('Booking of services'), page: '/accent-header/dashboards/services/calendarService', module: t('Booking of services') },
        { textKey: t('Bookings'), page: '/accent-header/dashboards/services/analytics', module: t('Booking of services') },
        { textKey: t('These are your analytics stats for today'), page: '/accent-header/dashboards/services/analytics', module: t('Booking of services') },
        { textKey: t('Credit'), page: '/accent-header/dashboards/services/analytics', module: t('Booking of services') },
        { textKey: t('Buy Credit'), page: '/accent-header/dashboards/services/analytics', module: t('Booking of services') },
        { textKey: t('Transactions'), page: '/accent-header/dashboards/services/analytics', module: t('Booking of services') },
        { textKey: t('Bookings'), page: '/accent-header/dashboards/services/analytics', module: t('Booking of services') },
        { textKey: t('Number of Bookings'), page: '/accent-header/dashboards/services/analytics', module: t('Booking of services') },
        { textKey: t('BOOKINGS FULLY PAY OR PARTIAL PAY'), page: '/accent-header/dashboards/services/analytics', module: t('Booking of services') },
        { textKey: t('Sale Booking'), page: '/accent-header/dashboards/services/analytics', module: t('Booking of services') },
        { textKey: t('Bookings Pending Payment'), page: '/accent-header/dashboards/services/analytics', module: t('Booking of services') },
        { textKey: t('Money pending payment'), page: '/accent-header/dashboards/services/analytics', module: t('Booking of services') },
        { textKey: t('Cost O&E'), page: '/accent-header/dashboards/services/analytics', module: t('Booking of services') },
        { textKey: t('Cost'), page: '/accent-header/dashboards/services/analytics', module: t('Booking of services') },
        { textKey: t('Most popular services'), page: '/accent-header/dashboards/services/analytics', module: t('Booking of services') },
        { textKey: t('Best day and time of sale'), page: '/accent-header/dashboards/services/analytics', module: t('Booking of services') },
    ]

    const arrayRentCar = [
        { textKey: t('Number of Bookings'), page: '/accent-header/management/products/rent-car/analytics', module: t('Rent car') },
        { textKey: t('Sales'), page: '/accent-header/management/products/rent-car/analytics', module: t('Rent car') },
        { textKey: t('Cost O&E'), page: '/accent-header/management/products/rent-car/analytics', module: t('Rent car') },
        { textKey: t('Most reserved cars'), page: '/accent-header/management/products/rent-car/analytics', module: t('Rent car') },
        { textKey: t('Best day and time of sale'), page: '/accent-header/management/products/rent-car/analytics', module: t('Rent car') },
        { textKey: t('Recommended cover dimensions'), page: '/accent-header/management/products/rent-car', module: t('Rent car') },
        { textKey: t('Click to add a new car'), page: '/accent-header/management/products/rent-car', module: t('Rent car') },
        { textKey: t('New rent car'), page: '/accent-header/management/products/rent-car', module: t('Rent car') },
        { textKey: t('Add the characteristics of the car to ren'), page: '/accent-header/management/products/rent-car', module: t('Rent car') },
        { textKey: t('Recommended vehicle dimensions'), page: '/accent-header/management/products/rent-car', module: t('Rent car') },
        { textKey: t('General information'), page: '/accent-header/management/products/rent-car', module: t('Rent car') },
        { textKey: t('Cars'), page: '/accent-header/management/products/rent-car', module: t('Rent car') },
        { textKey: t('Rent Car'), page: '/accent-header/management/products/rent-car/booking', module: t('Rent car') },
        { textKey: t('Check the scheduled reservations in your calendar'), page: '/accent-header/management/products/rent-car/booking', module: t('Rent car') },
        { textKey: t('Bookings'), page: '/accent-header/management/products/rent-car/booking', module: t('Rent car') },
    ]

    const arrayBookingtable = [
        { textKey: t('Booking Table'), page: '/accent-header/management/tableLayout', module: t('Booking table') },
        { textKey: t('Create here the distribution of the tables in your restaurant.'), page: '/accent-header/management/tableLayout', module: t('Booking table') },
        { textKey: t('Add Table'), page: '/accent-header/management/tableLayout', module: t('Booking table') },
        { textKey: t('Add Floor'), page: '/accent-header/management/tableLayout', module: t('Booking table') },
    ]

    const arrayChatBot = [
        { textKey: t('On-line Chat'), page: '/accent-header/applications/messenger', module: t('Chatbot') },
    ]

    const arrayExpenses = [
        { textKey: t('Total expenses'), page: '/accent-header/dashboards/Accounting/Analytics', module: t('Expenses') },
        { textKey: t('Total income'), page: '/accent-header/dashboards/Accounting/Analytics', module: t('Expenses') },
        { textKey: t('Budget spent'), page: '/accent-header/dashboards/Accounting/Analytics', module: t('Expenses') },
        { textKey: t('Remaining'), page: '/accent-header/dashboards/Accounting/Analytics', module: t('Expenses') },
        { textKey: t('Total budget'), page: '/accent-header/dashboards/Accounting/Analytics', module: t('Expenses') },
        { textKey: t('Expenses Accounting'), page: '/accent-header/dashboards/Accounting/Expenses', module: t('Expenses') },
        { textKey: t('this could be your expenses management panel.'), page: '/accent-header/dashboards/Accounting/Expenses', module: t('Expenses') },
        { textKey: t('Expence category'), page: '/accent-header/dashboards/Accounting/Expenses', module: t('Expenses') },
        { textKey: t('Type of expence'), page: '/accent-header/dashboards/Accounting/Expenses', module: t('Expenses') },
        { textKey: t('Receipt'), page: '/accent-header/dashboards/Accounting/Expenses', module: t('Expenses') },
        { textKey: t('Budget Accounting'), page: '/accent-header/dashboards/Accounting/Budget', module: t('Expenses') },
        { textKey: t('this could be your budget management panel.'), page: '/accent-header/dashboards/Accounting/Budget', module: t('Expenses') },
        { textKey: t('Budget used'), page: '/accent-header/dashboards/Accounting/Budget', module: t('Expenses') },
        { textKey: t('Budget'), page: '/accent-header/dashboards/Accounting/Budget', module: t('Expenses') },
    ]

    const arrayPages = [
        { textKey: t('Pages'), page: '/accent-header/management/dinamicPagesAndMenu', module: t('Pages') },
        { textKey: t('Edit Website'), page: '/accent-header/management/dinamicPagesAndMenu', module: t('Pages') },
        { textKey: t('Click on the page you would like to edit.'), page: '/accent-header/management/dinamicPagesAndMenu', module: t('Pages') },
        { textKey: t('Create new page'), page: '/accent-header/management/dinamicPagesAndMenu', module: t('Pages') },
        { textKey: t('You must name your new page'), page: '/accent-header/management/dinamicPagesAndMenu/new', module: t('Pages') },
    ]

    return {
        arrayRestaurants,
        arrayHotel,
        arrayBookingOfServices,
        arrayRentCar,
        arrayDefault,
        arrayBookingtable,
        arrayChatBot,
        arrayExpenses,
        arrayPages,
        arrayInventory,
        arrayInventoryShop
    }
}
