import { FC, MouseEvent, useContext, useRef, useState } from 'react';

import { Popover, Button, MenuItem, Menu, Typography, Stack, Divider, Box, Tooltip, Fab } from '@mui/material';
import CheckTwoToneIcon from '@mui/icons-material/CheckTwoTone';
import { styled } from '@mui/material/styles';
import UnfoldMoreTwoToneIcon from '@mui/icons-material/UnfoldMoreTwoTone';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ThemeContext } from 'src/theme/ThemeProvider';
import { SettingsIcon } from 'src/utils/icons';

const ThemeToggleWrapper = styled(Box)(({ theme }) => `
          padding: ${theme.spacing(2)};
          min-width: 220px;
  `
);

const ButtonWrapper = styled(Box)(({ theme }) => `
        cursor: pointer;
        position: relative;
        border-radius: ${theme.general.borderRadiusXl};
        padding: ${theme.spacing(.8)};
        display: flex;
        flex-direction: row;
        align-items: stretch;
        min-width: 80px;
        box-shadow: 0 0 0 2px ${theme.colors.primary.lighter};

        &:hover {
            box-shadow: 0 0 0 2px ${theme.colors.primary.light};
        }

        &.active {
            box-shadow: 0 0 0 2px ${theme.palette.primary.main};

            .colorSchemeWrapper {
                opacity: .6;
            }
        }
  `
);

const ColorSchemeWrapper = styled(Box)(({ theme }) => `
    position: relative;

    border-radius: ${theme.general.borderRadiusXl};
    height: 28px;
    
    &.colorSchemeWrapper {
        display: flex;
        align-items: stretch;
        width: 100%;

        .primary {
            border-top-left-radius: ${theme.general.borderRadiusXl};
            border-bottom-left-radius: ${theme.general.borderRadiusXl};
        }

        .secondary {
            border-top-right-radius: ${theme.general.borderRadiusXl};
            border-bottom-right-radius: ${theme.general.borderRadiusXl};
        }

        .primary,
        .secondary,
        .alternate {
            flex: 1;
        }
    }

    &.pureLight {
        .primary {
            background: #5569ff;
        }
    
        .secondary {
            background: #f2f5f9;
        }
    }

    &.boneLight {
        .primary {
            background: #CCAA8D;
        }
    
        .secondary {
            background: #f2f5f9;
        }
    }

    &.lightBloom {
        .primary {
            background: #1975FF;
        }
    
        .secondary {
            background: #000C57;
        }
    }

    &.greyGoose {
        .primary {
            background: #2442AF;
        }
    
        .secondary {
            background: #F8F8F8;
        }
    }
    
    &.purpleFlow {
        .primary {
            background: #9b52e1;
        }
    
        .secondary {
            background: #00b795;
        }
    }
    
    &.nebulaFighter {
        .primary {
            background: #8C7CF0;
        }
    
        .secondary {
            background: #070C27;
        }
    }

    &.greenFields {
        .primary {
            background: #44a574;
        }
    
        .secondary {
            background: #141c23;
        }
    }

    &.darkSpaces {
        .primary {
            background: #CB3C1D;
        }
    
        .secondary {
            background: #1C1C1C;
        }
    }

  `
);

const CheckSelected = styled(Box)(({ theme }) => `
    background: ${theme.palette.success.main};
    border-radius: 50px;
    height: 26px;
    width: 26px;
    color: ${theme.palette.success.contrastText};
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -13px 0 0 -13px;
    z-index: 5;

    .MuiSvgIcon-root {
        height: 16px;
        width: 16px;
    }

  `
);

const ThemeSettings: FC = (_props) => {
    const curThemeName = localStorage.getItem('appTheme') || 'BoneLightTheme';

    const [isOpen, setOpen] = useState<boolean>(false);
    const [theme, setTheme] = useState(curThemeName);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const setThemeName = useContext(ThemeContext);

    const ref = useRef<any>(null);

    const { t }: { t: any } = useTranslation();

    const open = Boolean(anchorEl);

    const handleOpen = (): void => setOpen(true);
    const handleClose = (): void => setOpen(false);

    const changeTheme = (theme): void => {
        setTheme(theme);
        setThemeName(theme);
    };

    const openMenu = ({ currentTarget }: MouseEvent<HTMLButtonElement>) => setAnchorEl(currentTarget);
    const closeMenu = () => setAnchorEl(null);

    return (
        <>
            {/* <ThemeSettingsButton> */}
            <Tooltip arrow title={t('Theme Settings')}>
                <Fab ref={ref} onClick={handleOpen} sx={{ bgcolor: 'transparent' }} aria-label="add">
                    <SettingsIcon />
                </Fab>
            </Tooltip>
            <Popover anchorEl={ref.current} onClose={handleClose} open={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
            >
                <Box p={2}>
                    <Typography sx={{ mb: 2, textAlign: 'center', fontWeight: 'bold', textTransform: 'uppercase' }} variant="body1">
                        {t('Layout Blueprints')}
                    </Typography>
                    <Button fullWidth size="large" variant="outlined" endIcon={<UnfoldMoreTwoToneIcon />}
                        color="primary" aria-haspopup="true" aria-expanded={open ? 'true' : undefined} onClick={openMenu}
                    >
                        {t('Choose layout')}
                    </Button>
                    <Menu anchorEl={anchorEl} open={open} onClose={closeMenu}
                        anchorOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'center',
                            horizontal: 'center',
                        }}
                    >
                        <MenuItem sx={{ fontWeight: 'bold' }} component={NavLink} to="/accent-header/loading" onClick={() => { localStorage.setItem('typeMenu', 'accent-header') }}>Accent header</MenuItem>
                        <MenuItem sx={{ fontWeight: 'bold' }} component={NavLink} to="/accent-sidebar/loading" onClick={() => { localStorage.setItem('typeMenu', 'accent-sidebar') }}>Accent sidebar</MenuItem>
                        <MenuItem sx={{ fontWeight: 'bold' }} component={NavLink} to="/boxed-sidebar/loading" onClick={() => { localStorage.setItem('typeMenu', 'boxed-sidebar') }}>Boxed sidebar</MenuItem>
                        <MenuItem sx={{ fontWeight: 'bold' }} component={NavLink} to="/collapsed-sidebar/loading" onClick={() => { localStorage.setItem('typeMenu', 'collapsed-sidebar') }}>Collapsed sidebar</MenuItem>
                        <MenuItem sx={{ fontWeight: 'bold' }} component={NavLink} to="/bottom-navigation/loading" onClick={() => { localStorage.setItem('typeMenu', 'bottom-navigation') }}>Bottom navigation</MenuItem>
                        <MenuItem sx={{ fontWeight: 'bold' }} component={NavLink} to="/top-navigation/loading" onClick={() => { localStorage.setItem('typeMenu', 'top-navigation') }}>Top navigation</MenuItem>
                    </Menu>
                </Box>
                <Divider />
                <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
                    <ThemeToggleWrapper>
                        <Typography sx={{ mt: 1, mb: 3, textAlign: 'center', fontWeight: 'bold', textTransform: 'uppercase' }} variant="body1">
                            {t('Light color schemes')}
                        </Typography>
                        <Stack alignItems="center" spacing={2}>
                            <Tooltip placement="left" title="Pure Light" arrow>
                                <ButtonWrapper className={theme === 'BoneLightTheme' ? 'active' : ''} onClick={() => changeTheme('BoneLightTheme')}>
                                    {theme === 'BoneLightTheme' && <CheckSelected>
                                        <CheckTwoToneIcon />
                                    </CheckSelected>}
                                    <ColorSchemeWrapper className="colorSchemeWrapper boneLight">
                                        <Box className="primary" />
                                        <Box className="secondary" />
                                    </ColorSchemeWrapper>
                                </ButtonWrapper>
                            </Tooltip>
                            <Tooltip placement="left" title="Pure Light" arrow>
                                <ButtonWrapper className={theme === 'PureLightTheme' ? 'active' : ''} onClick={() => {
                                    changeTheme('PureLightTheme');
                                }}>
                                    {theme === 'PureLightTheme' && <CheckSelected>
                                        <CheckTwoToneIcon />
                                    </CheckSelected>}
                                    <ColorSchemeWrapper className="colorSchemeWrapper pureLight">
                                        <Box className="primary" />
                                        <Box className="secondary" />
                                    </ColorSchemeWrapper>
                                </ButtonWrapper>
                            </Tooltip>
                            <Tooltip placement="left" title="Light Bloom" arrow>
                                <ButtonWrapper className={theme === 'LightBloomTheme' ? 'active' : ''} onClick={() => {
                                    changeTheme('LightBloomTheme');
                                }}>
                                    {theme === 'LightBloomTheme' && <CheckSelected>
                                        <CheckTwoToneIcon />
                                    </CheckSelected>}
                                    <ColorSchemeWrapper className="colorSchemeWrapper lightBloom">
                                        <Box className="primary" />
                                        <Box className="secondary" />
                                    </ColorSchemeWrapper>
                                </ButtonWrapper>
                            </Tooltip>
                            <Tooltip placement="left" title="Grey Goose" arrow>
                                <ButtonWrapper className={theme === 'GreyGooseTheme' ? 'active' : ''} onClick={() => {
                                    changeTheme('GreyGooseTheme');
                                }}>
                                    {theme === 'GreyGooseTheme' && <CheckSelected>
                                        <CheckTwoToneIcon />
                                    </CheckSelected>}
                                    <ColorSchemeWrapper className="colorSchemeWrapper greyGoose">
                                        <Box className="primary" />
                                        <Box className="secondary" />
                                    </ColorSchemeWrapper>
                                </ButtonWrapper>
                            </Tooltip>
                            <Tooltip placement="left" title="Purple Flow" arrow>
                                <ButtonWrapper className={theme === 'PurpleFlowTheme' ? 'active' : ''} onClick={() => {
                                    changeTheme('PurpleFlowTheme');
                                }}>
                                    {theme === 'PurpleFlowTheme' && <CheckSelected>
                                        <CheckTwoToneIcon />
                                    </CheckSelected>}
                                    <ColorSchemeWrapper className="colorSchemeWrapper purpleFlow">
                                        <Box className="primary" />
                                        <Box className="secondary" />
                                    </ColorSchemeWrapper>
                                </ButtonWrapper>
                            </Tooltip>
                        </Stack>
                    </ThemeToggleWrapper>
                    <ThemeToggleWrapper>
                        <Typography sx={{ mt: 1, mb: 3, textAlign: 'center', fontWeight: 'bold', textTransform: 'uppercase' }} variant="body1">
                            {t('Dark color schemes')}
                        </Typography>
                        <Stack alignItems="center" spacing={2}>
                            <Tooltip placement="left" title="Nebula Fighter" arrow>
                                <ButtonWrapper className={theme === 'NebulaFighterTheme' ? 'active' : ''} onClick={() => {
                                    changeTheme('NebulaFighterTheme');
                                }}>
                                    {theme === 'NebulaFighterTheme' && <CheckSelected>
                                        <CheckTwoToneIcon />
                                    </CheckSelected>}
                                    <ColorSchemeWrapper className="colorSchemeWrapper nebulaFighter">
                                        <Box className="primary" />
                                        <Box className="secondary" />
                                    </ColorSchemeWrapper>
                                </ButtonWrapper>
                            </Tooltip>
                            <Tooltip placement="left" title="Green Fields" arrow>
                                <ButtonWrapper className={theme === 'GreenFieldsTheme' ? 'active' : ''} onClick={() => {
                                    changeTheme('GreenFieldsTheme');
                                }}>
                                    {theme === 'GreenFieldsTheme' && <CheckSelected>
                                        <CheckTwoToneIcon />
                                    </CheckSelected>}
                                    <ColorSchemeWrapper className="colorSchemeWrapper greenFields">
                                        <Box className="primary" />
                                        <Box className="secondary" />
                                    </ColorSchemeWrapper>
                                </ButtonWrapper>
                            </Tooltip>
                            <Tooltip placement="left" title="Dark Spaces" arrow>
                                <ButtonWrapper className={theme === 'DarkSpacesTheme' ? 'active' : ''} onClick={() => {
                                    changeTheme('DarkSpacesTheme');
                                }}>
                                    {theme === 'DarkSpacesTheme' && <CheckSelected>
                                        <CheckTwoToneIcon />
                                    </CheckSelected>}
                                    <ColorSchemeWrapper className="colorSchemeWrapper darkSpaces">
                                        <Box className="primary" />
                                        <Box className="secondary" />
                                    </ColorSchemeWrapper>
                                </ButtonWrapper>
                            </Tooltip>
                        </Stack>
                    </ThemeToggleWrapper>
                </Stack>
            </Popover>
            {/* </ThemeSettingsButton> */}
        </>
    );
};

export default ThemeSettings;
