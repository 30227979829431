const generateWords = () =>{
	const Words = ["a","b","c","d","e","f","0","1","2","3","4","5","6","7","8","9"];
	const number = (Math.random()*15).toFixed(0);
	return Words[number];
}

export const genrateColor = () =>{
    let coolor = "";
	for(var i=0;i<6;i++){
		coolor = coolor + generateWords() ;
	}
	return "#" + coolor;
}