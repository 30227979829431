import { useEffect, useState } from 'react';
import { useDataSettings } from 'src/hooks/useSettings';
import { ArraysSearch } from './arraysSearch';

const Searchlist = () => {

    const {
        arrayRestaurants,
        arrayHotel,
        arrayBookingOfServices,
        arrayRentCar,
        arrayDefault,
        arrayBookingtable,
        arrayChatBot,
        arrayExpenses,
        arrayPages,
        arrayInventory,
        arrayInventoryShop
    } = ArraysSearch()
    const [listForSearch, setListForSearch] = useState<any[]>([])

    const { options } = useDataSettings()

    useEffect(() => {
        const getList = () => {
            const newList: any[] = []
            if (options) {
                if (options.restaurant) {
                    newList.push(...arrayRestaurants)
                }
                if (options.reservation) {
                    newList.push(...arrayHotel)
                }
                if (options.bookingOfServices) {
                    newList.push(...arrayBookingOfServices)
                }
                if (options.rentCar) {
                    newList.push(...arrayRentCar)
                }
                if (options.booking) {
                    newList.push(...arrayBookingtable)
                }
                if (options.chatbot) {
                    newList.push(...arrayChatBot)
                }
                if (options.expenses) {
                    newList.push(...arrayExpenses)
                }
                if (options.inventory) {
                    newList.push(...arrayInventory)
                }
                if (options.inventoryShop) {
                    newList.push(...arrayInventoryShop)
                }
                if (options.webpages) {
                    newList.push(...arrayPages)
                }
                newList.push(...arrayDefault)
                setListForSearch(newList)
            }
        }
        getList()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [options])



    return { listForSearch }
}

export default Searchlist