import { useState, useEffect } from 'react';
import { Box, List, ListItem, Tooltip, IconButton } from '@mui/material';
import { useLocation, matchPath, Link } from 'react-router-dom';
import SidebarMenuItem from './item';
import { MenuItem } from './items';
import TemporalPass from '../../../AccentHeaderLayout/Sidebar/SidebarMenu/TemporalPass';
import { styled } from '@mui/material/styles';
import { queryUserRestaurants, queryRoleUserbyRestaurant, queryDoc } from 'src/utils/firebase';
import { sidebarMenu } from 'src/utils/sidebarMenu';
import StorefrontIcon from '@mui/icons-material/Storefront';
import _ from 'lodash';


const MenuWrapper = styled(Box)(
  ({ theme }) => `
  .MuiList-root {
    margin-bottom: ${theme.spacing(1)};
    padding: 0;

    & > .MuiList-root {
      padding: 0 ${theme.spacing(2)} ${theme.spacing(2)};
    }
  }
`
);

const SubMenuWrapper = styled(Box)(
  ({ theme }) => `
    .MuiList-root {
      padding: 0;
      
      .MuiList-root .MuiList-root .MuiListItem-root .MuiIconButton-root {
        font-weight: normal !important;
      }

      .MuiListItem-root {
        padding: 2px 0;
        justify-content: center;
    
        .MuiIconButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 54px;
          height: 54px;
          justify-content: center;
          font-size: ${theme.typography.pxToRem(13)};
          padding: 0;
          position: relative;

          .name-wrapper {
            display: none;
            transition: ${theme.transitions.create(['color'])};
          }

          .MuiBadge-root {
            position: absolute;
            right: 8px;
            top: 12px;

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(9)};
              font-weight: bold;
              text-transform: uppercase;
              color: ${theme.palette.primary.contrastText};
            }
          }
  
          .MuiSvgIcon-root {
            transition: ${theme.transitions.create(['color'])};
            font-size: ${theme.typography.pxToRem(28)};
            color: ${theme.sidebar.menuItemIconColor};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiSvgIcon-root {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const [menuItems, setStateMenu] = useState([]);
  const [userRole, setUserRole] = useState<string>('');
  const [restName, setRestName] = useState<string>(null);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [chatbot, setChatbot] = useState<boolean>(false);
  const [options, setOptions] = useState<any>();
  const [uidUser, setUidUser] = useState<string>(null);
  const [reservationRoom, setReservationRoom] = useState<boolean>(false);

  const location = useLocation();
  useEffect(() => {
    let itemsnR = [];
    let typeSidebar = location.pathname.split('/')[1]
    const Promesa = new Promise(function (resolve, reject) {
      const queryItems = queryUserRestaurants('portalUsers/Restaurants/Users', null);
      resolve(queryItems);
    });

    const getOptionChatbot = async (restName: string) => {
      const query = await queryDoc(restName, "options")
      const data = query?.data()
      if (data) {
        setChatbot(data.chatbot)
        setReservationRoom(data.reservation)
        setOptions(data)
        if (data.inventory) {
          localStorage.setItem("inventory", 'true')
        } else {
          localStorage.removeItem("inventory")
        }
      }
    }

    const Promesa2 = new Promise(function (resolve, reject) {
      Promesa.then((usersRestaurants: any) => {
        if (usersRestaurants && usersRestaurants.restaurants.length > 0) {
          const firstRestName = usersRestaurants.restaurants[0];
          setOpenDialog(usersRestaurants.temporalPass)
          setUidUser(usersRestaurants.idEmail)
          if (!options) {
            getOptionChatbot(localStorage.getItem('restName') ?? firstRestName)
          }
          if (firstRestName !== "staff") {
            if (!localStorage.getItem('restName')) {
              localStorage.setItem('restName', firstRestName);
              setRestName(firstRestName)
            } else {
              setRestName(localStorage.getItem('restName'))
            }
          }
          resolve(queryRoleUserbyRestaurant('portalUsers', firstRestName));
          usersRestaurants.restaurants.forEach((r) => {
            itemsnR.push({
              name: r,
              link: `/${typeSidebar}/dashboards/analytics`
            });
          });
        }
      });
    });
    Promesa2.then((roleUser: any) => {
      setUserRole(localStorage.getItem('role'));
      const nameRestaurant = localStorage.getItem('restName');
      let menuItemsNew = sidebarMenu(roleUser, typeSidebar, nameRestaurant, itemsnR, options, chatbot, reservationRoom);
      setStateMenu(_.sortBy(menuItemsNew, 'heading'));
    });
  }, [userRole, location.pathname, options, chatbot, reservationRoom]);
  async function setNameRestaurant(item: any) {
    if (localStorage.getItem('role') !== "Staff") {
      localStorage.setItem('restName', item);
      setRestName(item)
      const role = await queryRoleUserbyRestaurant('portalUsers', item);
      setUserRole(role);
      window.location.reload();
    }
  }
  const renderSidebarMenuItems = ({
    items,
    path,
    heading
  }: {
    items: MenuItem[];
    path: string;
    heading: string;
  }): JSX.Element => {
    return heading === 'Restaurants' ? (
      <>
        <SubMenuWrapper>
          <List component="div">
            {items.map((item, i) => {
              return (
                <Link
                  to={item.link}
                  key={i}
                  style={{ textDecoration: 'none' }}
                  className='boneLightText'
                >
                  <ListItem>
                    <Tooltip title={item.name} disableInteractive placement="right" arrow>
                      <IconButton
                        aria-label="restaurant"
                        className={restName === item.name ? "Mui-active" : "Mui-active-hover"}
                        onClick={() => setNameRestaurant(item.name)}>
                        <StorefrontIcon color={restName === item.name ? "primary" : "secondary"} />
                      </IconButton>
                    </Tooltip>
                  </ListItem>
                </Link>
              );
            })}
          </List>
        </SubMenuWrapper>
        <TemporalPass openDialog={openDialog} setOpenDialog={setOpenDialog} uidUser={uidUser} />
      </>
    ) : (
      <SubMenuWrapper>
        <List component="div">
          {items.reduce(
            (ev, item) => reduceChildRoutes({ ev, item, path }),
            []
          )}
        </List>
      </SubMenuWrapper>
    );
  }

  const reduceChildRoutes = ({
    ev,
    path,
    item
  }: {
    ev: JSX.Element[];
    path: string;
    item: MenuItem;
  }): Array<JSX.Element> => {
    const key = item.name;

    const exactMatch = item.link ? !!matchPath({
      path: item.link,
      end: true
    }, path) : false;

    if (item.items) {
      const partialMatch = item.link ? !!matchPath({
        path: item.link,
        end: false
      }, path) : false;

      ev.push(
        <SidebarMenuItem
          key={key}
          active={partialMatch}
          open={partialMatch}
          name={item.name}
          icon={item.icon}
          link={item.link}
          badge={item.badge}
        >
          {renderSidebarMenuItems({
            path,
            items: item.items,
            heading: ''
          })}
        </SidebarMenuItem>
      );
    } else {
      ev.push(
        <SidebarMenuItem
          key={key}
          active={exactMatch}
          name={item.name}
          link={item.link}
          badge={item.badge}
          icon={item.icon}
        />
      );
    }

    return ev;
  }
  if (!menuItems) {
    return <></>;
  } else {
    return (
      <>
        {menuItems.map((section) => (
          <MenuWrapper key={section.heading}>
            <List component="div">
              {renderSidebarMenuItems({
                items: section.items,
                path: location.pathname,
                heading: section.heading
              })}
            </List>
          </MenuWrapper>
        ))}
      </>
    );
  }
}

export default SidebarMenu;
