import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import { Data } from 'src/models/user';
import firebase from 'src/utils/firebase';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

interface AuthState {
  isInitialized: boolean;
  isAuthenticated: boolean;
  user: Data | null;
}

interface AuthContextValue extends AuthState {
  method: 'FirebaseAuth';
  createUserWithEmailAndPassword: (
    email: string,
    password: string
  ) => Promise<any>;
  signInWithEmailAndPassword: (email: string, password: string) => Promise<any>;
  signInWithGoogle: () => Promise<any>;
  singInWithFacebook: () => Promise<any>;
  logout: () => Promise<void>;
}

interface AuthProviderProps {
  children: ReactNode;
}

type AuthStateChangedAction = {
  type: 'AUTH_STATE_CHANGED';
  payload: {
    isAuthenticated: boolean;
    user: Data | null;
  };
};

type Action = AuthStateChangedAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialized: true,
  user: null
};

const reducer = (state: AuthState, action: Action): AuthState => {
  if (action.type === 'AUTH_STATE_CHANGED') {
    const { isAuthenticated, user } = action.payload;

    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user
    };
  }

  return state;
};

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'FirebaseAuth',
  createUserWithEmailAndPassword: () => Promise.resolve(),
  signInWithEmailAndPassword: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  singInWithFacebook: () => Promise.resolve(),
  logout: () => Promise.resolve()
});

export const AuthProvider: FC<AuthProviderProps> = (props) => {

  const navigate = useNavigate()

  const { children } = props;
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const typeLogin = localStorage.getItem('login') ?? ""

  useEffect(() => firebase.auth().onAuthStateChanged((user) => {
    if (user && typeLogin) {
      if (typeLogin === 'googleOrFacebook') {
        navigate('/register')
        localStorage.setItem('dataRegister', JSON.stringify({
          uid: user.uid,
          email: user.email,
          userName: user.displayName,
          photoURL: user.photoURL
        }))
      } else {
        dispatch({
          type: 'AUTH_STATE_CHANGED',
          payload: {
            isAuthenticated: true,
            user: {
              idEmail: user.uid,
              avatar: user.photoURL,
              email: user.email,
              name: user.displayName || user.email,
              role: 'Admin',
              username: 'admin',
              posts: '4',
              coverImg: 'http://lorempixel.com/640/480/cats',
              followers: '5684',
              description:
                'Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups.',
              password: ''
            }
          }
        });
      }
    } else {
      dispatch({
        type: 'AUTH_STATE_CHANGED',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
    }
  }), [dispatch, typeLogin, navigate]);

  const signInWithEmailAndPassword = (email: string, password: string): Promise<any> => {
    return firebase.auth().signInWithEmailAndPassword(email, password);
  };

  const signInWithGoogle = (): Promise<any> => {
    const provider = new firebase.auth.GoogleAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  };

  const singInWithFacebook = (): Promise<any> => {
    const provider = new firebase.auth.FacebookAuthProvider();

    return firebase.auth().signInWithPopup(provider);
  }

  const createUserWithEmailAndPassword = async (
    email: string,
    password: string
  ): Promise<any> => {
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  };

  const logout = (): Promise<void> => {
    localStorage.removeItem('uidEmail')
    localStorage.removeItem('role')
    localStorage.removeItem('restName')
    localStorage.removeItem('openSideBarActive')
    localStorage.removeItem('inventory')
    localStorage.removeItem('inventoryShop')
    localStorage.removeItem('restNameDisplay')
    localStorage.removeItem('userName')
    localStorage.removeItem('login')

    return firebase.auth().signOut();
  };

  return (
    <AuthContext.Provider value={{
      ...state,
      method: 'FirebaseAuth',
      createUserWithEmailAndPassword,
      signInWithEmailAndPassword,
      signInWithGoogle,
      singInWithFacebook,
      logout
    }}
    >
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
