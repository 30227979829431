import { useState, ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next';
import { Typography, Button, Dialog, DialogTitle, DialogContent, DialogActions, FormControl, OutlinedInput, InputAdornment, IconButton, FormHelperText, Zoom } from '@mui/material';
import { InputLabel } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Visibility } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { updateTemporalPassword } from 'src/utils/firebase'
import { changePassword } from '../../../../utils/passwordControl'

interface State {
    password: string;
    showPassword: boolean;
    confirmPass: string;
    confirmShowPass: boolean;
    error: string;
}

function TemporalPass(props: any) {

    const { t }: { t: any } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const [save, setSave] = useState<boolean>(false)
    const [values, setValues] = useState<State>({
        password: '',
        showPassword: false,
        confirmPass: '',
        confirmShowPass: false,
        error: null,
    });

    const enqueueSnackbarView = (message: string, variant: any) => {
        enqueueSnackbar(t(message), {
            variant: variant,
            anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center'
            },
            TransitionComponent: Zoom
        });
    };

    const handleChange = (prop: keyof State) => ({ target }: ChangeEvent<HTMLInputElement>) => setValues({ ...values, [prop]: target.value });

    const handleClickShowPassword = (prop: keyof State) =>
        setValues(
            {
                ...values,
                [prop]: !values[prop]
            });


    const handleSaveNewPassword = async () => {
        if (values.password.length > 7 && values.confirmPass.length > 7 && values.password === values.confirmPass) {
            setSave(true)
            setValues({ ...values, error: null });
            const user = await changePassword(props.uidUser, values.password);
            if (user.status === "OK") {
                await updateTemporalPassword(props.uidUser, false)
                props.setOpenDialog(false)
                enqueueSnackbarView(t('Updated password'), "success")
            } else {
                enqueueSnackbarView(user.message ? user.message : t('Error 404'), "error")
            }
        } else {
            let error: string = null;
            if (values.password.length < 8 && values.confirmPass.length < 8) {
                error = "allPassword"
            } else if (values.password.length < 8) {
                error = "password"
            } else if (values.confirmPass.length < 8) {
                error = "confirmPassword"
            } else if (values.password !== values.confirmPass) {
                error = "notMatchPassword"
            }
            setValues({ ...values, error: error });
        }
    }

    return (
        <Dialog fullWidth maxWidth="xs" open={props.openDialog}>
            <DialogTitle sx={{ p: 3 }}>
                <Typography variant="h4" gutterBottom>
                    {t('Password temporal')}
                </Typography>
                <Typography variant="subtitle2">
                    {t('Your password is temporary, please change it by entering the following data')}
                </Typography>
            </DialogTitle>
            <DialogContent dividers sx={{ p: 3 }}>
                <FormControl fullWidth>
                    <InputLabel error={values.error === "allPassword" || values.error === "password" ? true : false}>{t('New password')}</InputLabel>
                    <OutlinedInput
                        disabled={save}
                        error={values.error === "allPassword" || values.error === "password" ? true : false}
                        id="temporalPassword-input"
                        type={values.showPassword ? 'text' : 'password'}
                        value={values.password}
                        onChange={handleChange('password')}
                        placeholder={t('Your password here...')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    disabled={save}
                                    onClick={() => handleClickShowPassword('showPassword')}
                                    edge="end">
                                    {values.showPassword ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText style={{ display: values.error === "allPassword" || values.error === "password" ? 'block' : 'none' }} error>{t('The password must contain at least 8 characters')}</FormHelperText>
                </FormControl>
                <FormControl fullWidth style={{ marginTop: "10px" }}>
                    <InputLabel error={values.error === "allPassword" || values.error === "confirmPassword" || values.error === "notMatchPassword" ? true : false}>{t('Confirm new password')}</InputLabel>
                    <OutlinedInput
                        disabled={save}
                        error={values.error === "allPassword" || values.error === "confirmPassword" || values.error === "notMatchPassword" ? true : false}
                        id="confirmTemporalPassword-input"
                        type={values.confirmShowPass ? 'text' : 'password'}
                        value={values.confirmPass}
                        onChange={handleChange('confirmPass')}
                        placeholder={t('Your password here...')}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    disabled={save}
                                    onClick={() => handleClickShowPassword('confirmShowPass')}
                                    edge="end">
                                    {values.confirmShowPass ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                    <FormHelperText style={{ display: values.error === "allPassword" || values.error === "confirmPassword" || values.error === "notMatchPassword" ? 'block' : 'none' }} error>{t(values.error === "notMatchPassword" ? 'The password does not match' : 'The password must contain at least 8 characters')}</FormHelperText>
                </FormControl>
            </DialogContent>
            <DialogActions sx={{ p: 3 }}>
                {
                    save
                        ?
                        <LoadingButton variant="contained" loading loadingPosition="start" startIcon={<SaveIcon />}>
                            {t('Saving')}
                        </LoadingButton>
                        :
                        <Button id={'saveTemporalPassword-button'} variant="contained" onClick={handleSaveNewPassword}>{t('Save password')}</Button>
                }
            </DialogActions>
        </Dialog>
    )
}

export default TemporalPass