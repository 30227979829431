import { FC, ReactNode, useContext,useEffect,useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Hidden, Drawer } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { getUserInfo } from 'src/utils/getUserInfo';
import BottomBar from './BottomBar';
import { SidebarContext } from 'src/contexts/SidebarContext';
// import SidebarMenu from 'src/layouts/BoxedSidebarLayout/Sidebar/SidebarMenu';
import SidebarMenu from '../../layouts/AccentHeaderLayout/Sidebar/SidebarMenu';
// import ThemeSettings from './ThemeSettings';
// import ThemeSettings from 'src/components/ThemeSettings';
import { Scrollbars } from 'react-custom-scrollbars-2';
import Logo from 'src/layouts/AccentHeaderLayout/Header/Logo';
import SidebarTopSection from 'src/layouts/AccentHeaderLayout/Sidebar/SidebarTopSection';

interface BottomNavigationLayoutProps {
  children?: ReactNode;
}

const MainWrapper = styled(Box)(
  ({ theme }) => `
      flex: 1 1 auto;
      display: flex;
      height: 100%;

      .footer-wrapper {
        overflow: hidden;
        height: 0;
        box-shadow: none;
        border: 0;
      }
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        padding-bottom: ${theme.header.height};
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
`
);

const SidebarWrapper = styled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        min-width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        position: relative;
        z-index: 5;
        height: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
          height: calc(100% - ${theme.header.height});
          margin-top: ${theme.header.height};
        }
`
);

const TopSection = styled(Box)(
  ({ theme }) => `
        margin: ${theme.spacing(2, 2)};
`
);

const BottomNavigationLayout: FC<BottomNavigationLayoutProps> = () => {

  const [ profileImage, SetProfileImage ] = useState<string>("")
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const closeSidebar = () => toggleSidebar();

  useEffect(()=>{
    const getDataUser = async() =>{
      let profileImageUser = await  getUserInfo()
      SetProfileImage(profileImageUser)
    }
    getDataUser()
  },[])
  return (
    <>
      <MainWrapper>
        <Scrollbars autoHide>
          <MainContent>
            <Outlet />
            <Hidden lgUp>
              <Drawer
                anchor="left"
                open={sidebarToggle}
                onClose={closeSidebar}
                variant="temporary"
                elevation={9}
              >
                <SidebarWrapper>
                  <Scrollbars autoHide>
                    <TopSection>
                        <Logo />
                      <SidebarTopSection profileImage={profileImage} />
                    </TopSection>
                    <SidebarMenu />
                  </Scrollbars>
                </SidebarWrapper>
              </Drawer>
            </Hidden>
          </MainContent>
        </Scrollbars>
        <BottomBar />
      </MainWrapper>
    </>
  );
};

export default BottomNavigationLayout;
