import { makeStyles } from 'tss-react/mui';

export const StyleHero = makeStyles()((theme) => ({
    dialog: {
        '.MuiDialog-container': {
            justifyContent: 'flex-end',
            alignItems: 'end'
        },
        '.MuiPaper-root': {
            borderRadius: '20px',
            width: '400px',

        },
        '.MuiInputBase-root': {
            borderBottom: 'azure',
        }
    },
    dialogTitle: {
        background: '#FF0A38',
        color: '#ffffff',
        fontSize: '15px',
        fontWeight: '700',
        fontFamily: 'Fira Sans',
        lineHeight: '62px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '4px',
        marginBottom: '10px'
    },
    dialogContent: {
        height: '400px',
        overflowX: 'hidden'
    },
    dialogActions: {
        borderTop: 'solid #E8E8E8',
        justifyContent: 'flex-start',
        display: 'flex',
        width: '100%'
    },
    CloseIcon: {
        color: '#ffffff',
        border: 'solid #ffffff 2.5px',
        borderRadius: '9px'
    },
    dialogBox: {
        display: 'flex',
        alignItems: 'center',
    },
    ArrowForwardIosIcon: {
        color: '#FF0A38',
        border: 'solid #FF0A38 2.5px',
        borderRadius: '9px',
        padding: '3px '
    },
    msg: {
        background: '#FF0A38',
        margin: '0px 5px 15px 2px',
        padding: '10px',
        color: '#ffffff',
        borderRadius: '6px',
        width: 'fit-content',
        marginLeft: 'auto',
        maxWidth: '80%'
    },
    boot: {
        background: '#E8E8E8',
        padding: '10px',
        borderRadius: '6px',
        color: '#1e2022',
        width: 'fit-content',
        maxWidth: '80%'
    },
    bootTextFiel: {
        width: '100%',
        marginLeft: '10px',
        '& .MuiInput-root:hover': {
            borderBottomColor: '#000000',
        },
        '& .MuiInput-root:focus': {
            borderBottomColor: '#000000',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: '#000000',
        },
        '& .MuiInputBase-root': {
            borderBottomColor: '#ffffff',
            '&:hover fieldset': {
                borderBottomColor: '#000000',
            },
            '&.Mui-focused fieldset': {
                borderBottomColor: '#000000',
                color: '#000000'
            },
        },
        '& label.Mui-focused': {
            color: '#0000',
        },
    }
}));