import { FC, lazy, ReactNode, useEffect, useState } from 'react';

import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { getUserInfo } from 'src/utils/getUserInfo';

import Sidebar from './Sidebar';
import Header from './Header';

const QuickSettingsButton = lazy(() => import('src/components/QuickSettings/QuickSettingsButton'))
interface AccentHeaderLayoutProps {
  children?: ReactNode;
}

const MainWrapper = styled(Box)(() => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
`);

const MainContent = styled(Box)(({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow-y: auto;
        overflow-x: hidden;
`);

const AccentHeaderLayout: FC<AccentHeaderLayoutProps> = () => {
  const [profileImage, SetProfileImage] = useState<string>("")

  useEffect(() => {
    const getDataUser = async () => {
      let profileImageUser = await getUserInfo()
      SetProfileImage(profileImageUser)
    }
    getDataUser()
  }, [])
  
  return (
    <MainWrapper>
      <Header profileImage={profileImage} />
      <Sidebar profileImage={profileImage} />
      <MainContent>
        <QuickSettingsButton />
        <Outlet />
      </MainContent>
    </MainWrapper>
  );
};

export default AccentHeaderLayout;
