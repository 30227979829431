import * as _ from 'lodash';
import moment from 'moment';
import { collection, query, onSnapshot } from 'firebase/firestore'

import { updateStatusTableBooking, queryDoc, changeStatusTableFirebase, db, changeStatusAndColorTable } from "src/utils/firebase"

export const assingTable = async (status, getBookings, setLoadingSave,numberTable,optionData) => {
    const restaurantName: string = localStorage.getItem("restName") ?? "";
    const idBooking: string = localStorage.getItem("idBooking") ?? "";
    try {
        setLoadingSave(true)
        const tables = await GetAllTables()
        await updateStatusTableBooking(restaurantName, idBooking, status)
        const found = tables.filter(element => element.tableNumber === numberTable);
        if (found.length > 0) {
            const { floor, idTable } = found[0]
            const ref = `Floor${floor}`
            let newColor = "#338600"
            let occupationDate  = moment().format("YYYY-MM-DD HH:mm:ss")
            let releaseDate  = moment().add(parseInt(optionData.tableReservationTime), 'hours').format("YYYY-MM-DD HH:mm:ss")
            try {
                await changeStatusAndColorTable(restaurantName, "layoutRestaurant",ref,idTable,"ocuppied",newColor,"ocuppied",occupationDate,releaseDate);
            } catch (error) {
                console.log(error)
            }
        }
        getBookings()
        setLoadingSave(false)

    } catch (error) {
        console.log('error', error)
        setLoadingSave(false)
    }
}

export const getTablesByFloor = async () => {
    const restaurantName: string = localStorage.getItem("restName") ?? "";
    const document: string = 'layoutRestaurant';
    try {
        let dataFloors = []
        const tablesRestaurant = await queryDoc(restaurantName, document);
        const dataTableRestaurant = tablesRestaurant?.data();
        Object.values(dataTableRestaurant).forEach((Floor) => {
            let dtataTablesbyFloor = []
            const { index, section } = Floor
            Object.values(Floor).forEach((value) => {
                if (typeof value === "object") {
                    dtataTablesbyFloor.push(value)
                }
            });
            dataFloors.push({
                section,
                index,
                tables: dtataTablesbyFloor
            })

        })
        let sortFlorrs = _.sortBy(dataFloors, o => o.index);
        return sortFlorrs
    } catch (error) {
        console.error(error);
    }
}

export const GetAllTables = async () => {
    const restaurantName: string = localStorage.getItem("restName") ?? "";
    const document: string = 'layoutRestaurant';
    try {
        const tablesRestaurant = await queryDoc(restaurantName, document);
        const dataTableRestaurant = tablesRestaurant?.data();
        if (dataTableRestaurant) {
            let dtataTablesbyFloor: any[] = []
            Object.values(dataTableRestaurant).forEach((Floor) => {
                Object.values(Floor).forEach((value) => {
                    if (typeof value === "object") {
                        dtataTablesbyFloor.push(value)
                    }
                });
            })
            let sortFlorrs = _.sortBy(dtataTablesbyFloor, o => o.floor);
            return sortFlorrs
        }
    } catch (error) {
        console.log(error)
    }
}

export const changeStatusTable = async (tables, status, tableNumber) => {
    const restaurantName: string = localStorage.getItem("restName") ?? "";
    if (tables?.length > 0) {
        if (tableNumber !== "pickup" && tableNumber) {
            const found = tables.filter(element => element.tableNumber === tableNumber);
            if (found.length > 0) {
                const { floor, idTable } = found[0]
                const ref = `Floor${floor}`
                try {
                    await changeStatusTableFirebase(restaurantName, "layoutRestaurant", ref, idTable, status);
                } catch (error) {
                    console.log(error)
                }

            }
        }
    }
}

export const getBookings = async (optionData) => {
    try {
        const restaurantName: string = localStorage.getItem("restName") ?? "";
        const dateCurrent = moment().format("YYYY-MM-DD")
        const dateCurrentFull = moment().format("YYYY-MM-DD HH:mm")
        const tablesRestaurant = await GetAllTables()
        console.log("tablesRestaurant111", tablesRestaurant)
        const q = query(collection(db, `/${restaurantName}/layoutRestaurant/calendar`));
        onSnapshot(q, (querySnapshot) => {
            const ordersRealtime = []
            querySnapshot.forEach(async (doc) => {
                const dateSplit = doc.data().startDate.split(" ")[0]
                if (moment(dateCurrent).isSame(dateSplit)) {
                    const isSameOrAfter = moment(dateCurrentFull).isSameOrAfter(doc.data().startDate)
                    const isSameOrBefore = moment(dateCurrentFull).isSameOrBefore(doc.data().endDate)
                    if (isSameOrAfter && isSameOrBefore) {
                        const foundTable = tablesRestaurant.filter(element => element.idTable === doc.data().idTable);
                        if (foundTable) {
                            const { floor, idTable, occupationDate } = foundTable[0]
                            const ref = `Floor${floor}`
                            const newState = "ocuppied"
                            const newColor = "#338600"
                            const occupationDateReserve = moment().format("YYYY-MM-DD HH:mm:ss")
                            const releaseDate = moment().add(parseInt(optionData.tableReservationTime), 'hours').format("YYYY-MM-DD HH:mm:ss")
                            try {
                                if (occupationDate === "") {
                                    await changeStatusAndColorTable(restaurantName, "layoutRestaurant", ref, idTable, newState, newColor, "reserved", occupationDateReserve, releaseDate);
                                }
                            } catch (error) {
                                console.log(error)
                            }
                        }
                    }
                    ordersRealtime.push(doc.data())
                    console.log("ordersRealtime",ordersRealtime)
                }
            })
        })
    } catch (error) {
        console.log(error)
    }
}