import { Box, SxProps, Theme } from "@mui/system"

export const AccountingIcon = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/AccountingIcon.svg" />
export const RoomsIcon = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/BedIcon.svg" />
export const SettingsIcon = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/SettingsIcon.svg" />
export const UsersIcon = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/CustomersIcon.svg" />
export const CustomersIcon = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/UsersIcon.svg" />
export const PagesIcon = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/PagesIcon.svg" />
export const ChatIcon = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/ChatIcon.svg" />
export const HistoryIcon = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/HistoryIcon.svg" />
export const AnalyticsIcon = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/AnalyticsIcon.svg" />
export const DiscountsIcon = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/DiscountsIcon.svg" />
export const SupportIcon = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/SupportIcon.svg" />
export const PencilIcon = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/PencilIcon.svg" />
export const DeleteIcon = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/DeleteIcon.svg" />
export const EyeIcon = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/EyeIcon.svg" />
//__________________________
export const Analytics = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/Analytics.svg" />
export const Support = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/Support.svg" />
export const Calender = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/Calender.svg" />
export const Discount = (props: SxProps<Theme> & { alt?: string }) => <Box sx={props} component='img' src="https://storage.googleapis.com/order-and-eat-now/Images/Iconos/PortalIcons/Discount.svg" />

