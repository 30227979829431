import { useMediaQuery } from '@mui/material'

export const BaseViewPorts = () => {

    const queryDesktop = useMediaQuery('(min-width:1600px)')
    const queryLaptop = useMediaQuery('(min-width:1280px)')
    const queryTablet = useMediaQuery('(min-width:768px)')
    const queryMobile = useMediaQuery('(min-width:360px)')

    const desktop = queryDesktop && queryLaptop && queryTablet && queryMobile
    const laptop = !queryDesktop && queryLaptop && queryTablet && queryMobile
    const tablet = !queryDesktop && !queryLaptop && queryTablet && queryMobile
    const mobile = !queryDesktop && !queryLaptop && !queryTablet && queryMobile

    return {
        desktop,
        laptop,
        tablet,
        mobile
    }
}
