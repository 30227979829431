import { useContext, useEffect } from 'react';

import {
  Box,
  Hidden,
  IconButton,
  Tooltip,
  alpha,
  useMediaQuery,
  useTheme,
  Divider,
  Button,
  Autocomplete,
  TextField,
  InputAdornment,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import HelpSupport from 'src/components/HelpSupport';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SearchIcon from '@mui/icons-material/Search';
import Logo from './Logo';
import { BaseViewPorts } from 'src/theme/viewports/BaseViewPorts';
import { useNavigate } from 'react-router-dom';
import Searchlist from 'src/layouts/utils/search';
import { useDataSettings } from 'src/hooks/useSettings';
import { LocalStorage } from 'src/localStorge/LocalStorage';
import MyStore from './Buttons/Store/MyStore';
import MockupMobile from 'src/components/Mockups/MockupMobile';
import Draggable from 'react-draggable';
import CloseIcon from '@mui/icons-material/Close';
import { queryDoc } from 'src/utils/firebase';
import { icon } from 'src/components/Prices/arrayPrices';
import PlanHeaderButton from './Plans';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
    color: ${theme.header.textColor};
    right: 0;
    left: 0;
    z-index: 6;
    background-color: ${theme.colors.alpha.trueWhite[100]};
    position: fixed;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 0;
`
);

const IconButtonPrimary = styled(IconButton)(
  ({ theme }) => `
    background: ${theme.colors.alpha.trueWhite[10]};
    color: ${theme.colors.alpha.trueWhite[70]};
    padding: 0;
    width: 50px;
    height: 50px;
    border-radius: 100%;

    &.active,
    &:active,
    &:hover {
      background: ${alpha(theme.colors.alpha.trueWhite[30], 0.2)};
      color: ${theme.colors.alpha.trueWhite[100]};
    }
`
);

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.grey[500], 0.15),
  border: 'none',
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    width: 'auto'
  }
}));

function Header(props) {
  const viewports = BaseViewPorts();
  let navigate = useNavigate();
  let { listForSearch } = Searchlist();
  const { role } = LocalStorage();
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const [state, setState] = useState(false);
  const restName = localStorage.getItem('restName') ?? '';
  const { t }: { t: any } = useTranslation();
  const [open, setOpen] = useState(false);
  const { options } = useDataSettings();
  const DialogHelpSupport = () => {
    return <HelpSupport isLogin={true} open={open} setOpen={setOpen} />;
  };
  const [planType, setPlanType] = useState<any>({
    finishAt: '',
    plan: '',
    price: 0,
    startedAt: ''
  });

  const freeIcon = icon[0].icons;
  const essentialIcon = icon[1].icons;
  const standardIcon = icon[2].icons;
  const premiumIcon = icon[3].icons;

  useEffect(() => {
    const getDataPlan = async () => {
      if (restName) {
        const plan = await queryDoc(restName, 'Plans');
        const planData = plan?.data();
        setPlanType(planData);
      }
    };
    getDataPlan();
  }, [restName]);

  const [isOpen, setIsOpen] = useState(false);

  const handleTooltipClose = () => {
    setIsOpen(false);
  };

  const handleTooltipOpen = () => {
    setIsOpen(true);
  };

  const planIcon = () => {
    switch (planType?.plan) {
      case 'Essential':
        return essentialIcon;
      case 'Standard':
        return standardIcon;
      case 'Premium':
        return premiumIcon;
      default:
        return freeIcon;
    }
  };

  const previewView = () => {
    return (
      <>
        <Draggable bounds="body" position={null} cancel="strong">
          <Box
            id={'container'}
            sx={{ width: '100%', position: 'relative', zIndex: 20 }}
          >
            <Box sx={{ position: 'absolute' }}>
              <strong className="no-cursor">
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    marginBottom: 1
                  }}
                >
                  <IconButton
                    sx={{
                      background: '#FFF',
                      borderRadius: '10px',
                      display: 'flex',
                      justifyContent: 'center',
                      border: '2px solid #F40937',
                      width: '30px',
                      height: '30px'
                    }}
                    id={'ButtonICon'}
                    onClick={() => setState(false)}
                  >
                    <CloseIcon color={'primary'} />
                  </IconButton>
                </Box>
              </strong>
              <MockupMobile>
                <strong className="no-cursor">
                  <Box
                    sx={{
                      width: '100%',
                      height: '100%',
                      borderRadius: '30px',
                      overflowY: 'auto',
                      position: 'relative'
                    }}
                  >
                    <iframe
                      title={'app'}
                      src={
                        options &&
                        options.addRedirects &&
                        Object.keys(options.addRedirects).length > 0 &&
                        options.addRedirects.value &&
                        role !== 'Staff'
                          ? `https://${options?.addRedirects.value}`
                          : `https://${options?.addRedirects}`
                      }
                      height={'100%'}
                      width={'100%'}
                    />
                  </Box>
                </strong>
              </MockupMobile>
            </Box>
          </Box>
        </Draggable>
      </>
    );
  };

  return (
    <>
      <HeaderWrapper
        sx={{ padding: 1, height: !mobile ? theme.header.height : null }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <Box display="flex" alignItems="center">
            {viewports.desktop || viewports.laptop ? (
              <Box
                sx={{
                  margin: viewports.mobile ? '14px' : '8px',
                  width: viewports.mobile
                    ? `calc(${theme.sidebar.width} - ${theme.spacing(20)});`
                    : `calc(${theme.sidebar.width} - ${theme.spacing(3)});`
                }}
              >
                <Logo />
              </Box>
            ) : (
              <Tooltip arrow title="Toggle Menu">
                <IconButtonPrimary
                  id="btn-open-side-bar"
                  onClick={toggleSidebar}
                  style={{ color: theme.colors.primary.main }}
                >
                  {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
                </IconButtonPrimary>
              </Tooltip>
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}
          >
            <Box
              sx={{
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                width: 'fit-content',
                gap: '10px'
              }}
            >
              <Hidden smDown>
                {role !== 'Staff' && (
                  <>
                    <Search>
                      <Autocomplete
                        freeSolo
                        disableClearable
                        groupBy={(option) => option.module}
                        getOptionLabel={(option: any) => option.textKey ?? ''}
                        options={listForSearch?.sort((a, b) =>
                          b.module.localeCompare(a.module)
                        )}
                        onInputChange={(event, newInputValue) => {
                          const obj = listForSearch?.find(
                            (option) => option.textKey === newInputValue
                          );
                          if (newInputValue && obj) {
                            navigate(obj.page);
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                              startAdornment: (
                                <InputAdornment position="start">
                                  <SearchIcon color="primary" />
                                </InputAdornment>
                              )
                            }}
                            placeholder={'Search...'}
                            size="small"
                            sx={{
                              width: '419px',
                              borderRadius: '10px',
                              boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.15)',
                              background: '#F2F3F4',
                              border: 'none'
                            }}
                          />
                        )}
                      />
                    </Search>
                    <Box>
                      <Button
                        onClick={() => setState(true)}
                        color={'secondary'}
                        sx={{
                          color: theme.palette.grey[900]
                        }}
                        endIcon={
                          <LazyLoadImage
                            src={
                              'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/previsualizacion.svg'
                            }
                            alt="View_preview"
                          />
                        }
                      >
                        {t('View preview')}
                      </Button>
                    </Box>
                  </>
                )}
              </Hidden>
              <>{state && previewView()}</>
            </Box>
            <Box display="flex" alignItems="center">
              {open && DialogHelpSupport()}
              {/* <ThemeSettings />*/}
              <Box
                sx={{
                  height: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  width: 'fit-content',
                  gap: '10px'
                }}
              >
                <MyStore />
                <Divider orientation="vertical" flexItem />
                <Box
                  sx={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    width: 'fit-content',
                    gap: '10px'
                  }}
                >
                  <HeaderButtons />
                  <HeaderUserbox profileImage={props.profileImage} />
                </Box>
                <Divider orientation="vertical" flexItem />
                  <PlanHeaderButton
                    planIcon={planIcon}
                    planType={planType}
                    handleTooltipClose={handleTooltipClose}
                    handleTooltipOpen={handleTooltipOpen}
                    isOpen={isOpen}
                  />
              </Box>
            </Box>
          </Box>
        </Box>
      </HeaderWrapper>
    </>
  );
}

export default Header;
