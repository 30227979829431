import { Button } from '@mui/material'
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';

//Responsive
import { BaseViewPorts } from 'src/theme/viewports/BaseViewPorts';

const GoogleButton = ({ fullWidth = false, hasOnClick, textButton }: any) => {

    const viewports = BaseViewPorts()
    const { t }: { t: any } = useTranslation();
    const iconGoogle = 'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/google.svg'

    const googleIcon = () => {
        return (
            <LazyLoadImage src={iconGoogle} alt="google" width={!viewports.mobile ? '26.34px' : '20px'} />
        )
    }

    return (
        <Button
            fullWidth={fullWidth}
            variant='contained'
            startIcon={googleIcon()}
            onClick={hasOnClick}
            sx={{
                backgroundColor: '#FFFFFF',
                color: '#1E232C',
                padding: 1.2,
                width: viewports.desktop || viewports.laptop ? '200px' : '100%',
                fontSize: '10px',
                ":hover": {
                    backgroundColor: '#e6e6e6'
                },
                paddingLeft: !textButton ? 3 : 1.5,
                border: '1.38601px solid #E6E8EC'
            }}
        >
            {t(textButton)}
        </Button>
    )
}

export default GoogleButton