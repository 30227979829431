import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
        padding: ${theme.spacing(0, 1, 0, 0)};
        display: flex;
        text-decoration: none;
        font-weight: ${theme.typography.fontWeightBold};
`
);

function Logo() {

  return (
    <LogoWrapper to="/">
      <img src="/orderandeatnow.svg" alt="Order And Eat Now" style={{height:"150px", paddingLeft:"10px" }}/>
      <img src="/orderandeatnowlabel.svg" alt="Order And Eat Now" style={{height:"60px", paddingLeft:"20px", marginTop: "50px"}}/>
    </LogoWrapper>
  );
}

export default Logo;
