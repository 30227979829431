import { FC, ReactNode,useEffect,useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { getUserInfo } from 'src/utils/getUserInfo';

import Sidebar from './Sidebar';
import Header from './Header';

interface AccentSidebarLayoutProps {
  children?: ReactNode;
}

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            padding-left: ${theme.sidebar.width};
        }
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
    margin-top: ${theme.header.height};
    flex: 1 1 auto;
    overflow-y: auto;
    overflow-x: hidden;
`
);

const AccentSidebarLayout: FC<AccentSidebarLayoutProps> = () => {
  const [ profileImage, SetProfileImage ] = useState<string>("")

  useEffect(()=>{
    const getDataUser = async() =>{
      let profileImageUser = await  getUserInfo()
      SetProfileImage(profileImageUser)
    }
    getDataUser()
  },[])
  return (
    <>
      <Sidebar profileImage={profileImage}/>
      <MainWrapper>
        <Header profileImage={profileImage}/>
        <MainContent>
          <Outlet />
          {/* <ThemeSettings /> */}
        </MainContent>
      </MainWrapper>
    </>
  );
};


export default AccentSidebarLayout;
