import { useState, useEffect } from 'react';

import { ListSubheader, Box, List, Button, Zoom } from '@mui/material';
import { useLocation, matchPath, useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { TFunction, useTranslation } from 'react-i18next';
import _ from 'lodash';

import SidebarMenuItem from './item';
import { MenuItem } from './items';
import TemporalPass from './TemporalPass';
import { queryUserRestaurants, queryRoleUserbyRestaurant, queryDoc } from 'src/utils/firebase';
import { sidebarMenu } from 'src/utils/sidebarMenu';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import CreateRedirectUrl from 'src/content/management/Settings/utils/CreateRedirectUrl';
import { LocalStorage } from 'src/localStorge/LocalStorage';
import { useSnackbar } from 'notistack';

const MenuWrapper = styled(Box)(({ theme }) => `
    .MuiList-root {
      margin-bottom: ${theme.spacing(0.5)};
      padding: 12px 0 12px 0;
      background-color: ${theme.colors.alpha.trueWhite[100]};
      box-shadow: none;

      & > .MuiList-root {
        padding: 0 ${theme.spacing(0)} ${theme.spacing(1)};
      }
    }

    .MuiListSubheader-root {
      font-weight: 600;
      font-size: ${theme.typography.pxToRem(15)};
      color: ${theme.sidebar.textColor};
      padding: ${theme.spacing(0.8, 2)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = styled(Box)(({ theme }) => `
  .boneLightText {
        color: ${theme.sidebar.textColor};

  }
    .MuiList-root {

      .MuiListItem-root {
        padding: 2px;
        padding-left: ${theme.spacing(0)};
        padding-right: ${theme.spacing(2)};

        .MuiBadge-root {
          position: absolute;
          right: ${theme.spacing(4)};

          .MuiBadge-standard {
            background: ${theme.colors.primary.main};
            font-size: ${theme.typography.pxToRem(9)};
            font-weight: 600;
            text-transform: uppercase;
            color: ${theme.palette.primary.contrastText};
          }
        }
        
        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          width: 100%;
          justify-content: flex-start;
          font-size: ${theme.typography.pxToRem(13)};
          padding-top: ${theme.spacing(1.2)};
          padding-left: ${theme.spacing(3)};
          padding-bottom: ${theme.spacing(1.2)};
          margin-left: ${theme.spacing(1)};
          border-radius: 8px;
          
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(26)};
            margin-right: ${theme.spacing(1.5)};
            color: ${theme.sidebar.menuItemIconColor};
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(22)};
          }

          &.Mui-active,
          &:hover {
            background: ${theme.colors.gradients.orange2};
            color: #fff;

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                filter: brightness(50);
            }
          }
        }

        &.Mui-children {
          flex-direction: column;
          line-height: 1;

          & > .MuiButton-root {
            .MuiBadge-root {
              right: ${theme.spacing(7)};
            }
          }
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            margin: 4px 0 4px 0;
            padding-left: ${theme.spacing(0)};
            padding-right: ${theme.spacing(0)};

            .MuiButton-root {
              padding: ${theme.spacing(1.5, 2, 1.5, 5.5)};
              font-weight: normal;

              &.Mui-active,
              &:hover {
                background-color: ${theme.sidebar.menuItemBgActive};
              }
            }
          }
        }
      }
    }
`
);

function SidebarMenu() {
  const [menuItems, setStateMenu] = useState([]);
  const [userRole, setUserRole] = useState('');
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [chatbot, setChatbot] = useState<boolean>(false);
  const [options, setOptions] = useState<any>();
  const [uidUser, setUidUser] = useState<string>(null);
  const [reservationRoom, setReservationRoom] = useState<boolean>(false);

  const navigate = useNavigate()
  const { pathname } = useLocation();

  const { t }: { t: TFunction<"translation"> } = useTranslation();

  const { restName, i18nextLng } = LocalStorage()
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    let itemsnR = [];
    let typeSidebar = pathname.split('/')[1]

    const Promesa = new Promise(function (resolve, reject) {
      const queryItems = queryUserRestaurants('portalUsers/Restaurants/Users', null);
      resolve(queryItems);
    });

    const getOptionChatbot = async (restName: string) => {
      const query = await queryDoc(restName, "options")
      const data = query?.data()

      if (data) {
        setChatbot(data.chatbot)
        setReservationRoom(data.reservation)
        setOptions(data)

        if (data.inventory) {
          localStorage.setItem("inventory", 'true')
        } else {
          localStorage.removeItem("inventory")
        }

        if (data.inventoryShop) {
          localStorage.setItem("inventoryShop", 'true')
        } else {
          localStorage.removeItem("inventoryShop")
        }
      }
    }

    const getDisplayName = async (restaurants: any, newPathRestaurant: string, firstRestName: string) => {
      const query = await queryDoc('portalUsers', 'Restaurants')
      const queryData = query?.data()

      queryData.Restaurants.forEach((restaurant) => {
        restaurants.forEach((r) => {
          if (r === restaurant.name) {
            if (r === firstRestName) {
              localStorage.setItem('restNameDisplay', restaurant.displayName ? restaurant.displayName : r);
            }
            itemsnR.push({
              name: r,
              subName: "Restaurants",
              displayName: restaurant.displayName ? restaurant.displayName : r,
              link: '/'
            });
          }
        })
      })

    }

    const Promesa2 = new Promise(function (resolve, reject) {
      Promesa.then((usersRestaurants: any) => {
        if (usersRestaurants && usersRestaurants.restaurants.length > 0) {
          let firstRestName = usersRestaurants.restaurants[0];

          setOpenDialog(usersRestaurants.temporalPass)
          setUidUser(usersRestaurants.idEmail)

          if (!options) {
            getOptionChatbot(localStorage.getItem('restName') ?? firstRestName)
          }

          if (firstRestName !== "staff") {
            if (!localStorage.getItem('restName')) {
              localStorage.setItem('restName', firstRestName);
              //setRestName(firstRestName)
            } else {
              firstRestName = localStorage.getItem('restName')
              //setRestName(localStorage.getItem('restName'))
            }
          }

          const newRole = localStorage.getItem('role')
          let newPathRestaurant = ''

          if (newRole === 'Admin') {
            newPathRestaurant = `/${typeSidebar}/dashboards/analytics`
          } else {
            switch (newRole) {
              case 'Kitchen':
                newPathRestaurant = `/${typeSidebar}/management/kitchen`
                break;
              case 'Delivery':
                newPathRestaurant = `/${typeSidebar}/management/delivery`
                break;
              case 'Cashier':
                newPathRestaurant = `/${typeSidebar}/management/cash`
                break;
              case 'Waiter':
                newPathRestaurant = `/${typeSidebar}/management/waiter`
                break;
            }
          }
          getDisplayName(usersRestaurants.restaurants, newPathRestaurant, firstRestName)
          resolve(queryRoleUserbyRestaurant('portalUsers', firstRestName));
        }
      });
    });

    Promesa2.then((roleUser: any) => {
      setUserRole(localStorage.getItem('role'));

      const nameRestaurant = localStorage.getItem('restName');
      let menuItemsNew = sidebarMenu(roleUser, typeSidebar, nameRestaurant, itemsnR, options, chatbot, reservationRoom);
      setStateMenu(_.sortBy(menuItemsNew, 'heading'));
    });
  }, [userRole, pathname, options, navigate, chatbot, reservationRoom]);

  const renderSidebarMenuItems = ({ items, path, heading }: { items: MenuItem[]; path: string; heading: string; }): JSX.Element => {
    return (
      <SubMenuWrapper id='submenu-wrapper'>
        <List component="div" id='submenu-list'>
          {items.reduce(
            (ev, item) => reduceChildRoutes({ ev, item, path }),
            []
          )}
        </List>
      </SubMenuWrapper>
    )
  };

  const reduceChildRoutes = ({ ev, path, item }: { ev: JSX.Element[]; path: string; item: MenuItem; }): Array<JSX.Element> => {
    const key = item.name;
    const exactMatch =
      item.link
        ?
        !!matchPath({
          path: item.link,
          end: true
        },
          path
        )
        :
        false;

    if (item.items) {
      let partialMatch =
        item.link
          ?
          !!matchPath({
            path: item.link,
            end: false
          },
            path
          )
          :
          false;
      partialMatch = item.subName ? false : partialMatch
      ev.push(
        <SidebarMenuItem
          key={key}
          active={partialMatch}
          open={partialMatch}
          name={item.name}
          icon={item.icon}
          link={item.link}
          badge={item.badge}
          subName={item.subName ? item.subName : null}
          displayName={item.displayName}
        >
          {renderSidebarMenuItems({
            path,
            items: item.items,
            heading: ''
          })}
        </SidebarMenuItem>
      );
    } else {
      ev.push(
        <SidebarMenuItem
          key={key}
          active={exactMatch}
          name={item.name}
          link={item.link}
          badge={item.badge}
          icon={item.icon}
          subName={item.subName ? item.subName : null}
          displayName={item.displayName}
        />
      );
    }
    return ev;
  };

  const handleCopy = () => {
    try {
      const { urlPath } = CreateRedirectUrl('addRedirects', options, restName, i18nextLng)
      if (urlPath) {
        navigator.clipboard.writeText(urlPath);
        enqueueSnackbar(t('Text copied to clipboard'), {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center'
          },
          TransitionComponent: Zoom
        });
      }
    } catch (err) {
      console.error('Error al copiar el texto', err);
    }
  };

  if (menuItems) {
    return (
      <>
        {menuItems.map((section, index) => {
          return (
            <MenuWrapper key={section.heading} id='menu-wrapper'>
              <List
                component="div"
                subheader={
                  <ListSubheader component="div" disableSticky id={`header-item-${index}`}>
                    {t(section.heading === 'Restaurants'
                      ? 'Venues'
                      : section.heading === 'Dashboards'
                        ? 'General'
                        : section.heading)}
                  </ListSubheader>
                }
              >
                {renderSidebarMenuItems({
                  items: section.items,
                  path: pathname,
                  heading: section.heading
                })}
              </List>
            </MenuWrapper>
          );
        })}
        <Box sx={{ marginX: 1, marginBottom: 3 }}>
          <Button
            variant='contained'
            sx={{
              width: '94%',
              background: 'linear-gradient(92.12deg, #F40937 -1.42%, #F46F05 102.45%)'
            }}
            endIcon={<LazyLoadImage src={'https://storage.googleapis.com/order-and-eat-now/Images/Iconos/Share_establishment_icon.svg'} alt="copy" />}
            onClick={handleCopy}
          >
            {t('Share your establishment')}
          </Button>
        </Box>
        <TemporalPass openDialog={openDialog} setOpenDialog={setOpenDialog} uidUser={uidUser} />
      </>
    );
  }
}

export default SidebarMenu;