import { useCallback, useEffect, useState } from "react";
import { queryDoc } from "src/utils/firebase";

export const useDataSettings = () => {
    const restName = localStorage.getItem("restName") ?? ""
    const [options, setOptions] = useState<any>({})
    const [reloadOptions, setReloadOptions] = useState<boolean>(false)

    const getOptionsByRestaurant = useCallback(async () => {
        try {
            if (reloadOptions) {
                setReloadOptions(false)
            }
            const options = await queryDoc(restName, "options");
            setOptions(options ? options.data() : {})
        } catch (err) {
            console.error(err);
        }
    }, [reloadOptions, restName]);

    useEffect(() => {
        getOptionsByRestaurant();
    }, [getOptionsByRestaurant]);

    return {
        options,
        reloadOptions,
        setReloadOptions
    }
}