export const i18nextLng: string = "i18nextLng"
export const versionPortal: string = "version_portal"
export const login: string = "login"
export const uid: string = "uidEmail"
export const userName: string = "userName"
export const restName: string = "restName"
export const role: string = "role"
export const restNameDisplay: string = "restNameDisplay"
export const cypress: string = "cypress"
export const dataRegister: string = "dataRegister"
