// import AnalyticsTwoToneIcon from '@mui/icons-material/AnalyticsTwoTone';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import iconLanguage from '@mui/icons-material/Language';
import ArtTrackIcon from '@mui/icons-material/ArtTrack';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import StorefrontIcon from '@mui/icons-material/Storefront';
import InventoryIcon from '@mui/icons-material/Inventory';
import DiningIcon from '@mui/icons-material/Dining';
import CarRentalIcon from '@mui/icons-material/CarRental';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import { AccountingIcon, RoomsIcon, SettingsIcon, UsersIcon, PagesIcon, ChatIcon, HistoryIcon, CustomersIcon, Analytics, Support, Calender, Discount } from './icons';
import { Buffer } from 'buffer';

export function sidebarMenu(roleUser: any, typeSidebar: string, nameRestaurant: string, itemsnR: any[], options: any, chatbot: boolean, reservationRoom: boolean = false) {
  let menuItemsNew = []
  if (itemsnR.length > 1 || roleUser === 'Staff') {
    menuItemsNew.push({
      heading: 'Restaurants',
      items: []
    })
  }
  if (roleUser === 'Admin' || roleUser === 'Staff') {
    menuItemsNew.push({
      heading: 'Management',
      items: []
    })
  }
  menuItemsNew.push({
    heading: 'Dashboards',
    items: []
  })

  if (roleUser === 'Staff') {
    const itemsNews = menuItemsNew.find(x => x.heading === 'Restaurants')
    itemsNews.items.push(
      {
        name: 'List All',
        icon: RestaurantIcon,
        link: `/${typeSidebar}/management/restaurants`
      }
    )
    const itemsNews2 = menuItemsNew.find(x => x.heading === 'Management')
    itemsNews2.items.push(
      {
        name: 'Users',
        icon: UsersIcon,
        link: `/${typeSidebar}/management/Users`
      },
      {
        name: 'Content',
        icon: iconLanguage,
        link: `/${typeSidebar}/management/content`
      },
      {
        name: 'Settings',
        icon: SettingsIcon,
        link: `/${typeSidebar}/management/settings`
      }
    )
    menuItemsNew.splice(2, 1)
  }
  if (nameRestaurant && roleUser !== 'Staff') {
    if (roleUser === 'Admin') {
      const itemsNews = menuItemsNew.find(x => x.heading === 'Restaurants')
      if (itemsNews) {
        let restaurants = []
        itemsnR.forEach((e) => {
          restaurants.push(e)
        })
        itemsNews.items.push({
          name: localStorage.getItem('restNameDisplay') ?? 'Restaurants',
          subName: 'Restaurants',
          icon: StorefrontIcon,
          link: `/${typeSidebar}/dashboards/analytics`,
          items: restaurants
        })
      }
      /* Dashboards */
      const itemsNews3 = menuItemsNew.find(x => x.heading === 'Dashboards')
      const items = [
        /* {
          name: 'Analytics',
          // icon: AnalyticsTwoToneIcon,
          link: `/${typeSidebar}/dashboards/analytics`
        }, */
        {
          name: 'Kitchen',
          link: `/${typeSidebar}/management/kitchen`
        },
        {
          name: 'Bartender',
          link: `/${typeSidebar}/management/bartender`
        },
        {
          name: 'Waiter',
          link: `/${typeSidebar}/management/waiter`
        },
        {
          name: 'Pickup',
          link: `/${typeSidebar}/management/pickup`
        },
        {
          name: 'Delivery',
          link: `/${typeSidebar}/management/delivery`
        },
        {
          name: 'Virtual Queue',
          link: `/${typeSidebar}/management/virtual-queue`
        },
        {
          name: 'Waiter orders',
          link: `/${typeSidebar}/management/waiter-order`
        },
        {
          name: 'Cash',
          // icon: MonetizationOnIcon,
          link: `/${typeSidebar}/management/cash`
        }
      ]

      if (options && options.delivery) {
        items.splice(6, 0, {
          name: 'Delivery driver',
          link: `/${typeSidebar}/management/delivery-man`
        })
      }

      if (options && options.restaurant) {
        items.push(
          {
            name: 'FeedBack',
            // icon: CommentIcon,
            link: `/${typeSidebar}/management/feedback`
          }
        )
      }
      if (options && options.callWaiter) {
        items.push(
          {
            name: 'Waiter calls',
            link: `/${typeSidebar}/management/waiter-call`
          }
        )
      }
      if (options && options.restaurant) {
        itemsNews3.items.push(
          {
            name: 'Orders',
            icon: AddShoppingCartIcon,
            link: `/${typeSidebar}/management/kitchen`,
            items,
          },
          {
            name: 'Products',
            icon: ArtTrackIcon,
            link: `/${typeSidebar}/management/categories`,
            items: [
              {
                name: 'Menu',
                link: `/${typeSidebar}/management/categories`
              }
            ]
          }
        )
      }
      if (reservationRoom) {
        // addItemNewToMenu(menuItemsNew, typeSidebar,3,'Rooms',AirlineSeatIndividualSuiteIcon,'management/rooms');
        const a = menuItemsNew.find(x => x.heading === 'Dashboards');
        a.items.splice(3, 0, {
          name: 'Rooms',
          icon: RoomsIcon,
          link: `/${typeSidebar}/management/rooms`,
          items: [
            {
              name: 'List rooms',
              link: `/${typeSidebar}/management/rooms`
            },
            {
              name: 'Enable booking dates',
              link: `/${typeSidebar}/management/calendar`
            },
            {
              name: 'Show Bookings',
              link: `/${typeSidebar}/management/ShowBookings`
            },
            {
              name: 'Find reservations',
              link: `/${typeSidebar}/management/findBooking`
            },
            /* {
              name: 'Analytics',
              link: `/${typeSidebar}/management/analyticsBooking`
            }, */
            {
              name: 'Select Days',
              link: `/${typeSidebar}/management/selectdays`
            },
          ]
        });
      }
      const dashboardsReservedRooms = menuItemsNew.find(x => x.heading === 'Dashboards');
      dashboardsReservedRooms.items.splice(4, 0, {
        name: 'Reserved rooms',
        icon: Calender,
        link: `/${typeSidebar}/dashboards/soporte`,
      });
      if (options && options.booking) {
        const a = menuItemsNew.find(x => x.heading === 'Dashboards');
        a.items.splice(2, 0, {
          name: 'Booking Table',
          icon: DiningIcon,
          link: `/${typeSidebar}/management/tableLayout`,
          items: [
            {
              name: 'Table Layout',
              link: `/${typeSidebar}/management/tableLayout`
            },
            {
              name: 'Show Bookings',
              link: `/${typeSidebar}/management/showtablesooking`
            },
            {
              name: 'Table Occupancy',
              link: `/${typeSidebar}/management/tableoccupancy`
            },
            {
              name: 'Booking Table',
              link: `/${typeSidebar}/management/bookingTable`
            },
          ]
        });
      }

      if (options && options.rentCar) {
        const a = menuItemsNew.find(x => x.heading === 'Dashboards')
        a.items.splice(5, 0, {
          name: 'Rent Car',
          icon: CarRentalIcon,
          link: `/${typeSidebar}/management/products/rent-car`,
          items: [
            {
              name: 'Cars',
              link: `/${typeSidebar}/management/products/rent-car`
            },
            {
              name: 'Bookings',
              link: `/${typeSidebar}/management/products/rent-car/booking`
            },
            /* {
              name: 'Analytics',
              link: `/${typeSidebar}/management/products/rent-car/analytics`
            } */
          ]
        })
      }
      /*
      "Statistics" 
       */
      const dashboardsStatistics = menuItemsNew.find(x => x.heading === 'Dashboards');
      dashboardsStatistics.items.splice(6, 0, {
        name: 'Statistics',
        icon: Analytics,
        link: `/${typeSidebar}/management/statistics`,
      });
      if (options && options.inventory) {
        const a = menuItemsNew.find(x => x.heading === 'Dashboards')
        const items = [
          {
            name: 'Products',
            link: `/${typeSidebar}/management/commerce/products`
          },
          /* {
            name: 'Analytics',
            link: `/${typeSidebar}/management/commerce/analytics`
          }, */
          {
            name: 'History',
            link: `/${typeSidebar}/management/commerce/history`
          }
        ]
        if (options && options.inventoryShop) {
          items.push({
            name: 'Shopping',
            link: `/${typeSidebar}/management/commerce/shop`
          })
          items.push({
            name: 'Transactions',
            link: `/${typeSidebar}/management/commerce/transation`
          })
        }
        a.items.splice(2, 0, {
          name: 'Inventory',
          icon: InventoryIcon,
          link: `/${typeSidebar}/management/commerce/products`,
          items,
        })
      }
      if (options && options.chatbot) {
        const a = menuItemsNew.find(x => x.heading === 'Dashboards')
        a.items.splice(3, 0, {
          name: 'On-line Chat',
          icon: ChatIcon,
          link: `/${typeSidebar}/applications/messenger`,
        })
      }
      if (options && options.expenses) {
        const a = menuItemsNew.find(x => x.heading === 'Dashboards')
        const items = [
          {
            name: 'Expenses',
            link: `/${typeSidebar}/dashboards/Accounting/Expenses`
          },
          /* {
            name: 'Analytics',
            link: `/${typeSidebar}/dashboards/Accounting/Analytics`
          }, */
          {
            name: 'Budget',
            link: `/${typeSidebar}/dashboards/Accounting/Budget`
          }
        ]
        a.items.splice(4, 0, {
          name: 'Accounting',
          icon: AccountingIcon,
          link: `/${typeSidebar}/dashboards/Accounting/Budget`,
          items,
        })
      }
      if (options && options.bookingOfServices) {
        const a = menuItemsNew.find(x => x.heading === 'Dashboards')
        const items = [
          {
            name: 'Services',
            link: `/${typeSidebar}/dashboards/services/services`
          },
          {
            name: 'Personal',
            link: `/${typeSidebar}/dashboards/services/personal`
          },
          {
            name: 'Calendar personal',
            link: `/${typeSidebar}/dashboards/services/calendarPersonal`
          },
          {
            name: 'Calendar services',
            link: `/${typeSidebar}/dashboards/services/calendarService`
          },
          /* {
            name: 'Analytics',
            link: `/${typeSidebar}/dashboards/services/analytics`
          } */
        ]
        a.items.splice(6, 0, {
          name: 'Services',
          icon: DesignServicesIcon,
          link: `/${typeSidebar}/dashboards/services/services`,
          items,
        })
      }
      /* Management */
      const itemsNews2 = menuItemsNew.find(x => x.heading === 'Management')
      itemsNews2.items.push(
        {
          name: 'Users',
          icon: UsersIcon,
          link: `/${typeSidebar}/management/Users`,
          items: [
            {
              name: 'List All',
              link: `/${typeSidebar}/management/Users`
            },
            {
              name: 'User Profile',
              link: `/${typeSidebar}/management/user/${localStorage.getItem(
                'uidEmail'
              )}/`
            }
          ]
        },
        {
          name: 'Transactions',
          icon: HistoryIcon,
          link: `/${typeSidebar}/management/transactions`,
          items: [
            {
              name: 'Services',
              link: `/${typeSidebar}/management/transactions`
            },
            {
              name: 'Invoices',
              link: `/${typeSidebar}/management/transactions/${localStorage.getItem('restName')}`
            }
          ]
        }
      )
      const customer = menuItemsNew.find(x => x.heading === 'Management')
      customer.items.splice(6, 0, {
        name: 'Customers',
        icon: CustomersIcon,
        // link: `/${typeSidebar}/management/settings/${localStorage.getItem('restName')}`,
        items: [
          {
            name: 'Report',
            link: `/${typeSidebar}/management/customers/report`
          }
        ]
      })
      if (options && options.webPages) {
        const a = menuItemsNew.find(x => x.heading === 'Management')
        a.items.splice(5, 0, {
          name: 'Pages',
          icon: PagesIcon,
          link: `/${typeSidebar}/management/dinamicPagesAndMenu`,
        })
      }
      const Discountt = menuItemsNew.find(x => x.heading === 'Management');
      Discountt.items.splice(5, 0, {
        name: 'Discounts',
        icon: Discount,
        link: `/`,
      });
      /* Discount */
      const dashboardsMenu = menuItemsNew.find(x => x.heading === 'Management');
      dashboardsMenu.items.splice(7, 0, {
        name: 'Soporte',
        icon: Support,
        link: `/${typeSidebar}/dashboards/soporte`,
      });
      const newSectionSettingApp = menuItemsNew.find(x => x.heading === 'Management')
      newSectionSettingApp.items.splice(7, 0, {
        name: 'App settings',
        icon: SettingsIcon,
        link: `/${typeSidebar}/management/settings/${localStorage.getItem('restName')}`,
        items: [
          {
            name: 'Settings',
            link: `/${typeSidebar}/management/settings/${localStorage.getItem('restName')}`
          },
          {
            name: 'Colors',
            link: `/${typeSidebar}/management/colors`
          },
          {
            name: 'Content',
            link: `/${typeSidebar}/management/content/${localStorage.getItem('restName')}`
          }
        ]
      })
      if (options && options.restaurant) {
        const index = newSectionSettingApp.items.findIndex((item: any) => item.name === 'App settings')
        newSectionSettingApp.items[index].items.push(
          {
            name: 'Discounts',
            // icon: LocalOfferIcon,
            link: `/${typeSidebar}/management/discounts/${localStorage.getItem('restName')}`
          }
        )
      } else {
        if (options && !options.bookingOfServices) {
          const index = newSectionSettingApp.items.findIndex((item: any) => item.name === 'App settings')
          newSectionSettingApp.items[index].items.push(
            {
              name: 'Discounts',
              // icon: LocalOfferIcon,
              link: `/${typeSidebar}/management/discounts/${localStorage.getItem('restName')}`
            }
          )
        }
      }
      if (options && options.delivery) {
        const index = newSectionSettingApp.items.findIndex((item: any) => item.name === 'App settings')
        newSectionSettingApp.items[index].items.push(
          {
            name: 'Delivery zones',
            // icon: LocalOfferIcon,
            link: `/${typeSidebar}/management/deliveryZone/${Buffer.from(localStorage.getItem('restName')).toString('base64')}`
          }
        )
      }
    } else {
      const itemsNews = menuItemsNew.find(x => x.heading === 'Restaurants')
      if (itemsNews) {
        let restaurants = []
        itemsnR.forEach((e) => {
          restaurants.push(e)
        })
        itemsNews.items.push({
          name: localStorage.getItem('restNameDisplay') ?? 'Restaurants',
          subName: 'Restaurants',
          icon: StorefrontIcon,
          link: `/${typeSidebar}/dashboards/analytics`,
          items: restaurants
        })
      }
      //menuItemsNew.splice(1,1)
      const itemsDashboards = menuItemsNew.find(x => x.heading === 'Dashboards')
      switch (roleUser) {
        case 'Kitchen':
          itemsDashboards.items.push(
            {
              name: 'Orders',
              icon: AddShoppingCartIcon,
              link: `/${typeSidebar}/management/kitchen`,
              items: [
                {
                  name: 'Kitchen',
                  link: `/${typeSidebar}/management/kitchen`
                },
                {
                  name: 'Bartender',
                  link: `/${typeSidebar}/management/bartender`
                }
              ]
            }
          )
          break;
        case 'Delivery':
          itemsDashboards.items.push(
            {
              name: 'Orders',
              icon: AddShoppingCartIcon,
              link: `/${typeSidebar}/management/kitchen`,
              items: [
                {
                  name: 'Delivery',
                  link: `/${typeSidebar}/management/delivery`
                }
              ]
            }
          )
          break;
        case 'Cashier':
          const items = [
            {
              name: 'Kitchen',
              link: `/${typeSidebar}/management/kitchen`
            },
            {
              name: 'Bartender',
              link: `/${typeSidebar}/management/bartender`
            },
            {
              name: 'Waiter',
              link: `/${typeSidebar}/management/waiter`
            },
            {
              name: 'Pickup',
              link: `/${typeSidebar}/management/pickup`
            },
            {
              name: 'Delivery',
              link: `/${typeSidebar}/management/delivery`
            },
            {
              name: 'Virtual Queue',
              link: `/${typeSidebar}/management/virtual-queue`
            },
            {
              name: 'Waiter orders',
              link: `/${typeSidebar}/management/waiter-order`
            }
          ]
          if (options && options.callWaiter) {
            items.push(
              {
                name: 'Waiter calls',
                link: `/${typeSidebar}/management/waiter-call`
              }
            )
          }
          itemsDashboards.items.push(
            {
              name: 'Orders',
              icon: AddShoppingCartIcon,
              link: `/${typeSidebar}/management/kitchen`,
              items,
            },
            {
              name: 'Cash',
              icon: MonetizationOnIcon,
              link: `/${typeSidebar}/management/cash`
            }
          )
          if (options && options.inventory && options.inventoryShop) {
            itemsDashboards.items.push({
              name: 'Inventory',
              icon: InventoryIcon,
              link: `/${typeSidebar}/management/commerce/products`,
              items: [
                {
                  name: 'Shopping',
                  link: `/${typeSidebar}/management/commerce/shop`
                }
              ]
            })
          }
          break;
        case 'Waiter':
          itemsDashboards.items.push(
            {
              name: 'Orders',
              icon: AddShoppingCartIcon,
              link: `/${typeSidebar}/management/kitchen`,
              items: [
                {
                  name: 'Waiter',
                  link: `/${typeSidebar}/management/waiter`
                }
              ]
            }
          )
          break;
        case 'Adviser':
          itemsDashboards.items.push(
            {
              name: 'Services',
              icon: DesignServicesIcon,
              link: `/${typeSidebar}/dashboards/services`,
              items: [
                {
                  name: 'Calendar services',
                  link: `/${typeSidebar}/dashboards/services/calendarService`
                },
              ]
            }
          )
          break;
      }
    }
  }

  return menuItemsNew;
}
// eslint-disable-next-line
function addItemNewToMenu(menuItemsNew: any[], typeSidebar: string, position: number, name: string, icon: any, link: string) {
  /**Funcion que permite agregar segun condicion elementos a el menu
   * arguments : menuItemsNew,typeSidebar,
   * position: en donde se va a gregar,
   * name,icon y link
   */
  const a = menuItemsNew.find(x => x.heading === 'Dashboards');
  a.items.splice(position, 0, {
    name,
    icon,
    link: `/${typeSidebar}/${link}`
  });
}
