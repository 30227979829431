import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';

import Guest from 'src/components/Guest';
import Authenticated from 'src/components/Authenticated';

import BoxedSidebarLayout from 'src/layouts/BoxedSidebarLayout';
import DocsLayout from './layouts/DocsLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import AccentHeaderLayout from 'src/layouts/AccentHeaderLayout';
import AccentSidebarLayout from 'src/layouts/AccentSidebarLayout';
import CollapsedSidebarLayout from 'src/layouts/CollapsedSidebarLayout';
import BottomNavigationLayout from 'src/layouts/BottomNavigationLayout';
import TopNavigationLayout from 'src/layouts/TopNavigationLayout';
import { Outlet } from 'react-router-dom';

import SuspenseLoader from 'src/components/SuspenseLoader';

const Loader = (Component) => (props) => (
  <Suspense fallback={<SuspenseLoader />}>
    <Component {...props} />
  </Suspense>
);

// Pages

// const Overview = Loader(lazy(() => import('src/content/overview')));

const Loading = Loader(lazy(() => import('src/components/Loading')))

// Dashboards

const Prices = Loader(lazy(()=> import('src/components/Prices/index')))
const SuccessfulPrice = Loader(lazy(()=> import('./components/Prices/Payment/SuccessfulPrice')))
const DeclinedPrice = Loader(lazy(()=> import('./components/Prices/Payment/DeclinedPrice')))
const Automation = Loader(lazy(() => import('src/content/dashboards/Automation')));
const Analytics = Loader(lazy(() => import('src/content/dashboards/Analytics/')));
const Banking = Loader(lazy(() => import('src/content/dashboards/Banking')));
const Commerce = Loader(lazy(() => import('src/content/dashboards/Commerce')));
const Crypto = Loader(lazy(() => import('src/content/dashboards/Crypto')));
const Finance = Loader(lazy(() => import('src/content/dashboards/Finance')));
const Fitness = Loader(lazy(() => import('src/content/dashboards/Fitness')));
const HealthcareDoctor = Loader(lazy(() => import('src/content/dashboards/Healthcare')));
const HealthcareHospital = Loader(lazy(() => import('src/content/dashboards/Healthcare/HospitalView')));
const Helpdesk = Loader(lazy(() => import('src/content/dashboards/Helpdesk')));
const Learning = Loader(lazy(() => import('src/content/dashboards/Learning')));
const Monitoring = Loader(lazy(() => import('src/content/dashboards/Monitoring')));
const Tasks = Loader(lazy(() => import('src/content/dashboards/Tasks')));
const Budget = Loader(lazy(() => import('src/content/dashboards/Accounting/Budget')))
const CreateBudget = Loader(lazy(() => import('src/content/dashboards/Accounting/Budget/createBudget/index')))
const AnalyticsExpenses = Loader(lazy(() => import('src/content/dashboards/Accounting/Analytics')))
const Expenses = Loader(lazy(() => import('src/content/dashboards/Accounting/Expenses')))
const CreateExpence = Loader(lazy(() => import('src/content/dashboards/Accounting/Expenses/createExpence')))
const Services = Loader(lazy(() => import('src/content/dashboards/Services/Services/Index')))
const Personal = Loader(lazy(() => import('src/content/dashboards/Services/Personal/Index')))
const AnalyticsServices = Loader(lazy(() => import('src/content/dashboards/Services/Analytics/Index')))
const CalendarServices = Loader(lazy(() => import('src/content/dashboards/Services/Calendars/Services/Index')))
const CalendarPersonal = Loader(lazy(() => import('src/content/dashboards/Services/Calendars/Personal/Index')))

// Applications

const FileManager = Loader(lazy(() => import('src/content/applications/FileManager')));
const Messenger = Loader(lazy(() => import('src/content/applications/Messenger')));
const Support = Loader(lazy(() => import('src/content/applications/Support')));
const Statistics = Loader(lazy(() => import('src/content/applications/Statistics')))
const Calendar = Loader(lazy(() => import('src/content/applications/Calendar')));
const JobsPlatform = Loader(lazy(() => import('src/content/applications/JobsPlatform')));
const ProjectsBoard = Loader(lazy(() => import('src/content/applications/ProjectsBoard')));
const Mailbox = Loader(lazy(() => import('src/content/applications/Mailbox')));

// Management

const Restaurants = Loader(lazy(() => import('src/content/management/Restaurants')));
const Settings = Loader(lazy(() => import('src/content/management/Settings')));
const Users = Loader(lazy(() => import('src/content/management/Users')));
const SingleUser = Loader(lazy(() => import('src/content/management/Users/single')));
const Discounts = Loader(lazy(() => import('src/content/management/Discounts/index')));
const EditDiscounts = Loader(lazy(() => import('src/content/management/Discounts/edit/')));
const Cash = Loader(lazy(() => import('src/content/management/Cash/index')));
const DetailsPayment = Loader(lazy(() => import('src/content/management/Cash/detailsPayment/DetailsPayment')));
const DeliveryZone = Loader(lazy(() => import('src/content/management/DeliveryZone/index')));

const ProjectsColors = Loader(lazy(() => import('src/content/management/Projects/ColorsIndex')));
const Kitchen = Loader(lazy(() => import('src/content/management/Projects/kitchen')));
const Bartender = Loader(lazy(() => import('src/content/management/Projects/bartender')))
const Waiter = Loader(lazy(() => import('src/content/management/Projects/waiter')))
const Pickup = Loader(lazy(() => import('src/content/management/Projects/pickup')))
const Delivery = Loader(lazy(() => import('src/content/management/Projects/delivery')))
const WaiterOrder = Loader(lazy(() => import('src/content/management/Projects/waiterOrder')));
const DeliveryMan = Loader(lazy(() => import('src/content/management/Projects/deliveryMan')))

const Categories = Loader(lazy(() => import('src/content/management/categories')))
const RentCar = Loader(lazy(() => import('src/content/management/RentCar')))
const RentCarNew = Loader(lazy(() => import('src/content/management/RentCar/new')))
const RentCarBooking = Loader(lazy(() => import('src/content/management/RentCar/Booking')))
const RentCarBookingDetails = Loader(lazy(() => import('src/content/management/RentCar/Booking/Details')))
const RentCarAnalytics = Loader(lazy(() => import('src/content/management/RentCar/Analytics/Index')))
const ListProducts = Loader(lazy(() => import('src/content/management/ListProducts')))
const ListRooms = Loader(lazy(() => import('src/content/management/ListRooms')))
const AddProductsTocategory = Loader(lazy(() => import('src/content/management/Products/createProduct')))
const Transactions = Loader(lazy(() => import('src/content/management/Invoices')))
const Invoices = Loader(lazy(() => import('src/content/management/Invoices')));
const SingleInvoice = Loader(lazy(() => import('src/content/management/Invoices/single')));

const Products = Loader(lazy(() => import('src/content/management/Products')));
const CreateProduct = Loader(lazy(() => import('src/content/management/Products/createCopy')));
const SingleProduct = Loader(lazy(() => import('src/content/management/Products/single')));
const Shop = Loader(lazy(() => import('src/content/management/Products/shop')));
const History = Loader(lazy(() => import('src/content/management/Products/history')));
const Transation = Loader(lazy(() => import('src/content/management/Products/transation')));
const ProductsInvoice = Loader(lazy(() => import('src/content/management/Products/invoice')));
const DownloadPDF = Loader(lazy(() => import('src/content/management/Products/DownloadPDF')));
const AnalyticsInvetory = Loader(lazy(() => import('src/content/management/Products/analytics')));
const DinamicPages = Loader(lazy(() => import('src/content/management/DinamycPages')))
const DinamicPagesEdit = Loader(lazy(() => import('src/content/management/DinamycPages/edit/EditPages')))

const Content = Loader(lazy(() => import('src/content/management/Language')));
const VirtualQueue = Loader(lazy(() => import('src/content/management/VirtualQueue')));

const CustomersReport = Loader(lazy(() => import('src/content/management/Customers/Report')))
// const DetailsPdf = Loader(lazy(() => import('src/content/management/Customers/Report/components/DetailsPdf')))

//ROOMS AND BOOKING ROOM
const Rooms = Loader(lazy(() => import('src/content/management/Rooms')));
const AddRooms = Loader(lazy(() => import('src/content/management/Rooms/CreateRooms')))
const CalendarSchedule = Loader(lazy(() => import('src/content/management/CalendarSchedule')));
const Bookings = Loader(lazy(() => import('src/content/management/Bookings')));
const DetailsBooking = Loader(lazy(() => import('src/content/management/Bookings/DetailsBooking')))
const FindBooking = Loader(lazy(() => import('src/content/management/Bookings/FindBookings')))
const AnalyticsBooking = Loader(lazy(() => import('src/content/management/Bookings/Analytics')));
const SelectDays = Loader(lazy(() => import('src/content/management/Bookings/SelecDays/SelectDays')));

//TABLES BOOKING
const TableLayout = Loader(lazy(() => import('src/content/management/TableReservation/TableDistribution')));
const ShowTablesBooking = Loader(lazy(() => import('src/content/management/TableReservation/TableDistribution/ShowBooking')));
const DetailsBookingTable = Loader(lazy(() => import('src/content/management/TableReservation/TableDistribution/DetailsReservation')))
const TableOccupancy = Loader(lazy(() => import('src/content/management/TableReservation/TableDistribution/TableOccupancy')))
const BookingTable = Loader(lazy(() => import('src/content/management/TableReservation/TableDistribution/BookingTable')))
const FeedBack = Loader(lazy(() => import('src/content/management/FeedBack')));

// Docs

const Introduction = Loader(lazy(() => import('src/content/docs/Introduction')));
const Authentication = Loader(lazy(() => import('src/content/docs/Authentication')));
const Changelog = Loader(lazy(() => import('src/content/docs/Changelog')));
const ContactSupport = Loader(lazy(() => import('src/content/docs/ContactSupport')));
const Dependencies = Loader(lazy(() => import('src/content/docs/Dependencies')));
const Installation = Loader(lazy(() => import('src/content/docs/Installation')));
const Internationalization = Loader(lazy(() => import('src/content/docs/Internationalization')));
const Routing = Loader(lazy(() => import('src/content/docs/Routing')));
const RtlLayout = Loader(lazy(() => import('src/content/docs/RtlLayout')));
const ServerRequests = Loader(lazy(() => import('src/content/docs/ServerRequests')));
const ThemesCustomization = Loader(lazy(() => import('src/content/docs/ThemesCustomization')));

// Auth

const LoginCover = Loader(lazy(() => import('src/content/pages/Auth/Login/Cover')));
const Logout = Loader(lazy(() => import('src/content/pages/Auth/Logout')));

const LoginBasic = Loader(lazy(() => import('src/content/pages/Auth/Login/Basic')));

const RegisterBasic = Loader(lazy(() => import('src/content/pages/Auth/Register/Basic')));

const RegisterWizard = Loader(lazy(() => import('src/content/pages/Auth/Register/Wizard')));

const Register = Loader(lazy(() => import('src/content/pages/Auth/Register')))

const RecoverPassword = Loader(lazy(() => import('src/content/pages/Auth/RecoverPassword')));

const ResetPassword = Loader(lazy(() => import('src/content/pages/Auth/ResetPassword')));

// Status

const UpgradePlan = Loader(lazy(() => import('src/content/pages/Status/UpgradePlan')))
const Status404 = Loader(lazy(() => import('src/content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('src/content/pages/Status/Status500')));
const StatusComingSoon = Loader(lazy(() => import('src/content/pages/Status/ComingSoon')));
const StatusMaintenance = Loader(lazy(() => import('src/content/pages/Status/Maintenance')));

const SuccessFUllStripe = Loader(lazy(() => import('src/Stripe/Successful')));

const managementChildren = [

  {
    path: 'settings',
    element: <Settings />
  },
  {
    path: 'settings',
    children: [
      {
        path: ":restaurantName",
        element: <Settings />
      }
    ]
  },
  {
    path: 'restaurants',
    element: <Restaurants />
  },
  {
    path: '/',
    element: (
      <Navigate
        to="/management/Users/"
        replace
      />
    )
  },
  {
    path: 'Users',
    element: <Users />
  },
  {
    path: 'Users',
    children: [
      {
        path: ':userId',
        element: <SingleUser />
      },
      {
        path: 'admin/:restaurantName',
        element: <Users />
      },
      {
        path: 'admin/:restaurantName/:userId',
        element: <SingleUser />
      },
    ]
  },
  {
    path: 'user',
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/management/user/"
            replace
          />
        )
      },
      {
        path: ':userId',
        element: <SingleUser />
      },
    ]
  },
  {
    path: 'kitchen',
    element: <Kitchen />
  },
  /* Statistics */
  {
    path: 'statistics',
    element: <Statistics />
  },
  {
    path: 'bartender',
    element: <Bartender />
  },
  {
    path: 'waiter',
    element: <Waiter />
  },
  {
    path: 'detailsBooking',
    element: <DetailsBooking />
  },
  {
    path: 'findBooking',
    element: <FindBooking />
  },
  {
    path: 'analyticsBooking',
    element: <AnalyticsBooking />
  },
  {
    path: 'detailsBookingTable',
    element: <DetailsBookingTable />
  },
  {
    path: 'tableoccupancy',
    element: <TableOccupancy />
  },
  {
    path: 'bookingTable',
    element: <BookingTable />
  },
  {
    path: 'selectdays',
    element: <SelectDays />
  },
  {
    path: 'tableLayout',
    element: <TableLayout />
  },
  {
    path: 'showtablesooking',
    element: <ShowTablesBooking />
  },
  {
    path: 'Categories',
    element: <Categories />
  },
  {
    path: 'products/rent-car',
    children: [
      {
        path: "/",
        element: <RentCar />
      },
      {
        path: "new",
        element: <RentCarNew />
      },
      {
        path: "edit/:idCurrent",
        element: <RentCarNew />
      },
      {
        path: "analytics",
        element: <RentCarAnalytics />
      },
      {
        path: "booking",
        children: [
          {
            path: "/",
            element: <RentCarBooking />
          },
          {
            path: ":idBooking",
            element: <RentCarBookingDetails />
          },
        ]
      }
    ]
  },
  {
    path: 'pickup',
    element: <Pickup />
  },
  {
    path: 'delivery',
    element: <Delivery />
  },
  {
    path: 'delivery-man',
    element: <DeliveryMan />
  },
  {
    path: 'colors',
    element: <ProjectsColors />
  },
  {
    path: 'listProducts',
    children: [
      {
        path: ":Category",
        element: <ListProducts />
      }
    ]
  },
  {
    path: 'listRooms',
    children: [
      {
        path: ":Category",
        element: <ListRooms />
      }
    ]
  },
  {
    path: 'createProduct',
    children: [
      {
        path: ":Category",
        element: <AddProductsTocategory />
      }
    ]
  },
  {
    path: 'createRoom',
    children: [
      {
        path: ":Category",
        element: <AddRooms />
      }
    ]
  },
  {
    path: 'transactions',
    element: <Transactions />

  },
  {
    path: 'transactions',
    children: [
      {
        path: ":restPos",
        element: <Transactions />
      }
    ]
  },
  {
    path: 'content',
    element: <Content />
  },
  {
    path: 'content',
    children: [
      {
        path: ":restaurantName",
        element: <Content />
      }
    ]
  },
  {
    path: 'virtual-queue',
    element: <VirtualQueue />
  },
  {
    path: 'waiter-order',
    element: <WaiterOrder />
  },
  {
    path: 'waiter-call',
    element: <Messenger />
  },
  {
    path: 'discounts',
    element: <Discounts />
  },
  {
    path: 'discounts',
    children: [
      {
        path: ":restaurantName",
        element: <Discounts />
      },
      {
        path: "edit/:typeD/:discountId",
        element: <EditDiscounts />
      }
    ]
  },
  {
    path: 'deliveryZone',
    children: [
      {
        path: ":restaurantName",
        element: <DeliveryZone />
      }
    ]
  },
  {
    path: 'cash',
    element: <Cash />
  },
  {
    path: 'cash',
    children: [
      {
        path: ":id",
        element: <DetailsPayment />
      }
    ]
  },
  {
    path: 'commerce',
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/management/commerce/shop"
            replace
          />
        )
      },
      {
        path: 'shop',
        element: <Shop />
      },
      {
        path: 'products',
        element: <Products />
      },
      {
        path: 'analytics',
        element: <AnalyticsInvetory />
      },
      {
        path: 'transation',
        children: [
          {
            path: "/",
            element: <Transation />
          },
          {
            path: ":id",
            element: <ProductsInvoice />
          }
        ]
      },
      {
        path: 'history',
        children: [
          {
            path: "/",
            element: <History />
          },
          {
            path: ":id",
            element: <ProductsInvoice />
          }
        ]
      },
      {
        path: 'product',
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="/management/commerce/product/1"
                replace
              />
            )
          },
          {
            path: 'create',
            element: <CreateProduct />
          },
          {
            path: ':productId',
            element: <SingleProduct />
          },
        ]
      },
      {
        path: 'pdf',
        children: [
          {
            path: "/",
            element: <DownloadPDF />
          },
          {
            path: ":collection",
            element: <DownloadPDF />
          }
        ]
      }
    ]
  },
  {
    path: 'rooms',
    element: <Rooms />
  },
  {
    path: 'calendar',
    element: <CalendarSchedule />
  },
  {
    path: 'ShowBookings',
    element: <Bookings />
  },
  {
    path: 'invoices',
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/management/invoices/list"
            replace
          />
        )
      },
      {
        path: 'list',
        element: <Invoices />
      },
    ]
  },
  {
    path: 'invoicePos',
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/management/invoicePos/1"
            replace
          />
        )
      },
      {
        path: ':invoiceIdPos',
        element: <SingleInvoice />
      }
    ]
  },
  {
    path: 'invoice',
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/management/invoice/1"
            replace
          />
        )
      },
      {
        path: ':invoiceId',
        element: <SingleInvoice />
      },
      {
        path: ':invoiceId/:phone',
        element: <SingleInvoice />
      },
      {
        path: ':invoiceId/:phone/:dateIncial/:dateFinal',
        element: <SingleInvoice />
      },
    ]
  },
  {
    path: 'dinamicPagesAndMenu',
    children: [
      {
        path: "/",
        element: <DinamicPages />
      },
      {
        path: ":id",
        element: <DinamicPagesEdit />
      }
    ]
  },
  {
    path: 'feedback',
    element: <FeedBack />
  },
  {
    path: 'customers',
    children: [
      {
        path: "/report",
        element: <CustomersReport />
      }
    ]
  }
]

const applicationsChildren = [
  {
    path: '/',
    element: (
      <Navigate
        to="calendar"
        replace
      />
    )
  },
  {
    path: 'calendar',
    element: <Calendar />
  },
  {
    path: 'file-manager',
    element: <FileManager />
  },
  {
    path: 'jobs-platform',
    element: <JobsPlatform />
  },
  {
    path: 'projects-board',
    element: <ProjectsBoard />
  },
  {
    path: 'messenger',
    element: <Messenger />
  },
  {
    path: 'mailbox',
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/applications/mailbox/inbox"
            replace
          />
        )
      },
      {
        path: 'tag/:labelTag',
        element: <Mailbox />
      },
      {
        path: 'tag/:labelTag/:mailboxCategory',
        element: <Mailbox />
      },
      {
        path: ':categoryTag',
        element: <Mailbox />
      },
      {
        path: ':categoryTag/:mailboxCategory',
        element: <Mailbox />
      }
    ]
  },
]

const dashboardsChildren = [
  {
    path: '/',
    element: (
      <Navigate
        to={`analytics`}
        replace
      />
    )
  },
  {
    path: '/home',
    element: <Statistics/>
  },
  {
    path: '/plan',
    element: <UpgradePlan/>
  },
  {
    path: '/prices',
    element: <Prices/>
      
  },
  {
    path: '/successful-price-stripe/:CHECKOUT_SESSION_ID',
    element: <SuccessfulPrice/>
  },
  {
    path: '/declined-price-stripe',
    element: <DeclinedPrice/>
  },
  {
    path: 'soporte',
    element: <Support />
  },
  {
    path: 'automation',
    element: <Automation />
  },
  {
    path: 'analytics',
    element: <Analytics />
  },
  {
    path: 'banking',
    element: <Banking />
  },
  {
    path: 'commerce',
    element: <Commerce />
  },
  {
    path: 'crypto',
    element: <Crypto />
  },
  {
    path: 'finance',
    element: <Finance />
  },
  {
    path: 'fitness',
    element: <Fitness />
  },
  {
    path: 'healthcare',
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="hospital"
            replace
          />
        )
      },
      {
        path: 'hospital',
        element: <HealthcareHospital />
      },
      {
        path: 'doctor',
        element: <HealthcareDoctor />
      },
    ]
  },
  {
    path: 'helpdesk',
    element: <Helpdesk />
  },
  {
    path: 'learning',
    element: <Learning />
  },
  {
    path: 'monitoring',
    element: <Monitoring />
  },
  {
    path: 'tasks',
    element: <Tasks />
  },
  {
    path: 'Accounting',
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/dashboards/accounting/budget"
            replace
          />
        )
      },
      {
        path: 'budget',
        children: [
          {
            path: "/",
            element: <Budget />
          },
          {
            path: "create",
            element: <CreateBudget />
          }
        ]
      },
      {
        path: 'analytics',
        element: <AnalyticsExpenses />
      },
      {
        path: 'expenses',
        children: [
          {
            path: "/",
            element: <Expenses />
          },
          {
            path: "create",
            element: <CreateExpence />
          }
        ]
      },
    ]
  },
  {
    path: 'Services',
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/dashboards/services/services"
            replace
          />
        )
      },
      {
        path: 'services',
        element: <Services />
      },
      {
        path: 'analytics',
        element: <AnalyticsServices />
      },
      {
        path: 'personal',
        element: <Personal />
      },
      {
        path: 'calendarService',
        element: <CalendarServices />
      },
      {
        path: 'calendarPersonal',
        element: <CalendarPersonal />
      },
    ]
  },
]

const routes: PartialRouteObject[] = [
  {
    path: 'account',
    children: [
      {
        path: 'login',
        element: (
          <Guest>
            <LoginCover />
          </Guest>
        )
      },
      {
        path: 'login-basic',
        element: <LoginBasic />
      },
      {
        path: 'login-cover',
        element: <LoginCover />
      },
      {
        path: 'recover-password',
        element: <RecoverPassword />
      },
      {
        path: 'change-password',
        element: <ResetPassword />
      },
      {
        path: 'logout',
        element: <Logout />
      },
      {
        path: 'register-basic',
        element: <RegisterBasic />
      },
      {
        path: 'register-wizard',
        element: <RegisterWizard />
      },
    ]
  },
  {
    path: '*',
    element: <BaseLayout />,
    children: [
      {
        path: '/',
        element: (
          <Authenticated>
            <Outlet />
          </Authenticated>
        ),
        children: [
          {
            path: '/',
            element: <Loading />
          }
        ]
      },
      {
        path: 'overview',
        element: (
          <Guest>
            <LoginCover />
          </Guest>
        )
      },
      {
        path: 'register',
        element: (
          <Guest>
            <Register />
          </Guest>
        )
      },
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: (
              <Navigate
                to="404"
                replace
              />
            )
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          },
          {
            path: 'coming-soon',
            element: <StatusComingSoon />
          },
        ]
      },
      {
        path: '*',
        element: <Status404 />
      },
    ]
  },
  {
    path: 'docs',
    element: (
      <DocsLayout />
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to="/docs/introduction"
            replace
          />
        )
      },
      {
        path: 'introduction',
        element: <Introduction />
      },

      {
        path: 'authentication',
        element: <Authentication />
      },
      {
        path: 'changelog',
        element: <Changelog />
      },
      {
        path: 'contact-support',
        element: <ContactSupport />
      },
      {
        path: 'dependencies',
        element: <Dependencies />
      },
      {
        path: 'installation',
        element: <Installation />
      },
      {
        path: 'internationalization',
        element: <Internationalization />
      },
      {
        path: 'routing',
        element: <Routing />
      },
      {
        path: 'rtl-layout',
        element: <RtlLayout />
      },
      {
        path: 'server-requests',
        element: <ServerRequests />
      },
      {
        path: 'themes-customization',
        element: <ThemesCustomization />
      }
    ]
  },
  // Boxed Sidebar
  {
    path: '/boxed-sidebar/loading',
    element: (
      <Authenticated>
        <BoxedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Loading />
      }
    ]
  },
  {
    path: 'boxed-sidebar/dashboards',
    element: (
      <Authenticated>
        <BoxedSidebarLayout />
      </Authenticated>
    ),
    children: dashboardsChildren
  },
  {
    path: '/boxed-sidebar/management',
    element: (
      <Authenticated>
        <BoxedSidebarLayout />
      </Authenticated>
    ),
    children: managementChildren
  },
  {
    path: '/boxed-sidebar/applications',
    element: (
      <Authenticated>
        <BoxedSidebarLayout />
      </Authenticated>
    ),
    children: applicationsChildren
  },
  {
    path: '/boxed-sidebar/successful-stripe',
    element: (
      <Authenticated>
        <BoxedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: ":session_id",
        element: <SuccessFUllStripe />
      }
    ]
  },
  {
    path: '/boxed-sidebar/un-successful-stripe',
    element: (
      <Authenticated>
        <BoxedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to={`/boxed-sidebar/dashboards/analytics`}
            replace
          />
        )
      },
    ]
  },
  {
    path: '/boxed-sidebar/un-successful-stripe-services',
    element: (
      <Authenticated>
        <BoxedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to={`/boxed-sidebar/dashboards/services/analytics`}
            replace
          />
        )
      },
    ]
  },
  // Accent Sidebar 
  {
    path: '/accent-sidebar/loading',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Loading />
      }
    ]
  },
  {
    path: '/accent-sidebar/dashboards',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: dashboardsChildren
  },
  {
    path: '/accent-sidebar/management',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: managementChildren
  },
  {
    path: '/accent-sidebar/applications',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: applicationsChildren
  },
  {
    path: '/accent-sidebar/successful-stripe',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: ":session_id",
        element: <SuccessFUllStripe />
      }
    ]
  },
  {
    path: '/accent-sidebar/un-successful-stripe',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to={`/accent-sidebar/dashboards/analytics`}
            replace
          />
        )
      },
    ]
  },
  {
    path: '/accent-sidebar/un-successful-stripe-services',
    element: (
      <Authenticated>
        <AccentSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to={`/accent-sidebar/dashboards/services/analytics`}
            replace
          />
        )
      },
    ]
  },
  // Collapsed Sidebar
  {
    path: '/collapsed-sidebar/loading',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Loading />
      }
    ]
  },
  {
    path: 'collapsed-sidebar/dashboards',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: dashboardsChildren
  },
  {
    path: '/collapsed-sidebar/management',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: managementChildren
  },
  {
    path: '/collapsed-sidebar/applications',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: applicationsChildren
  },
  {
    path: '/collapsed-sidebar/successful-stripe',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: ":session_id",
        element: <SuccessFUllStripe />
      }
    ]
  },
  {
    path: '/collapsed-sidebar/un-successful-stripe',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to={`/collapsed-sidebar/dashboards/analytics`}
            replace
          />
        )
      },
    ]
  },
  {
    path: '/collapsed-sidebar/un-successful-stripe-services',
    element: (
      <Authenticated>
        <CollapsedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to={`/collapsed-sidebar/dashboards/services/analytics`}
            replace
          />
        )
      },
    ]
  },
  // Bottom Navigation
  {
    path: '/bottom-navigation/loading',
    element: (
      <Authenticated>
        <BottomNavigationLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Loading />
      }
    ]
  },
  {
    path: 'bottom-navigation/dashboards',
    element: (
      <Authenticated>
        <BottomNavigationLayout />
      </Authenticated>
    ),
    children: dashboardsChildren
  },
  {
    path: '/bottom-navigation/management',
    element: (
      <Authenticated>
        <BottomNavigationLayout />
      </Authenticated>
    ),
    children: managementChildren
  },
  {
    path: '/bottom-navigation/applications',
    element: (
      <Authenticated>
        <BottomNavigationLayout />
      </Authenticated>
    ),
    children: applicationsChildren
  },
  {
    path: '/bottom-navigation/successful-stripe',
    element: (
      <Authenticated>
        <BottomNavigationLayout />
      </Authenticated>
    ),
    children: [
      {
        path: ":session_id",
        element: <SuccessFUllStripe />
      }
    ]
  },
  {
    path: '/bottom-navigation/un-successful-stripe',
    element: (
      <Authenticated>
        <BottomNavigationLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to={`/bottom-navigation/dashboards/analytics`}
            replace
          />
        )
      },
    ]
  },
  {
    path: '/bottom-navigation/un-successful-stripe-services',
    element: (
      <Authenticated>
        <BottomNavigationLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to={`/bottom-navigation/dashboards/services/analytics`}
            replace
          />
        )
      },
    ]
  },
  // Top Navigation
  {
    path: '/top-navigation/loading',
    element: (
      <Authenticated>
        <TopNavigationLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Loading />
      }
    ]
  },
  {
    path: 'top-navigation/dashboards',
    element: (
      <Authenticated>
        <TopNavigationLayout />
      </Authenticated>
    ),
    children: dashboardsChildren
  },
  {
    path: '/top-navigation/management',
    element: (
      <Authenticated>
        <TopNavigationLayout />
      </Authenticated>
    ),
    children: managementChildren
  },
  {
    path: '/top-navigation/applications',
    element: (
      <Authenticated>
        <TopNavigationLayout />
      </Authenticated>
    ),
    children: applicationsChildren
  },
  {
    path: '/top-navigation/successful-stripe',
    element: (
      <Authenticated>
        <TopNavigationLayout />
      </Authenticated>
    ),
    children: [
      {
        path: ":session_id",
        element: <SuccessFUllStripe />
      }
    ]
  },
  {
    path: '/top-navigation/un-successful-stripe',
    element: (
      <Authenticated>
        <TopNavigationLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to={`/top-navigation/dashboards/analytics`}
            replace
          />
        )
      },
    ]
  },
  {
    path: '/top-navigation/un-successful-stripe-services',
    element: (
      <Authenticated>
        <TopNavigationLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to={`/top-navigation/dashboards/services/analytics`}
            replace
          />
        )
      },
    ]
  },
  // Accent Header
  {
    path: '/accent-header/loading',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: <Loading />
      }
    ]
  },
  {
    path: '/accent-header/dashboards',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: dashboardsChildren
  },
  {
    path: '/accent-header/applications',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: applicationsChildren
  },
  {
    path: '/accent-header/management',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: managementChildren
  },
  {
    path: '/accent-header/successful-stripe',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: [
      {
        path: ":session_id",
        element: <SuccessFUllStripe />
      }
    ]
  },
  {
    path: '/accent-header/un-successful-stripe',
    element: (
      <Authenticated>
        <AccentHeaderLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to={`/accent-header/dashboards/analytics`}
            replace
          />
        )
      },
    ]
  },
  {
    path: '/accent-header/un-successful-stripe-services',
    element: (
      <Authenticated>
        <BoxedSidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '/',
        element: (
          <Navigate
            to={`/accent-header/dashboards/services/analytics`}
            replace
          />
        )
      },
    ]
  },
];

export default routes;