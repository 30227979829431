
import { useState, useEffect, useCallback } from 'react'
import { queryData, updateData, } from 'src/utils/firebase';

export const useFavoritesSection = () => {
    const restName = localStorage.getItem("restName") ?? ""
    const uidEmail = localStorage.getItem('uidEmail') ?? ""
    const [favorites, setFavorites] = useState<any>([])
    const [reload, setReload] = useState<boolean>(false)
    const getFavoritesSections = useCallback(async () => {
        try {
            if (reload) {
                setReload(false)
            }
            const dataPortalUsers = await queryData(`portalUsers/Restaurants/Users`)
            const docs = dataPortalUsers.docs
            let docUser = docs.find((doc) => doc.id === uidEmail)?.data()
            if (docUser) {
                if (!docUser.hasOwnProperty('favorites')) {
                    docUser = {
                        ...docUser, ...{
                            favorites: {
                                [restName]: []
                            }
                        }
                    }
                    await updateData(`portalUsers/Restaurants/Users`, uidEmail, docUser)
                } else {
                    if (!docUser.favorites[restName]) {
                        docUser.favorites[`${restName}`] = []
                        await updateData(`portalUsers/Restaurants/Users`, uidEmail, docUser)
                    }
                }
                setFavorites(docUser.favorites[restName])
            }
        } catch (err) {
            console.error(err);
        }
    }, [reload, restName, uidEmail]);

    useEffect(() => {
        getFavoritesSections();
    }, [getFavoritesSections]);

    return {
        favorites,
        setFavorites,
        reload,
        setReload
    }
}