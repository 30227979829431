import { useContext, useState } from 'react';

import { Box, Hidden, IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';
import ThemeSettings from 'src/components/ThemeSettings';

// import HeaderMenu from './Menu';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import FavoritesButton from 'src/components/Favorites';
import { useFavoritesSection } from 'src/hooks/useFavoritesSections';
import { useTranslation } from 'react-i18next';
import HelpSupport from 'src/components/HelpSupport';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
// import Logo from 'src/components/Logo';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.md}px) {
            left: 120px;
            width: auto;
        }
`
);

function Header(props) {

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  const { favorites } = useFavoritesSection()

  const { t }: { t: any } = useTranslation();
  const [open, setOpen] = useState(false)

  const helpSupport = () => {
    const uidEmail = localStorage.getItem('uidEmail') ?? ""
    if (uidEmail) {
      setOpen(true)
    }
  }
  const DialogHelpSupport = () => {
    return < HelpSupport isLogin={true} open={open} setOpen={setOpen} />
  }

  return (
    <HeaderWrapper display="flex" flexDirection={'column'} sx={{ paddingY: 0.5 }}>
      <Box display="flex" alignItems="center" justifyContent={mobile || favorites.length === 0 ? 'end' : 'space-between'}>
        {!mobile && (
          <FavoritesButton />
        )}
        <Box display="flex" alignItems="center">
          {open && DialogHelpSupport()}
          <Box paddingRight={0.5}>
            <Tooltip arrow title={t('Help')}>
              <IconButton color='primary' onClick={helpSupport}>
                <ContactSupportIcon fontSize='large' />
              </IconButton>
            </Tooltip>
          </Box>
          <ThemeSettings />
          <HeaderButtons />
          <HeaderUserbox profileImage={props.profileImage} />
          <Hidden mdUp>
            <Tooltip arrow title="Toggle Menu">
              <IconButton color="primary" onClick={toggleSidebar}>
                {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
              </IconButton>
            </Tooltip>
          </Hidden>
        </Box>
      </Box>
      {mobile && (
        <FavoritesButton />
      )}
    </HeaderWrapper>
  );
}

export default Header;
